import React, { useState } from 'react'

import { withTranslation } from 'react-i18next'

import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Tooltip from '@material-ui/core/Tooltip'

//Medium Modal
import MediumModal from '../../../components/Modals/MediumModal'
import StyledDialogTitle from '../../../components/StyledDialogTitle'
import StyledTextField from '../../../components/StyledTextField'
import MainButton from '../../../components/Buttons/MainButton'
import SecondaryButton from '../../../components/Buttons/SecondaryButton'

function AddItemLink({ sideBarLayout, t, onSubmit }) {
  const [newLink, setNewLink] = useState('')
  const [newLinkName, setNewLinkName] = useState('')
  const [isDialogOpen, setDialogOpen] = useState(false)

  const openLinkDialog = () => {
    setNewLink('')
    setNewLinkName('')
    setDialogOpen(true)
  }

  const closeLinkDialog = () => {
    setDialogOpen(false)
  }

  const addLinkItem = () => {
    const newWorkItem = {
      link: newLink.includes('https://') ? newLink : `https://${newLink}`,
      name: newLinkName,
      date: new Date(),
    }

    onSubmit(newWorkItem)

    closeLinkDialog()
  }

  return (
    <>
      <Tooltip title={t('workItemSubmenu.itemTooltip')} placement={'bottom'}>
        {sideBarLayout ? (
          <div className="submenu-head">
            {t('common.business.item')}
            <span className="invite-link" onClick={openLinkDialog}>
              {t('workItemSubmenu.add')}
            </span>
          </div>
        ) : (
          <div className="add-item-input">
            <span className="invite-link" onClick={openLinkDialog}>
              {t('addWorkItem.label')}
            </span>
          </div>
        )}
      </Tooltip>

      <MediumModal open={isDialogOpen}>
        <StyledDialogTitle>{t('workItemSubmenu.linkDialog.title')}</StyledDialogTitle>
        <DialogContent>
          <StyledTextField
            value={newLink}
            autoFocus={true}
            label={t('workItemSubmenu.link')}
            type="text"
            onChange={(event) => setNewLink(event.target.value)}
          />
          <StyledTextField
            value={newLinkName}
            label={t('workItemSubmenu.linkName')}
            type="text"
            onChange={(event) => setNewLinkName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={closeLinkDialog}>{t('common.button.cancel')}</SecondaryButton>
          <MainButton disabled={newLink === '' || newLinkName === ''} onClick={addLinkItem}>
            {t('common.button.add')}
          </MainButton>
        </DialogActions>
      </MediumModal>
    </>
  )
}

export default withTranslation('common')(AddItemLink)
