import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Lottie from 'react-lottie'
import { withTranslation } from 'react-i18next';

// Material UI
// import IconButton from "@material-ui/core/IconButton";
import { withStyles } from '@material-ui/core/styles'

import Tooltip from '@material-ui/core/Tooltip'
import Icon from '@material-ui/core/Icon'

// Custom Components
import PersonContainer from './PersonContainer'
import MainButton from '../../components/Buttons/MainButton'
import Invite from '../../containers/Invite'

// Helpers
import animationData from '../../assets/images/H-flier-man.json'
// import {
//   selectWorkspace,
//   selectMission,
//   selectMotion,
// } from "../../store/actions";

const styles = {
  button: {
    color: 'white',
    backgroundColor: '#3EC4E5',
    margin: '10px',
    boxShadow: 'none',
  },
}

class OrgMembers extends Component {
  userComponents = []

  constructor(props) {
    super(props)
    this.state = {
      // showEditOrgModal: false,
      // organizationName: "",
      // showConfirmDeleteOrgModal: false,
      // showManagePeopleDialog: false,
      dialogInviteUser: false,
      // tabValue: 0,
      // whatWeDo: "",
      // ShowEditPicture: false,
    }

    // this.handleSubmitConfirmDeleteOrgModal = this.handleSubmitConfirmDeleteOrgModal.bind(
    //   this
    // );
  }

  componentDidUpdate() {
    if (!_.isEmpty(this.props.users)) this.userComponents = this.getOrgUser()
    // console.log("user components", this.userComponents);
  }

  renderTooltip = (title) => {
    return (
      <Tooltip title={title} placement="top">
        <Icon style={{ fontSize: 14, color: 'grey' }}>info</Icon>
      </Tooltip>
    )
  }

  // Handling Invite Dialog
  openInviteUserDialog = () => {
    this.setState({ dialogInviteUser: true })
  }

  closeInviteUserDialog = () => {
    this.setState({ dialogInviteUser: false })
  }

  hideManagePeopleDialog = () => {
    this.setState({ showManagePeopleDialog: false })
  }

  getOrgUser() {
    const { users } = this.props

    if (!_.isEmpty(users)) {
      // create person components
      return Object.values(users).map((user, i) => {
        if (user) {
          return (
            <PersonContainer
              user={user}
              index={i}
              key={user.id}
              hideManagePeopleDialog={this.hideManagePeopleDialog}
            />
          )
        } else {
          return null
        }
      })
    } else return null
  }

  render() {
    const { activeOrg, t } = this.props
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }
    this.userComponents = (
      <div style={{ height: '20vh', width: '10vw', margin: '30px auto' }}>
        <Lottie options={defaultOptions} />
      </div>
    )
    if (!_.isEmpty(this.props.users)) this.userComponents = this.getOrgUser()

    return (
      <div className="org-member-container">
        <div className="org-members-header">
          <h3 className="action-header"> {t('orgMembers.members')} {this.renderTooltip(t('orgMembers.tooltip.title'))}</h3>
        </div>
        <div className="line-div" />
        <div className="member-invite">
          <MainButton
            variant="contained"
            // style={styles.button}
            className="create-button"
            onClick={this.openInviteUserDialog}
          >
            + {t('orgMembers.invite.people')}
          </MainButton>
        </div>
        <div className="members-container">
          <ul className="members">{this.userComponents}</ul>
        </div>
        <Invite
          dialogInviteUser={this.state.dialogInviteUser}
          closeInviteUserDialog={this.closeInviteUserDialog}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  // let orgOwner = null;
  // if (
  //   state.instance.org &&
  //   state.instance.org.owner &&
  //   state.instance.org.owner[0] &&
  //   state.database.orgInstanceUsers &&
  //   state.database.orgInstanceUsers[state.instance.org.owner[0]]){
  //     orgOwner = state.database.orgInstanceUsers[state.instance.org.owner[0]];
  //     orgOwner.id = state.instance.org.owner[0]
  // }

  return {
    //   auth: state.firebase.auth,
    activeOrg: state.instance.org,
    users: state.database.orgInstanceUsers,
    // orgOwner,
    //   previewImage: state.instance.userProfileImagePreview,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // hideProfileModel: () => dispatch(actions.showProfileModel(false)),
    // closePreview: () => dispatch(actions.closePreview()),
    // setPreview: preview => dispatch(actions.setPreview(preview)),
    // setLoadedFile: loadedFile => dispatch(actions.setLoadedFile(loadedFile)),
    // selectOrg: orgId => dispatch(actions.selectOrg(orgId)),
    // hideProfileModel: () => dispatch(showProfileModel(false)),
    // softDeleteOrg: org => dispatch(actions.softDeleteOrg(org)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation('common')(OrgMembers)))
