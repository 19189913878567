// Libraries
import React, { Component } from 'react'
import { auth } from '../../utils/firebase-service'
import { envEndpoint } from '../../utils/firebase-service'
import { withTranslation } from 'react-i18next'

// Material UI
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import { t } from 'i18next'

const style = {
  button: {
    backgroundColor: '#3EC4E5',
    color: 'white',
  },
}

class ForgotPassword extends Component {
  state = {
    resetEmail: '',
    errorMessage: '',
    error: false,
  }

  sendEmail = async () => {
    const { t } = this.props

    if (this.state.resetEmail !== '') {
      try {
        // await auth().sendPasswordResetEmail(this.state.resetEmail)
        await fetch(`${envEndpoint}/auth/resetPassword`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: this.state.resetEmail,
          }),
        })
        this.props.toggleResetEmailSent()
      } catch (err) {
        switch (err.code) {
          case 'auth/invalid-email':
            this.setState({ errorMessage: t('forgotPassword.invalidEmail'), error: true })
            return
          case 'auth/user-not-found':
            this.setState({ errorMessage: t('forgotPassword.userDontExist'), error: true })
            return
          default:
            this.setState({ errorMessage: t('forgotPassword.Error'), error: true })
        }
      }
    }
  }

  handleChange = (e) => {
    this.setState({
      resetEmail: e.target.value,
    })
  }

  keyPreLogin = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.sendEmail(e)
    }
  }

  handleClose = () => {
    this.setState({
      resetEmail: '',
      error: false,
    })
  }

  render() {
    const { t } = this.props

    return (
      <div className="workspace flex login-body">
        <div className="login-div">
          <div onClick={() => this.props.toggleForgotPassword()}>
            <p className="go-back">
              <span>&#8592;</span> {t('common.button.back')}
            </p>
          </div>

          <form>
            <div className="forgot-text">{t('forgotPassword.resetTitle')}</div>

            <input
              onChange={this.handleChange}
              value={this.state.resetEmail}
              onKeyPress={this.keyPreLogin}
              id="forgotEmail"
              className="login-input w-input"
              placeholder={t('forgotPassword.placeholder')}
            />

            <Button onClick={() => this.sendEmail()} variant="contained" style={style.button}>
              {t('forgotPassword.reset')}
            </Button>
          </form>
        </div>

        <Snackbar
          open={this.state.error}
          message={this.state.errorMessage}
          autoHideDuration={5000}
          onClose={this.handleClose}
        />
      </div>
    )
  }
}

export default withTranslation('common')(ForgotPassword)
