import * as actionTypes from "../actionTypes";

export function showAddNewOrgDialog() {
  return {
    type: actionTypes.SHOW_ADD_NEW_ORG_DIALOG,
  };
}

export function hideAddNewOrgDialog() {
    return {
      type: actionTypes.HIDE_ADD_NEW_ORG_DIALOG,
    };
}