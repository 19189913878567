// Libraries
import React, { Component } from 'react'

// Material UI Components
import Toolbar from '@material-ui/core/Toolbar'
// import { ToolbarSeparator } from "material-ui/Toolbar";

// Custom Components
import AddNewOrgDialog from './AddNewOrgDialog'
import OrgMenu from './OrgMenu'
import HelpMenu from './HelpMenu'
// import SettingsMenu from './SettingsMenu';
import UserMenu from './UserMenu'

//Material UI
import { withStyles } from '@material-ui/core/styles'

const styles = {
  toolbarlogin: {
    backgroundColor: '#333541',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    alignItems: 'center',
    height: 56,
    minHeight: 'auto',
  },
  button: {
    color: 'white',
    textTransform: 'capitalize',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 16,
  },
  gutter: {
    padding: '0px 35px 0px 35px',
  },
}

class NavBar extends Component {
  render() {
    const { classes } = this.props
    return (
      <Toolbar classes={{ root: classes.toolbarlogin, gutters: classes.gutter }}>
        <OrgMenu />
        <AddNewOrgDialog />
        {/* <Button style={styles.button} onClick={() => this.props.selectWorkspace('dashboard')}>
          My Dashboard
        <SettingsMenu/>
        </Button> */}

        <img
          alt="logo"
          className="logo logologin"
          src={require('../../assets/images/LOGO-white-gold@600x.png')}
        />

        <HelpMenu />
        <UserMenu />
      </Toolbar>
    )
  }
}

export default withStyles(styles)(NavBar)
