import React from "react";
import { useTranslation } from "react-i18next";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import SvgIcon from "@material-ui/core/SvgIcon";
import { langs } from '../../utils/i18n';

const style = {
  fontWeight: 700, 
  fontSize: '14px', 
  marginBottom: 0,
  marginRight: '10px'
}

function LanguageSwitcher(props) {
  const { t } = useTranslation('common');
  const {
    value, onChange
  } = props;
  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <label style={style}>{t('common.language')}: </label>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <Select
        disableUnderline 
        value={value}
        onChange={onChange}
      >
        {
            langs.map(lang => (
                <MenuItem
                    value={lang.key}
                    key={lang.key}
                >
                    <SvgIcon width="26" height="20" viewBox="0 0 26 20">
                      <lang.icon/>
                    </SvgIcon>
                </MenuItem>
            ))
        }
      </Select>
    </FormControl>
    </div>
  );
}
export default LanguageSwitcher;