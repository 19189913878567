// Libraries
import React, { Component } from "react";
import { connect } from 'react-redux';
import _ from "lodash";
import { withTranslation } from 'react-i18next';

// Material Icons
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";

// Helpers
import { selectMission, selectWorkspace } from "../../store/actions";

class DraftsMenu extends Component {
  getDrafts() {
    const { draftMissions, t } = this.props;

    if (_.isEmpty(draftMissions)) {
      return (
        <div className="emptystate">
          <li>
            {t('draftMenu.emptystate.li')}
          </li>
        </div>
      );
    }

    return draftMissions.map(mission => {
      return (
        <li className="list_item" key={mission.id}>
          <span onClick={() => { this.props.selectMission(mission); this.props.selectWorkspace('draft')}}>
            {` ${mission.name} `}
          </span>
          <div className="delete-draft-template-container">
            <Tooltip title={"Detele this draft"} placement={"bottom"}>
              <Icon onClick={() => this.props.openDeleteDialog(mission)}
                style={{ fontSize: 14, cursor: "pointer" }}
                className="material-icons">
                delete
              </Icon>
            </Tooltip>
          </div>
        </li>
      );
    });
  }

  render() {
    return (
      <div className="panel_section">
        <ul className="notification-list">{this.getDrafts()}</ul>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const filteredDraftMissions = state.database.orgInstanceDraftMissions.filter(mission => mission.creator === state.firebase.auth.uid);

  return {
    auth: state.firebase.auth,
    orgs: state.database.userOrgs,
    draftMissions: filteredDraftMissions,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectMission: mission => dispatch(selectMission(mission)),
    selectWorkspace: workspace => dispatch(selectWorkspace(workspace))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(DraftsMenu));
