import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { ReactComponent as Eng } from '../assets/images/icons/eng.svg'
import { ReactComponent as German } from '../assets/images/icons/german.svg'
import { ReactComponent as Ukranian } from '../assets/images/icons/ukraine.svg'
// import { ReactComponent as France } from '../assets/images/icons/french.svg';

const langs = [
  {
    key: 'en-GB',
    label: 'EN',
    icon: Eng,
  },
  {
    key: 'uk-UA',
    label: 'UA',
    icon: Ukranian,
  },
  // {
  //   key: 'fr-FR',
  //   label: 'FR',
  //   icon: France
  // },
  {
    key: 'de-DE',
    label: 'DE',
    icon: German,
  },
]

const fallbackLanguage = 'en-GB'
const supportedLanguages = ['en-GB', 'de-DE', 'uk-UA']

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: fallbackLanguage,
    debug: process.env.REACT_APP_ENV === 'development',
    supportedLngs: supportedLanguages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['localStorage'],
      lookupFromPathIndex: 0,
      caches: ['localStorage'],
    },
    keySeparator: false,
    load: 'currentOnly',
    returnObjects: true,
  })

export { langs }
export default i18n
