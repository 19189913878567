// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import GooglePicker from 'react-google-picker'
import { withTranslation } from 'react-i18next'

// Material UI
import { withStyles } from '@material-ui/core/styles'
// import TextField from "@material-ui/core/TextField";
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DatePicker from 'material-ui/DatePicker'
// import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
// import Checkbox from "@material-ui/core/Checkbox";
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Tooltip from '@material-ui/core/Tooltip'

import DropboxPicker from '../../components/DropboxPicker/DropboxPicker'

// Custom Material UI Components
import MainButton from '../../components/Buttons/MainButton'
import SecondaryButton from '../../components/Buttons/SecondaryButton'
import StyledTextField from '../../components/StyledTextField'

//Helpers
import { selectWorkspace, selectMission, selectMotion } from '../../store/actions'
import { addMission, addMotion, addWorkItem } from '../../utils/graphql-req'
// import motionPresets from '../../utils/motionPresets'
import { MuiThemeProvider, getMuiTheme } from 'material-ui/styles'

//Large Modal
import LargeModal from '../../components/Modals/LargeModal'
import AddItemLink from '../SideDrawerEdit/AddItemLink'

const mainMuiTheme = getMuiTheme({
  flatButton: {
    // buttonFilterColor: "transparent",
    primaryTextColor: '#333541',
    '&:hover': {
      buttonFilterColor: 'orange',
      primaryTextColor: '#3EC4E5',
    },
  },
  datePicker: {
    selectColor: '#3EC4E5',
    selectTextColor: '#333541',
    headerColor: '#333541',
  },
})

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 350,
    marginBottom: 5,
  },
  radio: {
    display: 'inline-block',
  },
  button: {
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: '#074880',
  },
  dialog: {
    width: '75%',
    textAlign: 'center',
    margin: 'auto',
    minHeight: 400,
  },
  checkBox: {
    width: 265,
    marginTop: 15,
    marginLeft: -5,
  },
  buttonRoot: {
    backgroundColor: '#EEF2E5',
    border: '1px solid #E1E8D0',
    '&:hover': {
      backgroundColor: '#E1E8D0',
      border: '1px solid #E1E8D0',
    },
  },
  centerTitle: {
    textAlign: 'center',
  },
  googlelogos: {
    height: '120px',
    width: '120px',
    margin: '10px',
    cursor: 'pointer',
  },
  '@global': {
    '.MuiStepIcon-root.MuiStepIcon-active': {
      color: '#B8DED5',
    },
    '.MuiStepIcon-root.MuiStepIcon-completed': {
      color: '#B8DED5',
    },
    '.MuiStepIcon-root': {
      color: '#4EA0A9',
    },
    '.MuiRadio-colorPrimary.Mui-checked': {
      color: '#3EC4E5',
    },
    '.MuiStepConnector-line': {
      borderColor: 'black',
    },
    //'.MuiButton-outlined':{
    // border:"1px solid #EEF2E5"
    // }
  },
})

class NewMissionModal extends Component {
  state = {
    startEndValue: 'start',
    winflowName: '',
    winflowDescription: '',
    blockchain: false,
    activeStep: 0,
    error: null,
    document: null,
    extensionIcon: 'document',
    pickerApiLoaded: false,
    oauthToken: null,
    clickedMotion: null,
    winflowDate: null,
  }

  toggleWinflow = (id) => {
    const {
      selectWorkspace,
      //selectWinflow
    } = this.props
    //selectWinflow(id);
    selectWorkspace('mission')
    this.props.toggleNewMissionDialog()
  }

  handleChange = (event) => {
    this.setState({ startEndValue: event.target.value })
  }

  handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked })
  }

  handleStepperNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }))
  }

  handleStepperBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }))
  }

  handleSubmitNewLink = (newLink) => {
    this.setState({
      document: newLink,
      error: null,
    })
  }

  extensionIconFinder = (name) => {
    name = name.name ? name.name : name
    if (name.isDir === true) {
      return 'folder'
    } else if (name.split('.').pop() === 'ppt' || name.split('.').pop() === 'pptx') {
      return 'powerpoint'
    } else if (name.split('.').pop() === 'xls' || name.split('.').pop() === 'xlsx') {
      return 'excel'
    } else if (name.split('.').pop() === 'doc' || name.split('.').pop() === 'docx') {
      return 'word'
    } else if (name.split('.').pop() === 'pdf') {
      return 'pdf'
    } else {
      return 'document'
    }
  }

  // changeDocInProcess = async (data) => {
  //   const { mission, motion } = this.props

  //   if (!mission || !motion || !data) return null

  //   const newWorkItem = {
  //     ...data,
  //     mission: mission.id,
  //     motion: [],
  //   }

  //   delete newWorkItem['isDir']
  //   delete newWorkItem['linkType']
  //   delete newWorkItem['id']
  //   delete newWorkItem['thumbnailLink']
  //   // console.log(newWorkItem)

  //   // push to DB
  //   await addWorkItem(newWorkItem)
  // }

  onSuccessDropboxChooser = (files) => {
    const doc = files[0]
    //console.log(doc)
    const extensionIconDropBox = this.extensionIconFinder(doc)

    this.setState({
      document: doc,
      extensionIcon: extensionIconDropBox,
      error: null,
    })

    // this.changeDocInProcess(doc)
  }

  onSuccessGooglePicker = (files) => {
    const doc = files.docs

    if (doc) {
      const file = doc[0]

      //Choose extenstion
      let extensionIconGoogle = ''
      let folder = 'false'
      if (file.type === 'folder') {
        extensionIconGoogle = 'folder'
        folder = 'true'
      } else if (file.serviceId === 'DoclistBlob') {
        extensionIconGoogle = this.extensionIconFinder(file.name)
        //console.log(extensionIconGoogle)
      } else if (file.serviceId === 'doc') {
        extensionIconGoogle = 'word'
      } else if (file.serviceId === 'spread') {
        extensionIconGoogle = 'excel'
      } else if (file.serviceId === 'pres') {
        extensionIconGoogle = 'powerpoint'
      } else {
        extensionIconGoogle = 'document'
      }

      const googleDoc = {
        bytes: file.sizeBytes,
        icon: file.iconUrl,
        isDir: folder,
        link: file.url,
        name: file.name,
      }

      this.setState({
        document: googleDoc,
        extensionIcon: extensionIconGoogle,
        error: null,
      })

      // this.changeDocInProcess(googleDoc)
    }
  }

  getButtons = () => {
    const { t } = this.props
    return t('motionPresets:items').map((item, i) => {
      return (
        <div className="grid20" key={item.name + i}>
          <Tooltip
            title={item.subheader ? item.subheader : t('common:newMissionModal.noSubheader')}
            placement="bottom"
          >
            <Button
              className="edit-microflow more-height"
              classes={{ root: this.props.classes.buttonRoot }}
              onClick={(e) => this.saveMotionToState(e, item)}
              variant={
                this.state.clickedMotion && this.state.clickedMotion.name === item.name
                  ? 'contained'
                  : 'outlined'
              }
            >
              {t(`motionPresets:${item.name}`)}
            </Button>
          </Tooltip>
        </div>
      )
    })
  }

  saveMotionToState = (e, value) => {
    this.setState({
      clickedMotion: value,
    })
  }

  createNewMission = async () => {
    //create new Winflow
    let newMission = {}
    newMission.name = this.state.winflowName
    newMission.description = this.state.winflowDescription
    newMission.blockchain = this.state.blockchain
    newMission.creator = this.props.auth.uid
    newMission.status = 'draft'
    newMission.members = [this.props.auth.uid]
    newMission.org = this.props.instance.org.id
    newMission.invitations = []

    // push to DB
    const mission = await addMission(newMission)

    //create new Motion
    let newMotion = {}
    newMotion.name = this.state.clickedMotion.name
    newMotion.org = this.props.instance.org.id
    newMotion.index = 0
    newMotion.mission = mission.id
    newMotion.missionStatus = 'draft'
    newMotion.motionStatus = 'draft'
    newMotion.private = false
    newMotion.makeActive = false
    newMotion.description = ''
    newMotion.subheader = ''
    if (this.state.startEndValue === 'start')
      newMotion.startDate = this.state.winflowDate.toString()
    else newMotion.endDate = this.state.winflowDate.toString()
    newMotion.members = this.state.clickedMotion.members
    newMotion.members[0].uid = this.props.auth.uid
    newMotion.comments = []

    // push to database
    const motion = await addMotion(newMotion)

    // create new workItem if one was added
    if (this.state.document) {
      const newWorkItem = { ...this.state.document }

      delete newWorkItem['isDir']
      delete newWorkItem['linkType']
      delete newWorkItem['thumbnailLink']
      delete newWorkItem['id']
      newWorkItem['mission'] = mission.id
      newWorkItem['motion'] = []

      // pust to database
      await addWorkItem(newWorkItem)
    }

    // cleanup
    this.props.selectMission(mission)
    this.props.selectMotion(motion)
    this.props.selectWorkspace('draft')
    this.props.toggleNewMissionDialog()
  }

  render() {
    const { classes, t } = this.props
    const { activeStep } = this.state
    return (
      <LargeModal modal="true" open={this.props.dialogNewMission} maxWidth="md" fullWidth={true}>
        <DialogTitle color="primary" id="form-dialog-title" className={classes.centerTitle}>
          {t('common:newMissionModal.title')}
        </DialogTitle>
        {activeStep === 0 && (
          <div className={classes.centerTitle}> {t('common:newMissionModal.step.0')}</div>
        )}
        {activeStep === 1 && (
          <div className={classes.centerTitle}> {t('common:newMissionModal.step.1')}</div>
        )}
        {activeStep === 2 && (
          <div className={classes.centerTitle}> {t('common:newMissionModal.step.2')}</div>
        )}
        <Stepper activeStep={activeStep} alternativeLabel>
          {t('common:newMissionModal.steps').map((label, index) => {
            const props = {}
            const labelProps = {}
            return (
              <Step key={label} {...props}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>

        <DialogContent>
          {activeStep === 0 && (
            <div className="flex-form">
              <div className="flex50">
                <Tooltip title={t('common:common.button.cancel')} placement={'bottom'}>
                  <i className="material-icons x-icon" onClick={this.props.toggleNewMissionDialog}>
                    clear
                  </i>
                </Tooltip>
                <StyledTextField
                  autoFocus={true}
                  id="standard-name"
                  label={t('common:common.label.whatMission')}
                  className={classes.textField}
                  value={this.state.winflowName}
                  maxLength={40}
                  onChange={(event) => this.setState({ winflowName: event.target.value })}
                  margin="normal"
                />
                <StyledTextField
                  id="standard-multiline-flexible"
                  label={t('common:newMissionModal.describeMission')}
                  multiline
                  rowsMax="4"
                  value={this.state.winflowDescription}
                  maxLength={58}
                  onChange={(event) => this.setState({ winflowDescription: event.target.value })}
                  className={classes.textField}
                  margin="normal"
                />
                <br />
              </div>
              <div className="flex50">
                <label className="label">{t('common:common.label.chooseOne')}:</label>

                <RadioGroup
                  row
                  aria-label="startend"
                  name="startend"
                  className={classes.group}
                  value={this.state.startEndValue}
                  onChange={this.handleChange}
                >
                  <FormControlLabel
                    value="start"
                    control={<Radio color="primary" />}
                    label={t('common:common.label.startDate')}
                  />
                  <FormControlLabel
                    value="end"
                    control={<Radio color="primary" />}
                    label={t('common:common.label.completionDate')}
                  />
                </RadioGroup>

                <label className="label">
                  {this.state.startEndValue === 'end'
                    ? t('common:common.label.shouldDone')
                    : t('common:common.label.shouldStart')}
                </label>

                <MuiThemeProvider muiTheme={mainMuiTheme}>
                  <DatePicker
                    firstDayOfWeek={0}
                    autoOk={true}
                    hintText={t('common:common.label.date')}
                    style={{ float: 'left' }}
                    locale={'en-US'}
                    onChange={(n, date) => this.setState({ winflowDate: date })}
                    value={this.state.winflowDate}
                  ></DatePicker>
                </MuiThemeProvider>
              </div>
            </div>
          )}

          {activeStep === 1 && (
            <div>
              <Tooltip title={t('common:common.button.cancel')} placement={'bottom'}>
                <i className="material-icons x-icon" onClick={this.props.toggleNewMissionDialog}>
                  clear
                </i>
              </Tooltip>
              <div className="edit-microflow-container">{this.getButtons()}</div>
            </div>
          )}

          {activeStep === 2 && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Tooltip title={t('common:common.button.cancel')} placement={'bottom'}>
                <i className="material-icons x-icon" onClick={this.props.toggleNewMissionDialog}>
                  clear
                </i>
              </Tooltip>

              <div className="storage-wrapper">
                <DropboxPicker onSuccess={(files) => this.onSuccessDropboxChooser(files)} />

                <AddItemLink onSubmit={this.handleSubmitNewLink} />

                {/* 
                  Google picker hidden until its fixed
                */}

                {/* <GooglePicker
                  clientId={
                    '632460170241-6jq1ihh6tf91r89qh0go8qvcfoc7sr8k.apps.googleusercontent.com'
                  }
                  developerKey={'AIzaSyDqy3cMYPpz5U01WG0WtvJpSs3BFrgDXe8'}
                  scope={['https://www.googleapis.com/auth/drive.readonly']}
                  onAuthenticate={(token) => console.warn('onAuthenticate: ' + token)}
                  onChange={(data) => this.onSuccessGooglePicker(data)}
                  multiselect={false}
                  navHidden={true}
                  authImmediate={false}
                  createPicker={(google, oauthToken) => {
                    const googleViewId = google.picker.ViewId.DOCS

                    const docsView = new google.picker.DocsView(googleViewId)
                      .setIncludeFolders(true)
                      .setSelectFolderEnabled(true)

                    const picker = new window.google.picker.PickerBuilder()
                      .addView(docsView)
                      .setOAuthToken(oauthToken)
                      .setDeveloperKey('AIzaSyDqy3cMYPpz5U01WG0WtvJpSs3BFrgDXe8')
                      .setCallback((data) => {
                        this.onSuccessGooglePicker(data)
                      })

                    picker.build().setVisible(true)
                  }}
                >
                  <div className="dropbox-button">
                    <Tooltip title={'Google Drive'} placement={'bottom'}>
                      <img
                        src={require('../../assets/images/googledrive.png')}
                        alt="require"
                        className={classes.googlelogos}
                      />
                    </Tooltip>
                  </div>
                </GooglePicker> */}
              </div>
              {!!this.state.document ? (
                <img
                  src={require(`../../assets/images/file-${this.state.extensionIcon}.png`)}
                  alt="Document Icon"
                />
              ) : (
                ''
              )}
              {!!this.state.document && (
                <div>
                  {this.state.document
                    ? this.state.document.name
                    : t('common:common.label.documentName')}
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions className="dialogActions">
          {activeStep === 0 && (
            <React.Fragment>
              <div>
                <MainButton
                  onClick={this.handleStepperNext}
                  disabled={!this.state.winflowName || !this.state.winflowDate}
                >
                  {t('common:common.button.next')}
                </MainButton>
              </div>
            </React.Fragment>
          )}

          {activeStep === 1 && (
            <React.Fragment>
              <div>
                <SecondaryButton onClick={this.handleStepperBack}>
                  {t('common:common.button.back')}
                </SecondaryButton>
                <MainButton onClick={this.handleStepperNext} disabled={!this.state.clickedMotion}>
                  {t('common:common.button.next')}
                </MainButton>
              </div>
            </React.Fragment>
          )}

          {activeStep === 2 && (
            <React.Fragment>
              <div>
                <SecondaryButton onClick={this.handleStepperBack}>
                  {t('common:common.button.back')}
                </SecondaryButton>
                <MainButton onClick={this.createNewMission}>
                  {t('common:common.button.create')}
                </MainButton>
              </div>
            </React.Fragment>
          )}
        </DialogActions>
      </LargeModal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    instance: state.instance,
    orgInstanceDraftMotions: state.database.orgInstanceDraftMotions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
    selectMission: (mission) => dispatch(selectMission(mission)),
    selectMotion: (motion) => dispatch(selectMotion(motion)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation(['common', 'motionPresets'])(NewMissionModal)))
