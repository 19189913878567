import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import DialogContentText from "@material-ui/core/DialogContentText";

const styles = {
  root: {}
};

const StyledDialogContentText = props => {
  const { classes } = props;

  return (
    <DialogContentText classes={{ root: classes.root }}>
      {props.children}
    </DialogContentText>
  );
};

StyledDialogContentText.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(StyledDialogContentText);
