import {
    ApolloClient, ApolloLink, HttpLink, InMemoryCache, split
  } from 'apollo-boost';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { getAccessToken } from './auth';
  
let httpUrl = 'http://localhost:4000/graphql';
let wsUrl = 'ws://localhost:4000/graphql';

if(process.env.REACT_APP_ENV === "testing"){
  httpUrl = 'https://backend.dev.hi-flier.com/graphql';
  wsUrl = 'wss://backend.dev.hi-flier.com/graphql';
}
if(process.env.REACT_APP_ENV === "non-prod-gcp"){
  httpUrl = 'https://backend.dev.hi-flier.com/graphql';
  wsUrl = 'wss://backend.dev.hi-flier.com/graphql';
}
if(process.env.REACT_APP_ENV === "stage"){
  httpUrl = 'https://stage-mcsaleipkq-ew.a.run.app/graphql';
  wsUrl = 'wss://stage-mcsaleipkq-ew.a.run.app/graphql';
}

if(process.env.REACT_APP_ENV === "production"){
  httpUrl = 'https://backend-qujhf7yzla-ew.a.run.app/graphql';
  wsUrl = 'wss://backend-qujhf7yzla-ew.a.run.app/graphql';
}

const httpLink = ApolloLink.from([
  new ApolloLink((operation, forward) => {
    const token = getAccessToken();
    if (token) {
      operation.setContext({headers: {'authorization': `Bearer ${token}`}});
    }
    return forward(operation);
  }),
  new HttpLink({uri: httpUrl})
]);

const wsLink = new WebSocketLink({ 
  uri: wsUrl, 
  options: { 
    connectionParams: () => ({
      accessToken: getAccessToken()
    }),
    lazy: true, 
    reconnect: true 
  }
});

function isSubscription(operation){
  const definition = getMainDefinition(operation.query);
  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
}

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: split(isSubscription, wsLink, httpLink),
  defaultOptions: {query: {fetchPolicy: 'no-cache'}}
});

export default client;
