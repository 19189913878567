import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MainButton from "../../components/Buttons/MainButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

class DeleteTemplateDialog extends Component {
  render() {
    const { t } = this.props;
    return (
      <Dialog
        open={this.props.showDeleteTemplateDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" >
        <DialogTitle id="alert-dialog-title">
          {t('template.delete.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('template.delete.description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={this.props.hideDeleteTemplateDialog} >
            {t('common.button.goBack')}
          </SecondaryButton>
          <MainButton onClick={this.props.deleteTemplate} >
            {t('common.button.confirm')}
          </MainButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation('common')(DeleteTemplateDialog);
