// Libraries
import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";

//Material UI
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const styles = {
	formControl: {
		width: "100%"
	},
  input:{
    backgroundColor:"white",
	border:"1px solid #333541",
	padding: "10px 8px"
  },
  '@global': {
    '.MuiInput-underline:after': {
      borderBottom:"none"
    },
    '.MuiInput-underline:before': {
      transition:"none"
    },
    '.MuiInput-underline:hover': {
      borderBottom:"none"
    },
  }
};

const StyledTextInput = props => {
  const { classes } = props;

  return (
  	<TextField
  		classes={{root:classes.formControl}}
  	  InputProps={{
  	    classes: {root: classes.input},
  	  }}
  	  value={props.value}
  	  onChange={props.onChange}
  	  placeholder={props.placeholder}
  	  onKeyDown={props.onKeyDown}
  	  multiline
  	  rowsMax="3"

  	/>
  );
};

StyledTextInput.propTypes = {
	classes: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
};

export default withStyles(styles)(Radium(StyledTextInput));