import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Search extends Component {
  state = {
    typed: '',
  }

  onSubmit = (e) => {
    e.preventDefault()
  }

  onChange = (event) => {
    if (typeof this.props.searchValue === 'function') {
      this.props.searchValue(event.target.value)
    }
    this.setState({ typed: event.target.value })
  }

  clearSearch = () => {
    this.searchInput.value = ''
    this.props.searchValue('')
    this.setState({ typed: '' })
  }

  render() {
    const { t } = this.props;
    return (
      <div className="form-block">
        <form onSubmit={this.onSubmit}>
          <input
            ref={(input) => (this.searchInput = input)}
            onChange={this.onChange}
            type="text"
            className="panel__search_field w-input"
            name="Search"
            placeholder={this.props.placeholder ? this.props.placeholder : t('search.missions')}
            required=""
          />
          {this.state.typed === '' ? (
            <img
              alt="search"
              src={require('../../assets/images/MagnifyGrey.png')}
              className="search-image"
            />
          ) : (
            <img
              alt="search"
              onClick={this.clearSearch}
              src={require('../../assets/images/CloseXGrey.png')}
              className="search-image clear"
            />
          )}
        </form>
        <div className="searchText">{t('search.sortby')}:</div>
      </div>
    )
  }
}

export default withTranslation('common')(Search)
