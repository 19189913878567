// Library Reducers
import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from 'redux-firestore';

// Custom Reducers
import auth from './auth';
import instance from './instance';
import database from './database';
import dialogs from './dialogs';

export default combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    auth,
    instance,
    dialogs,
    database
});