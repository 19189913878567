// Auth
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const IS_RESET_PASSWORD = "IS_RESET_PASSWORD";

// Instance
export const SELECT_WORKSPACE = "SELECT_WORKSPACE";
export const SELECT_ORG = "SELECT_ORG";
export const SELECT_MISSION = "SELECT_MISSION";
export const SELECT_MOTION = "SELECT_MOTION";
export const SHOW_PROFILE_MODEL = "SHOW_PROFILE_MODEL";
export const SHOW_PASSWORD_RESET_MODAL = "SHOW_PASSWORD_RESET_MODAL";
export const CLOSE_PREVIEW = "CLOSE_PREVIEW";
export const SET_PREVIEW = "SET_PREVIEW";
export const SET_LOADED_FILE = "SET_LOADED_FILE";
export const CLEAR_INSTANCE_DATA = "CLEAR_INSTANCE_DATA"

// Database
export const SET_USER_ORGS = "SET_USER_ORGS";
export const ON_USER_ORG_ADDED = "ON_USER_ORG_ADDED";
export const SET_USER = "SET_USER";
export const ON_USER_UPDATED = "ON_USER_UPDATED";
export const SET_ORG_INSTANCE_USERS = "SET_ORG_INSTANCE_USERS";
export const UPDATE_ORG_INSTANCE_USER = "UPDATE_ORG_INSTANCE_USER";
export const DELETE_USER_FROM_ORG_LOCAL = "DELETE_USER_FROM_ORG_LOCAL"
export const ON_ORG_MEMBER_UPDATED = "ON_ORG_MEMBER_UPDATED"

export const SET_ORG_INSTANCE_ACTIVE_MISSIONS = "SET_ORG_INSTANCE_ACTIVE_MISSIONS";
export const SET_ORG_INSTANCE_ALL_ACTIVE_MISSIONS = "SET_ORG_INSTANCE_ALL_ACTIVE_MISSIONS";
export const ON_ORG_INSTANCE_ACTIVE_MISSION_UPDATED = "ON_ORG_INSTANCE_ACTIVE_MISSION_UPDATED";
export const SET_ORG_INSTANCE_ACTIVE_MOTIONS = "SET_ORG_INSTANCE_ACTIVE_MOTIONS";
export const ON_ORG_INSTANCE_ACTIVE_MOTION_UPDATED = "ON_ORG_INSTANCE_ACTIVE_MOTION_UPDATED"

export const SET_ORG_INSTANCE_DRAFT_MISSIONS = "SET_ORG_INSTANCE_DRAFT_MISSIONS";
export const ON_ORG_INSTANCE_DRAFT_MISSION_UPDATED = "ON_ORG_INSTANCE_DRAFT_MISSION_UPDATED";
export const SET_ORG_INSTANCE_DRAFT_MOTIONS = "SET_ORG_INSTANCE_DRAFT_MOTIONS";
export const ON_ORG_INSTANCE_DRAFT_MOTION_UPDATED = "ON_ORG_INSTANCE_DRAFT_MOTION_UPDATED"

export const SET_ORG_INSTANCE_COMPLETE_MISSIONS = "SET_ORG_INSTANCE_COMPLETE_MISSIONS";
export const ON_ORG_INSTANCE_COMPLETE_MISSION_UPDATED = "ON_ORG_INSTANCE_COMPLETE_MISSION_UPDATED";
export const SET_ORG_INSTANCE_COMPLETE_MOTIONS = "SET_ORG_INSTANCE_COMPLETE_MOTIONS";
export const ON_ORG_INSTANCE_COMPLETE_MOTION_UPDATED = "ON_ORG_INSTANCE_COMPLETE_MOTION_UPDATED"

export const SET_ORG_INSTANCE_MISSION_TEMPLATES = "SET_ORG_INSTANCE_MISSION_TEMPLATES";
export const ON_ORG_INSTANCE_MISSION_TEMPLATES_UPDATED = "ON_ORG_INSTANCE_MISSION_TEMPLATES_UPDATED";

export const SET_LOGS = "SET_LOGS";
export const ON_LOGS_UPDATED = "ON_LOGS_UPDATED";

export const SET_WORK_ITEMS = "SET_WORK_ITEMS";
export const ON_WORK_ITEMS_UPDATED = "ON_WORK_ITEMS_UPDATED";

export const CLEAR_STORE = "CLEAR_STORE";
export const ORG_INSTANCE_CHANGE = "ORG_INSTANCE_CHANGE";

// Dialogs
export const SHOW_ADD_NEW_ORG_DIALOG = 'SHOW_ADD_NEW_ORG_DIALOG';
export const HIDE_ADD_NEW_ORG_DIALOG = 'HIDE_ADD_NEW_ORG_DIALOG';