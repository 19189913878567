import * as actionTypes from "../actionTypes";

export function setUserOrgs(orgs) {
  return {
    type: actionTypes.SET_USER_ORGS,
    payload: orgs
  };
}

export function onUserOrgAdded(org) {
    return {
      type: actionTypes.ON_USER_ORG_ADDED,
      payload: org
    };
}

export function setUser(user) {
  return {
    type: actionTypes.SET_USER,
    payload: user
  };
}

export function onUserDetailsUpdated(user) {
    return {
      type: actionTypes.ON_USER_UPDATED,
      payload: user
    };
}

export function setOrgInstanceUsers(users){
  return {
    type: actionTypes.SET_ORG_INSTANCE_USERS,
    payload: users
  }
}

export function updateSingleOrgInstanceUser(user){
  return {
    type: actionTypes.UPDATE_ORG_INSTANCE_USER,
    payload: user
  }
}

export function deleteUserFromOrgLocal(deletedOrg, user){
  return {
    type: actionTypes.DELETE_USER_FROM_ORG_LOCAL,
    payload: { deletedOrg, user }
  }
}

export function onOrgMemberUpdated(user) {
  return {
    type: actionTypes.ON_ORG_MEMBER_UPDATED,
    payload: user
  }
}

// Active Mission and Motions
export function setOrgInstanceActiveMissions(missions){
  return {
    type: actionTypes.SET_ORG_INSTANCE_ACTIVE_MISSIONS,
    payload: missions
  }
}

export function setOrgInstanceAllActiveMissions(missions){
  return {
    type: actionTypes.SET_ORG_INSTANCE_ALL_ACTIVE_MISSIONS,
    payload: missions
  }
}

export function onOrgInstanceActiveMissionUpdated(mission){
  return {
    type: actionTypes.ON_ORG_INSTANCE_ACTIVE_MISSION_UPDATED,
    payload: mission
  }
}

export function setOrgInstanceActiveMotions(motions){
  return {
    type: actionTypes.SET_ORG_INSTANCE_ACTIVE_MOTIONS,
    payload: motions
  }
}

export function onOrgInstanceActiveMotionUpdated(motion){
  return {
    type: actionTypes.ON_ORG_INSTANCE_ACTIVE_MOTION_UPDATED,
    payload: motion
  }
}

// Draft Mission and Motions
export function setOrgInstanceDraftMissions(missions){
  return {
    type: actionTypes.SET_ORG_INSTANCE_DRAFT_MISSIONS,
    payload: missions
  }
}

export function onOrgInstanceDraftMissionUpdated(mission){
  return {
    type: actionTypes.ON_ORG_INSTANCE_DRAFT_MISSION_UPDATED,
    payload: mission
  }
}

export function setOrgInstanceDraftMotions(motions){
  return {
    type: actionTypes.SET_ORG_INSTANCE_DRAFT_MOTIONS,
    payload: motions
  }
}

export function onOrgInstanceDraftMotionUpdated(motion){
  return {
    type: actionTypes.ON_ORG_INSTANCE_DRAFT_MOTION_UPDATED,
    payload: motion
  }
}

// Completed Mission and Motions
export function setOrgInstanceCompleteMissions(missions){
  return {
    type: actionTypes.SET_ORG_INSTANCE_COMPLETE_MISSIONS,
    payload: missions
  }
}

export function onOrgInstanceCompleteMissionUpdated(mission){
  return {
    type: actionTypes.ON_ORG_INSTANCE_COMPLETE_MISSION_UPDATED,
    payload: mission
  }
}

export function setOrgInstanceCompleteMotions(motions){
  return {
    type: actionTypes.SET_ORG_INSTANCE_COMPLETE_MOTIONS,
    payload: motions
  }
}

export function onOrgInstanceCompleteMotionUpdated(motion){
  return {
    type: actionTypes.ON_ORG_INSTANCE_COMPLETE_MOTION_UPDATED,
    payload: motion
  }
}

// Templates
export function setOrgInstanceMissionTemplate(missionTemplates){
  return {
    type: actionTypes.SET_ORG_INSTANCE_MISSION_TEMPLATES,
    payload: missionTemplates
  }
}

export function onOrgInstanceMissionTemplateUpdated(missionTemplate){
  return {
    type: actionTypes.ON_ORG_INSTANCE_MISSION_TEMPLATES_UPDATED,
    payload: missionTemplate
  }
}

// Logs
export function setLogs(logs) {
  return {
    type: actionTypes.SET_LOGS,
    payload: logs
  }
}

export function onLogChanged(log){
  return {
    type: actionTypes.ON_LOGS_UPDATED,
    payload: log
  }
}

// Work Items
export function setWorkItems(workItems) {
  return {
    type: actionTypes.SET_WORK_ITEMS,
    payload: workItems
  }
}

export function onWorkItemChanged(workItem){
  return {
    type: actionTypes.ON_WORK_ITEMS_UPDATED,
    payload: workItem
  }
}

export function orgInstanceChange() {
  return {
    type: actionTypes.ORG_INSTANCE_CHANGE
  };
}

export function clearStore() {
  return {
    type: actionTypes.CLEAR_STORE
  };
}