// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { get, cloneDeep } from 'lodash'
import AvatarEdit from 'react-avatar-edit'
import { RIEInput } from 'riek'
import { withTranslation } from 'react-i18next'
import { langs } from '../../utils/i18n'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import SettingsIcon from '@material-ui/icons/Settings'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Tooltip from '@material-ui/core/Tooltip'
import Icon from '@material-ui/core/Icon'
import SvgIcon from '@material-ui/core/SvgIcon'
import { grey } from '@material-ui/core/colors'
import PersonIcon from '@material-ui/icons/Person'

// Custom Material UI Components
import SidePanelButton from '../../components/Buttons/SidePanelButton'

// Local Components
import TabMenu from './TabMenu'
import Search from '../../components/Search'
import HelpMenuItem from '../../containers/Navbar/HelpMenu'
import DeleteDraftDialog from '../DraftSpace/DeleteDraftDialog'
import statsFlag from '../../assets/images/stats-flag-gold.svg'
import members from '../../assets/images/stats-person.svg'
import HiFlierMan from '../../assets/images/Hi-flier-man.png'

// Helpers
import * as actions from '../../store/actions'
import { selectMission, selectWorkspace } from '../../store/actions'
import { signOut, showProfileModel } from '../../store/actions'
import { deleteMission, addMotion, deleteMotion, updateUser } from '../../utils/graphql-req'

const styles = () => ({
  drawerPaper: {
    // marginTop: "56px",
    width: 240,
    backgroundColor: '#333541',
    overflowY: 'hidden',
    // height: "96%"
    padding: '0px 10px 55px',
  },
  white: {
    backgroundColor: '#fff',
  },
  dot: {
    height: '25px',
    width: '25px',
    backgroundColor: 'white',
    borderRadius: '50%',
    display: 'inline-block',
  },
  footerContainer: {
    position: 'absolute',
    bottom: '30px',
    color: 'white',
  },
  icon: {
    cursor: 'pointer',
    color: grey[200],
    '&:hover': {
      color: grey[400],
    },
  },
})

class SidePanel extends Component {
  state = {
    searchValue: '',
    valueSingle: '3',
    anchorEl: null,
    showDeleteDialog: false,
  }

  openDeleteDialog = (mission) => {
    this.setState({ showDeleteDialog: true, targetMission: mission })
  }

  hideDeleteDialog = () => {
    this.setState({ showDeleteDialog: false, targetMission: null })
  }

  getSearchValue = (value) => {
    this.setState({ searchValue: value })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleShowProfileModel = () => {
    this.handleClose()
    this.props.showProfileModel()
  }

  checkInput = (description) => {
    if (description.match(/^\s+$/) || description === '') {
      return false
    }
    return true
  }

  changeWhatIDo = (whatIDo) => {
    this.whatIDo = whatIDo.whatIDo
  }

  submitWhatIDo = async () => {
    // if (this.props.user && this.props.user.whatIDo) {
    //   delete this.props.user['__typename']
    //   await updateWhatIDo(this.props.user.id, this.whatIDo)
    // }

    const { auth, user } = this.props

    if (!auth.uid) return

    const updatedUser = cloneDeep(user)
    // updatedUser.avatar = user.avatar ? user.avatar : ''
    // updatedUser.fullName = this.fullName ? this.fullName : user.fullName
    // updatedUser.phoneNumber = this.phoneNumber ? this.phoneNumber : user.phoneNumber
    updatedUser.whatIDo = this.whatIDo ? this.whatIDo : user.whatIDo
    if (updatedUser['__typename']) delete updatedUser['__typename']
    if (updatedUser.invitations) {
      updatedUser.invitations.forEach((invitation) => {
        if (invitation['__typename']) delete invitation['__typename']
        if (invitation.mission && invitation.mission['__typename'])
          delete invitation.mission['__typename']
        if (invitation.org && invitation.org['__typename']) delete invitation.org['__typename']
      })
    }

    // push to DB (listeners will update the views)
    const result = await updateUser(updatedUser)
    this.props.updateUser(result)
  }

  deleteDraft = () => {
    const { activeMission, draftMissions, draftMotions } = this.props
    const missionToDelete = this.state.targetMission
    if (!missionToDelete) return null

    // delete mission
    const updatedMissionToDelete = cloneDeep(missionToDelete)
    delete updatedMissionToDelete['__typename']
    if (updatedMissionToDelete.invitations)
      updatedMissionToDelete.invitations.map((invite) => delete invite['__typename'])

    if (missionToDelete.id === activeMission.id) {
      let draftIndex = draftMissions.findIndex((mission) => activeMission.id === mission.id)
      if (draftMissions.length > 1) {
        if (draftIndex !== -1 && draftIndex !== draftMissions.length - 1) {
          this.props.selectMission(draftMissions[draftIndex + 1])
        } else {
          this.props.selectMission(draftMissions[0])
        }
      } else {
        this.props.selectMission(null)
      }
    }

    deleteMission(updatedMissionToDelete)

    // delete associated motions
    if (this.props.draftMotions && this.props.draftMotions[updatedMissionToDelete.id]) {
      const motionsToDelete = this.props.draftMotions[updatedMissionToDelete.id]
      motionsToDelete.forEach((motion) => {
        let motionToDelete = { ...motion }
        if (motionToDelete['__typename']) delete motionToDelete['__typename']
        if (motionToDelete.members) {
          motionToDelete.members.forEach((member) => {
            delete member['__typename']
          })
        }
        if (motionToDelete.comments) {
          motionToDelete.comments.forEach((comment) => {
            delete comment['__typename']
          })
        }
        deleteMotion(motionToDelete)
      })
    }

    // cleanup
    this.hideDeleteDialog()
  }

  render() {
    const {
      activeOrg,
      classes,
      closePreview,
      workspace,
      selectWorkspace,
      user,
      userOrgs,
      draftMissions,
      t,
      i18n,
    } = this.props
    const { searchValue, chipValue, anchorEl } = this.state

    // let myActiveMissionCount = 0;
    // if (this.props.orgs.length) {
    // this.props.orgs.forEach(org => myActiveMissionCount += org.orgMissions.length)
    // }
    // console.log("orgs", this.props.orgs)
    // console.log("mission count", myActiveMissionCount)

    let filterArea = null
    let plannedMissions = null

    if (workspace === 'complete' || workspace === 'mission') {
      filterArea = (
        <div className="drawer">
          <div
            className="missionFont"
            style={{
              // paddingBottom: '5rem',
              // height: '450px',
              overflowX: 'auto',
              overflowY: 'hidden',
            }}
          >
            <div className="panel__search_container shortsearch">
              <Search
                key={3}
                searchValue={this.getSearchValue}
                styles={{
                  margin: '0 auto',
                  backgroundColor: '#333541',
                  borderBottom: '1px solid black',
                  width: '90%',
                  borderRadius: '30px',
                }}
              />
            </div>
            <TabMenu
              chipValue={chipValue}
              searchValue={searchValue}
              handleChipChange={this.handleChipChange}
            />
          </div>
        </div>
      )
    }

    plannedMissions = draftMissions.map((mission, i) => {
      return (
        <div
          key={`${mission}-${i}`}
          style={{
            color: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            // paddingRight: '10px',
          }}
        >
          <div
            onClick={() => {
              this.props.selectMission(mission)
              this.props.selectWorkspace('draft')
            }}
            key={mission.id}
          >
            <div
              className={
                this.props.activeMission && mission.id === this.props.activeMission.id
                  ? 'winflow-links-bold list_item clickable'
                  : 'winflow-links list_item clickable'
              }
            >
              {` ${mission.name} `}
            </div>
          </div>
          <Icon
            onClick={() => this.openDeleteDialog(mission)}
            style={{ fontSize: 14, cursor: 'pointer' }}
            className="material-icons"
          >
            delete
          </Icon>
        </div>
      )
    })

    if (workspace === 'add' || workspace === 'draft') {
      filterArea = (
        <div
          className="missionFont"
          style={{
            // paddingBottom: '5rem',
            height: 'calc(100vh - 400px)',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {plannedMissions.length === 0 ? (
            <div style={{ color: 'white', textAlign: 'center' }}>{t('sidePanel.noMissions')}</div>
          ) : (
            plannedMissions
          )}
        </div>
      )
    }

    let sideBarContent
    if (this.props.workspace === 'home') {
      sideBarContent = (
        <div>
          <img
            alt="logo"
            src={require('../../assets/images/LOGO-white-gold@600x.png')}
            style={{ padding: '10px 0px' }}
          />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {_.get(user, 'avatar') ? (
              <div>
                <span>
                  <Tooltip title={t('common.button.update')} placement="top">
                    <img
                      className="mini-reload"
                      src={HiFlierMan}
                      onClick={() => {
                        window.location.reload()
                      }}
                    />
                  </Tooltip>
                  {/* <div
                    style={{ position: 'absolute', left: '190px', top: '110px', color: 'white' }}
                  >
                    Reload
                  </div> */}
                </span>
                <Tooltip title={t('sidePanel.mySetting')} placement="top">
                  <img
                    className="profile-picture"
                    src={_.get(user, 'avatar')}
                    alt="avatar"
                    onClick={this.handleShowProfileModel}
                  />
                </Tooltip>
                <SvgIcon
                  width="26"
                  height="20"
                  viewBox="0 0 26 20"
                  className="mini-lang"
                  component={langs.find((item) => item.key === i18n.language).icon}
                />
              </div>
            ) : (
              <div>
                <span>
                  <Tooltip title={t('common.button.update')} placement="top">
                    <img
                      className="mini-reload"
                      src={HiFlierMan}
                      onClick={() => {
                        window.location.reload()
                      }}
                    />
                  </Tooltip>
                  {/* <div
                    style={{ position: 'absolute', left: '190px', top: '110px', color: 'white' }}
                  >
                    Reload
                  </div> */}
                </span>
                <Tooltip title={t('sidePanel.mySetting')} placement="top">
                  <img
                    className="profile-picture"
                    src={members}
                    alt="avatar"
                    onClick={this.handleShowProfileModel}
                  />
                </Tooltip>
                <SvgIcon
                  width="26"
                  height="20"
                  viewBox="0 0 26 20"
                  className="mini-lang"
                  component={langs.find((item) => item.key === i18n.language).icon}
                />
              </div>
              // <PersonIcon className="profile-picture"></PersonIcon>
            )}
          </div>
          <div className="profile-info-container" style={{ padding: '0px 20px' }}>
            <h2
              style={{
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                // padding: '10px 0px',
              }}
            >
              {_.get(user, 'fullName')}
            </h2>
            <div style={{ color: 'white', display: 'flex', justifyContent: 'center' }}>
              {/* {_.get(user, 'whatIDo')} */}

              {user && (
                <>
                  <div style={{ textAlign: 'center' }}>
                    <RIEInput
                      value={user.whatIDo ? user.whatIDo : t('sidePanel.addWhatDo')}
                      change={this.changeWhatIDo.bind(this)}
                      afterFinish={this.submitWhatIDo.bind(this)}
                      propName="whatIDo"
                      className="sidebar-user-input"
                      validate={this.checkInput.bind(this)}
                      editProps={{ maxLength: '35' }}
                    />
                  </div>
                  <Tooltip title={t('sidePanel.clickToEdit')} placement="right">
                    <Icon
                      className={`material-icons small-space`}
                      style={{ fontSize: 13, zIndex: 11, color: grey }}
                    >
                      create
                    </Icon>
                  </Tooltip>
                </>
              )}
            </div>
            <div
              style={{
                margin: '15px 10px 0px',
                display: 'flex',
                justifyContent: 'center',
                // padding: '0px 10px',
              }}
            >
              <div>
                <img
                  src={statsFlag}
                  style={{ paddingTop: '3px', width: '65px', marginLeft: '10px' }}
                ></img>
                <div
                  style={{
                    position: 'relative',
                    bottom: '34px',
                    left: '15px',
                    fontSize: '21px',
                    width: '37px',
                    textAlign: 'center',
                  }}
                >
                  {this.props.myActiveMissionCount}
                </div>
                <div
                  style={{
                    color: 'white',
                    fontSize: '10px',
                    wordWrap: 'break-word',
                    // width: '70%',
                    paddingTop: '8px',
                    lineHeight: '14px',
                    position: 'relative',
                    bottom: '20px',
                    textAlign: 'center',
                    paddingRight: '8px',
                  }}
                >
                  {t('sidePanel.totalActive')}
                </div>
              </div>
              <div>
                <span
                  style={{
                    height: '48px',
                    width: '48px',
                    backgroundColor: '#f8bb3a',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '21px',
                    margin: '0px 14px',
                  }}
                >
                  {userOrgs.length}
                </span>
                <div
                  style={{
                    color: 'white',
                    fontSize: '10px',
                    wordWrap: 'break-word',
                    // width: '70%',
                    textAlign: 'center',
                    paddingTop: '8px',
                    lineHeight: '14px',
                  }}
                >
                  {t('organizations.upperCase')}
                </div>
              </div>
            </div>
          </div>
          {/* <SidePanelButton
            isActiveTab={workspace === "notifications"}
            onClick={() => selectWorkspace("notifications")}
          >
            Mission Control
          </SidePanelButton> */}
        </div>
      )
    } else {
      sideBarContent = (
        <div>
          <img
            alt="logo"
            src={require('../../assets/images/LOGO-white-gold@600x.png')}
            style={{ padding: '10px 0px' }}
          />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {activeOrg && activeOrg.logo ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {_.get(user, 'avatar') ? (
                  <Tooltip title={t('sidePanel.home')} placement="top">
                    <img
                      className="mini-profile-picture"
                      src={_.get(user, 'avatar')}
                      onClick={() => {
                        selectWorkspace('home')
                        // window.location.reload()
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={t('sidePanel.home')} placement="top">
                    <img
                      className="mini-profile-picture"
                      src={members}
                      onClick={() => {
                        selectWorkspace('home')
                        // window.location.reload()
                      }}
                    />
                  </Tooltip>
                  // <PersonIcon className="profile-picture"></PersonIcon>
                )}
                <img
                  className={
                    workspace === 'notifications' ? 'org-picture-notification' : 'org-picture'
                  }
                  src={_.get(activeOrg, 'logo')}
                  alt="organization logo"
                  onClick={() => selectWorkspace('notifications')}
                />
              </div>
            ) : (
              <div className={classes.settingsLogo}>
                {_.get(user, 'avatar') ? (
                  <Tooltip title={t('sidePanel.home')} placement="top">
                    <img
                      className="mini-profile-picture"
                      src={_.get(user, 'avatar')}
                      onClick={() => {
                        selectWorkspace('home')
                        // window.location.reload()
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={t('sidePanel.home')} placement="top">
                    <img
                      className="mini-profile-picture"
                      src={members}
                      onClick={() => {
                        selectWorkspace('home')
                        // window.location.reload()
                      }}
                    />
                  </Tooltip>
                  // <PersonIcon className="profile-picture"></PersonIcon>
                )}
                <div
                  className={
                    workspace === 'notifications' ? 'org-picture-notification' : 'org-picture'
                  }
                  onClick={() => selectWorkspace('notifications')}
                >
                  {activeOrg && activeOrg.name && activeOrg.name.charAt(0)}
                </div>
              </div>
            )}
          </div>
          <div className="profile-info-container">
            <h2
              style={{
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                wordBreak: 'break-word',
                hyphens: 'auto',
                overflowWrap: 'anywhere',
                // padding: '10px 0px',
              }}
            >
              {_.get(activeOrg, 'name')}
              {/* <SettingsIcon
                className={"clickable"}
                style={{ marginLeft: "10px" }}
              >
                {" "}
                Some Gear
              </SettingsIcon> */}
            </h2>
          </div>
          {/* <SidePanelButton
              isActiveTab={workspace === "home"}
              onClick={() => selectWorkspace("home")}>
              Home
            </SidePanelButton> */}

          {/* <SidePanelButton
            isActiveTab={workspace === "notifications"}
            onClick={() => selectWorkspace("notifications")}
          >
            Mission Control
          </SidePanelButton> */}

          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <SidePanelButton
              isActiveTab={workspace === "mission"}
              onClick={() => {
                selectWorkspace("mission");
              }}
            >
              {" "}
              {t('sidePanel.active')}{" "}
            </SidePanelButton>
            <SidePanelButton
              isActiveTab={workspace === "add" || workspace === "draft"}
              onClick={() => selectWorkspace("add")}
            >
              {" "}
              {t('sidePanel.planned')}{" "}
            </SidePanelButton>
            <SidePanelButton
              isActiveTab={workspace === "complete"}
              onClick={() => {
                selectWorkspace("complete");
              }}
            >
              {" "}
              {t('sidePanel.completed')}{" "}
            </SidePanelButton>
          </div> */}

          <div
            style={{
              display: 'flex',
              // justifyContent: 'space-between',
              marginTop: '20px',
              paddingBottom: '10px',
            }}
          >
            {/* <button className="side-panel-mission-buttons" onClick={() => selectWorkspace('draft')}>
              {' '}
              {t('sidePanel.planned')}{' '}
            </button>
            <button
              className="side-panel-mission-buttons"
              onClick={() => {
                selectWorkspace('mission')
              }}
            >
              {' '}
              {t('sidePanel.active')}{' '}
            </button>
            <button
              className="side-panel-mission-buttons"
              onClick={() => {
                selectWorkspace('complete')
              }}
            >
              {' '}
              {t('sidePanel.completed')}{' '}
            </button> */}
            <SidePanelButton
              isActiveTab={workspace === 'add' || workspace === 'draft'}
              className="side-panel-mission-buttons"
              onClick={() => selectWorkspace('draft')}
            >
              {t('sidePanel.planned')}
            </SidePanelButton>

            <SidePanelButton
              isActiveTab={workspace === 'mission'}
              className="side-panel-mission-buttons"
              onClick={() => {
                selectWorkspace('mission')
              }}
            >
              {t('sidePanel.active')}
            </SidePanelButton>

            <SidePanelButton
              isActiveTab={workspace === 'complete'}
              className="side-panel-mission-buttons"
              onClick={() => {
                selectWorkspace('complete')
              }}
            >
              {t('sidePanel.completed')}
            </SidePanelButton>
          </div>

          {filterArea}
        </div>
      )
    }

    return (
      <div>
        <Drawer variant="permanent" classes={{ paper: classes.drawerPaper }}>
          {/* <Divider 
          classes={{ root: classes.white }}
        />  */}

          {/* <img alt="logo"src={require("../../assets/images/preview-white.svg")}/>
      <div style={{display: "flex", justifyContent: "center"}}>
          {activeOrg && activeOrg.logo ? (
            
            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
              <img className="mini-profile-picture" src={_.get(user, "avatar")} onClick={() => selectWorkspace("home")}></img>
              <img className="org-picture" src={_.get(activeOrg, "logo")} alt="organization logo"/>
            </div>
          ) : (
            <div className={classes.settingsLogo}>
               <img className="mini-profile-picture" src={_.get(user, "avatar")} onClick={() => selectWorkspace("home")}></img>
              <div className="companyLogo" style={{border: "1px solid white", marginTop: "20px"}}>
                {activeOrg && activeOrg.name && activeOrg.name.charAt(0)}
            </div>
            </div>
          )}

        </div>
        <div className="profile-info-container">
            <div style={{color: "white", display: "flex", justifyContent: "center", paddingTop: "20px"}}>
              {_.get(activeOrg, "name")}
              <SettingsIcon style={{marginLeft: "10px"}}> Some Gear</SettingsIcon>
              </div>
        </div> */}
          {/* <SidePanelButton
          isActiveTab={workspace === "home"}
          onClick={() => selectWorkspace("home")}>
          Home
        </SidePanelButton> */}

          {/* <SidePanelButton
          isActiveTab={workspace === "notifications"}
          onClick={() => selectWorkspace("notifications")}>
          Mission Control
        </SidePanelButton> */}

          {/* <div style={{display: "flex", justifyContent: "space-evenly", marginTop: "20px", paddingBottom: "20px"}}>
          <button className="side-panel-mission-buttons"  onClick={() => selectWorkspace("draft")}> {t('sidePanel.planned')} </button>
          <button className="side-panel-mission-buttons"  onClick={() => { selectWorkspace("mission");}}> {t('sidePanel.active')} </button>
          <button className="side-panel-mission-buttons"  onClick={() => { selectWorkspace("complete");}}> {t('sidePanel.completed')} </button>
        </div> */}

          {/* 
        <SidePanelButton
          isActiveTab={workspace === "mission"} onClick={() => { selectWorkspace("mission");}}>
          &bull; {t('sidePanel.active')}
        </SidePanelButton>

        <SidePanelButton
          isActiveTab={workspace === "add" || (workspace === "draft")}
          onClick={() => selectWorkspace("add")}>
          &bull; {t('sidePanel.planned')}
        </SidePanelButton>

          <SidePanelButton
          isActiveTab={workspace === "complete"}
          onClick={() => { selectWorkspace("complete");
          }}>
          &bull; {t('sidePanel.completed')}
        </SidePanelButton> */}

          {/* <Divider 
          classes={{ root: classes.divider }}
        /> */}

          {/* { filterArea } */}

          {sideBarContent}
          <div
            style={{
              position: 'absolute',
              color: 'white',
              bottom: '0px',
              width: '95%',
              display: 'flex',
              justifyContent: 'space-evenly',
              marginTop: '5px',
              backgroundColor: '#333541',
              flexWrap: 'wrap',
            }}
          >
            {/* <Tooltip title={t('sidePanel.mySetting')} placement="top-start"> */}
            <div className="footer-buttons">
              <SettingsIcon
                className={classes.icon + ' material-icons ' + 'help-icon'}
                style={{ backgroundColor: '#333541' }}
                onClick={this.handleShowProfileModel}
              />
              <div style={{ lineHeight: '10px', fontSize: '1.2vh' }}>
                {t('sidePanel.mySetting')}
              </div>
            </div>
            {/* </Tooltip> */}
            <div className="footer-buttons">
              <HelpMenuItem />
              <div style={{ lineHeight: '10px', fontSize: '1.2vh' }}>{t('common.label.help')}</div>
            </div>
            {/* <button style={{backgroundColor: "#333541"}} onClick={this.props.signOut}> Signout</button> */}
            {/* <Tooltip title={t('sidePanel.logOut')} placement="top-start"> */}
            <div className="footer-buttons">
              <ExitToAppIcon
                className={classes.icon + ' material-icons ' + 'help-icon'}
                style={{}}
                onClick={this.props.signOut}
              />
              <div style={{ lineHeight: '10px', fontSize: '1.2vh' }}>{t('sidePanel.logOut')}</div>
            </div>
            {/* </Tooltip> */}
          </div>
        </Drawer>

        <DeleteDraftDialog
          showDeleteDialog={this.state.showDeleteDialog}
          hideDeleteDialog={this.hideDeleteDialog}
          deleteDraft={this.deleteDraft}
        />
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  const filteredDraftMissions = state.database.orgInstanceDraftMissions.filter(
    (mission) => mission.creator === state.firebase.auth.uid
  )
  return {
    activeOrg: state.instance.org,
    workspace: state.instance.workspace,
    user: state.database.userDetails,
    activeMission: state.instance.mission,
    userOrgs: state.database.userOrgs,
    draftMissions: filteredDraftMissions,
    draftMotions: state.database.orgInstanceDraftMotions,
    auth: state.firebase.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
    signOut: () => dispatch(signOut()),
    showProfileModel: () => dispatch(showProfileModel(true)),
    selectMission: (mission) => dispatch(selectMission(mission)),
    updateUser: (updatedUser) => dispatch(actions.updateSingleOrgInstanceUser(updatedUser)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation('common')(SidePanel)))
