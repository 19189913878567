// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Draggable } from 'react-beautiful-dnd'
import { get } from 'lodash'
import _ from 'lodash'

// Custom Components
import DeleteMotionDialog from './DeleteMotionDialog'
import LastMotionDialog from './LastMotionDialog'
import MotionCard from './MotionCard'
import Timeline from './Timeline'

// Helpers
import { userRoles } from '../../utils/handleRoles'
import { updateMotion, deleteMotion, updateWorkItem, addLog } from '../../utils/graphql-req'

const isRoleAccountable = (role) => get(userRoles, [role, 'roleGroup'], '') === 'accountable'

class Motion extends Component {
  state = {
    showDeleteMotionDialog: false,
    showLastMotionDialog: false,
  }

  showDeleteMotionDialog = () => {
    this.setState({ showDeleteMotionDialog: true })
  }

  hideDeleteMotionDialog = () => {
    this.setState({ showDeleteMotionDialog: false })
  }

  showLastMotionDialog = () => {
    this.setState({ showLastMotionDialog: true })
  }

  hideLastMotionDialog = () => {
    this.setState({ showLastMotionDialog: false })
  }

  deleteMotion = async (passedMotion) => {
    const { activeMissionMotions, activeMission, workItems, userDetails } = this.props

    if (activeMission.status === 'active') {
      var actionUser = userDetails.fullName ? userDetails.fullName : userDetails.email
      var logText = `${actionUser} removed ${passedMotion.name} from the Mission`

      const log = {
        mission: activeMission.id,
        date: Date().toString(),
        text: logText,
      }

      await addLog(log)
    }

    const updatedMotions = []
    activeMissionMotions.forEach((motion) => {
      if (motion.id !== passedMotion.id) {
        if (motion.index < passedMotion.index) {
          const motionCopy = _.cloneDeep(motion)
          if (motion.startDate) motionCopy.startDate = motion.startDate
          if (motion.endDate) motionCopy.endDate = motion.endDate
          updatedMotions.push(motionCopy)
        } else {
          const motionCopy = _.cloneDeep(motion)
          if (motion.startDate) motionCopy.startDate = motion.startDate
          if (motion.endDate) motionCopy.endDate = motion.endDate
          motionCopy.index = motion.index - 1
          updatedMotions.push(motionCopy)
        }
      }
    })

    updatedMotions.forEach(async (updatedMotion) => {
      if (updatedMotion['__typename']) delete updatedMotion['__typename']
      if (updatedMotion.members) {
        updatedMotion.members.forEach((member) => {
          if (member['__typename']) delete member['__typename']
        })
      }
      if (updatedMotion.comments) {
        updatedMotion.comments.forEach((comment) => {
          if (comment['__typename']) delete comment['__typename']
        })
      }
      await updateMotion(updatedMotion)
    })

    let motionToDelete = { ...passedMotion }
    if (motionToDelete['__typename']) delete motionToDelete['__typename']
    if (motionToDelete.members) {
      motionToDelete.members.forEach((member, index) => {
        delete motionToDelete.members[index]['__typename']
      })
    }

    workItems.forEach(async (workItem) => {
      const updatedWorkItem = { ...workItem }
      const targetIndex = updatedWorkItem.motion.findIndex((motion) => motion === motionToDelete.id)
      if (targetIndex !== -1) {
        updatedWorkItem.motion.splice(targetIndex, 1)
        if (updatedWorkItem['__typename']) delete updatedWorkItem['__typename']

        // push to db
        await updateWorkItem(updatedWorkItem)
      }
    })

    // var actionUser = userDetails.fullName ? userDetails.fullName : userDetails.email;
    // var logText = `${actionUser} removed ${passedMotion.name} from the Mission`;

    // const log = {
    //   mission: activeMission.id,
    //   date: Date().toString(),
    //   text: logText,
    // };

    // await addLog(log);

    await deleteMotion(motionToDelete)

    this.hideDeleteMotionDialog()
  }

  render() {
    const {
      // motion,
      // motions,
      passedMotion,
      // missions,
      activeMission,
      activeMotion,
      // missionId,
      // index,
      uid,
      auth,
    } = this.props

    let isOwner = false
    if (activeMission && activeMission.creator === uid) isOwner = true

    //only owners and A-types can mark private / owner and all in motion can view
    let canSetPrivateMotion = false

    const usersInMotion = get(passedMotion, ['members'], [])
    var isUserInMotion = false
    Object.values(usersInMotion).forEach((user) => {
      if (user.uid === auth.uid) isUserInMotion = true
    })

    let active = false
    if (passedMotion) {
      if (passedMotion.members) {
        Object.keys(passedMotion.members).forEach((memberIndex) => {
          if (
            (uid === passedMotion.members[memberIndex].uid &&
              isRoleAccountable(passedMotion.members[memberIndex].role)) ||
            isOwner
          ) {
            canSetPrivateMotion = true
          }
        })
      }
      // console.log(passedMotion)
      // console.log(activeMotion)
      active =
        passedMotion.id === activeMotion.id && (isUserInMotion || passedMotion.private !== true)
    } else {
      active = passedMotion.id === activeMotion.id && isUserInMotion
    }

    if (!passedMotion) return <div id="motionDeleting" />

    let timeline = <Timeline passedMotion={this.props.passedMotion} />
    if (!isUserInMotion && passedMotion.private) timeline = null

    return (
      <div>
        <DeleteMotionDialog
          showDeleteMotionDialog={this.state.showDeleteMotionDialog}
          hideDeleteMotionDialog={this.hideDeleteMotionDialog}
          deleteMotion={() => this.deleteMotion(passedMotion)}
        />

        <LastMotionDialog
          showLastMotionDialog={this.state.showLastMotionDialog}
          hideLastMotionDialog={this.hideLastMotionDialog}
        />

        <Draggable
          draggableId={passedMotion.id}
          index={passedMotion.index}
          isDragDisabled={
            !active ||
            (activeMission &&
              (activeMission.status === 'complete' || activeMission.status === 'cancelled')) ||
            !isOwner
          }
        >
          {(provided, snapshot) => (
            <div
              style={{ float: 'left' }}
              className={`microflowWrapper1 ${active && 'active'} ${
                snapshot.isDragging && 'isDragging'
              }`}
            >
              <div
                className={active ? 'microflowWrapper2 active ' : 'microflowWrapper2 '}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <MotionCard
                  active={active}
                  passedMotion={passedMotion}
                  showDeleteMotionDialog={this.showDeleteMotionDialog}
                  provided={provided}
                  snapshot={snapshot}
                  showLastMotionDialog={this.showLastMotionDialog}
                  canSetPrivateMotion={canSetPrivateMotion}
                />

                {timeline}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Draggable>
      </div>
    )
  }
}

const mapStateToProps = (state, passed) => {
  const { workspace } = state.instance
  const { passedMotion } = passed

  let activeMissionMotions = []
  if (workspace === 'draft') {
    activeMissionMotions = state.database.orgInstanceDraftMotions[state.instance.mission.id]
  } else if (workspace === 'mission') {
    activeMissionMotions = state.database.orgInstanceActiveMotions[state.instance.mission.id]
  } else if (workspace === 'complete') {
    activeMissionMotions = []
  }

  let workItems = []
  if (!_.isEmpty(state.database.workItems)) {
    state.database.workItems.forEach((workItem) => {
      if (workItem.motion.findIndex((motion) => motion === passedMotion.id) !== -1)
        workItems.push(workItem)
    })
  }

  return {
    auth: state.firebase.auth,
    uid: state.firebase.auth.uid,
    activeMission: state.instance.mission,
    activeMotion: state.instance.motion,
    userDetails: state.database.userDetails,
    activeMissionMotions,
    workItems,
  }
}

export default connect(mapStateToProps, null)(Motion)
