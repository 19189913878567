// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DragDropContext } from 'react-beautiful-dnd'
import _ from 'lodash'
import Lottie from 'react-lottie'
import animationData from '../../assets/images/H-flier-man.json'
import { withTranslation } from 'react-i18next';
// import shortid from 'shortid';

// Material UI
import Snackbar from '@material-ui/core/Snackbar'
import Fade from '@material-ui/core/Fade'

// Custom Components
import NavBar from '../../containers/Navbar'
import ProfileModel from '../../containers/ProfileModel'
import SidePanel from '../../containers/SidePanel'
import Notifications from '../NotificationSpace'
import DraftSpace from '../DraftSpace'
import DraftCompletionSpace from '../DraftCompletionSpace'
import ActiveSpace from '../ActiveSpace'
import CompleteSpace from '../CompleteSpace'
import ForgotPasswordProfile from '../ForgotPasswordProfile'
import Home from '../Home'

// Store
import {
  // workspace changes
  selectOrg,
  selectMission,
  selectMotion,

  // user
  setUser,
  onUserDetailsUpdated,
  setOrgInstanceUsers,
  onOrgMemberUpdated,

  // org
  setUserOrgs,
  onUserOrgAdded,

  // mission & motions
  setOrgInstanceActiveMissions,
  setOrgInstanceAllActiveMissions,
  onOrgInstanceActiveMissionUpdated,
  setOrgInstanceActiveMotions,
  onOrgInstanceActiveMotionUpdated,
  setOrgInstanceDraftMissions,
  onOrgInstanceDraftMissionUpdated,
  setOrgInstanceDraftMotions,
  onOrgInstanceDraftMotionUpdated,
  setOrgInstanceCompleteMissions,
  onOrgInstanceCompleteMissionUpdated,
  setOrgInstanceCompleteMotions,
  onOrgInstanceCompleteMotionUpdated,

  // Logs
  setLogs,
  onLogChanged,

  // Work Items
  setWorkItems,
  onWorkItemChanged,

  // Templates
  setOrgInstanceMissionTemplate,
  onOrgInstanceMissionTemplateUpdated,

  // Cleanup
  orgInstanceChange,
} from '../../store/actions'

// Utils
import { getAccessToken } from '../../utils/auth'
// import motionPresets from '../../utils/motionPresets'
import {
  // User DB calls
  getUser,
  onUserUpdated,
  onMemberUpdated,
  // updateUser,

  // Org DB calls
  getOrgMembers,
  getOrgs,
  onOrgAdded,
  addUserToInvitations,

  // Missions DB calls
  getOrgInstanceActiveMissions,
  getOrgInstanceAllActiveMissions,
  onOrgInstanceActiveMissionsUpdated,
  getOrgInstanceDraftMissions,
  onOrgInstanceDraftMissionsUpdated,
  getOrgInstanceCompleteMissions,
  onOrgInstanceCompleteMissionsUpdated,

  // Motions DB calls
  getOrgInstanceActiveMotions,
  getOrgInstanceDraftMotions,
  getOrgInstanceCompleteMotions,
  onOrgInstanceActiveMotionsUpdated,
  onOrgInstanceDraftMotionsUpdated,
  onOrgInstanceCompleteMotionsUpdated,
  updateMotionMember,
  addMotionMember,
  addMemberToMission,
  addMotion,
  updateMotion,

  // Templates
  getOrgInstanceMissionTemplates,
  onOrgInstanceMissionTemplatesUpdated,

  // Logs DB Calls
  getLogs,
  onLogUpdated,
  addLog,

  // Work Items DB calls
  getWorkItems,
  updateWorkItem,
  onWorkItemUpdated,
} from '../../utils/graphql-req'
import { envEndpoint } from '../../utils/firebase-service'

class Main extends Component {
  state = {
    showSnackbar: false,
    orgs: [],
    myActiveMissionCount: 0,
  }

  orgSubscription = null
  userSubscription = null
  orgMembersSubscription = null

  orgInstanceActiveMissionsSubscription = null
  orgInstanceDraftMissionsSubscription = null
  orgInstanceCompleteMissionsSubscription = null

  orgInstanceActiveMotionSubscription = null
  orgInstanceDraftMotionSubscription = null
  orgInstanceCompleteMotionSubscription = null
  orgInstanceMissionTemplateSubscription = null

  workItemSubscription = null
  logSubscription = null

  // Fetching checks
  updatingOrgInstanceActiveMissionsSubscription = false
  updatingOrgInstanceDraftMissionsSubscription = false
  updatingOrgInstanceCompleteMissionsSubscription = false

  instanceActiveMotionsFetching = false
  instanceDraftMotionsFetching = false
  instanceCompleteMotionsFetching = false

  updatingOrgInstanceMissionTemplates = false
  instanceUsersFetching = false
  instanceWorkItemsFetching = false
  instanceLogsFetching = false

  async componentDidMount() {
    const { auth, userOrgs, i18n } = this.props

    if (getAccessToken()) {
      const orgs = await getOrgs(auth.uid)
      this.props.setUserOrgs(orgs)

      this.orgSubscription = onOrgAdded(auth.uid, (org) => {
        //   console.log('org in main', org)
        if (this.props.activeOrg && this.props.activeOrg.id === org.id) this.props.selectOrg(org)
        this.props.onUserOrgAdded(org)
      })
    }

    if (getAccessToken()) {
      const user = await getUser(auth.uid)
      if (user.language && i18n.options.supportedLngs.includes(user.language)) {
        i18n.changeLanguage(user.language)
      } else {
        i18n.changeLanguage(i18n.options.fallbackLng[0])
      }
      this.props.setUser(user)
      this.userSubscription = onUserUpdated(auth.uid, (user) => {
        this.props.onUserDetailsUpdated(user)
      })
    }
    const state = await this.setStateFunction();
    await new Promise((resolve) => this.setState(state, () => resolve()))

    // this.setState({ orgs: this.collectOrgData() })
    // this.setState(
    // this.setStateFunction()
    // refreshChild: !this.state.refreshChild}, () => {console.log(this.state)
    // );
  }

  async setStateFunction(state, props) {
    const { auth, userOrgs } = this.props
    const newState = { ...state, orgs: await this.collectOrgData(auth, userOrgs) }
    return newState
  }

  async collectOrgData(auth, orgs) {
    // CUSTOM DATA STRUCTURE FOR HOME PAGE
    let orgData = []
    for (let i = 0; i < orgs.length; i++) {
      const org = orgs[i];
      const orgMissions = await getOrgInstanceActiveMissions(auth.uid, org.id)
      const allActiveMissions = await getOrgInstanceAllActiveMissions(org.id)
      const missionIds = orgMissions.map((mission) => mission.id)
      const orgMotions = await getOrgInstanceActiveMotions(missionIds, org.id)

      this.setState({
        myActiveMissionCount: (this.state.myActiveMissionCount += orgMissions.length),
      })

      let orgObj = {
        ...org,
        orgMissions: orgMissions,
        orgMotions: orgMotions,
        allActiveMissions: allActiveMissions.length,
      }
      orgData.push(orgObj)
    }
    return orgData
  }

  addNewOrg = (org) => {
    // console.log(state)
    // const { orgs } = this.state
    let orgObj = {
      ...org,
      orgMissions: [],
      orgMotions: [],
      allActiveMissions: 0,
    }

    let newOrgData = [...this.state.orgs, orgObj]
    this.setState({ orgs: newOrgData })
  }

  async componentDidUpdate(prevProps) {
    const { auth, activeOrg, activeMission, instanceActiveMissions, instanceAllActiveMissions } = this.props;
    const { orgs } = this.state;

    // return early if necessary
    if (!activeOrg) return
    // if (this.state.orgs !== userOrgs.length) return
    
    if (orgs.length > 0 && prevProps?.activeOrg?.id === activeOrg.id) {
      if (instanceAllActiveMissions && instanceAllActiveMissions?.length !== prevProps?.instanceAllActiveMissions?.length) {
        this.setState(prevState => {
          const currentOrgIndex = prevState.orgs.findIndex(item => item.id === activeOrg.id);
          const updatedOrg = [...prevState.orgs];
          updatedOrg[currentOrgIndex].allActiveMissions = instanceAllActiveMissions.length;
          return {...prevState, orgs: updatedOrg}
        })
      }
      if (instanceActiveMissions && instanceActiveMissions.length !== prevProps?.instanceActiveMissions.length) {
        const missionIds = instanceActiveMissions.map((mission) => mission.id);
        const orgMotions = await getOrgInstanceActiveMotions(missionIds, activeOrg.id);
        this.setState(prevState => {
          const currentOrgIndex = prevState.orgs.findIndex(item => item.id === activeOrg.id);
          const updatedOrg = [...prevState.orgs];
          const newActiveMissionCount = instanceActiveMissions.length - updatedOrg[currentOrgIndex].orgMissions.length;
          updatedOrg[currentOrgIndex].orgMissions = instanceActiveMissions;
          updatedOrg[currentOrgIndex].orgMotions = orgMotions;
          return { 
            ...prevState, 
            orgs: updatedOrg, 
            myActiveMissionCount: prevState.myActiveMissionCount + newActiveMissionCount
          }
        })
      }
      const currentOrgIndex = orgs.findIndex(item => item.id === activeOrg.id);
      if (activeOrg.name !== orgs[currentOrgIndex].name || 
        activeOrg.members.length !== orgs[currentOrgIndex].members.length) {
          this.setState(prevState => {
            const currentOrgIndex = prevState.orgs.findIndex(item => item.id === activeOrg.id);
            const updatedOrg = [...prevState.orgs];
            updatedOrg[currentOrgIndex] = {
              ...updatedOrg[currentOrgIndex],
              ...activeOrg,
            }
            return {...prevState, orgs: updatedOrg}
          })
      }
    }

    // These "IF" statements prevent multiple calls to the backend
    if (
      (!prevProps.activeOrg && activeOrg && activeOrg.id) ||
      (prevProps.activeOrg && prevProps.activeOrg.id !== activeOrg.id)
    ) {
      this.props.orgInstanceChange()

      // Reset mission && motion subscriptions
      if (this.orgInstanceActiveMissionsSubscription) {
        this.orgInstanceActiveMissionsSubscription.unsubscribe()
        this.orgInstanceActiveMissionsSubscription = null
      }

      if (this.orgInstanceDraftMissionsSubscription) {
        this.orgInstanceDraftMissionsSubscription.unsubscribe()
        this.orgInstanceDraftMissionsSubscription = null
      }

      if (this.orgInstanceCompleteMissionsSubscription) {
        this.orgInstanceCompleteMissionsSubscription.unsubscribe()
        this.orgInstanceCompleteMissionsSubscription = null
      }

      if (this.orgInstanceDraftMotionSubscription) {
        this.orgInstanceDraftMotionSubscription.unsubscribe()
        this.orgInstanceDraftMotionSubscription = null
      }

      if (this.orgInstanceActiveMotionSubscription) {
        this.orgInstanceActiveMotionSubscription.unsubscribe()
        this.orgInstanceActiveMotionSubscription = null
      }

      if (this.orgInstanceCompleteMotionSubscription) {
        this.orgInstanceCompleteMotionSubscription.unsubscribe()
        this.orgInstanceCompleteMotionSubscription = null
      }

      if (this.orgInstanceMissionTemplateSubscription) {
        this.orgInstanceMissionTemplateSubscription.unsubscribe()
        this.orgInstanceMissionTemplateSubscription = null
      }

      if (this.orgMembersSubscription) {
        this.orgMembersSubscription.unsubscribe();
        this.orgMembersSubscription = null;
      }

      // Fetches all active missions based on org instance
      if (
        !this.orgInstanceActiveMissionsSubscription &&
        !this.updatingOrgInstanceActiveMissionsSubscription &&
        getAccessToken()
      ) {
        this.updatingOrgInstanceActiveMissionsSubscription = true
        const allActiveMissions = await getOrgInstanceAllActiveMissions(activeOrg.id);
        this.props.setOrgInstanceAllActiveMissions(allActiveMissions);
        const orgInstanceActiveMissions = await getOrgInstanceActiveMissions(auth.uid, activeOrg.id);
        this.props.setOrgInstanceActiveMissions(orgInstanceActiveMissions)
        this.orgInstanceActiveMissionsSubscription = onOrgInstanceActiveMissionsUpdated(
          { orgId: activeOrg.id, uid: auth.uid },
          (mission) => {
            this.props.onOrgInstanceActiveMissionUpdated(mission)
            if (this.props.activeMission && this.props.activeMission.id === mission.id)
              this.props.selectMission(mission)
          }
        )
        this.updatingOrgInstanceActiveMissionsSubscription = false
      }

      // Fetches all draft missions based on org instance
      if (
        !this.orgInstanceDraftMissionsSubscription &&
        !this.updatingOrgInstanceDraftMissionsSubscription &&
        getAccessToken()
      ) {
        this.updatingOrgInstanceDraftMissionsSubscription = true
        const orgInstanceDraftMissions = await getOrgInstanceDraftMissions(auth.uid, activeOrg.id)
        orgInstanceDraftMissions.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
        this.props.setOrgInstanceDraftMissions(orgInstanceDraftMissions)
        this.orgInstanceDraftMissionsSubscription = onOrgInstanceDraftMissionsUpdated(
          { orgId: activeOrg.id, uid: auth.uid },
          (mission) => {
            this.props.onOrgInstanceDraftMissionUpdated(mission)
            if (this.props.activeMission && this.props.activeMission.id === mission.id)
              this.props.selectMission(mission)
          }
        )
        this.updatingOrgInstanceDraftMissionsSubscription = false
      }

      // Fetches all completed missions based on org instance
      if (
        !this.orgInstanceCompleteMissionsSubscription &&
        !this.updatingOrgInstanceCompleteMissionsSubscription &&
        getAccessToken()
      ) {
        this.updatingOrgInstanceCompleteMissionsSubscription = true
        const orgInstanceCompleteMissions = await getOrgInstanceCompleteMissions(
          auth.uid,
          activeOrg.id
        )
        this.props.setOrgInstanceCompleteMissions(orgInstanceCompleteMissions)
        this.orgInstanceCompleteMissionsSubscription = onOrgInstanceCompleteMissionsUpdated(
          { orgId: activeOrg.id, uid: auth.uid },
          (mission) => {
            this.props.onOrgInstanceCompleteMissionUpdated(mission)
            if (this.props.activeMission.id === mission.id) this.props.selectMission(mission)
          }
        )
        this.updatingOrgInstanceCompleteMissionsSubscription = false
      }

      // Fetches all mission templates based on org instance
      if (
        !this.orgInstanceMissionTemplateSubscription &&
        !this.updatingOrgInstanceMissionTemplates &&
        getAccessToken()
      ) {
        this.updatingOrgInstanceMissionTemplates = true
        const orgInstanceMissionTemplates = await getOrgInstanceMissionTemplates(activeOrg.id)
        this.props.setOrgInstanceMissionTemplate(orgInstanceMissionTemplates)
        this.orgInstanceMissionTemplateSubscription = onOrgInstanceMissionTemplatesUpdated(
          { orgId: activeOrg.id },
          (missionTemplate) => {
            this.props.onOrgInstanceMissionTemplateUpdated(missionTemplate)
          }
        )
        this.updatingOrgInstanceMissionTemplates = false
      }

      // These "IF" statements prevent multiple calls to the backend
      if (_.isEmpty(this.props.instanceActiveMotions) && !this.instanceActiveMotionsFetching) {
        this.instanceActiveMotionsFetching = true
        const missionIds = this.props.instanceActiveMissions.map((mission) => mission.id)
        const orgInstanceActiveMotions = await getOrgInstanceActiveMotions(missionIds, activeOrg.id)
        this.props.setOrgInstanceActiveMotions(orgInstanceActiveMotions)
        this.orgInstanceActiveMotionSubscription = onOrgInstanceActiveMotionsUpdated(
          { orgId: activeOrg.id, uid: auth.uid },
          (motion) => {
            this.props.onOrgInstanceActiveMotionUpdated(motion)
            if (this.props.activeMotion && this.props.activeMotion.id === motion.id)
              this.props.selectMotion(motion)
          }
        )
        this.instanceActiveMotionsFetching = false
      }

      // These "IF" statements prevent multiple calls to the backend
      if (_.isEmpty(this.props.instanceDraftMotions) && !this.instanceDraftMotionsFetching) {
        this.instanceDraftMotionsFetching = true
        const missionIds = this.props.instanceDraftMissions.map((mission) => mission.id)
        const orgInstanceDraftMotions = await getOrgInstanceDraftMotions(missionIds, activeOrg.id)
        this.props.setOrgInstanceDraftMotions(orgInstanceDraftMotions)
        this.orgInstanceDraftMotionSubscription = onOrgInstanceDraftMotionsUpdated(
          { orgId: activeOrg.id, uid: auth.uid },
          (motion) => {
            this.props.onOrgInstanceDraftMotionUpdated(motion)
            if (this.props.activeMotion && this.props.activeMotion.id === motion.id)
              this.props.selectMotion(motion)
          }
        )
        this.instanceDraftMotionsFetching = false
      }

      // These "IF" statements prevent multiple calls to the backend
      if (_.isEmpty(this.props.instanceCompleteMotions) && !this.instanceCompleteMotionsFetching) {
        this.instanceCompleteMotionsFetching = true
        const missionIds = this.props.instanceCompleteMissions.map((mission) => mission.id)
        const orgInstanceCompleteMotions = await getOrgInstanceCompleteMotions(
          missionIds,
          activeOrg.id
        )
        this.props.setOrgInstanceCompleteMotions(orgInstanceCompleteMotions)
        this.orgInstanceCompleteMotionSubscription = onOrgInstanceCompleteMotionsUpdated(
          { orgId: activeOrg.id, uid: auth.uid },
          (motion) => {
            this.props.onOrgInstanceCompleteMotionUpdated(motion)
            if (this.props.activeMotion && this.props.activeMotion.id === motion.id)
              this.props.selectMotion(motion)
          }
        )
        this.instanceCompleteMotionsFetching = false
      }

      // Fetches all users based on active org
      if (
        _.isEmpty(this.props.instanceUsers) &&
        getAccessToken() &&
        !this.instanceUsersFetching &&
        activeOrg.members
      ) {
        this.instanceUsersFetching = true
        const users = await getOrgMembers(activeOrg.members);
        const ownIndx = users.findIndex(user => activeOrg.owner.find(own => user.id === own));
        const slicedUsers = users.splice(ownIndx, 1);
        const usersObj = {};
        [...slicedUsers, ...users].forEach(item => {usersObj[item.id] = item });
        this.props.setOrgInstanceUsers(usersObj)
        this.instanceUsersFetching = false
        if (this.orgMembersSubscription) {
          this.orgMembersSubscription.unsubscribe();
          this.orgMembersSubscription = null;
        }
        this.orgMembersSubscription = onMemberUpdated(
          { userId: auth.uid, members: activeOrg.members }, (user) => {
          this.props.onOrgMemberUpdated(user);
        })
      }
    }

    // new member added to active org
    if (
      activeOrg &&
      activeOrg.members &&
      prevProps.activeOrg &&
      prevProps.activeOrg.members &&
      !_.isEqual(activeOrg.members, prevProps.activeOrg.members) &&
      activeOrg.id === prevProps.activeOrg.id
    ) {
      if (getAccessToken() && !this.instanceUsersFetching && activeOrg.members) {
        this.instanceUsersFetching = true
        const users = await getOrgMembers(activeOrg.members);
        const ownIndx = users.findIndex(user => activeOrg.owner.find(own => user.id === own));
        const slicedUsers = users.splice(ownIndx, 1);
        const usersObj = {};
        [...slicedUsers, ...users].forEach(item => {usersObj[item.id] = item });
        this.props.setOrgInstanceUsers(usersObj)
        this.instanceUsersFetching = false;
        if (this.orgMembersSubscription) {
          this.orgMembersSubscription.unsubscribe();
          this.orgMembersSubscription = null;
        }
        this.orgMembersSubscription = onMemberUpdated(
          { userId: auth.uid, members: activeOrg.members }, (user) => {
          this.props.onOrgMemberUpdated(user);
        })
      }
    }

    if (
      (!prevProps.activeMission && activeMission && activeMission.id) ||
      (prevProps.activeMission && activeMission && prevProps.activeMission.id !== activeMission.id)
    ) {
      // Fetches all work items based on active mission
      if (!this.instanceWorkItemsFetching && this.props.activeMission?.id) {
        this.instanceWorkItemsFetching = true
        const workItems = await getWorkItems(this.props.activeMission.id)
        this.props.setWorkItems(workItems)
        this.workItemSubscription = onWorkItemUpdated(this.props.activeMission.id, (workItem) => {
          this.props.onWorkItemChanged(workItem)
        })
        this.instanceWorkItemsFetching = false
      }

      // Fetches all logs based on active mission
      if (!this.instanceLogsFetching) {
        this.instanceLogsFetching = true
        const logs = await getLogs(this.props.activeMission.id)
        this.props.setLogs(logs)
        this.logSubscription = onLogUpdated(this.props.activeMission.id, (workItem) => {
          this.props.onLogChanged(workItem)
        })
        this.instanceLogsFetching = false
      }
    }
  }

  componentWillUnmount() {
    if (this.orgSubscription) this.orgSubscription.unsubscribe()
    if (this.userSubscription) this.userSubscription.unsubscribe()
    if (this.orgMembersSubscription) this.orgMembersSubscription.unsubscribe()
    if (this.orgInstanceActiveMissionsSubscription)
      this.orgInstanceActiveMissionsSubscription.unsubscribe()
    if (this.orgInstanceDraftMissionsSubscription)
      this.orgInstanceDraftMissionsSubscription.unsubscribe()
    if (this.orgInstanceActiveMotionSubscription)
      this.orgInstanceActiveMotionSubscription.unsubscribe()
    if (this.orgInstanceDraftMotionSubscription)
      this.orgInstanceDraftMotionSubscription.unsubscribe()
    if (this.orgInstanceMissionTemplateSubscription)
      this.orgInstanceMissionTemplateSubscription.unsubscribe()
  }

  onDragEnd = (result) => {
    if (!result.destination) return
    switch (result.type) {
      case 'USER':
        return this.userDrag(result)
      case 'DOC':
        return this.docDrag(result)
      case 'MOTION':
        return this.motionDrag(result)
      default:
        return
    }
  }

  userDrag = async (result) => {
    // if dropped back into sidedrawer, do nothing and return early
    if (result.destination.droppableId === 'SIDE_DRAWER') return

    const { activeMission, userDetails, activeOrg, activeMotion } = this.props
    const [
      motionId,
      assignmentIndex,
      oldMemberId,
      activeMemberId,
      role,
      status,
    ] = result.destination.droppableId.split('/')
    const newMemberId = result.draggableId
    // console.log("result", result)

    const response = await fetch(`${envEndpoint}/auth/getUser`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId: result.draggableId }),
    })

    if (response.status !== 200 && response.status !== 201) {
      console.log('Error!')
      throw new Error('Something Went Wrong!')
    }

    const { reqUser } = await response.json()

    if (oldMemberId === 'null' && role === 'null') {
      const objectToAdd = {
        uid: newMemberId,
        index: activeMotion.members.length,
        status: null,
        role: null,
      }
      await addMotionMember(motionId, objectToAdd)
    } else if (oldMemberId && oldMemberId === newMemberId) {
      // same users is taking on the role, nothing need to change, return early
      return null
    } else {
      const objectToDelete = {}
      if (assignmentIndex) objectToDelete['index'] = parseInt(assignmentIndex, 10)
      // update role
      if (role !== 'null') objectToDelete['role'] = role
      else objectToDelete['role'] = null

      // update uid
      if (oldMemberId !== 'null') objectToDelete['uid'] = oldMemberId
      else objectToDelete['uid'] = null

      // update status
      if (status !== 'null') objectToDelete['status'] = status
      else objectToDelete['status'] = null

      const objectToAdd = { ...objectToDelete }
      objectToAdd['uid'] = newMemberId

      if (activeMission.status === 'active') {
        var actionUser = userDetails.fullName ? userDetails.fullName : userDetails.email
        var impactedUser = reqUser.fullName ? reqUser.fullName : reqUser.email
        var logText = `${actionUser} added ${impactedUser} to ${activeMotion.name} ${activeMotion.subheader}`

        const log = {
          mission: activeMission.id,
          date: Date().toString(),
          text: logText,
        }

        await addLog(log)
      }

      // updated DB
      await updateMotionMember(motionId, objectToAdd, objectToDelete)
    }

    // *******
    //  TODO: implemnemnt User Invitation
    // ******
    //only if user adds a different user
    //need to add condition to not add user if they are already invited
    if (
      result.draggableId !== activeMemberId &&
      activeMission.members.findIndex((member) => member === result.draggableId) === -1
    ) {
      // add this user to the mission's member
      const invitation = {
        invitationDate: Date().toString(),
        inviter: userDetails.fullName,
        userId: result.draggableId,
      }
      // mission: {
      //     id: activeMission.id,
      //     name: activeMission.name
      // },
      // org: {
      //     id: activeOrg.id,
      //     name: activeOrg.name
      // }

      // console.log(result)

      // send email notification to new user added to mission after mission has been started
      if (activeMission.status === 'active') {
        // const response = await fetch(`${envEndpoint}/auth/getUser`, {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({ userId: result.draggableId }),
        // });

        // if (response.status !== 200 && response.status !== 201) {
        //   console.log("Error!");
        //   throw new Error("Something Went Wrong!");
        // }

        // const { reqUser } = await response.json();
        // console.log(missionUser);

        // const { org, user } = this.props;

        let inviteDetails = {
          address: reqUser.email,
          invited: reqUser.fullName,
          inviter: userDetails.fullName,
          mission: activeMission.name,
          org: activeOrg.name,
        }

        // console.log('is user placeholder after Start?', reqUser);

        if (reqUser.placeholder === true) {
          await fetch(`${envEndpoint}/invites/sendStartMissionNewHF`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              inviteDetails: inviteDetails,
            }),
          })
        } else {
          await fetch(`${envEndpoint}/invites/sendStartMission`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              inviteDetails: inviteDetails,
            }),
          })
        }
      }

      await addUserToInvitations(activeMission.id, invitation)
      await addMemberToMission(activeMission.id, result.draggableId)
    }
  }

  motionDrag = async (result) => {
    const {
      activeOrg,
      activeMission,
      activeMissionMotions,
      auth,
      workspace,
      userDetails,
      t,
    } = this.props
    let actionUser
    let logText

    // Grabbing item from sidedrawer and dropping it back into the sidedrawer
    if (
      result.destination.droppableId === 'SIDE_DRAWER' &&
      result.source.droppableId === 'SIDE_DRAWER'
    )
      return
    // Grabbing item from sidedrawer and dropping it into the motions container
    else if (
      result.destination.droppableId !== 'SIDE_DRAWER' &&
      result.source.droppableId === 'SIDE_DRAWER'
    ) {
      const updatedMotions = []
      activeMissionMotions.forEach((motion) => {
        if (motion.index < result.destination.index) {
          const motionCopy = _.cloneDeep(motion)
          if (motion.startDate) motionCopy.startDate = motion.startDate
          if (motion.endDate) motionCopy.endDate = motion.endDate
          updatedMotions.push(motionCopy)
        } else {
          const motionCopy = _.cloneDeep(motion)
          if (motion.startDate) motionCopy.startDate = motion.startDate
          if (motion.endDate) motionCopy.endDate = motion.endDate
          motionCopy.index = motion.index + 1
          updatedMotions.push(motionCopy)
        }
      })

      updatedMotions.forEach(async (updatedMotion) => {
        if (updatedMotion['__typename']) delete updatedMotion['__typename']
        if (updatedMotion.members) {
          updatedMotion.members.forEach((member) => {
            if (member['__typename']) delete member['__typename']
          })
        }
        if (updatedMotion.comments) {
          updatedMotion.comments.forEach((comment) => {
            if (comment['__typename']) delete comment['__typename']
          })
        }
        await updateMotion(updatedMotion)
      })
      const motionPresets = t('motionPresets:items');
      // create new motion
      const newMotion = {
        org: activeOrg.id,
        mission: activeMission.id,
        subheader: '',
        description: '',
        endDate: null,
        index: result.destination.index,
        name: motionPresets[result.source.index].name,
        missionStatus: activeMission.status,
        startDate: null,
        private: false,
        members: motionPresets[result.source.index].members,
      }

      if (workspace === 'draft' || workspace === 'add') {
        newMotion['motionStatus'] = 'draft'
      } else {
        newMotion['motionStatus'] = 'active'
      }

      //Make creator take on first responsibility
      Object.values(newMotion.members).forEach((member, i) => {
        if (i === 0) newMotion.members[i].uid = auth.uid
      })

      // Add new motion to DB
      await addMotion(newMotion)

      if (activeMission.status === 'active') {
        actionUser = userDetails.fullName ? userDetails.fullName : userDetails.email
        logText = `${actionUser} added ${newMotion.name} to the Mission`

        const log = {
          mission: activeMission.id,
          date: Date().toString(),
          text: logText,
        }

        await addLog(log)
      }

      return
    } else if (
      result.destination.droppableId !== 'SIDE_DRAWER' &&
      result.source.droppableId !== 'SIDE_DRAWER'
    ) {
      // If motion moved to the same spot, do nothing and exit early
      if (result.destination.index === result.source.index) return

      // if motion was reordered for logging purposes
      if (result.destination.index !== result.source.index && activeMission.status === 'active') {
        actionUser = userDetails.fullName ? userDetails.fullName : userDetails.email
        logText = `${actionUser} reordered the motions in the Mission`

        console.log(Date().toString())
        const log = {
          mission: activeMission.id,
          date: Date().toString(),
          text: logText,
        }

        await addLog(log)
      }

      // motion indexes might have changed
      const updatedMotions = []
      activeMissionMotions.forEach(async (motion) => {
        //going to move motion to the right
        if (result.destination.index > result.source.index) {
          if (motion.index === result.source.index) {
            const updatedMotion = _.cloneDeep(motion)
            updatedMotion.index = result.destination.index
            updatedMotions.push(updatedMotion)
          } else if (motion.index > result.destination.index) {
            const updatedMotion = _.cloneDeep(motion)
            updatedMotions.push(updatedMotion)
          } else if (motion.index < result.source.index) {
            const updatedMotion = _.cloneDeep(motion)
            updatedMotions.push(updatedMotion)
          } else {
            const updatedMotion = _.cloneDeep(motion)
            const newMotionIndex = updatedMotion.index - 1
            updatedMotion.index = newMotionIndex
            updatedMotions.push(updatedMotion)
          }
        }

        //going to move the motion to the left
        if (result.destination.index < result.source.index) {
          if (motion.index === result.source.index) {
            const updatedMotion = _.cloneDeep(motion)
            updatedMotion.index = result.destination.index
            updatedMotions.push(updatedMotion)
          } else if (motion.index < result.destination.index) {
            const updatedMotion = _.cloneDeep(motion)
            updatedMotions.push(updatedMotion)
          } else if (motion.index > result.source.index) {
            const updatedMotion = _.cloneDeep(motion)
            updatedMotions.push(updatedMotion)
          } else {
            const updatedMotion = _.cloneDeep(motion)
            const newMotionIndex = updatedMotion.index + 1
            updatedMotion.index = newMotionIndex
            updatedMotions.push(updatedMotion)
          }
        }
      })

      updatedMotions.forEach(async (updatedMotion) => {
        if (updatedMotion['__typename']) delete updatedMotion['__typename']
        if (updatedMotion.members) {
          updatedMotion.members.forEach((member) => {
            if (member['__typename']) delete member['__typename']
          })
        }
        if (updatedMotion.comments) {
          updatedMotion.comments.forEach((comment) => {
            if (comment['__typename']) delete comment['__typename']
          })
        }
        await updateMotion(updatedMotion)
      })
    }
  }

  docDrag = async (result) => {
    const { workItems, userDetails, activeMotion, activeMission } = this.props
    const destinationDetails = result.destination.droppableId.split('/')
    const targetMotionId = destinationDetails[1]
    const workItemId = result.draggableId

    if (!_.isEmpty(workItems)) {
      const targetWorkItem = workItems.find((workItem) => workItem.id === workItemId)
      // console.log("document", targetWorkItem)
      if (targetWorkItem) {
        if (targetWorkItem.motion.findIndex((motion) => motion === targetMotionId) === -1) {
          const updatedWorkItem = { ...targetWorkItem }
          updatedWorkItem.motion.push(targetMotionId)
          // push to db
          if (updatedWorkItem['__typename']) delete updatedWorkItem['__typename']
          await updateWorkItem(updatedWorkItem)

          if (activeMission.status === 'active') {
            var actionUser = userDetails.fullName ? userDetails.fullName : userDetails.email
            var logText = `${actionUser} added ${updatedWorkItem.name} to ${activeMotion.name} ${activeMotion.subheader}`

            const log = {
              mission: activeMission.id,
              date: Date().toString(),
              text: logText,
            }

            await addLog(log)
          }
        } else {
          // this work item is already part of motion
          // maybe add a popup that shows this error
          this.setState({ showSnackbar: true })
          return
        }
      } else {
        // This shouldn't be possible...
        console.log('Something went wrong....')
      }
    }
  }

  handleSnackbarClose = () => {
    this.setState({ showSnackbar: false })
  }

  render() {
    const { workspace, userOrgs, userDetails, t } = this.props
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }

    if (this.props.history.location.pathname !== '/') this.props.history.push('/')

    return (
      <div>
        {/* <NavBar/> */}
        {userDetails && <ProfileModel />}
        <ForgotPasswordProfile />
        <DragDropContext onDragEnd={this.onDragEnd}>
          {/* {this.state.orgs.length === userOrgs.length && ( */}
          <div id="workspace" className="workspace">
            <SidePanel myActiveMissionCount={this.state.myActiveMissionCount} />
            {workspace === 'home' && this.state.orgs.length === userOrgs.length ? (
              <Home orgs={this.state.orgs} addNewOrg={this.addNewOrg} />
            ) : (
              <div>
                {workspace === 'home' && (
                  <div style={{ height: '37vh', width: '17vw', margin: 'auto 50vw' }}>
                    <Lottie options={defaultOptions} />
                  </div>
                )}
              </div>
            )}
            {workspace === 'notifications' && this.state.orgs.length === userOrgs.length && (
              <Notifications orgs={this.state.orgs} addNewOrg={this.addNewOrg} />
            )}
            {/* {workspace === 'add' && <DraftSpace />} */}
            {workspace === 'draft' && <DraftCompletionSpace />}
            {workspace === 'mission' && <ActiveSpace />}
            {workspace === 'complete' && <CompleteSpace />}
          </div>
          {/* )} */}
        </DragDropContext>
        <Snackbar
          open={this.state.showSnackbar}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          TransitionComponent={Fade}
          ContentProps={{ 'aria-describedby': 'message-id' }}
          style={{ color: 'white' }}
          message={<span id="message-id">{t('common:main.message')}</span>}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { workspace } = state.instance
  let activeMissionMotions = []
  // console.log('the state', state)

  if (workspace === 'draft' && state.instance.mission) {
    activeMissionMotions = state.database.orgInstanceDraftMotions[state.instance.mission.id]
  } else if (workspace === 'mission' && state.instance.mission) {
    activeMissionMotions = state.database.orgInstanceActiveMotions[state.instance.mission.id]
  } else if (workspace === 'complete' && state.instance.mission) {
    // console.log(state.instance.mission.id)
    activeMissionMotions = state.database.orgInstanceCompleteMotions[state.instance.mission.id]
  }

  return {
    auth: state.firebase.auth,
    workspace: state.instance.workspace,
    activeOrg: state.instance.org,
    activeMission: state.instance.mission,
    activeMotion: state.instance.motion,
    userDetails: state.database.userDetails,
    userOrgs: state.database.userOrgs,
    instanceUsers: state.database.orgInstanceUsers,
    instanceActiveMissions: state.database.orgInstanceActiveMissions,
    instanceAllActiveMissions: state.database.orgInstanceAllActiveMissions,
    instanceActiveMotions: state.database.orgInstanceActiveMotions,
    instanceDraftMissions: state.database.orgInstanceDraftMissions,
    instanceDraftMotions: state.database.orgInstanceDraftMotions,
    instanceCompleteMissions: state.database.orgInstanceCompleteMissions,
    instanceCompleteMotions: state.database.orgInstanceCompleteMotions,
    workItems: state.database.workItems,
    activeMissionMotions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectOrg: (org) => dispatch(selectOrg(org)),
    selectMission: (mission) => dispatch(selectMission(mission)),
    selectMotion: (motion) => dispatch(selectMotion(motion)),
    setUserOrgs: (orgs) => dispatch(setUserOrgs(orgs)),
    onUserOrgAdded: (org) => dispatch(onUserOrgAdded(org)),
    onOrgMemberUpdated: (user) => dispatch(onOrgMemberUpdated(user)),
    setUser: (user) => dispatch(setUser(user)),
    onUserDetailsUpdated: (user) => dispatch(onUserDetailsUpdated(user)),
    setOrgInstanceUsers: (users) => dispatch(setOrgInstanceUsers(users)),

    setOrgInstanceActiveMissions: (missions) => dispatch(setOrgInstanceActiveMissions(missions)),
    setOrgInstanceAllActiveMissions: (missions) => dispatch(setOrgInstanceAllActiveMissions(missions)),
    onOrgInstanceActiveMissionUpdated: (mission) =>
      dispatch(onOrgInstanceActiveMissionUpdated(mission)),
    setOrgInstanceActiveMotions: (motions) => dispatch(setOrgInstanceActiveMotions(motions)),
    onOrgInstanceActiveMotionUpdated: (motion) =>
      dispatch(onOrgInstanceActiveMotionUpdated(motion)),

    setOrgInstanceDraftMissions: (missions) => dispatch(setOrgInstanceDraftMissions(missions)),
    onOrgInstanceDraftMissionUpdated: (mission) =>
      dispatch(onOrgInstanceDraftMissionUpdated(mission)),
    setOrgInstanceDraftMotions: (motions) => dispatch(setOrgInstanceDraftMotions(motions)),
    onOrgInstanceDraftMotionUpdated: (motion) => dispatch(onOrgInstanceDraftMotionUpdated(motion)),

    setOrgInstanceCompleteMissions: (missions) =>
      dispatch(setOrgInstanceCompleteMissions(missions)),
    onOrgInstanceCompleteMissionUpdated: (mission) =>
      dispatch(onOrgInstanceCompleteMissionUpdated(mission)),
    setOrgInstanceCompleteMotions: (motions) => dispatch(setOrgInstanceCompleteMotions(motions)),
    onOrgInstanceCompleteMotionUpdated: (motion) =>
      dispatch(onOrgInstanceCompleteMotionUpdated(motion)),

    setOrgInstanceMissionTemplate: (missionTemplates) =>
      dispatch(setOrgInstanceMissionTemplate(missionTemplates)),
    onOrgInstanceMissionTemplateUpdated: (missionTemplate) =>
      dispatch(onOrgInstanceMissionTemplateUpdated(missionTemplate)),

    setWorkItems: (workItems) => dispatch(setWorkItems(workItems)),
    onWorkItemChanged: (workItem) => dispatch(onWorkItemChanged(workItem)),

    setLogs: (logs) => dispatch(setLogs(logs)),
    onLogChanged: (log) => dispatch(onLogChanged(log)),

    orgInstanceChange: () => dispatch(orgInstanceChange()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common', 'motionPresets'])(Main)))
