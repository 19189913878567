// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Droppable } from 'react-beautiful-dnd'
import { withTranslation } from 'react-i18next';

// Material UI
import Popover, { PopoverAnimationVertical } from 'material-ui/Popover'
import Menu from 'material-ui/Menu'
import MenuItem from 'material-ui/MenuItem'
// import Button from "@material-ui/core/Button";
// import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions'
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import ArrowDropRight from 'material-ui/svg-icons/navigation-arrow-drop-right'

// Custom Components
import MainButton from '../../components/Buttons/MainButton'
import SecondaryButton from '../../components/Buttons/SecondaryButton'

// Helpers
import { selectWorkspace, selectMission } from '../../store/actions'
import {
  getRoleNameByRoleId,
  getRolesForUserInCurrentWinflow,
  checkResponsible,
  checkAccountable,
} from '../../utils/handleRoles'
import {
  updateMission,
  updateMotion,
  updateMotionMember,
  deleteMotionMember,
  addLog,
  deleteMissionMember,
} from '../../utils/graphql-req'
// import motionPresets from '../../utils/motionPresets'
import { envEndpoint } from '../../utils/firebase-service'

//Small Modal
import SmallModal from '../../components/Modals/SmallModal'

const styles = {
  modalPadding: {
    padding: '20px 0px 80px 20px',
  },
}

class UserCircle extends Component {
  state = {
    userMenuAnchor: undefined,
    dialogDeleteUser: false,
    dialogRoleEnforce: false,
  }

  showUserMenu = (event) => {
    this.setState({ showUserMenu: true, userMenuAnchor: event.currentTarget })
  }

  hideUserMenu = (event) => {
    this.setState({ showUserMenu: false, userMenuAnchor: undefined })
  }

  getMotionRoles = () => {
    const { passedMotion, passedMember, t } = this.props
    const currentPreset = t('motionPresets:items').find((preset) => preset.name === passedMotion.name)
    if (!currentPreset) return
    if (passedMember && passedMember.role === 'watcher') {
      return Object.values(currentPreset.members)
        .map((user) => user.role)
        .filter((role) => role !== passedMember.role && !checkAccountable(role))
    } else {
      const oldRoles = Object.values(currentPreset.members)
        .map((user) => user.role)
        .filter((role) => role !== passedMember.role && !checkAccountable(role))
      return [...oldRoles, 'watcher']
    }
  }

  renderRolesList = (roles) => {
    const { t } = this.props;
    return roles.map((role) => (
      <MenuItem primaryText={t(`roleNames:${role}`)} onClick={() => this.setUserRole(role)} />
    ))
  }

  setUserRole = async (role) => {
    const { activeMission, activeMotion, passedMember, user, activeUser } = this.props

    if (!checkResponsible(role) && this.checkResponsibleRemaining()) return

    const objectToDelete = { ...passedMember }
    if (objectToDelete['__typename']) delete objectToDelete['__typename']

    const objectToAdd = { ...passedMember }
    if (objectToAdd['__typename']) delete objectToAdd['__typename']
    objectToAdd['role'] = role

    // updated DB
    this.hideUserMenu()
    await updateMotionMember(activeMotion.id, objectToAdd, objectToDelete)

    if (activeMission.status === 'active') {
      var actionUser = activeUser.fullName ? activeUser.fullName : activeUser.email
      var impactedUser = user.fullName ? user.fullName : user.email
      var logText = `${actionUser} added ${impactedUser} to ${activeMotion.name} ${activeMotion.subheader}`

      const log = {
        mission: activeMission.id,
        date: Date().toString(),
        text: logText,
      }

      await addLog(log)
    }
  }

  setUserStatus = async (status) => {
    const {
      activeMission,
      activeMotion,
      passedMember,
      activeMissionMotions,
      user,
      activeOrg,
      activeUser,
    } = this.props

    // update member status
    const objectToDelete = { ...passedMember }
    if (objectToDelete['__typename']) delete objectToDelete['__typename']

    const objectToAdd = { ...passedMember }
    if (objectToAdd['__typename']) delete objectToAdd['__typename']
    objectToAdd['status'] = status

    // cleanup
    this.hideUserMenu()
    const result = await updateMotionMember(activeMotion.id, objectToAdd, objectToDelete)

    // Update Mission Log
    // console.log('the completed user', objectToAdd)
    if (objectToAdd.status === 'completed') {
      const impactedUser = user.fullName ? user.fullName : user.email
      let logText
      if (status === 'rejected') {
        logText = `${impactedUser} said they can't do their action in ${activeMotion.name} ${activeMotion.subheader}`
      } else {
        logText = `${impactedUser} completed their action in ${activeMotion.name} ${activeMotion.subheader}`
      }
      const log = {
        mission: activeMission.id,
        date: Date().toString(),
        text: logText,
      }

      await addLog(log)
    }

    // check if motion is complete
    let motionComplete = true
    if (result && result.members) {
      result.members.forEach((member) => {
        if (checkResponsible(member.role) && member.status !== 'completed') motionComplete = false
      })
    }

    if (motionComplete) {
      const updatedMotion = { ...result }
      if (updatedMotion['__typename']) delete updatedMotion['__typename']
      updatedMotion.motionStatus = 'complete'
      if (updatedMotion.members) {
        updatedMotion.members.forEach((member) => {
          if (member['__typename']) delete member['__typename']
        })
      }
      if (updatedMotion.comments) {
        updatedMotion.comments.forEach((comment) => {
          if (comment['__typename']) delete comment['__typename']
        })
      }

      await updateMotion(updatedMotion)

      //log completed motion
      if (updatedMotion.motionStatus === 'complete') {
        setTimeout(async function () {
          let logText = `${activeMotion.name} ${activeMotion.subheader} is completed`

          const log = {
            mission: activeMission.id,
            date: Date().toString(),
            text: logText,
          }

          await addLog(log)
        }, 500)
      }

      if (activeMotion.members) {
        const notifiedMembers = []
        updatedMotion.members.forEach(async (member) => {
          if (!notifiedMembers.includes(member.uid)) {
            notifiedMembers.push(member.uid)
            const response = await fetch(`${envEndpoint}/auth/getUser`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ userId: member.uid }),
            })

            if (response.status !== 200 && response.status !== 201) {
              console.log('Error!')
              throw new Error('Something Went Wrong!')
            }

            const { reqUser } = await response.json()

            let inviteDetails = {
              address: reqUser.email,
              invited: reqUser.fullName,
              inviter: user.fullName,
              mission: activeMission.name,
              motion: activeMotion.name,
              motionSub: activeMotion.subheader,
              org: activeOrg.name,
              language: reqUser.language || 'en-GB',
            }

            if (reqUser) {
              await fetch(`${envEndpoint}/invites/sendCompletedMotion`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  inviteDetails: inviteDetails,
                }),
              })
            }
          }
        })
      }
    }

    // Update Mission Log
    // const impactedUser = user.fullName ? user.fullName : user.email;
    // let logText = `${impactedUser} ${status} the ${ activeMotion.name } motion of the ${activeMission.name} mission`;
    // const log = {
    //   mission: activeMission.id,
    //   date: Date().toString(),
    //   text: logText
    // }

    // await addLog(log);

    // check if mission is complete
    let missionComplete = true
    activeMissionMotions.forEach((motion) => {
      motion.members.forEach((member) => {
        if (checkResponsible(member.role) && member.status !== 'completed') missionComplete = false
      })
    })

    if (missionComplete) {
      // db update mission and motions to complete
      activeMissionMotions.forEach(async (motion) => {
        const updatedMotion = { ...motion }
        if (updatedMotion['__typename']) delete updatedMotion['__typename']
        updatedMotion.motionStatus = 'complete'
        updatedMotion.missionStatus = 'complete'
        if (updatedMotion.members) {
          updatedMotion.members.forEach((member) => {
            if (member['__typename']) delete member['__typename']
          })
        }
        if (updatedMotion.comments) {
          updatedMotion.comments.forEach((comment) => {
            if (comment['__typename']) delete comment['__typename']
          })
        }
        await updateMotion(updatedMotion)
      })

      const updatedMission = { ...activeMission }
      if (updatedMission['__typename']) delete updatedMission['__typename']
      updatedMission.status = 'complete'
      updatedMission.invitations.forEach((invitation) => {
        if (invitation['__typename']) delete invitation['__typename']
      })
      await updateMission(updatedMission)

      // log completed mission
      if (updatedMission.status === 'complete') {
        setTimeout(async function () {
          var logText = 'Mission is completed!'

          const log = {
            mission: activeMission.id,
            date: Date().toString(),
            text: logText,
          }

          await addLog(log)
        }, 1000)
      }

      this.props.selectWorkspace('complete')

      // Send email for mission completion
      activeMission.members.forEach(async (userId) => {
        // if (userId !== user.id) {
        const response = await fetch(`${envEndpoint}/auth/getUser`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId: userId }),
        })

        if (response.status !== 200 && response.status !== 201) {
          console.log('Error!')
          throw new Error('Something Went Wrong!')
        }

        const { reqUser } = await response.json()
        let inviteDetails = {
          address: reqUser.email,
          invited: reqUser.fullName,
          inviter: user.fullName,
          mission: activeMission.name,
          org: activeOrg.name,
          language: reqUser.language || 'en-GB',
        }

        if (reqUser) {
          await fetch(`${envEndpoint}/invites/sendCompletedMission`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              inviteDetails: inviteDetails,
            }),
          })
        }
        // }
      })
    }
  }

  removeMember = async () => {
    const {
      activeMission,
      activeMotion,
      passedMember,
      user,
      activeMissionMotions,
      activeUser,
    } = this.props

    let activeMotions = []
    for (var i = 0; i < activeMissionMotions.length; i++) {
      activeMotions.push(activeMissionMotions[i].id)
    }

    // update member status
    const objectToDelete = { ...passedMember }
    if (objectToDelete['__typename']) delete objectToDelete['__typename']

    // cleanup
    this.hideUserMenu()
    await deleteMotionMember(activeMotion.id, objectToDelete)

    await deleteMissionMember(activeMotions, passedMember.uid, activeMission.id)
    // Update Mission Log
    let actionUser = activeUser.fullName ? activeUser.fullName : activeUser.email
    let logText
    if (activeMission.status === 'active' && user) {
      // console.log(this.props)
      // console.log(user)
      // console.log(passedMember)
      var impactedUser = user.fullName ? user.fullName : user.email
      logText = `${actionUser} removed ${impactedUser} from ${activeMotion.name} ${activeMotion.subheader}`

      const log = {
        mission: activeMission.id,
        date: Date().toString(),
        text: logText,
      }

      await addLog(log)
    } else if (activeMission.status === 'active' && !user && passedMember && passedMember.role) {
      // var actionUser = activeUser.fullName ? activeUser.fullName : auth.email;
      let impactedRole = passedMember.role
      logText = `${actionUser} removed the role ${impactedRole} from ${activeMotion.name} ${activeMotion.subheader}`
    }
  }

  //   getMotionEmails = () => {
  //     const { motions, motion, missionId, users } = this.props;
  //     const motionUsers = get(motions, [missionId, motion.id, "users"], []);
  //     const emails = motionUsers.map(user =>
  //       get(users, [user.uid, "email"], undefined)
  //     );
  //     return compact(uniq(emails));
  //   };

  //   buildEmailParams = () => {
  //     const { missions, motions, motion, missionId } = this.props;
  //     const winflowName = get(missions, [missionId, "winflowName"], "");
  //     const motionName = get(motions, [missionId, motion.id, "motionName"], "");
  //     return {
  //       winflowName,
  //       motionName,
  //       env: envCode,
  //       actionType: "completeMotion",
  //       address: this.getMotionEmails()
  //     };
  //   };

  dialogUserDelete = () => {
    const { t } = this.props;
    return (
      <SmallModal
        open={this.state.dialogDeleteUser}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={styles.modalPadding}>
          {t('common:userCircle.delete.title')}
        </DialogTitle>
        <DialogActions>
          <SecondaryButton onClick={() => this.setState({ dialogDeleteUser: false })}>
            {t('common:common.button.cancel')}
          </SecondaryButton>
          <MainButton
            onClick={() => {
              this.removeMember()
              this.setState({ dialogDeleteUser: false })
            }}
          >
            {t('common:common.button.remove')}
          </MainButton>
        </DialogActions>
      </SmallModal>
    )
  }

  dialogRoleEnforce = () => {
    const { t } = this.props;
    return (
      <SmallModal
        open={this.state.dialogRoleEnforce}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={styles.modalPadding}>
          {t('common:userCircle.enforce.title')}
        </DialogTitle>
        <DialogActions>
          <MainButton onClick={() => this.setState({ dialogRoleEnforce: false })}>
            {t('common:common.button.confirm')}
          </MainButton>
        </DialogActions>
      </SmallModal>
    )
  }

  //returns true if only one R-type is left in the motion
  checkResponsibleRemaining = () => {
    const { activeMotion, passedMember } = this.props

    if (passedMember.role && checkResponsible(passedMember.role)) {
      const responsibles = activeMotion.members.filter(
        (member) => member.role !== null && checkResponsible(member.role)
      )
      if (responsibles.length === 1) {
        this.setState({ dialogRoleEnforce: true, showUserMenu: false })
        return true
      }
    }
    return false
  }

  remove = () => {
    if (this.checkResponsibleRemaining()) return
    this.setState({ dialogDeleteUser: true, showUserMenu: false })
  }

  renderUserMenu = () => {
    const { showUserMenu, userMenuAnchor } = this.state
    const { activeMission, passedMotion, passedMember, activeMissionMotions, uid, t } = this.props

    const roles = this.getMotionRoles()
    const { permissions } = getRolesForUserInCurrentWinflow(
      uid,
      activeMissionMotions,
      activeMission
    )
    const isCurrentUser = passedMember.uid === uid

    //if clicks on themself, has permission to complete/reject, winflow is active, and motion isn't completed show action menu
    const actionButtonsVisible =
      isCurrentUser &&
      permissions.changeActionInMotion &&
      activeMission.status === 'active' &&
      passedMotion.motionStatus !== 'complete'
    const canRemoveUser =
      permissions.deleteUserUidFromMotion && !checkAccountable(passedMember.role)
    const canChangeRole = permissions.changeUserRoleInMotion && !checkAccountable(passedMember.role)
    const isMissionActive = activeMission.status === 'active' || activeMission.status === 'draft'

    // For supervisor or viewer roles
    let noAction = false
    const noActionRoles = ['requester', 'orchestrator', 'watcher', 'subjectExpert', null]
    if (noActionRoles.includes(passedMember.role)) noAction = true

    return (
      <Popover
        open={showUserMenu}
        anchorEl={userMenuAnchor}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        onRequestClose={this.hideUserMenu}
        animation={PopoverAnimationVertical}
      >
        <Paper>
          {isMissionActive && (
            <Menu
              style={{ fontSize: 13 }}
              className="usermenu"
              menuItemStyle={{ fontSize: 13 }}
              desktop={true}
            >
              {/*actionButtonsVisible && noAction && (<MenuItem primaryText="Approve" onClick={() => this.setUserStatus("completed")}/>)*/}
              {actionButtonsVisible && !noAction && (
                <MenuItem primaryText={t('common:userCircle.done')} onClick={() => this.setUserStatus('completed')} />
              )}
              {actionButtonsVisible && !noAction && (
                <MenuItem
                  primaryText={t('common:userCircle.cantDo')}
                  onClick={() => this.setUserStatus('rejected')}
                />
              )}
              {roles.length && canChangeRole && (
                <MenuItem
                  rightIcon={<ArrowDropRight />}
                  primaryText={t('common:userCircle.changeRole')}
                  menuItems={this.renderRolesList(roles)}
                />
              )}
              {canRemoveUser && <MenuItem onClick={() => this.remove()} primaryText={t('common:common.button.remove')} />}
            </Menu>
          )}
        </Paper>
      </Popover>
    )
  }

  render() {
    const { grid, active, user, auth, passedMotion, passedMember, t } = this.props

    let userStatus = ''
    if (passedMotion && passedMotion.members)
      userStatus = passedMotion.members.find((member) => member.index === passedMember.index).status

    let roleName = null
    if (passedMember && passedMember.role) roleName = t(`roleNames:${passedMember.role}`)//getRoleNameByRoleId(passedMember.role)

    let shouldShowUserCircle = true
    if (passedMotion.motionStatus === 'complete' && !active) {
      shouldShowUserCircle = false
    }

    return (
      <div
        className={`grid${grid}`}
        style={{
          zIndex: 400,
          marginLeft: '50px',
          visibility: shouldShowUserCircle ? 'visible' : 'hidden',
        }}
      >
        <Droppable
          droppableId={`${passedMotion.id}/${passedMember.index}/${passedMember.uid}/${auth.uid}/${passedMember.role}/${passedMember.status}`}
          type="USER"
          direction="horizontal"
          isDropDisabled={!active}
        >
          {(provided, snapshot) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div
                  className={`avatar__wrapper  ${active ? '' : 'nonActive'}`}
                  style={{ marginRight: '50px' }}
                >
                  <div
                    className={`avatar__border smallblue 
                          ${active && 'smallwhite '}  
                          ${userStatus || ''} 
                          ${passedMember.role || ''} 
                          ${
                            active
                              ? snapshot.isDraggingOver
                                ? 'avatarDraggingOver '
                                : 'avatar '
                              : ''
                          }
                          `}
                  >
                    <img
                      className="avatar__img"
                      onClick={(event) => this.showUserMenu(event)}
                      alt="Avatar"
                      src={
                        user && user.avatar ? user.avatar : require('../../assets/images/user.jpg')
                      }
                    />
                  </div>
                </div>
                {provided.placeholder}
              </div>
            )
          }}
        </Droppable>
        {active && this.dialogUserDelete()}
        {active && this.dialogRoleEnforce()}
        {active && (
          <div className={`user__text ${active && 'active'}`} style={{ marginRight: '50px' }}>
            <div
              className={`name-text  ${passedMember.uid === auth.uid ? 'active-name' : ''}`}
              style={{ minWidth: '100px' }}
            >
              {user ? user.fullName : t('common:common.unassigned')}
            </div>
            <div className={`title-text ${passedMember.uid === auth.uid ? 'active-title' : ''}`}>
              {roleName ? roleName : t('common:common.open')}
            </div>
          </div>
        )}
        {this.state.showUserMenu && !checkAccountable(passedMember.role) && this.renderUserMenu()}
      </div>
    )
  }
}

const mapStateToProps = (state, passed) => {
  const { passedMember } = passed
  const { workspace } = state.instance

  let user = null
  if (
    state.database.orgInstanceUsers &&
    passedMember.uid &&
    state.database.orgInstanceUsers[passedMember.uid]
  )
    user = state.database.orgInstanceUsers[passedMember.uid]

  let activeMissionMotions = []
  if (workspace === 'draft') {
    activeMissionMotions = state.database.orgInstanceDraftMotions[state.instance.mission.id]
  } else if (workspace === 'mission') {
    activeMissionMotions = state.database.orgInstanceActiveMotions[state.instance.mission.id]
  } else if (workspace === 'complete') {
    activeMissionMotions = []
  }

  return {
    auth: state.firebase.auth,
    uid: state.firebase.auth.uid,
    workspace: state.instance.workspace,
    activeOrg: state.instance.org,
    activeMission: state.instance.mission,
    activeMotion: state.instance.motion,
    activeUser: state.database.userDetails,
    activeMissionMotions,
    user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectWorkspace: (workspace, updatingWinflow) =>
      dispatch(selectWorkspace(workspace, updatingWinflow)),
    selectMission: (mission) => dispatch(selectMission(mission)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common', 'roleNames', 'motionPresets'])(UserCircle))
