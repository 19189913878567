import client from './provider-setup'
import gql from 'graphql-tag'

//**********************
// ORG COLLECTION DATABASE REQUEST SCHEMA
//**********************
export const orgsQuery = gql`
  query OrgQuery($userId: String!) {
    orgs(userId: $userId) {
      id
      logo
      name
      members
      owner
      status
      creator
      whatWeDo
      linkedIn
      website
    }
  }
`

export const orgMembersQuery = gql`
  query OrgMembersQuery($members: [String!]) {
    orgMembers(members: $members) {
      id
      fullName
      avatar
      email
      phoneNumber
      whatIDo
      personalWebsite
      linkedIn
      language
      invitations {
        invitationDate
        invitee
        placeholderUserId
        mission {
          id
          name
        }
        org {
          id
          name
        }
      }
      placeholder
      placeholderCreatedDate
      userOnboarded
    }
  }
`

export const addOrgMutation = gql`
  mutation AddOrgMutation($input: OrgInput!) {
    org: addOrg(input: $input) {
      id
      logo
      name
      members
      owner
      status
      creator
      whatWeDo
      linkedIn
      website
    }
  }
`

export const updateOrgMutation = gql`
  mutation UpdateOrgMutation($input: OrgInput!) {
    org: updateOrg(input: $input) {
      id
      logo
      name
      members
      owner
      status
      creator
      whatWeDo
      linkedIn
      website
    }
  }
`

export const orgAddedSubscription = gql`
  subscription onOrgAdded($userId: String!) {
    orgAdded(userId: $userId) {
      id
      logo
      name
      members
      owner
      status
      creator
      whatWeDo
      linkedIn
      website
    }
  }
`

//**********************
// MISSION COLLECTION DATABASE REQUEST SCHEMA
//**********************

// QUERIES
export const orgInstanceActiveMissions = gql`
  query OrgInstanceActiveMissionsQuery($userId: String!, $orgId: String!) {
    orgInstanceActiveMissions(userId: $userId, orgId: $orgId) {
      id
      invitations {
        invitationDate
        inviter
        userId
      }
      name
      org
      creator
      description
      members
      status
      startDate
    }
  }
`

export const orgInstanceAllActiveMissions = gql`
  query OrgInstanceAllActiveMissionsQuery($orgId: String!) {
    orgInstanceAllActiveMissions(orgId: $orgId) {
      id
      invitations {
        invitationDate
        inviter
        userId
      }
      name
      org
      creator
      description
      members
      status
      startDate
    }
  }
`

export const orgInstanceDraftMissions = gql`
  query OrgInstanceDraftMissionsQuery($userId: String!, $orgId: String!) {
    orgInstanceDraftMissions(userId: $userId, orgId: $orgId) {
      id
      invitations {
        invitationDate
        inviter
        userId
      }
      name
      org
      creator
      description
      members
      status
    }
  }
`

export const orgInstanceCompleteMissions = gql`
  query OrgInstanceCompleteMissionsQuery($userId: String!, $orgId: String!) {
    orgInstanceCompleteMissions(userId: $userId, orgId: $orgId) {
      id
      name
      org
      creator
      description
      members
      status
      startDate
    }
  }
`

// SUBSCRIPTIONS
export const orgInstanceActiveMissionsSubscription = gql`
  subscription orgInstanceActiveMissionsSubscription($orgId: String!, $uid: String!) {
    orgInstanceActiveMissionsUpdated(orgId: $orgId, uid: $uid) {
      id
      invitations {
        invitationDate
        inviter
        userId
      }
      name
      org
      creator
      description
      members
      status
      startDate
    }
  }
`

export const orgInstanceDraftMissionsSubscription = gql`
  subscription orgInstanceDraftMissionsSubscription($orgId: String!, $uid: String!) {
    orgInstanceDraftMissionsUpdated(orgId: $orgId, uid: $uid) {
      id
      invitations {
        invitationDate
        inviter
        userId
      }
      name
      org
      creator
      description
      members
      status
    }
  }
`

export const orgInstanceCompleteMissionsSubscription = gql`
  subscription orgInstanceCompleteMissionsSubscription($orgId: String!, $uid: String!) {
    orgInstanceCompleteMissionsUpdated(orgId: $orgId, uid: $uid) {
      id
      invitations {
        invitationDate
        inviter
        userId
      }
      name
      org
      creator
      description
      members
      status
      startDate
    }
  }
`

// MUTATIONS
export const addMissionMutation = gql`
  mutation AddMissionMutation($input: MissionInput!) {
    mission: addMission(input: $input) {
      id
      invitations {
        invitationDate
        inviter
        userId
      }
      name
      org
      creator
      description
      members
      status
    }
  }
`

export const updateMissionMutation = gql`
  mutation UpdateMissionMutation($input: MissionInput!) {
    mission: updateMission(input: $input) {
      id
      invitations {
        invitationDate
        inviter
        userId
      }
      name
      org
      creator
      description
      members
      status
      startDate
    }
  }
`

export const updateMissionTitleMutation = gql`
  mutation updateMissionTitleMutation($missionId: String!, $missionTitle: String!) {
    updateMissionTitle(missionId: $missionId, missionTitle: $missionTitle) {
      id
      name
      org
      creator
      description
      members
      status
    }
  }
`

export const updateMissionDescripMutation = gql`
  mutation updateMissionDescripMutation($missionId: String!, $missionDescrip: String!) {
    updateMissionDescrip(missionId: $missionId, missionDescrip: $missionDescrip) {
      id
      name
      org
      creator
      description
      members
      status
    }
  }
`

export const addMemberToMissionMutation = gql`
  mutation addMemberToMissionMutation($missionId: String!, $newMemberId: String!) {
    addMemberToMission(missionId: $missionId, newMemberId: $newMemberId) {
      id
      name
      org
      creator
      description
      members
      status
    }
  }
`

export const deleteMissionMutation = gql`
  mutation deleteMissionMutation($missionToDelete: MissionInput!) {
    mission: deleteMission(missionToDelete: $missionToDelete) {
      id
      name
      org
      creator
      description
      members
      status
    }
  }
`

export const AddUserToInvitationsMutation = gql`
  mutation AddUserToInvitationsMutation($missionId: String!, $invitation: MissionInvitesInput!) {
    invitation: addUserToInvitations(missionId: $missionId, invitation: $invitation) {
      id
      invitations {
        invitationDate
        inviter
        userId
      }
      name
      org
      creator
      description
      members
      status
    }
  }
`

export const removeInvitationMutation = gql`
  mutation removeInvitationMutation($missionId: String!, $invitation: MissionInvitesInput!) {
    invitation: removeInvitation(missionId: $missionId, invitation: $invitation) {
      id
      invitations {
        invitationDate
        inviter
        userId
      }
      name
      org
      creator
      description
      members
      status
    }
  }
`

export const deleteMissionMemberMutation = gql`
  mutation deleteMissionMemberMutation(
    $activeMotions: [String!]!
    $userId: String!
    $missionId: String!
  ) {
    mission: deleteMissionMember(
      activeMotions: $activeMotions
      userId: $userId
      missionId: $missionId
    ) {
      id
      invitations {
        invitationDate
        inviter
        userId
      }
      name
      org
      creator
      description
      members
      status
    }
  }
`

//**********************
// MOTION COLLECTION DATABASE REQUEST SCHEMA
//**********************

// QUERIES
export const orgInstanceActiveMotions = gql`
  query OrgInstanceActiveMotionsQuery($missionIds: [String!], $orgId: String!) {
    orgInstanceActiveMotions(missionIds: $missionIds, orgId: $orgId) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
      private
    }
  }
`

export const orgInstanceDraftMotions = gql`
  query OrgInstanceDraftMotionsQuery($missionIds: [String!], $orgId: String!) {
    orgInstanceDraftMotions(missionIds: $missionIds, orgId: $orgId) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
      private
    }
  }
`

export const orgInstanceCompleteMotions = gql`
  query OrgInstanceCompleteMotionsQuery($missionIds: [String!], $orgId: String!) {
    orgInstanceCompleteMotions(missionIds: $missionIds, orgId: $orgId) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
      private
    }
  }
`

export const motionDates = gql`
  query motionDatesQuery($userId: String!, $orgId: String!, $arg1: Int!, $arg2: Int!) {
    motionDates(userId: $userId, orgId: $orgId, arg1: $arg1, arg2: $arg2) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
      private
    }
  }
`

// MUTATIONS
export const addMotionMutation = gql`
  mutation AddMotionMutation($input: MotionInput!) {
    motion: addMotion(input: $input) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
      private
    }
  }
`

export const updateMotionMutation = gql`
  mutation updateMotionMutation($updatedMotion: MotionInput!) {
    motion: updateMotion(updatedMotion: $updatedMotion) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
      private
    }
  }
`

export const deleteMotionMutation = gql`
  mutation deleteMotionMutation($motionToDelete: MotionInput!) {
    motion: deleteMotion(motionToDelete: $motionToDelete) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
      private
    }
  }
`

export const updateMotionDescriptionMutation = gql`
  mutation updateMotionDescriptionMutation($motionId: String!, $motionDescription: String!) {
    motion: updateMotionDescription(motionId: $motionId, motionDescription: $motionDescription) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
      private
    }
  }
`

export const updateMotionStartDateMutation = gql`
  mutation updateMotionStartDateMutation($motionId: String!, $motionStartDate: DateTime!) {
    motion: updateMotionStartDate(motionId: $motionId, motionStartDate: $motionStartDate) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
      private
    }
  }
`

export const updateMotionEndDateMutation = gql`
  mutation updateMotionEndDateMutation($motionId: String!, $motionEndDate: DateTime!) {
    motion: updateMotionEndDate(motionId: $motionId, motionEndDate: $motionEndDate) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
      private
    }
  }
`

export const updateMotionMemberMutation = gql`
  mutation updateMotionMemberMutation(
    $motionId: String!
    $motionMemberToAdd: MemberInput!
    $motionMemberToDelete: MemberInput!
  ) {
    motion: updateMotionMember(
      motionId: $motionId
      motionMemberToAdd: $motionMemberToAdd
      motionMemberToDelete: $motionMemberToDelete
    ) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
      private
    }
  }
`

export const addMotionMemberMutation = gql`
  mutation addMotionMemberMutation($motionId: String!, $motionMemberToAdd: MemberInput!) {
    motion: addMotionMember(motionId: $motionId, motionMemberToAdd: $motionMemberToAdd) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
      private
    }
  }
`

export const deleteMotionMemberMutation = gql`
  mutation deleteMotionMemberMutation($motionId: String!, $motionMemberToDelete: MemberInput!) {
    motion: deleteMotionMember(motionId: $motionId, motionMemberToDelete: $motionMemberToDelete) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
      private
    }
  }
`

// SUBSCRIPTIONS
export const orgInstanceDraftMotionSubscription = gql`
  subscription orgInstanceDraftMotionSubscription($orgId: String!, $uid: String!) {
    orgInstanceDraftMotionUpdated(orgId: $orgId, uid: $uid) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
      private
    }
  }
`

export const orgInstanceActiveMotionSubscription = gql`
  subscription orgInstanceActiveMotionSubscription($orgId: String!, $uid: String!) {
    orgInstanceActiveMotionUpdated(orgId: $orgId, uid: $uid) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
      private
    }
  }
`

export const orgInstanceCompleteMotionSubscription = gql`
  subscription orgInstanceCompleteMotionSubscription($orgId: String!, $uid: String!) {
    orgInstanceCompleteMotionUpdated(orgId: $orgId, uid: $uid) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
      private
    }
  }
`

//**********************
// USER/S COLLECTION DATABASE REQUEST SCHEMA
//**********************
export const userQuery = gql`
  query UserQuery($userId: String!) {
    user(userId: $userId) {
      id
      fullName
      avatar
      email
      phoneNumber
      whatIDo
      personalWebsite
      linkedIn
      language
      invitations {
        invitationDate
        invitee
        placeholderUserId
        mission {
          id
          name
        }
        org {
          id
          name
        }
      }
      placeholder
      placeholderCreatedDate
      userOnboarded
    }
  }
`

export const updateUserMutation = gql`
  mutation UpdateUserMutation($input: UserInput!) {
    user: updateUser(input: $input) {
      id
      fullName
      avatar
      email
      phoneNumber
      whatIDo
      personalWebsite
      linkedIn
      language
      invitations {
        invitationDate
        invitee
        placeholderUserId
        mission {
          id
          name
        }
        org {
          id
          name
        }
      }
      placeholder
      placeholderCreatedDate
      userOnboarded
    }
  }
`

export const userUpdatedSubscription = gql`
  subscription onUserUpdated($userId: String!) {
    userUpdated(userId: $userId) {
      id
      fullName
      avatar
      email
      phoneNumber
      whatIDo
      personalWebsite
      linkedIn
      invitations {
        invitationDate
        invitee
        placeholderUserId
        mission {
          id
          name
        }
        org {
          id
          name
        }
      }
      language
      placeholder
      placeholderCreatedDate
      userOnboarded
    }
  }
`

export const memberUpdatedSubscription = gql`
  subscription onMemberUpdated($userId: String!, $members: [String!]!) {
    memberUpdated(userId: $userId, members: $members) {
      id
      fullName
      avatar
      email
      phoneNumber
      whatIDo
      personalWebsite
      linkedIn
      invitations {
        invitationDate
        invitee
        placeholderUserId
        mission {
          id
          name
        }
        org {
          id
          name
        }
      }
      language
      placeholder
      placeholderCreatedDate
      userOnboarded
    }
  }
`

//**********************
// LOG COLLECTION DATABASE REQUEST SCHEMA
//**********************
export const missionInstanceLogs = gql`
  query MissionInstanceLogsQuery($missionId: String!) {
    missionInstanceLogs(missionId: $missionId) {
      id
      date
      mission
      text
    }
  }
`

export const addLogMutation = gql`
  mutation AddLogMutation($input: LogInput!) {
    log: addLog(input: $input) {
      id
      date
      mission
      text
    }
  }
`

export const logSubscription = gql`
  subscription onLogUpdated($missionId: String!) {
    logUpdated(missionId: $missionId) {
      id
      date
      mission
      text
    }
  }
`

//**********************
// WORKITEM COLLECTION DATABASE REQUEST SCHEMA
//**********************
export const orgInstanceWorkItems = gql`
  query OrgInstanceWorkItemsQuery($missionId: String!) {
    orgInstanceWorkItems(missionId: $missionId) {
      id
      bytes
      icon
      link
      # linkType
      mission
      motion
      name
      # thumbnailLink
      date
    }
  }
`

export const addWorkItemMutation = gql`
  mutation AddWorkItemMutation($input: WorkItemInput!) {
    workItem: addWorkItem(input: $input) {
      id
      bytes
      icon
      link
      mission
      motion
      name
      date
    }
  }
`

export const updateWorkItemMutation = gql`
  mutation UpdateWorkItemMutation($updatedWorkItem: WorkItemInput!) {
    workItem: updateWorkItem(updatedWorkItem: $updatedWorkItem) {
      id
      bytes
      icon
      link
      mission
      motion
      name
      date
    }
  }
`

export const workItemSubscription = gql`
  subscription onWorkItemUpdated($missionId: String!) {
    workItemUpdated(missionId: $missionId) {
      id
      bytes
      icon
      link
      mission
      motion
      name
    }
  }
`

export const deleteWorkItemMutation = gql`
  mutation DeleteWorkItemMutation($workItemId: String!, $missionId: String!) {
    workItem: deleteWorkItem(workItemId: $workItemId, missionId: $missionId) {
      id
    }
  }
`

//**********************
// MISSION TEMPLATE COLLECTION DATABASE REQUEST SCHEMA
//**********************

// QUERIES
export const orgInstanceMissionTemplatesQuery = gql`
  query orgInstanceMissionTemplatesQuery($orgId: String!) {
    orgInstanceMissionTemplates(orgId: $orgId) {
      id
      name
      org
      creator
      description
      members
      status
    }
  }
`

export const motionTemplatesQuery = gql`
  query motionTemplatesQuery($missionId: String!) {
    motionTemplates(missionId: $missionId) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
    }
  }
`

// MUTATIONS
export const addMissionTemplateMutation = gql`
  mutation AddMissionTemplateMutation($input: MissionTemplateInput!) {
    missionTemplate: addMissionTemplate(input: $input) {
      id
      name
      org
      creator
      description
      members
      status
    }
  }
`

export const deleteMissionTemplateMutation = gql`
  mutation deleteMissionTemplateMutation($missionTemplate: MissionTemplateInput!) {
    missionTemplate: deleteMissionTemplate(missionTemplate: $missionTemplate) {
      id
      name
    }
  }
`

export const addMotionTemplateMutation = gql`
  mutation AddMotionTemplateMutation($input: MotionTemplateInput!) {
    motionTemplate: addMotionTemplate(input: $input) {
      id
      index
      subheader
      description
      members {
        index
        role
        status
        uid
      }
      comments {
        commenter
        edited
        notification
        text
        timestamp
      }
      mission
      missionStatus
      motionStatus
      name
      org
      startDate
      endDate
    }
  }
`

//  SUBSCRIPTIONS
export const orgInstanceMissionTemplatesSubscription = gql`
  subscription orgInstanceMissionTemplatesSubscription($orgId: String!) {
    orgInstanceMissionTemplatesUpdated(orgId: $orgId) {
      id
      name
      org
      creator
      description
      members
      status
    }
  }
`

// ***************************
// ORG COLLECTION DATABASE ACTIONS
// ***************************
export async function getOrgs(userId) {
  const { data } = await client.query({ query: orgsQuery, variables: { userId } })
  return data.orgs
}

export async function getOrgMembers(members) {
  const { data } = await client.query({ query: orgMembersQuery, variables: { members } })
  return data.orgMembers
}

export async function addOrg(orgDetails) {
  const { data } = await client.mutate({
    mutation: addOrgMutation,
    variables: { input: orgDetails },
  })
  return data.org
}

export async function updateOrg(orgDetails) {
  const { data } = await client.mutate({
    mutation: updateOrgMutation,
    variables: { input: orgDetails },
  })
  return data.org
}

export function onOrgAdded(userId, handleOrg) {
  const observable = client.subscribe({ query: orgAddedSubscription, variables: { userId } })
  return observable.subscribe((result) => {
    handleOrg(result.data.orgAdded)
  })
}

// ***************************
// MISSION COLLECTION DATABASE ACTIONS
// ***************************

// QUERIES
export async function getOrgInstanceActiveMissions(userId, orgId) {
  const { data } = await client.query({
    query: orgInstanceActiveMissions,
    variables: { userId, orgId },
  })
  return data.orgInstanceActiveMissions
}

export async function getOrgInstanceAllActiveMissions(orgId) {
  const { data } = await client.query({ query: orgInstanceAllActiveMissions, variables: { orgId } })
  return data.orgInstanceAllActiveMissions
}

export async function getOrgInstanceDraftMissions(userId, orgId) {
  const { data } = await client.query({
    query: orgInstanceDraftMissions,
    variables: { userId, orgId },
  })
  return data.orgInstanceDraftMissions
}

export async function getOrgInstanceCompleteMissions(userId, orgId) {
  const { data } = await client.query({
    query: orgInstanceCompleteMissions,
    variables: { userId, orgId },
  })
  return data.orgInstanceCompleteMissions
}

// MUTATIONS
export async function addMission(missionDetails) {
  const { data } = await client.mutate({
    mutation: addMissionMutation,
    variables: { input: missionDetails },
  })
  return data.mission
}

export async function updateMission(updatedMission) {
  const { data } = await client.mutate({
    mutation: updateMissionMutation,
    variables: { input: updatedMission },
  })
  return data.mission
}

export async function deleteMission(missionToDelete) {
  const { data } = await client.mutate({
    mutation: deleteMissionMutation,
    variables: { missionToDelete },
  })
  return data.mission
}

export async function updateMissionTitle(missionId, missionTitle) {
  const { data } = await client.mutate({
    mutation: updateMissionTitleMutation,
    variables: { missionId, missionTitle },
  })
  return data.updateMissionTitle
}

export async function updateMissionDescrip(missionId, missionDescrip) {
  const { data } = await client.mutate({
    mutation: updateMissionDescripMutation,
    variables: { missionId, missionDescrip },
  })
  return data.updateMissionDescrip
}

export async function addMemberToMission(missionId, newMemberId) {
  const { data } = await client.mutate({
    mutation: addMemberToMissionMutation,
    variables: { missionId, newMemberId },
  })
  return data.addMemberToMission
}

export async function addUserToInvitations(missionId, invitation) {
  const { data } = await client.mutate({
    mutation: AddUserToInvitationsMutation,
    variables: { missionId, invitation },
  })
  return data.invitation
}

export async function removeInvitation(missionId, invitation) {
  const { data } = await client.mutate({
    mutation: removeInvitationMutation,
    variables: { missionId, invitation },
  })
  return data.invitation
}

export async function deleteMissionMember(activeMotions, userId, missionId) {
  const { data } = await client.mutate({
    mutation: deleteMissionMemberMutation,
    variables: { activeMotions, userId, missionId },
  })
  return data.mission
}

// SUBSCRIPTIONS
export function onOrgInstanceActiveMissionsUpdated(
  { orgId, uid },
  handleOrgInstanceActiveMissions
) {
  const observable = client.subscribe({
    query: orgInstanceActiveMissionsSubscription,
    variables: { orgId, uid },
  })
  return observable.subscribe((result) => {
    handleOrgInstanceActiveMissions(result.data.orgInstanceActiveMissionsUpdated)
  })
}

export function onOrgInstanceDraftMissionsUpdated(args, handleOrgInstanceDraftMissions) {
  const observable = client.subscribe({
    query: orgInstanceDraftMissionsSubscription,
    variables: { orgId: args.orgId, uid: args.uid },
  })
  return observable.subscribe((result) => {
    handleOrgInstanceDraftMissions(result.data.orgInstanceDraftMissionsUpdated)
  })
}

export function onOrgInstanceCompleteMissionsUpdated(args, handleOrgInstanceCompleteMissions) {
  const observable = client.subscribe({
    query: orgInstanceCompleteMissionsSubscription,
    variables: { orgId: args.orgId, uid: args.uid },
  })
  return observable.subscribe((result) =>
    handleOrgInstanceCompleteMissions(result.data.orgInstanceCompleteMissionsUpdated)
  )
}

// ***************************
// MOTION COLLECTION DATABASE ACTIONS
// ***************************

// Queries
export async function getOrgInstanceActiveMotions(missionIds, orgId) {
  const { data } = await client.query({
    query: orgInstanceActiveMotions,
    variables: { missionIds, orgId },
  })
  return data.orgInstanceActiveMotions
}

export async function getOrgInstanceDraftMotions(missionIds, orgId) {
  const { data } = await client.query({
    query: orgInstanceDraftMotions,
    variables: { missionIds, orgId },
  })
  return data.orgInstanceDraftMotions
}

export async function getOrgInstanceCompleteMotions(missionIds, orgId) {
  const { data } = await client.query({
    query: orgInstanceCompleteMotions,
    variables: { missionIds, orgId },
  })
  return data.orgInstanceCompleteMotions
}

export async function getMotionDates(userId, orgId, arg1, arg2) {
  const { data } = await client.query({
    query: motionDates,
    variables: { userId, orgId, arg1, arg2 },
  })
  return data.motionDates
}

// Mutations
export async function addMotion(motionDetails) {
  const { data } = await client.mutate({
    mutation: addMotionMutation,
    variables: { input: motionDetails },
  })
  return data.motion
}

export async function updateMotion(updatedMotion) {
  const { data } = await client.mutate({
    mutation: updateMotionMutation,
    variables: { updatedMotion },
  })
  return data.motion
}

export async function deleteMotion(motionToDelete) {
  const { data } = await client.mutate({
    mutation: deleteMotionMutation,
    variables: { motionToDelete },
  })
  return data.motion
}

export async function updateMotionDescription(motionId, motionDescription) {
  const { data } = await client.mutate({
    mutation: updateMotionDescriptionMutation,
    variables: { motionId, motionDescription },
  })
  return data.motion
}

export async function updateMotionStartDate(motionId, motionStartDate) {
  const { data } = await client.mutate({
    mutation: updateMotionStartDateMutation,
    variables: { motionId, motionStartDate },
  })
  return data.motion
}

export async function updateMotionEndDate(motionId, motionEndDate) {
  const { data } = await client.mutate({
    mutation: updateMotionEndDateMutation,
    variables: { motionId, motionEndDate },
  })
  return data.motion
}

export async function updateMotionMember(motionId, motionMemberToAdd, motionMemberToDelete) {
  const { data } = await client.mutate({
    mutation: updateMotionMemberMutation,
    variables: { motionId, motionMemberToAdd, motionMemberToDelete },
  })
  return data.motion
}

export async function addMotionMember(motionId, motionMemberToAdd) {
  const { data } = await client.mutate({
    mutation: addMotionMemberMutation,
    variables: { motionId, motionMemberToAdd },
  })
  return data.motion
}

export async function deleteMotionMember(motionId, motionMemberToDelete) {
  const { data } = await client.mutate({
    mutation: deleteMotionMemberMutation,
    variables: { motionId, motionMemberToDelete },
  })
  return data.motion
}

// Subscriptions
export function onOrgInstanceActiveMotionsUpdated({ orgId, uid }, handleOrgInstanceActiveMotions) {
  const observable = client.subscribe({
    query: orgInstanceActiveMotionSubscription,
    variables: { orgId, uid },
  })
  return observable.subscribe((result) =>
    handleOrgInstanceActiveMotions(result.data.orgInstanceActiveMotionUpdated)
  )
}

export function onOrgInstanceDraftMotionsUpdated({ orgId, uid }, handleOrgInstanceDraftMotions) {
  const observable = client.subscribe({
    query: orgInstanceDraftMotionSubscription,
    variables: { orgId, uid },
  })
  return observable.subscribe((result) => {
    handleOrgInstanceDraftMotions(result.data.orgInstanceDraftMotionUpdated)
  })
}

export function onOrgInstanceCompleteMotionsUpdated(
  { orgId, uid },
  handleOrgInstanceCompleteMotions
) {
  const observable = client.subscribe({
    query: orgInstanceCompleteMotionSubscription,
    variables: { orgId, uid },
  })
  return observable.subscribe((result) =>
    handleOrgInstanceCompleteMotions(result.data.orgInstanceCompleteMotionUpdated)
  )
}

// ***************************
// USER/S COLLECTION DATABASE ACTIONS
// ***************************
export async function getUser(userId) {
  const { data } = await client.query({ query: userQuery, variables: { userId } })
  return data.user
}

export async function updateUser(userDetails) {
  const { data } = await client.mutate({
    mutation: updateUserMutation,
    variables: { input: userDetails },
  })
  return data.user
}

export function onUserUpdated(userId, handleUser) {
  const observable = client.subscribe({ query: userUpdatedSubscription, variables: { userId } })
  return observable.subscribe((result) => handleUser(result.data.userUpdated))
}

export function onMemberUpdated({userId, members}, handleMember) {
  const observable = client.subscribe(
    { 
      query: memberUpdatedSubscription, 
      variables: { userId, members } 
    }
  )
  return observable.subscribe((result) => handleMember(result.data.memberUpdated))
}

// ***************************
// LOGS COLLECTION DATABASE ACTIONS
// ***************************
export async function getLogs(missionId) {
  const { data } = await client.query({ query: missionInstanceLogs, variables: { missionId } })
  return data.missionInstanceLogs
}

export async function addLog(logDetails) {
  const { data } = await client.mutate({
    mutation: addLogMutation,
    variables: { input: logDetails },
  })
  return data.log
}

export function onLogUpdated(missionId, handleLog) {
  const observable = client.subscribe({ query: logSubscription, variables: { missionId } })
  return observable.subscribe((result) => handleLog(result.data.logUpdated))
}

// ***************************
// WORKITEMS COLLECTION DATABASE ACTIONS
// ***************************
export async function getWorkItems(missionId) {
  const { data } = await client.query({ query: orgInstanceWorkItems, variables: { missionId } })
  return data.orgInstanceWorkItems
}

export async function addWorkItem(workItemDetails) {
  const { data } = await client.mutate({
    mutation: addWorkItemMutation,
    variables: { input: workItemDetails },
  })
  return data.workItem
}

export async function updateWorkItem(workItemDetails) {
  const { data } = await client.mutate({
    mutation: updateWorkItemMutation,
    variables: { updatedWorkItem: workItemDetails },
  })
  return data.workItem
}

export async function deleteWorkItem(workItemId, missionId) {
  const { data } = await client.mutate({
    mutation: deleteWorkItemMutation,
    variables: { workItemId, missionId },
  })
  return data.workItem
}

export function onWorkItemUpdated(missionId, handleWorkItem) {
  const observable = client.subscribe({ query: workItemSubscription, variables: { missionId } })
  return observable.subscribe((result) => handleWorkItem(result.data.workItemUpdated))
}

// ***************************
// MISSION TEMPLATE COLLECTION DATABASE ACTIONS
// ***************************

// QUERIES
export async function getOrgInstanceMissionTemplates(orgId) {
  const { data } = await client.query({
    query: orgInstanceMissionTemplatesQuery,
    variables: { orgId },
  })
  return data.orgInstanceMissionTemplates
}

export async function getMotionTemplates(missionId) {
  const { data } = await client.query({ query: motionTemplatesQuery, variables: { missionId } })
  return data.motionTemplates
}

// MUTATIONS
export async function addMissionTemplate(missionTemplate) {
  const { data } = await client.mutate({
    mutation: addMissionTemplateMutation,
    variables: { input: missionTemplate },
  })
  return data.missionTemplate
}

export async function addMotionTemplate(motionTemplate) {
  const { data } = await client.mutate({
    mutation: addMotionTemplateMutation,
    variables: { input: motionTemplate },
  })
  return data.motionTemplate
}

export async function deleteMissionTemplate(missionTemplate) {
  const { data } = await client.mutate({
    mutation: deleteMissionTemplateMutation,
    variables: { missionTemplate },
  })
  return data.missionTemplate
}

// SUBSCRIPTIONS
export function onOrgInstanceMissionTemplatesUpdated(args, handleOrgInstanceMissionTemplate) {
  const observable = client.subscribe({
    query: orgInstanceMissionTemplatesSubscription,
    variables: { orgId: args.orgId },
  })
  return observable.subscribe((result) =>
    handleOrgInstanceMissionTemplate(result.data.orgInstanceMissionTemplatesUpdated)
  )
}
