// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'
import { withTranslation } from 'react-i18next'
// import shortid from 'shortid'

// Material UI
import { withStyles } from '@material-ui/core/styles'
// import TextField from "@material-ui/core/TextField";
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DatePicker from 'material-ui/DatePicker'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
// import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from '@material-ui/core/Tooltip'
import Snackbar from '@material-ui/core/Snackbar'

//Custom Material UI Components
import MainButton from '../../components/Buttons/MainButton'

//Helpers
import { selectMission, selectWorkspace } from '../../store/actions'
import { addMission, addMotion, addWorkItem } from '../../utils/graphql-req'
// import { duration } from "moment";

//Custom Components
import StyledTextField from '../../components/StyledTextField'

//Helpers
import { MuiThemeProvider, getMuiTheme } from 'material-ui/styles'

const mainMuiTheme = getMuiTheme({
  flatButton: {
    // buttonFilterColor: "transparent",
    primaryTextColor: '#333541',
  },
  datePicker: {
    selectColor: '#3EC4E5',
    selectTextColor: '#333541',
    headerColor: '#333541',
  },
})

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 265,
    marginBottom: 5,
  },
  radio: {
    display: 'inline-block',
  },
  checkBox: {
    width: 265,
    marginTop: 15,
  },

  '@global': {
    '.MuiRadio-colorPrimary.Mui-checked': {
      color: '#3EC4E5',
    },
    //'.MuiButton-outlined':{
    // border:"1px solid #EEF2E5"
    // }
  },
})

class DuplicateModal extends Component {
  state = {
    startEndValue: 'start',
    missionName: '',
    missionDescription: '',
    blockchain: false,
    missionDate: null,
    activeStep: 0,
    snackBarOpen: false,
  }

  componentDidMount() {
    const { activeMission } = this.props
    this.setState({
      missionName: `Copy of ${activeMission.name}`,
      missionDescription: activeMission.description,
    })
  }

  componentDidUpdate(prevProps) {
    const { activeMission } = this.props
    if (prevProps.activeMission.name !== activeMission.name) {
      this.setState({
        missionName: `Copy of ${activeMission.name}`,
        missionDescription: activeMission.description,
      })
    }
  }

  handleChange = (event) => {
    this.setState({ startEndValue: event.target.value })
  }

  handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked })
  }

  //Calculate the difference between dates
  getDateDifference = (date1, date2) => {
    const startDate = new Date(date1)
    const endDate = new Date(date2)
    const msecondsInDays = 1000 * 60 * 60 * 24
    let days = Math.ceil((endDate - startDate) / msecondsInDays)
    return days
  }

  //Get new date for start to end
  getNewDate = (date, numDays) => {
    const origDate = new Date(date)
    const num = numDays
    origDate.setDate(origDate.getDate() + num)
    return origDate
  }

  //duplicates the motions with selected date as start of first motion
  duplicateByStartDate = (newMotions, oldMotions, duplicatedWorkItems, missionId, userId) => {
    let motionDateDiff = 0
    let startDate = this.state.missionDate
    for (let i = 0; i < oldMotions.length; i++) {
      let duplicateMotion = cloneDeep(oldMotions[i])
      let motionDuration = this.getDateDifference(oldMotions[i].startDate, oldMotions[i].endDate)
      if (i > 0) {
        motionDateDiff = this.getDateDifference(
          oldMotions[i - 1].startDate,
          oldMotions[i].startDate
        )
        startDate = newMotions[i - 1].startDate
      }
      for (let j = 0; j < duplicatedWorkItems.length; j++) {
        if (duplicatedWorkItems[j]['id']) delete duplicatedWorkItems[j]['id']
        if (duplicatedWorkItems[j]['__typename']) delete duplicatedWorkItems[j]['__typename']
        const motionIndex = duplicatedWorkItems[j].motion.findIndex(
          (motionId) => motionId === oldMotions[j].id
        )
        if (motionIndex !== -1) duplicatedWorkItems[j].motion[motionIndex] = oldMotions[j].index
      }
      if (duplicateMotion['id']) delete duplicateMotion['id']
      if (duplicateMotion['__typename']) delete duplicateMotion['__typename']
      duplicateMotion.mission = missionId
      duplicateMotion.comments = []
      duplicateMotion.missionStatus = 'draft'
      duplicateMotion.motionStatus = 'draft'
      duplicateMotion.startDate = this.getNewDate(startDate, motionDateDiff)
      duplicateMotion.endDate = this.getNewDate(duplicateMotion.startDate, motionDuration)
      duplicateMotion.members.forEach((member, index) => {
        if (index === 0) member.uid = userId
        else member.uid = null
        member.status = null
        if (member['__typename']) delete member['__typename']
      })
      newMotions.push(duplicateMotion)
    }
  }

  //duplicates the motion with selected date as end of last motion
  duplicateByEndDate = (newMotions, oldMotions, duplicatedWorkItems, missionId, userId) => {
    let motionDateDiff = 0
    let endDate = this.state.missionDate
    for (let i = oldMotions.length - 1; i >= 0; i--) {
      let duplicateMotion = cloneDeep(oldMotions[i])
      let motionDuration = this.getDateDifference(oldMotions[i].startDate, oldMotions[i].endDate)
      if (i < oldMotions.length - 1) {
        motionDateDiff = this.getDateDifference(oldMotions[i + 1].endDate, oldMotions[i].endDate)
        endDate = newMotions[i + 1].endDate
      }
      for (let j = 0; j < duplicatedWorkItems.length; j++) {
        if (duplicatedWorkItems[j]['id']) delete duplicatedWorkItems[j]['id']
        if (duplicatedWorkItems[j]['__typename']) delete duplicatedWorkItems[j]['__typename']
        const motionIndex = duplicatedWorkItems[j].motion.findIndex(
          (motionId) => motionId === oldMotions[j].id
        )
        if (motionIndex !== -1) duplicatedWorkItems[j].motion[motionIndex] = oldMotions[j].index
      }
      if (duplicateMotion['id']) delete duplicateMotion['id']
      if (duplicateMotion['__typename']) delete duplicateMotion['__typename']
      duplicateMotion.mission = missionId
      duplicateMotion.comments = []
      duplicateMotion.missionStatus = 'draft'
      duplicateMotion.motionStatus = 'draft'
      duplicateMotion.endDate = this.getNewDate(endDate, motionDateDiff)
      duplicateMotion.startDate = this.getNewDate(duplicateMotion.endDate, -motionDuration)
      duplicateMotion.members.forEach((member, index) => {
        if (index === 0) member.uid = userId
        else member.uid = null
        member.status = null
        if (member['__typename']) delete member['__typename']
      })
      newMotions[i] = duplicateMotion
    }
  }

  duplicateMission = async () => {
    const { activeMission, activeMissionMotions, userId, workItems } = this.props
    const duplicatedMission = cloneDeep(activeMission)
    duplicatedMission.creator = userId
    duplicatedMission.name = this.state.missionName
    duplicatedMission.blockchain = this.state.blockchain
    duplicatedMission.description = this.state.missionDescription
    duplicatedMission.status = 'draft'
    duplicatedMission.invitations = []
    duplicatedMission.members = [userId]
    if (duplicatedMission.id) delete duplicatedMission.id
    if (duplicatedMission['__typename']) delete duplicatedMission['__typename']

    const mission = await addMission(duplicatedMission)
    //Save work items
    const duplicatedWorkItems = cloneDeep(workItems)
    const duplicateMotions = []
    if (this.state.startEndValue === 'start') {
      this.duplicateByStartDate(
        duplicateMotions,
        activeMissionMotions,
        duplicatedWorkItems,
        mission.id,
        userId
      )
    } else if (this.state.startEndValue === 'end') {
      this.duplicateByEndDate(
        duplicateMotions,
        activeMissionMotions,
        duplicatedWorkItems,
        mission.id,
        userId
      )
    }

    // push to database
    const motions = []
    for (let i = 0; i < duplicateMotions.length; i++) {
      const motion = await addMotion(duplicateMotions[i])
      motions.push(motion)
      duplicatedWorkItems.forEach((workItem) => {
        const motionIndex = workItem.motion.findIndex((index) => index === motion.index)
        if (motionIndex !== -1) workItem.motion[motionIndex] = motion.id
      })
    }

    duplicatedWorkItems.forEach(async (workItem) => {
      workItem.mission = mission.id
      await addWorkItem(workItem)
    })

    // switch to active mission tab
    this.setState({ snackBarOpen: true })
    this.props.toggleDuplicateDialog()
  }

  render() {
    const { classes, t } = this.props

    return (
      <div>
        <Dialog
          modal="true"
          className="invite-dialog"
          open={this.props.dialogDuplicateWinflow}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle color="primary" id="form-dialog-title">
            {t('newMissionModal.title')}
            <br />
            <span className="second-line">{t('dublicateModal.second.title')}</span>
          </DialogTitle>

          <DialogContent>
            <div className="flex-form">
              <div className="flex50">
                <Tooltip title={t('common.button.cancel')} placement={'bottom'}>
                  <i className="material-icons x-icon" onClick={this.props.toggleDuplicateDialog}>
                    clear
                  </i>
                </Tooltip>
                <StyledTextField
                  id="standard-name"
                  label={t('common.label.whatMission')}
                  className={classes.textField}
                  value={this.state.missionName}
                  maxLength={40}
                  onChange={(event) => this.setState({ missionName: event.target.value })}
                  margin="normal"
                />

                <StyledTextField
                  id="standard-multiline-flexible"
                  label={t('common.description')}
                  multiline
                  rowsMax="4"
                  value={this.state.missionDescription}
                  maxLength={58}
                  onChange={(event) => this.setState({ missionDescription: event.target.value })}
                  className={classes.textField}
                  margin="normal"
                />
              </div>
              <div className="flex50">
                <label className="label">{t('common.label.chooseOne')}:</label>

                <RadioGroup
                  row
                  aria-label="startend"
                  name="startend"
                  className={classes.group}
                  value={this.state.startEndValue}
                  onChange={this.handleChange}
                >
                  <FormControlLabel
                    value="start"
                    control={<Radio color="primary" />}
                    label={t('common.label.startDate')}
                  />
                  <FormControlLabel
                    value="end"
                    control={<Radio color="primary" />}
                    label={t('common.label.completionDate')}
                  />
                </RadioGroup>

                <label className="label">
                  {this.state.startEndValue === 'end'
                    ? t('common.label.shouldDone')
                    : t('common.label.shouldStart')}
                </label>

                <MuiThemeProvider muiTheme={mainMuiTheme}>
                  <DatePicker
                    firstDayOfWeek={0}
                    autoOk={true}
                    hintText={t('common:common.label.date')}
                    style={{ float: 'left' }}
                    locale={'en-US'}
                    onChange={(n, date) => this.setState({ missionDate: date })}
                    value={this.state.missionDate && new Date(this.state.missionDate)}
                  />
                </MuiThemeProvider>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <MainButton
              onClick={this.duplicateMission}
              disabled={!this.state.missionName || !this.state.missionDate}
            >
              {t('common.button.create')}
            </MainButton>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.snackBarOpen}
          onClose={() => {
            this.setState({ snackBarOpen: false })
          }}
          autoHideDuration={3000}
          ContentProps={{ 'aria-describedby': 'message-id' }}
          message={<span id="message-id"> {t('dublicateModal.success')} </span>}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { workspace } = state.instance

  let activeMissionMotions = []
  if (workspace === 'draft') {
    activeMissionMotions = state.database.orgInstanceDraftMotions[state.instance.mission.id]
  } else if (workspace === 'mission') {
    activeMissionMotions = state.database.orgInstanceActiveMotions[state.instance.mission.id]
  } else if (workspace === 'complete') {
    activeMissionMotions = state.database.orgInstanceCompleteMotions[state.instance.mission.id]
  }

  return {
    userId: state.firebase.auth.uid,
    activeOrg: state.instance.org,
    activeMission: state.instance.mission,
    activeMissionMotions,
    workItems: state.database.workItems,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectMission: (winflowID) => dispatch(selectMission(winflowID)),
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation('common')(DuplicateModal)))
