// Modules
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

// Material UI
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// Helpers
import { signOut, showProfileModel } from "../../store/actions";

const styles = {
  avatar: {
    height: "30px",
    width: "30px",
    cursor: "pointer"
  },
  listSubheader: {},
  menuItem: {},
  link: {
    textDecoration: "none"
  },
  iconButton: {
    padding: "9px"
  }
};

class UserMenu extends Component {
  state = {
    valueSingle: "3",
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleShowProfileModel = () => {
    this.handleClose();
    this.props.showProfileModel();
  };

  render() {
    const { classes, user, auth, t } = this.props;
    const { anchorEl } = this.state;

    return (
      <div>
        <IconButton className={classes.iconButton}>
          <Tooltip title={t('common.profile')} placement="bottom-start">
            <Avatar
              onClick={this.handleClick}
              className={classes.avatar}
              src={
                user && user.avatar
                  ? user.avatar
                  : require("../../assets/images/user.jpg")
              }
            />
          </Tooltip>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}>
          <ListSubheader> {auth.email} </ListSubheader>
          <Divider />
          <MenuItem
            style={styles.menuItem}
            onClick={this.handleShowProfileModel}>
            {t('common.myProfile')}
          </MenuItem>
          <MenuItem style={styles.menuItem} onClick={this.props.signOut}>
            {t('common.logout')}
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    user: state.database.userDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
    showProfileModel: () => dispatch(showProfileModel(true))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation('common')(UserMenu)));
