// Libraries
import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

// Material UI
// import Button from "@material-ui/core/Button";
// import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// Custom Components
import MainButton from "../../components/Buttons/MainButton";
// import SecondaryButton from "../../components/Buttons/SecondaryButton";

//Small Modal
import SmallModal from "../../components/Modals/SmallModal";




class LastMotionDialog extends Component {
  render() {
    const { t } = this.props;
    return (
      <SmallModal
        open={this.props.showLastMotionDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {t('lastMotionDialof.title')}
        </DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <MainButton onClick={this.props.hideLastMotionDialog} color="primary">
            {t('common.button.close')}
          </MainButton>
        </DialogActions>
      </SmallModal>
    );
  }
}

export default withTranslation('common')(LastMotionDialog);
