// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Joyride, { EVENTS } from 'react-joyride'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

// Material UI
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Icon from '@material-ui/core/Icon'
import { grey } from '@material-ui/core/colors'
import Divider from '@material-ui/core/Divider'
import ListSubheader from '@material-ui/core/ListSubheader'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import WelcomeScreenContent from './WelcomeScreenContent'

// Custom Components
import MainButton from '../../components/Buttons/MainButton'

// Helpers
import { updateUser } from '../../utils/graphql-req'

const styles = {
  icon: {
    cursor: 'pointer',
    color: grey[200],
    '&:hover': {
      color: grey[400],
    },
  },
  listSubheader: {},
  menutItem: {},
  link: {
    textDecoration: 'none',
  },
  optionIcons: {
    marginRight: '0.8rem',
  },
}

const menuItems = [
  {
    icon: 'question_answer',
    text: 'faq',
    url: '//hi-flier.com/faq-2/',
    target: '_blank',
  },
  {
    icon: 'chat_bubble',
    text: 'contact_us',
    url: '//hi-flier.com/contact-2/',
    target: '_blank',
  },
  {
    icon: 'list_alt',
    text: 'terms_of_use',
    url: '//hi-flier.com/beta-terms-of-use/',
    target: '_blank',
  },
  {
    icon: 'vpn_key',
    text: 'privacy_policy',
    url: '//hi-flier.com/privacy-policy/',
    target: '_blank',
  },
]

const TooltipBody = styled.div`
  background-color: #fff;
  min-width: 290px;
  max-width: 550px;
  position: relative;
`

const TooltipContent = styled.div`
  color: #000;
  padding: 20px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  h1 {
    text-align: center;
  }
  img {
    width: 80%;
  }
  p {
    margin: 10px;
  }
`

const TooltipFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 15px 15px 15px;

  p {
    margin: 10px 15px;
    color: #333541;
    font-weight: bold;
    cursor: pointer;
  }
  div {
    display: flex;
    align-items:center button {
      text-transform: none;
    }
  }
`

const toolTip = withTranslation('common')(
  ({
    continuous,
    index,
    step,
    closeProps,
    primaryProps,
    tooltipProps,
    skipProps,
    backProps,
    t,
  }) => (
    <TooltipBody {...tooltipProps}>
      <TooltipContent>
        {step.content}
        <Tooltip title={'Close'} placement={'bottom'}>
          <i {...skipProps} className="material-icons x-icon">
            clear
          </i>
        </Tooltip>
      </TooltipContent>
      <TooltipFooter>
        {step.showSkipButton && <p {...skipProps}>{t('common.button.skip')}</p>}
        {step.showNextButton && <p {...primaryProps}>{t('common.button.next')}</p>}
        {step.action && <MainButton {...primaryProps}>{t(step.action)}</MainButton>}
      </TooltipFooter>
    </TooltipBody>
  )
)

class HelpMenuItem extends Component {
  state = {
    valueSingle: '3',
    anchorEl: null,
    run: false,
    steps: [
      {
        target: 'body',
        placement: 'center',
        action: 'helpMenu.steps.1.locale',
        showSkipButton: true,
        content: (
          <WelcomeScreenContent
            header="helpMenu.steps.1.h1"
            imgSrc={require('../../assets/images/Quick1.png')}
            alt="Quick1"
            subText={['helpMenu.steps.1.p.1', 'helpMenu.steps.1.p.2']}
          />
        ),
      },
      {
        target: 'body',
        placement: 'center',
        showNextButton: true,
        content: (
          <WelcomeScreenContent
            header="helpMenu.steps.2.h1"
            imgSrc={require('../../assets/images/Quick2.png')}
            alt="Quick2"
            subText={['helpMenu.steps.2.p.1']}
          />
        ),
      },
      {
        target: 'body',
        placement: 'center',
        showNextButton: true,
        content: (
          <WelcomeScreenContent
            header="helpMenu.steps.3.h1"
            imgSrc={require('../../assets/images/Quick3.png')}
            alt="Quick3"
            subText={['helpMenu.steps.3.p.1', 'helpMenu.steps.3.p.2']}
          />
        ),
      },
      {
        target: 'body',
        placement: 'center',
        action: 'helpMenu.steps.4.locale',
        content: (
          <WelcomeScreenContent
            header="helpMenu.steps.4.h1"
            imgSrc={require('../../assets/images/Quick4.png')}
            alt="Quick4"
            subText={['helpMenu.steps.4.p.1', 'helpMenu.steps.4.p.2']}
          />
        ),
      },
    ],
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.userDetails && !nextProps.userDetails.userOnboarded && !this.state.run)
      this.setState({ run: true, anchorEl: null })
    return true
  }

  handleClickStart = (event) => {
    this.setState({ run: true, anchorEl: null })
  }

  handleJoyrideCallback = (data) => {
    const { type } = data

    if (type === EVENTS.TOUR_END && this.state.run) {
      this.setState({ run: false })

      if (this.props.userDetails && !this.props.userDetails.userOnboarded) {
        // console.log('user details in conditional', this.props.userDetails)
        // remove invitation from user
        const updatedUser = _.cloneDeep(this.props.userDetails)
        updatedUser.userOnboarded = true
        // update user
        if (updatedUser['__typename']) delete updatedUser['__typename']
        if (!_.isEmpty(updatedUser.invitations)) {
          updatedUser.invitations.forEach((invitation) => {
            if (invitation['__typename']) delete invitation['__typename']
            if (invitation.org && invitation.org['__typename']) delete invitation.org['__typename']
            if (invitation.mission && invitation.mission['__typename'])
              delete invitation.mission['__typename']
          })
        }
        // console.log('updated user in help menu', updatedUser)
        updateUser(updatedUser)
      }
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { classes, t } = this.props
    const { anchorEl } = this.state

    const HelpMenuItems = Object.keys(menuItems).map((key) => (
      <Link key={key} style={styles.link} to={menuItems[key].url} target={menuItems[key].target}>
        <MenuItem
          value={t(menuItems[key].text)}
          onClick={this.handleClose}
          style={styles.menutItem}
        >
          <Icon className={classes.optionIcons + ' material-icons'}>{menuItems[key].icon}</Icon>
          {t(menuItems[key].text)}
        </MenuItem>
      </Link>
    ))

    return (
      <div>
        <Joyride
          continuous
          hideBackButton
          steps={this.state.steps}
          run={this.state.run}
          callback={this.handleJoyrideCallback}
          tooltipComponent={toolTip}
        />
        {/* <Tooltip title="Help" placement="bottom-start"> */}
        <HelpOutlineIcon
          className={classes.icon + ' material-icons ' + 'help-icon'}
          onClick={this.handleClick}
        >
          help
        </HelpOutlineIcon>
        {/* </Tooltip> */}
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <div>
            <ListSubheader style={styles.listSubheader}>{t('common.label.help')}</ListSubheader>
            <Divider />
          </div>
          <MenuItem onClick={this.handleClickStart}>
            <Icon className={'material-icons'} style={{ marginRight: '12.8px' }}>
              school
            </Icon>
            {t('helpMenu.intro')}
          </MenuItem>
          {HelpMenuItems}
        </Menu>
      </div>
    )
  }
}

HelpMenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    userDetails: state.database.userDetails,
  }
}

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation('common')(HelpMenuItem)))
