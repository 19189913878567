import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Snackbar from 'material-ui/Snackbar'
import { withTranslation } from 'react-i18next';

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
// import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
// import WebIcon from '@material-ui/icons/Web'
import LanguageIcon from '@material-ui/icons/Language'
import SvgIcon from '@material-ui/core/SvgIcon';

// Custom Components
import MainButton from '../../components/Buttons/MainButton'
import SecondaryButton from '../../components/Buttons/SecondaryButton'

// Helpers
import { langs } from '../../utils/i18n';
import { updateOrg, addLog } from '../../utils/graphql-req';
import { deleteUserFromOrgLocal } from '../../store/actions'
import { envEndpoint } from '../../utils/firebase-service';

const styles = {
  icon: {
    color: '#333541',
    cursor: 'pointer',
    marginRight: '15px',
    marginLeft: '8px',
    marginBlock: 'auto',
    '&:hover': {
      fontWeight: 'bold',
    },
  },
  iconError: {
    cursor: 'pointer',
    marginBlock: 'auto',
    '&:hover': {
      fontWeight: 'bold',
    },
  },
  mainContainer: {
    justifyContent: 'center',
  },
  linkIcon: {
    color: '#333541',
    marginRight: '3px',
    '&:hover': {
      cursor: 'pointer',
      color: '#3fc4e5',
    },
  },
  langIcon: {
    width: '21px',
    height: '15px',
    marginRight: '4.5px', 
    marginBottom: '4.5px', 
    borderRadius: '3px',
  }
}

class PersonInstance extends Component {
  state = {
    showConfirmRemoveUserDialog: false,
    showConfirmReinviteUserDialog: false,
    hasActiveWinflows: false,
    error: false,
    errorMessage: '',
  }

  openConfirmRemoveUserDialog = () => {
    this.setState({ showConfirmRemoveUserDialog: true, hasActiveWinflows: false })
  }

  openConfirmReinviteUserDialog = () => {
    this.setState({ showConfirmReinviteUserDialog: true })
  }

  hideConfirmRemoveUserDialog = () => {
    this.setState({ showConfirmRemoveUserDialog: false })
  }

  hideConfirmReinviteUserDialog = () => {
    this.setState({ showConfirmReinviteUserDialog: false })
  }

  handleSubmitRemoveUserFromOrg = () => {
    this.setState({ showConfirmRemoveUserDialog: false })
  }

  inviteUser = async () => {
    const { activeOrg, user, userDetails } = this.props

    const inviteDetails = {
      address: user.email,
      invited: user.fullName,
      inviter: userDetails.fullName,
      org: activeOrg.name,
      orgId: activeOrg.id,
    }

    try {
      await fetch(`${envEndpoint}/invites/sendInviteNewHF`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inviteDetails: inviteDetails,
        }),
      })
      this.hideConfirmReinviteUserDialog();
    } catch (err) {
      console.log(err)
      this.setState({ error: true, errorMessage: err })
    }
  }

  deleteUserFromOrg = async (user) => {
    if (this.props.activeOrg && user && user.id) {
      const updatedOrg = { ...this.props.activeOrg }
      if (updatedOrg.members) {
        const targetIndex = updatedOrg.members.findIndex((member) => member === user.id)
        if (targetIndex !== -1) {
          updatedOrg.members.splice(targetIndex, 1)
        }
      }
      // update org
      if (updatedOrg['__typename']) delete updatedOrg['__typename']
      const deletedOrg = await updateOrg(updatedOrg)
      this.props.deleteUserFromOrgLocal(deletedOrg, user)
      this.hideConfirmRemoveUserDialog()
      this.props.hideManagePeopleDialog()
      if (user.id === this.props.userDetails.id) {
        const actionUser = user.fullName ? user.fullName : user.email
        var logText = `${actionUser} has left the Mission`;
        const missions = [];
        const activeMotions = [];
        Object.values(this.props.instanceActiveMotions).forEach(item => {activeMotions.push(...item)});
        activeMotions.forEach(item => {
          if (missions.indexOf(item.mission) === -1) {
            missions.push(item.mission);
          }
        });
        await Promise.all(missions.map((item) => {
          const log = {
            mission: item,
            date: Date().toString(),
            text: logText,
          }
          return addLog(log);
        }));
        document.location.reload();
      }
    }
  }

  handleRequestClose = () => {
    this.setState({
      error: false,
      errorMessage: '',
    })
  }

  shortenedName = (name) => {
    let newName
    if (name.length > 35) {
      newName = name.slice(0, 17) + '...' + name.slice(name.length - 16)
      return newName
    } else {
      return name
    }
  }

  reinviteButton = (needsReinvite) => {
    return needsReinvite ? (
      <Tooltip title={this.props.t('personContainer.notSigned')} placement={'right'}>
        {/* <img src={require('../../assets/images/overdue.svg')} alt="overdue" /> */}
        <Icon color="error" style={styles.iconError} onClick={this.openConfirmReinviteUserDialog}>
          error
        </Icon>
      </Tooltip>
    ) : null;
  }

  userLanguage = (user) => {
    const { i18n } = this.props;
    const lang = langs.find(item => item.key === user?.language);
    if (lang) {
      return (
        <SvgIcon
          width="26" height="20" viewBox="0 0 26 20"
          component={lang.icon}
          style={styles.langIcon}
        />
      )
    } else {
      return (
        <SvgIcon
          width="26" height="20" viewBox="0 0 26 20"
          component={langs.find(item => item.key === i18n.options.fallbackLng[0]).icon}
          style={styles.langIcon}
        />
      )
    }
  }

  render() {
    const { user, activeOrg, userDetails, t } = this.props;
    const needsReinvite = user.userOnboarded === null && user.id !== userDetails.id;

    return (
      // <div>
      <div key={user.id}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ display: 'flex' }}>
            <img
              src={user && user.avatar ? user.avatar : require('../../assets/images/user.jpg')}
              alt="user avatar"
              style={{
                width: '40px',
                height: '40px',
                marginRight: '10px',
                marginBottom: '10px',
                // marginTop: '5px',
                borderRadius: '50%',
                border: '1px solid white',
              }}
            />
            {activeOrg.owner.find((userId) => user.id === userId) ? (
              <span>
                <div
                  style={{
                    display: 'block',
                    lineHeight: '12px',
                    // fontWeight: "300",
                    minWidth: '180px',
                    // marginRight: "50px",
                    marginBottom: '3px',
                    marginTop: '5px',
                  }}
                >
                  {user.fullName ? `${user.fullName} (Owner)` : `${user.email} (Owner)`}
                </div>
                {/* <br/> */}
                <div
                  style={{
                    display: 'block',
                    lineHeight: '12px',
                    fontWeight: '200',
                    minWidth: '180px',
                    // maxWidth: '180px',
                    fontSize: 'smaller',
                    marginBottom: '5px',
                    // marginRight: "50px"
                    // width: 'calc(100vw - 1090px)',
                  }}
                >
                  {this.shortenedName(user.whatIDo)}
                </div>
                {this.userLanguage(user)}
                {user.linkedIn && (
                  <a
                    href={
                      user.linkedIn.slice(0, 8) === 'https://'
                        ? user.linkedIn
                        : 'https://' + user.linkedIn
                    }
                    target="_blank"
                    style={styles.linkIcon}
                  >
                    <Tooltip title={`${user.fullName.split(' ')[0]}'s LinkedIn`}>
                      <LinkedInIcon style={{ width: '20px' }} />
                    </Tooltip>
                  </a>
                )}
                {user.personalWebsite && (
                  <a
                    href={
                      user.personalWebsite.slice(0, 8) === 'https://'
                        ? user.personalWebsite
                        : 'https://' + user.personalWebsite
                    }
                    target="_blank"
                    style={styles.linkIcon}
                  >
                    <Tooltip title={`${user.fullName.split(' ')[0]}'s web link`}>
                      <LanguageIcon style={{ width: '20px' }} />
                    </Tooltip>
                  </a>
                )}
              </span>
            ) : (
              <span>
                <div
                  style={{
                    display: 'block',
                    lineHeight: '12px',
                    // fontWeight: "300",
                    minWidth: '180px',
                    // maxWidth: '180px',
                    // marginRight: "50px",
                    marginBottom: '3px',
                    marginTop: '5px',
                  }}
                >
                  {user.fullName ? user.fullName : user.email}
                </div>
                {/* <br /> */}
                <div
                  style={{
                    display: 'block',
                    lineHeight: '12px',
                    fontWeight: '200',
                    minWidth: '180px',
                    fontSize: 'smaller',
                    // whiteSpace: 'pre',
                    marginBottom: '5px',
                    // width: 'calc(100vw - 1090px)',
                    // marginRight: "50px"
                  }}
                >
                  {this.shortenedName(user.whatIDo)}
                </div>
                {this.userLanguage(user)}
                {user.linkedIn && (
                  <a
                    href={
                      user.linkedIn.slice(0, 8) === 'https://'
                        ? user.linkedIn
                        : 'https://' + user.linkedIn
                    }
                    target="_blank"
                    className={styles.linkIcon}
                  >
                    <Tooltip title={`${user.fullName.split(' ')[0]}'s LinkedIn`}>
                      <LinkedInIcon style={{ width: '20px' }} />
                    </Tooltip>
                  </a>
                )}
                {user.personalWebsite && (
                  <a
                    href={
                      user.personalWebsite.slice(0, 8) === 'https://'
                        ? user.personalWebsite
                        : 'https://' + user.personalWebsite
                    }
                    target="_blank"
                    style={styles.linkIcon}
                  >
                    <Tooltip title={t('personContainer.website', {name: user.fullName.split(' ')[0]})}>
                      <LanguageIcon style={{ width: '20px' }} />
                    </Tooltip>
                  </a>
                )}
              </span>
            )}
          </span>
          {activeOrg.owner.find((userId) => user.id === userId) ||
          activeOrg.owner.find((userId) => userDetails.id !== userId) ? (
            <div
              style={{
                marginRight: '15px',
                marginLeft: '10px',
                display: 'flex'
              }}
            >
              {this.reinviteButton(needsReinvite)}
            </div>
          ) : (
            <div style={{display: 'flex'}}>
              {this.reinviteButton(needsReinvite)}
              <Tooltip title={t('personContainer.removeMemder')} placement={'right'}>
                <Icon style={styles.icon} onClick={this.openConfirmRemoveUserDialog}>
                  cancel
                </Icon>
              </Tooltip>
            </div>
          )}
          {
            userDetails.id === user.id && activeOrg.owner.find((userId) => user.id !== userId) && (
              <div style={{display: 'flex'}}>
                <Tooltip title={t('personContainer.leaveOrg')} placement={'right'}>
                  <Icon style={styles.icon} onClick={this.openConfirmRemoveUserDialog}>
                    cancel
                  </Icon>
                </Tooltip>
              </div>
            )
          }
        </div>
        {/* </div> */}

        {/* Confirm Remove User From Org Dialog */}
        <Dialog
          open={this.state.showConfirmRemoveUserDialog}
          onClose={this.hideConfirmRemoveUserDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{userDetails.id === user.id ? t('personContainer.leaveOrg') : t('personContainer.removeMemder')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {userDetails.id === user.id ? t('personContainer.description.myself') : t('personContainer.description')}
            </DialogContentText>
            {this.state.hasActiveWinflows && (
              <DialogContentText id="alert-dialog-description">
                {t('personContainer.description.2')}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={this.hideConfirmRemoveUserDialog} color="primary">
              {t('common.button.cancel')}
            </SecondaryButton>
            <MainButton onClick={() => this.deleteUserFromOrg(user)} color="primary" autoFocus>
              {t('common.button.confirm')}
            </MainButton>
          </DialogActions>
        </Dialog>

        {/* Confirm Re-invite User Dialog */}
        <Dialog
          open={this.state.showConfirmReinviteUserDialog}
          onClose={this.hideConfirmReinviteUserDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t('personContainer.reinvite')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('personContainer.reinvite.description')}
            </DialogContentText>
            {
              user.fullName ? (
                <>
                  <DialogContentText id="alert-dialog-description">
                    {user.fullName}
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description">
                    {user.email}
                  </DialogContentText>
                </>
              ) : (
                <DialogContentText id="alert-dialog-description">
                  {user.email}
                </DialogContentText> 
              )
            }
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={this.hideConfirmReinviteUserDialog} color="primary">
              {t('common.button.cancel')}
            </SecondaryButton>
            <MainButton onClick={() => this.inviteUser()} color="primary" autoFocus>
              {t('common.button.reinvite')}
            </MainButton>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={this.state.error}
          message={this.state.errorMessage}
          autoHideDuration={4000}
          onRequestClose={this.props.handleRequestClose}
          style={{ backgroundColor: 'red', color: 'white' }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, passed) => {
  return {
    activeOrg: state.instance.org,
    userDetails: state.database.userDetails,
    instanceActiveMotions: state.database.orgInstanceActiveMotions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteUserFromOrgLocal: (deletedOrg, user) =>
      dispatch(deleteUserFromOrgLocal(deletedOrg, user)),
  }
}

PersonInstance.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation('common')(PersonInstance)))
