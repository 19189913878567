// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Droppable } from 'react-beautiful-dnd'
import _, { get } from 'lodash'
import { withTranslation } from 'react-i18next';

// Material UI
import Icon from '@material-ui/core/Icon'

// Custom Components
import MissionMeta from '../MissionMeta'
import MissionStart from '../MissionStart'
import Motion from '../Motion'
import SideDrawerEdit from '../SideDrawerEdit'
import SideDrawerLog from '../SideDrawerLog'
import SideDrawerRepeat from '../SideDrawerRepeat'

// helpers
import { selectMission, selectWorkspace, selectMotion } from '../../store/actions'
import { getOrgInstanceActiveMissions } from '../../utils/graphql-req'
import { userRoles } from '../../utils/handleRoles'

const isRoleAccountable = (role) => get(userRoles, [role, 'roleGroup'], '') === 'accountable'

class ActiveSpace extends Component {
  state = {
    showSideDrawerEdit: false,
    showSideDrawerLog: false,
    showSideDrawerRepeat: false,
  }

  motionComponents = []

  handleSideDrawersToggle = (drawerName) => {
    if (drawerName === 'edit') {
      this.setState({
        showSideDrawerEdit: !this.state.showSideDrawerEdit,
      })
    } else if (drawerName === 'log') {
      this.setState({
        showSideDrawerLog: !this.state.showSideDrawerLog,
      })
    } else if (drawerName === 'repeat') {
      this.setState({
        showSideDrawerRepeat: !this.state.showSideDrawerRepeat,
      })
    }
  }

  async componentDidMount() {
    const { missions, activeMission } = this.props

    // Added for condition where selectiong mission from home page
    const orgMissions = await getOrgInstanceActiveMissions(
      this.props.auth.uid,
      this.props.activeOrg.id
    )

    const sortedMissions = missions.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    )

    //*********
    // This solves the problem of not having an active mission selected
    //*********
    if (_.isEmpty(activeMission) && missions.length > 0) this.props.selectMission(sortedMissions[0])

    //*********
    // This solves the problem of selecting an 'active mission' missionId
    // when the currently missionId is a 'draft mission', for cases when
    // the user is switching from draft mission component to active mission
    // component
    //*********
    if (
      activeMission &&
      missions.findIndex((mission) => mission.id === activeMission.id) === -1 &&
      // Added below line for when selecting mission from home page where missions isnt updated right away and fails above conditional
      orgMissions.findIndex((mission) => mission.id === activeMission.id) === -1
    ) {
      this.props.selectMission(sortedMissions[0])
    }
  }

  getMotionComponents = () => {
    const { activeMission, activeMotion, motions, uid } = this.props

    if (this.props.motions && this.props.motions[this.props.activeMission.id]) {
      // Setup needed variables
      const motionsForThisMission = motions[activeMission.id]
      if (!_.isEmpty(motionsForThisMission) && activeMission) {
        const isOwner = activeMission.creator === uid
        let activeMotionSet = false
        if (activeMotion && activeMotion.id) {
          motionsForThisMission.forEach((motion) => {
            if (motion.id === activeMotion.id) activeMotionSet = true
          })
        }

        this.motionComponents = []
        // Create a list of motionComponents based on order
        motionsForThisMission
          .sort((a, b) => a.index - b.index)
          .forEach((motion) => {
            if (motion.motionStatus && motion.org && motion.mission) {
              if (!activeMotionSet && motion.motionStatus !== 'complete') {
                const userInMotion =
                  motion.members &&
                  motion.members[uid] &&
                  (isRoleAccountable(motion.members[uid].role) || isOwner)
                // const userInMotion = motion.members && motion.members.find(member => member.uid === uid  && isRoleAccountable(member.role) || isOwner)
                if (!motion.private || userInMotion) {
                  activeMotionSet = true
                  this.props.selectMotion(motion)
                }
              }
              this.motionComponents.push(<Motion passedMotion={motion} key={motion.id} />)
            }
          })

        this.motionComponents.push(
          <div key="star-graphic-2">
            <div className="star-graphic microflowWrapper1">
              <img src={require('../../assets/images/flag-grey.svg')} alt="flag" />
            </div>
          </div>
        )
      }
    }
  }

  render() {
    const { activeMission, motions, uid, t } = this.props

    // if mission and motions haven't loaded, return empty early
    if (_.isEmpty(motions) || _.isEmpty(activeMission)) {
      return (
        <div className={'emptystate'}>
          <div>
            <p> {t('activeSpace.emptystate.p')} </p>
            <p className={'blue-link'} onClick={() => this.props.selectWorkspace('notifications')}>
              {' '}
              {t('common.space.emptystate.create')}{' '}
            </p>
          </div>
        </div>
      )
    }

    this.getMotionComponents()

    return (
      <div className="winflow">
        {
          <Droppable
            droppableId={activeMission.id}
            direction="horizontal"
            type="MOTION"
            isDropDisabled={
              activeMission.status === 'complete' ||
              activeMission.status === 'cancelled' ||
              activeMission.creator !== uid
            }
          >
            {(provided) => (
              <div
                className="workspace-canvas"
                ref={provided.innerRef}
                style={{
                  paddingRight: this.state.showSideDrawerEdit && '250px',
                }}
              >
                <div className="microflowsWrapper">
                  <MissionStart />
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    {this.motionComponents}
                  </div>
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        }

        <MissionMeta />

        <SideDrawerEdit
          showSideDrawerEdit={this.state.showSideDrawerEdit}
          handleSideDrawersToggle={this.handleSideDrawersToggle}
        />

        <SideDrawerLog
          showSideDrawerLog={this.state.showSideDrawerLog}
          handleSideDrawersToggle={this.handleSideDrawersToggle}
        />

        <SideDrawerRepeat
          showSideDrawerRepeat={this.state.showSideDrawerRepeat}
          handleSideDrawersToggle={this.handleSideDrawersToggle}
        />

        <div className="edit-button-container">
          <div onClick={() => this.handleSideDrawersToggle('edit')} className="edit-button">
            <Icon key={1} className="material-icons small-space">
              arrow_left
            </Icon>
            <div className="edit-button-text">{t('common.button.flierControl')}</div>
          </div>

          <div onClick={() => this.handleSideDrawersToggle('log')} className="edit-button">
            <Icon key={2} className="material-icons small-space">
              arrow_left
            </Icon>
            <div className="edit-button-text">{t('common.button.logbook')}</div>
          </div>

          <div onClick={() => this.handleSideDrawersToggle('repeat')} className="edit-button">
            <Icon key={2} className="material-icons small-space">
              arrow_left
            </Icon>
            <div className="edit-button-text">{t('common.button.autopilot')}</div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  //   // processing orgs
  //   const orgs = {};
  //   if(state.firestore.data.orgs) {
  //     Object.keys(state.firestore.data.orgs).forEach(orgKey => {
  //       if(state.firestore.data.orgs[orgKey]) orgs[orgKey] = state.firestore.data.orgs[orgKey]
  //     })
  //   }

  //   // processing instance active missions
  // 	let instanceActiveMissions = null;
  //   Object.keys(state.firestore.data).forEach(key => {
  //     if(key.includes(`instanceActiveMissions_${state.instance.orgId}`)) instanceActiveMissions = (state.firestore.data[key])
  //   })

  //   // processing motions only for all active missions in selected org
  //   let instanceActiveMissionsMotions = null;
  //   Object.keys(state.firestore.data).forEach(key => {
  //     if(key.includes(`instanceActiveMissionsMotions_${state.instance.missionId}`))
  //       instanceActiveMissionsMotions = { [key.substring(30, key.length)]: (state.firestore.data[key]) }
  //   })

  //   let missions = [];
  //   if(instanceActiveMissions) Object.keys(instanceActiveMissions).forEach(missionId => {
  //     missions.push({
  //       ...instanceActiveMissions[missionId],
  //       id: missionId
  //     })
  //   })

  //   let mission = null;
  //   if(instanceActiveMissions && instanceActiveMissions[state.instance.missionId])
  //     mission = {
  //       ...instanceActiveMissions[state.instance.missionId],
  //       id: state.instance.missionId
  //     };

  //   const motions = [];
  //   if(instanceActiveMissionsMotions && instanceActiveMissionsMotions[state.instance.missionId]) {
  //     Object.keys(instanceActiveMissionsMotions[state.instance.missionId]).forEach(motionId => {
  //       motions.push({ ...instanceActiveMissionsMotions[state.instance.missionId][motionId], id: motionId})
  //     })
  //   }

  //   let motion = null;
  //   if(!_.isEmpty(motions)) {
  //     motions.forEach(motionObj => {
  //       if(motionObj.id === state.instance.motionId) motion = motionObj;
  //     })
  //   }

  return {
    // orgs,
    auth: state.firebase.auth,
    workspace: state.instance.workspace,
    activeOrg: state.instance.org,
    activeMission: state.instance.mission,
    activeMotion: state.instance.motion,
    uid: state.firebase.auth.uid,
    missions: state.database.orgInstanceActiveMissions,
    motions: state.database.orgInstanceActiveMotions,
    // updatingWinflow: state.instance.updatingWinflow,
    // missions,
    // mission,
    // motions,
    // motion
  }
}

const mapDispatchToProps = (dispatch) => ({
  selectMotion: (motionID) => dispatch(selectMotion(motionID)),
  selectMission: (missionId) => dispatch(selectMission(missionId)),
  selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ActiveSpace))
