// Modules
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Avatar from 'react-avatar-edit'
import { cloneDeep } from 'lodash'
import { withTranslation } from 'react-i18next';

// Material UI components
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Tooltip from '@material-ui/core/Tooltip'
// import Snackbar from "@material-ui/core/Snackbar";

// Custom Components
// import ChangePasswordDialog from "./components/ChangePassword";
import MainButton from '../../components/Buttons/MainButton'
import SecondaryButton from '../../components/Buttons/SecondaryButton'
import StyledTextField from '../../components/StyledTextField'
import LanguageSwitcher from '../../components/LanguageSwitcher';

// Helpers
import * as actions from '../../store/actions'
import { updateUser } from '../../utils/graphql-req'
import { storage, envEndpoint } from '../../utils/firebase-service'
import { updateOrg } from '../../utils/graphql-req';
import { signOut } from "../../store/actions";

import members from '../../assets/images/stats-person.svg'

const styles = {
  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0px 100px 0px 50px',
    minWidth: '200px',
  },
  textContainer: {
    marginRight: '130px',
  },
  profileTitleText: {
    textAlign: 'center',
    fontSize: '24px',
  },
  avatarContainer: {
    margin: '0px 0px 30px 0px',
  },
  lastLine: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '8px',
    alignItems: 'start',
  },
  deleteBorder: {
    border: '4px solid #F16664',
  },
}

class ProfileModel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      submitEnabled: true,
      showChangePassword: false,
      showDeleteAccount: false,
      showEditPicture: false,
      showSnackbar: false,
      snackbarMessage: '',
      fullName: '',
      whatIDo: '',
      phoneNumber: '',
      personalWebsite: '',
      linkedIn: '',
      language: '',
    }
  }

  componentDidMount() {
    // if (!_.isEmpty(this.props.users)) this.userComponents = this.getOrgUser()

    const { user, i18n } = this.props
    if (user && user.fullName) this.setState({ fullName: user.fullName })
    // else this.setState({ organizationName: '' })

    if (user && user.whatIDo) this.setState({ whatIDo: user.whatIDo })
    // else this.setState({ whatIDo: '' })

    if (user && user.phoneNumber) this.setState({ phoneNumber: user.phoneNumber })
    // else this.setState({ phoneNumber: '' })

    if (user && user.linkedIn) this.setState({ linkedIn: user.linkedIn })
    // else this.setState({ linkedIn: '' })

    if (user && user.personalWebsite) this.setState({ personalWebsite: user.personalWebsite })
    // else this.setState({ personalWebsite: '' })

    this.setState({ language: user.language || i18n.language });
  }

  handleChange = (name) => (event) => {
    switch (name) {
      case 'fullName':
        this.setState({ fullName: event.target.value })
        // event.target.value === 0
        //   ? this.setState({ submitEnabled: false })
        //   : this.setState({ submitEnabled: true })
        return
      case 'whatIDo':
        this.setState({ whatIDo: event.target.value })
        return
      case 'phoneNumber':
        this.setState({ phoneNumber: event.target.value })
        return
      case 'personalWebsite':
        this.setState({ personalWebsite: event.target.value })
        return
      case 'linkedIn':
        this.setState({ linkedIn: event.target.value })
        return
      case 'language':
        this.setState({ language: event.target.value })
        return
      default:
        return
    }
  }

  handleSubmit = async () => {
    const { auth, user, i18n } = this.props
    this.props.hideProfileModel()

    if (!auth.uid) return

    const updatedUser = cloneDeep(user)
    updatedUser.avatar = user.avatar ? user.avatar : ''
    updatedUser.fullName = this.state.fullName

    updatedUser.phoneNumber = this.state.phoneNumber
    updatedUser.whatIDo = this.state.whatIDo
    updatedUser.linkedIn = this.state.linkedIn
    updatedUser.personalWebsite = this.state.personalWebsite
    updatedUser.language = this.state.language

    if (updatedUser['__typename']) delete updatedUser['__typename']
    if (updatedUser.invitations) {
      updatedUser.invitations.forEach((invitation) => {
        if (invitation['__typename']) delete invitation['__typename']
        if (invitation.mission && invitation.mission['__typename'])
          delete invitation.mission['__typename']
        if (invitation.org && invitation.org['__typename']) delete invitation.org['__typename']
      })
    }

    // push to DB (listeners will update the views)
    const result = await updateUser(updatedUser)
    i18n.changeLanguage(updatedUser.language);
    this.props.updateUser(result)
  }

  onCropImg = (img) => {
    fetch(img)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], 'profile.jpg')
        this.props.setPreview(img)
        this.file = file
        this.props.setLoadedFile(file)
      })
  }

  handleSave = async () => {
    const { user } = this.props

    if (!this.file || !this.props.previewImage) return
    this.handleHideEditPicture()

    try {
      await storage()
        .ref(user.id + '/profilePic/' + this.file.name)
        .put(this.file)
      const url = await storage()
        .ref(user.id + '/profilePic')
        .child('profile.jpg')
        .getDownloadURL()
      const updatedUser = cloneDeep(user)
      if (updatedUser['__typename']) delete updatedUser['__typename']
      if (updatedUser.invitations) {
        updatedUser.invitations.forEach((invitation) => {
          if (invitation['__typename']) delete invitation['__typename']
          if (invitation.mission && invitation.mission['__typename'])
            delete invitation.mission['__typename']
          if (invitation.org && invitation.org['__typename']) delete invitation.org['__typename']
        })
      }
      updatedUser.avatar = url
      await updateUser(updatedUser)
    } catch (err) {
      console.log(err)
    }
  }

  handleShowPasswordChange = () => {
    this.props.showPasswordResetModal()
    // this.setState({ showChangePassword: true });
  }

  handleShowDeleteAccount = () => {
    this.setState({ showDeleteAccount: true })
  }

  handleCloseDeleteAccount = () => {
    this.setState({ showDeleteAccount: false })
  }

  // handleHidePasswordChange = () => {
  //   this.setState({ showChangePassword: false });
  // };

  handleShowEditPicture = () => {
    this.setState({ showEditPicture: true })
  }

  handleHideEditPicture = () => {
    this.setState({ showEditPicture: false })
  }

  submitDeleteAccount = async () => {
    const { userOrgs, user } = this.props;
    await Promise.all(userOrgs.map(async (org) => {
      if (org.owner.find(item => item === user.id)) {
        const updatedOrg = cloneDeep(org);
        updatedOrg.status = 'inactive';
        if (updatedOrg['__typename']) delete updatedOrg['__typename'];
        await updateOrg(updatedOrg);
      } else {
        const updatedOrg = cloneDeep(org);
        updatedOrg.members = [...updatedOrg.members.filter(item => item !== user.id)];
        if (updatedOrg['__typename']) delete updatedOrg['__typename'];
        await updateOrg(updatedOrg);
      }
    }));
    this.props.signOut();
    const response = await fetch(`${envEndpoint}/auth/deleteUser`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId: user.id }),
    })

    if (response.status >= 300) {
      console.log('Error!')
      throw new Error('Something Went Wrong!')
    }
  }

  render() {
    const { user, profileModelState, t } = this.props
    const {
      showEditPicture,
      fullName,
      whatIDo,
      phoneNumber,
      linkedIn,
      personalWebsite,
    } = this.state
    // console.log(this.props)

    // console.log('the user: ', user)

    if (!user) return <div />

    return (
      <>
        <Dialog
          open={profileModelState}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="md"
        >
          {/* {showChangePassword === false && ( */}
          <div>
            <DialogTitle id="form-dialog-title" style={styles.profileTitleText}>
              {t('profile.update')}
            </DialogTitle>
            <DialogContent style={{ display: 'flex' }}>
              <Tooltip title={t('common.button.cancel')} placement={'bottom'}>
                <i
                  className="material-icons x-icon"
                  onClick={() => {
                    this.props.hideProfileModel()
                    this.handleHideEditPicture()
                  }}
                >
                  clear
                </i>
              </Tooltip>

              {showEditPicture === false && (
                <div style={styles.imgContainer}>
                  {user.avatar ? (
                    <img className="avatarPadding" src={user.avatar} alt="avatar" />
                  ) : (
                    <img className="avatarPadding" src={members} alt="avatar" />
                  )}
                  <SecondaryButton width={500} onClick={this.handleShowEditPicture}>
                    {t('orgSetting.change.photo')}
                  </SecondaryButton>
                  <p className="profile-tip">{t('orgSetting.photo.tip')}</p>
                </div>
              )}

              {showEditPicture === true && (
                <div style={styles.imgContainer}>
                  <div style={styles.avatarContainer}>
                    <Avatar
                      label={t('orgSetting.choose.photo')}
                      width={200}
                      height={200}
                      onCrop={(img) => this.onCropImg(img)}
                      onClose={() => {
                        this.props.closePreview()
                      }}
                    />
                  </div>
                  <SecondaryButton onClick={this.handleSave}>{t('orgSetting.save.photo')}</SecondaryButton>
                  <p className="profile-tip">{t('orgSetting.photo.tip')}</p>
                </div>
              )}

              <div style={styles.textContainer}>
                <StyledTextField
                  autoFocus={true}
                  margin="dense"
                  id="fullName"
                  onChange={this.handleChange('fullName')}
                  value={this.state.fullName}
                  label={t('common.fullName')}
                  maxLength={25}
                  type="text"
                  fullWidth
                />
                <StyledTextField
                  margin="dense"
                  id="whatIDo"
                  onChange={this.handleChange('whatIDo')}
                  value={this.state.whatIDo}
                  label={t('profile.whatIDo')}
                  maxLength={35}
                  type="text"
                  fullWidth
                />
                <StyledTextField
                  margin="dense"
                  id="phoneNumber"
                  onChange={this.handleChange('phoneNumber')}
                  defaultValue={this.state.phoneNumber}
                  label={t('profile.phone')}
                  type="text"
                  fullWidth
                />
                <StyledTextField
                  margin="dense"
                  id="linkedIn"
                  onChange={this.handleChange('linkedIn')}
                  value={this.state.linkedIn}
                  label="LinkedIn"
                  type="text"
                  fullWidth
                />
                <StyledTextField
                  margin="dense"
                  id="personalWebsite"
                  onChange={this.handleChange('personalWebsite')}
                  value={this.state.personalWebsite}
                  label={t('profile.personalWebsite')}
                  type="text"
                  fullWidth
                />
                <div style={styles.lastLine}>
                  <div>
                    <p className="changePassword" onClick={() => this.handleShowPasswordChange()}>
                      {t('profile.change.pass')}
                    </p>
                    <p className="changePassword" onClick={() => this.handleShowDeleteAccount()}>
                      {t('profile.deactivate.acc')}
                    </p>
                  </div>
                  <LanguageSwitcher value={this.state.language} onChange={this.handleChange('language')}/>
                </div>
              </div>
            </DialogContent>

            <DialogActions>
              <MainButton onClick={this.handleSubmit} disabled={this.state.fullName.length === 0}>
                {t('common.button.update')}
              </MainButton>
            </DialogActions>
          </div>
          {/* )} */}

          {/* {showChangePassword === true && (
            <div>
              <DialogTitle id="form-dialog-title" style={styles.profileTitleText}>
                Password Reset
              </DialogTitle>

              <DialogContent style={{ display: "flex", justifyContent: "center" }}>
                <Tooltip title={"Cancel"} placement={"bottom"}>
                  <i
                    className="material-icons x-icon"
                    onClick={() => {
                      this.props.hideProfileModel();
                      this.handleHideEditPicture();
                      this.handleHidePasswordChange();
                    }}
                  >
                    clear
                  </i>
                </Tooltip>

                <ForgotPasswordProfile
                  toggleForgotPassword={this.handleHidePasswordChange}
                  // toggleResetEmailSent={this.toggleResetEmailSent}
                />
              </DialogContent>
            </div>
          )} */}
        </Dialog>
        <Dialog
            open={this.state.showDeleteAccount}
            onClose={this.handleCloseDeleteAccount}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div style={styles.deleteBorder}>
              <DialogTitle id="alert-dialog-title">{t('profile.leave.header')}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t('profile.leave.description')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <SecondaryButton onClick={this.handleCloseDeleteAccount}>
                  {t('common.button.cancel')}
                </SecondaryButton>
                <MainButton
                  onClick={() => {
                    this.handleCloseDeleteAccount();
                    this.submitDeleteAccount();
                  }}
                >
                  {t('common.button.confirm')}
                </MainButton>
              </DialogActions>
            </div>
          </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    profileModelState: state.instance.showProfileModel,
    passwordResetModal: state.instance.showPasswordResetModal,
    auth: state.firebase.auth,
    user: state.database.userDetails,
    previewImage: state.instance.userProfileImagePreview,
    userOrgs: state.database.userOrgs,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    hideProfileModel: () => dispatch(actions.showProfileModel(false)),
    closePreview: () => dispatch(actions.closePreview()),
    setPreview: (preview) => dispatch(actions.setPreview(preview)),
    setLoadedFile: (loadedFile) => dispatch(actions.setLoadedFile(loadedFile)),
    showPasswordResetModal: () => dispatch(actions.showPasswordResetModal(true)),
    updateUser: (updatedUser) => dispatch(actions.updateSingleOrgInstanceUser(updatedUser)),
    // updateUserAvatar: (avatarFile, uid) => dispatch(actions.updateUserAvatar(avatarFile, uid))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ProfileModel))
