// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withTranslation } from 'react-i18next';

// Material UI
import Icon from '@material-ui/core/Icon'
// import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

// Helper
import { updateOrg, updateMission, updateUser, removeInvitation } from '../../utils/graphql-req'
import { envEndpoint } from '../../utils/firebase-service'
import {
  selectWorkspace,
  selectMission,
  selectMotion,
  selectOrg,
  setOrgInstanceDraftMotions,
  setOrgInstanceActiveMotions,
  setOrgInstanceCompleteMotions,
} from '../../store/actions'

const styles = {
  iconButton: {
    padding: '3px!important',
    width: '25px!important',
    height: '25px!important',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}

class Invitations extends Component {
  state = {
    hover: false,
  }

  gotoInvitation = async (orgId, missionId, placeholderUserId) => {
    try {
      const OrgResponse = await fetch(`${envEndpoint}/orgs/joinOrg`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orgId,
          missionId,
        }),
      })

      if (OrgResponse.status !== 200 && OrgResponse.status !== 201) {
        console.log('Error!')
        throw new Error('Something Went Wrong!')
      }

      const { org, mission, motions } = await OrgResponse.json()

      if (org && org.members) {
        const targetIndex = org.members.findIndex((memberId) => memberId === placeholderUserId)

        if (targetIndex !== -1) {
          // remove dummy user from org and add actual user
          const updatedOrg = _.cloneDeep(org)
          updatedOrg.members[targetIndex] = this.props.user.id
          await updateOrg(updatedOrg)

          // remove invitation from user
          const updatedUser = _.cloneDeep(this.props.user)
          if (updatedUser && !_.isEmpty(updatedUser.invitations)) {
            const targetIndex = updatedUser.invitations.findIndex(
              (invitation) => invitation.placeholderUserId === placeholderUserId
            )
            if (targetIndex !== -1) updatedUser.invitations.splice(targetIndex, 1)
          }

          // update user
          if (updatedUser['__typename']) delete updatedUser['__typename']
          if (!_.isEmpty(updatedUser.invitations)) {
            updatedUser.invitations.forEach((invitation) => {
              if (invitation['__typename']) delete invitation['__typename']
              if (invitation.org && invitation.org['__typename'])
                delete invitation.org['__typename']
              if (invitation.mission && invitation.mission['__typename'])
                delete invitation.mission['__typename']
            })
          }
          await updateUser(updatedUser)
        } else {
          // add user to org
          const updatedOrg = _.cloneDeep(org)
          updatedOrg.members.push(this.props.user.id)
          await updateOrg(updatedOrg)

          // remove invitation from user
          const updatedUser = _.cloneDeep(this.props.user)
          if (updatedUser && !_.isEmpty(updatedUser.invitations)) {
            const targetIndex = updatedUser.invitations.findIndex(
              (invitation) => invitation.org.id === org.id
            )
            if (targetIndex !== -1) updatedUser.invitations.splice(targetIndex, 1)
          }

          // update user
          if (updatedUser['__typename']) delete updatedUser['__typename']
          if (!_.isEmpty(updatedUser.invitations)) {
            updatedUser.invitations.forEach((invitation) => {
              if (invitation['__typename']) delete invitation['__typename']
              if (invitation.org && invitation.org['__typename'])
                delete invitation.org['__typename']
              if (invitation.mission && invitation.mission['__typename'])
                delete invitation.mission['__typename']
            })
          }

          await updateUser(updatedUser)
        }
      }

      if (mission && mission.members) {
        const targetIndex = mission.members.findIndex((memberId) => memberId === placeholderUserId)
        if (targetIndex !== -1) {
          // remove dummy user from mission and add actual user
          const updatedMission = _.cloneDeep(mission)
          updatedMission.members[targetIndex] = this.props.user.id
          await updateMission(updatedMission)
          this.props.selectMission(updatedMission)
        } else {
          // add user to mission
          const updatedMission = _.cloneDeep(mission)
          updatedMission.members.push(this.props.user.id)
          await updateMission(updatedMission)
          this.props.selectMission(updatedMission)
        }

        // manual setting of first motions
        if (mission && mission.status && !_.isEmpty(motions)) {
          if (mission.status === 'draft') {
            this.props.setOrgInstanceDraftMotions(motions)
            this.props.selectWorkspace('draft')
          } else if (mission.status === 'active') {
            this.props.setOrgInstanceActiveMotions(motions)
            this.props.selectWorkspace('mission')
          } else if (mission.status === 'complete') {
            this.props.setOrgInstanceCompleteMotions(motions)
            this.props.selectWorkspace('complete')
          }
          this.props.selectMotion(motions[0])
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  goToMission = async (mission, invite) => {
    const targetOrg = this.props.userOrgs.find((org) => org.id === this.props.orgId)
    this.props.selectOrg(targetOrg)
    this.props.selectMission(mission)
    this.props.selectWorkspace('mission')
    delete invite['__typename']
    await removeInvitation(mission.id, invite)
  }

  // handleRemoveInvitation = async (mission, invite) => {
  //   await removeInvitation(mission.id, invite)
  // }

  renderInvitations = () => {
    const { invitations, inviteIndex, t } = this.props

    if (!invitations || _.isEmpty(invitations)) {
      // if (!user || _.isEmpty(user.invitations)) {
      return (
        <div className="emptystate">
          <li>{t('invitation.emptystate.li')}</li>
        </div>
      )
    }

    return invitations.map((invitation, index) => {
      // return user.invitations.map((invitation, index) => {
      return (
        <li key={index} className="notification-list-item">
          <span>
            {invitation.invitations[inviteIndex[index]].inviter || t('common.someone')} {t('invitation.notification')}{' '}
          </span>
          {/* eslint-disable-next-line */}
          <strong>
            <a
              href={null}
              onClick={() =>
                this.goToMission(invitation, invitation.invitations[inviteIndex[index]])
              }
            >
              {invitation.name}
            </a>
          </strong>
          {/* <Tooltip title="Ok" placement="bottom">
            <IconButton
              onMouseEnter={this.toggleHover}
              onMouseLeave={this.toggleHover}
              // onClick={() => this.gotoInvitation(invitation.org.id, invitation.mission.id, invitation.placeholderUserId)}
              onClick={() => this.handleRemoveInvitation(invitation, invitation.invitations[inviteIndex[index]])}
              disableFocusRipple={true}
              disableRipple={true}
              classes={{ root: classes.iconButton }}
              >
              {this.state.hover
                ? <Icon style={{ fontSize: 12, color: "#3EC4E5" }}>check_circle</Icon>
                : <Icon style={{ fontSize: 12, color: "#333541" }}>check_circle_outline</Icon>
              }
            </IconButton>
          </Tooltip> */}
        </li>
      )
      // else {
      //   return (
      //     <li key={index} className="notification-list-item">
      //       <span>
      //         {invitation.invitee || "Someone"} added you to {" "}
      //       </span>
      //       {/* eslint-disable-next-line */}
      //       <strong>
      //         <a href={null} onClick={() => this.gotoInvitation(invitation.org.id, null, invitation.placeholderUserId)}>
      //           {invitation.org.name}
      //         </a>
      //       </strong>
      //       <Tooltip title="Ok" placement="bottom" onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
      //         <IconButton
      //           onMouseLeave={this.toggleHover}
      //           onClick={() => this.gotoInvitation(invitation.org.id, invitation.placeholderUserId)}
      //           classes={{ root: classes.iconButton }}
      //           >
      //           {this.state.hover
      //            ? <Icon style={{ fontSize: 12, color: "#3EC4E5" }}>check_circle</Icon>
      //            : <Icon style={{ fontSize: 12, color: "#333541" }}>check_circle_outline</Icon>
      //           }
      //         </IconButton>
      //       </Tooltip>
      //     </li>
      //   )
      // }
    })
  }

  // renderTooltip = () => {
  //   return(
  //     <Tooltip title="A look at Missions that you've been added to" placement="top">
  //       <Icon style={{ fontSize: 14, color: "grey" }}>info</Icon>
  //     </Tooltip>
  //   );
  // }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover })
  }

  render() {
    const { t } = this.props;
    return (
      <div className="notification-container">
        <div className="notification-item">
          <h3>{t('invitation.header')}</h3>
          <div className="line-div" />
          <ul className="notification-list-missions">{this.renderInvitations()}</ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log("state", state)
  // console.log("ownProps", ownProps)
  let invitations = []
  let inviteIndex = []

  if (ownProps.orgMissions && state.database.userDetails) {
    ownProps.orgMissions.forEach((mission) => {
      if (mission.invitations) {
        let index = mission.invitations.findIndex(
          (invite) => state.database.userDetails.id === invite.userId
        )
        if (index !== -1) {
          invitations.push(mission)
          inviteIndex.push(index)
        }
      }
    })
  }

  return {
    currentOrg: ownProps.org,
    invitations,
    inviteIndex,
    user: state.database.userDetails,
    orgId: ownProps.orgId,
    userOrgs: state.database.userOrgs,
  }
}

const mapDisptachToProps = (dispatch) => {
  return {
    selectOrg: (orgId) => dispatch(selectOrg(orgId)),
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
    selectMission: (mission) => dispatch(selectMission(mission)),
    selectMotion: (motion) => dispatch(selectMotion(motion)),
    setOrgInstanceDraftMotions: (motions) => dispatch(setOrgInstanceDraftMotions(motions)),
    setOrgInstanceActiveMotions: (motions) => dispatch(setOrgInstanceActiveMotions(motions)),
    setOrgInstanceCompleteMotions: (motions) => dispatch(setOrgInstanceCompleteMotions(motions)),
  }
}

export default connect(mapStateToProps, mapDisptachToProps)(withStyles(styles)(withTranslation('common')(Invitations)))
