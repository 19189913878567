// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AvatarEdit from 'react-avatar-edit'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

// Material UI
import { withStyles } from '@material-ui/core/styles'
// import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Tooltip from '@material-ui/core/Tooltip'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import SettingsIcon from '@material-ui/icons/Settings'
import Snackbar from '@material-ui/core/Snackbar'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
// import WebIcon from '@material-ui/icons/Web'
import LanguageIcon from '@material-ui/icons/Language'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

// Custom Components
import PersonContainer from './PersonContainer'
import Invite from '../../containers/Invite'
import MainButton from '../../components/Buttons/MainButton'
import SecondaryButton from '../../components/Buttons/SecondaryButton'
import StyledTextField from '../../components/StyledTextField'

// Helpers
import { storage } from '../../utils/firebase-service'
import { updateOrg } from '../../utils/graphql-req'
import * as actions from '../../store/actions'

const styles = {
  orgNameStyles: {
    textAlign: 'left',
    margin: '5px 0px',
    wordBreak: 'break-word',
    hyphens: 'auto',
    overflowWrap: 'anywhere',
    // fontSize: 20,
    // fontWeight: 'bold',
  },
  orgInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  whatWeDoStyles: {
    textAlign: 'left',
    lineHeight: '15px',
  },
  logoStyles: {
    marginTop: '10px',
    textAlign: 'center',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  optionStyles: {
    cursor: 'pointer',
    color: '#333541',
    fontWeight: 'bold',
    '&:hover': {
      fontWeight: 'bold',
      color: '#3EC4E5',
    },
    display: 'flex',
    flexDirection: 'row',
    width: 'min-content',
    whiteSpace: 'nowrap',
  },
  dividerStyles: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  tabStyles: {
    justifyContent: 'start',
  },

  companyLogo: {
    float: 'left',
    width: '100px',
    height: '100px',
    borderRadius: '100%',
    marginRight: '10px',
    minWidth: '100px',
    minHeight: '100px',
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '20px 80px 0px 50px',
    alignItems: 'center',
  },
  settingsLogo: {
    float: 'left',
    backgroundColor: '#333541',
    borderRadius: '100%',
    color: 'white',
    display: '-webkit-box',
    display: '-moz-box',
    display: '-ms-flexbox',
    display: '-webkit-flex',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontWeight: '600',
    marginTop: '3px',
    width: '170px',
    height: '170px',
    fontSize: '80px',
    marginTop: '0px',
    marginBottom: '15px',
  },

  contentRoot: {
    '&:first-child': { paddingTop: '0px' },
  },
  titleRoot: {
    paddingBottom: '0px',
    textAlign: 'center',
  },
  textContainer: {
    marginRight: '30px',
    marginTop: '30px',
  },
  tabColor: {
    color: '#333541',
  },
  indicator: {
    backgroundColor: '#4EA0A9',
  },
  avatarContainer: {
    margin: '0px 0px 15px 0px',
  },
  deleteBorder: {
    border: '4px solid #F16664',
  },
  linkIcon: {
    color: '#333541',
    marginLeft: '10px',
    '&:hover': {
      cursor: 'pointer',
      // color: '#3fc4e5',
      fontWeight: 'bold',
      color: '#3EC4E5',
    },
  },
}

class OrgSettings extends Component {
  userComponents = []

  constructor(props) {
    super(props)
    this.state = {
      showEditOrgModal: false,
      organizationName: '',
      showConfirmDeleteOrgModal: false,
      showConfirmTransferOwnModal: false,
      showManagePeopleDialog: false,
      dialogInviteUser: false,
      tabValue: 0,
      whatWeDo: '',
      linkedIn: '',
      website: '',
      ShowEditPicture: false,
      errorMessage: undefined,
      error: false,
      newOwnerId: null,
    }

    // this.handleSubmitConfirmDeleteOrgModal = this.handleSubmitConfirmDeleteOrgModal.bind(
    //   this
    // );
  }

  // handling 'manage people' dialog
  openManagePeopleDialog = () => {
    this.setState({ showManagePeopleDialog: true, tabValue: 0 })
  }

  hideManagePeopleDialog = () => {
    this.setState({ showManagePeopleDialog: false })
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.users)) this.userComponents = this.getOrgUser()

    const { activeOrg } = this.props
    if (activeOrg && activeOrg.name) this.setState({ organizationName: activeOrg.name })
    else this.setState({ organizationName: '' })

    if (activeOrg && activeOrg.whatWeDo) this.setState({ whatWeDo: activeOrg.whatWeDo })
    else this.setState({ whatWeDo: '' })

    if (activeOrg && activeOrg.linkedIn) this.setState({ linkedIn: activeOrg.linkedIn })
    else this.setState({ linkedIn: '' })

    if (activeOrg && activeOrg.website) this.setState({ website: activeOrg.website })
    else this.setState({ website: '' })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.activeOrg &&
      nextProps.activeOrg &&
      this.props.activeOrg.name !== nextProps.activeOrg.name
    ) {
      this.setState({ organizationName: nextProps.activeOrg.name })
    }

    if (
      this.props.activeOrg &&
      nextProps.activeOrg &&
      this.props.activeOrg.whatWeDo !== nextProps.activeOrg.whatWeDo
    ) {
      this.setState({ whatWeDo: nextProps.activeOrg.whatWeDo })
    }

    if (
      this.props.activeOrg &&
      nextProps.activeOrg &&
      this.props.activeOrg.linkedIn !== nextProps.activeOrg.linkedIn
    ) {
      this.setState({ linkedIn: nextProps.activeOrg.linkedIn })
    }

    if (
      this.props.activeOrg &&
      nextProps.activeOrg &&
      this.props.activeOrg.website !== nextProps.activeOrg.website
    ) {
      this.setState({ website: nextProps.activeOrg.website })
    }

    return true
  }

  componentDidUpdate() {
    if (!_.isEmpty(this.props.users)) this.userComponents = this.getOrgUser()
  }

  getOrgUser() {
    const { users } = this.props

    if (!_.isEmpty(users)) {
      const members = Object.keys(users).map((memberKey) => {
        if (users[memberKey]) {
          users[memberKey]['id'] = memberKey
          return users[memberKey]
        }
      })

      // sort alphatically
      const sortedMembers = members.sort((a, b) => {
        return a.fullName > b.fullName ? 1 : b.fullName > a.fullName ? -1 : 0
      })

      // create person components
      return sortedMembers.map((user, i) => {
        if (user) {
          return (
            <PersonContainer
              user={user}
              index={i}
              key={user.id}
              hideManagePeopleDialog={this.hideManagePeopleDialog}
            />
          )
        } else {
          return null
        }
      })
    } else return null
  }

  // Handling Edit Org Modal
  handleChange = (name) => (event) => {
    switch (name) {
      case 'newOrgName':
        // if (this.state.organizationName.length < 41) {
        this.setState({ organizationName: event.target.value })
        return
      // }
      case 'whatWeDo':
        // if (this.state.whatWeDo.length < 41) {
        this.setState({ whatWeDo: event.target.value })
        return
      // }
      case 'linkedIn':
        this.setState({ linkedIn: event.target.value })
        return
      case 'website':
        this.setState({ website: event.target.value })
        return
      default:
        return
    }
  }

  openEditOrgModal = () => {
    this.setState({ showEditOrgModal: true, anchorEl: null })
  }

  handleEditOrgClose = () => {
    this.setState({ showEditOrgModal: false })
  }

  handleEditOrgSubmit = async () => {
    // if (this.state.organizationName.length > 41) {
    //   this.setState({
    //     errorMessage: `Organization Name Must Be Less Than 40 Characters: ${this.state.organizationName.length}/40`,
    //     error: true,
    //   })
    //   return false
    // }
    // if (this.state.whatWeDo.length > 41) {
    //   this.setState({
    //     errorMessage: `What We Do Must Be Less Than 40 Characters: ${this.state.whatWeDo.length}/40`,
    //     error: true,
    //   })
    //   return false
    // }
    this.setState({ showManagePeopleDialog: false })

    const updatedOrg = _.cloneDeep(this.props.activeOrg)
    if (this.state.organizationName) updatedOrg.name = this.state.organizationName
    if (this.state.whatWeDo) updatedOrg.whatWeDo = this.state.whatWeDo
    if (this.state.linkedIn) updatedOrg.linkedIn = this.state.linkedIn
    if (this.state.website) updatedOrg.website = this.state.website

    if (!_.isEqual(updatedOrg, this.props.activeOrg)) {
      if (updatedOrg['__typename']) delete updatedOrg['__typename']
      await updateOrg(updatedOrg)
    }
  }

  handleTransferOwnSubmit = async () => {
    const newOwn = this.state.newOwnerId
    if (newOwn) {
      const updatedOrg = _.cloneDeep(this.props.activeOrg)
      updatedOrg.members = [newOwn, ...updatedOrg.members.filter((item) => item !== newOwn)]
      updatedOrg.owner = [newOwn]

      if (!_.isEqual(updatedOrg, this.props.activeOrg)) {
        if (updatedOrg['__typename']) delete updatedOrg['__typename']
        await updateOrg(updatedOrg)
      }

      this.hideManagePeopleDialog()
    }
  }

  openConfirmDeleteOrgModal = () => {
    this.setState({ showEditOrgModal: false, showConfirmDeleteOrgModal: true })
  }

  openConfirmTransferOwn = () => {
    this.setState({ showConfirmTransferOwnModal: true })
  }

  handleConfirmDeleteOrgModalClose = () => {
    this.setState({ showConfirmDeleteOrgModal: false })
  }

  handleConfirnTransferOwnClose = () => {
    this.setState({ showConfirmTransferOwnModal: false, newOwnerId: null })
  }

  async handleSubmitConfirmDeleteOrgModal() {
    const { activeOrg } = this.props
    this.setState({ showConfirmDeleteOrgModal: false })
    const updatedOrg = _.cloneDeep(activeOrg)
    updatedOrg.status = 'inactive'
    delete updatedOrg['__typename']
    await updateOrg(updatedOrg)
    window.location.reload(false)
  }

  // Handling Invite Dialog
  openInviteUserDialog = () => {
    this.setState({ dialogInviteUser: true })
  }

  closeInviteUserDialog = () => {
    this.setState({ dialogInviteUser: false })
  }

  // Handling Setting Dialog
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue })
  }

  handleShowEditPicture = () => {
    this.setState({ ShowEditPicture: true })
  }

  handleHideEditPicture = () => {
    this.setState({ ShowEditPicture: false })
  }

  handleLogoSave = async () => {
    const { previewImage, activeOrg } = this.props

    this.handleHideEditPicture()

    if (!this.file || !previewImage) {
      //TODO:
      // Option 1: Show a pop-up stating that user need to upload picture first
      // Option 2: Prevent user clicking "Save Org Picture" when nothing has been uploaded
      return
    }

    try {
      await storage()
        .ref(activeOrg.id + '/logo/' + this.file.name)
        .put(this.file)
      const url = await storage()
        .ref(activeOrg.id + '/logo')
        .child('company.png')
        .getDownloadURL()
      const updatedOrg = _.cloneDeep(activeOrg)
      if (updatedOrg['__typename']) delete updatedOrg['__typename']
      updatedOrg.logo = url
      await updateOrg(updatedOrg)
    } catch (err) {
      console.log(err)
    }
  }

  onCropImg = (img) => {
    fetch(img)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], 'company.png')
        this.props.setPreview(img)
        this.file = file
        this.props.setLoadedFile(file)
      })
  }

  handleClose = () => {
    this.setState({
      error: false,
      errorMessage: '',
    })
  }

  render() {
    const { activeOrg, classes, orgOwner, closePreview, t, users } = this.props
    const { newOwnerId } = this.state

    let optionsContainer
    if (orgOwner && this.props.auth.uid === orgOwner.id) {
      optionsContainer = (
        <div className={classes.optionsContainer}>
          <span
            className={classes.optionStyles}
            onClick={this.openManagePeopleDialog}
            style={{ marginBottom: '0px' }}
          >
            <SettingsIcon />
            <p style={{ margin: '2px 0px' }}>{t('common.settings')}</p>
          </span>
          {activeOrg.linkedIn && (
            <a href={activeOrg.linkedIn} target="_blank" style={styles.linkIcon}>
              <Tooltip title={`${activeOrg.name} LinkedIn`}>
                <LinkedInIcon />
              </Tooltip>
            </a>
          )}
          {activeOrg.website && (
            <a href={activeOrg.website} target="_blank" style={styles.linkIcon}>
              <Tooltip title={`${activeOrg.name} website`}>
                <LanguageIcon />
              </Tooltip>
            </a>
          )}
          {/* <p className={classes.dividerStyles}>|</p>
          <p className={classes.optionStyles} onClick={this.openInviteUserDialog}>
            Invite
          </p> */}
        </div>
      )
    } else if (orgOwner) {
      optionsContainer = (
        <div className={classes.optionsContainer}>
          <p style={{ margin: '2px 0px' }}>
            <b>{t('common.owner')}:</b> {orgOwner.fullName}
          </p>
          {/* <p className={classes.dividerStyles}>|</p>
          <p className={classes.optionStyles} onClick={this.openInviteUserDialog}>
            Invite
          </p> */}
          {activeOrg.linkedIn && (
            <a href={activeOrg.linkedIn} target="_blank" style={styles.linkIcon}>
              <Tooltip title={`${activeOrg.name} LinkedIn`}>
                <LinkedInIcon />
              </Tooltip>
            </a>
          )}
          {activeOrg.website && (
            <a href={activeOrg.website} target="_blank" style={styles.linkIcon}>
              <Tooltip title={`${activeOrg.name} website`}>
                <LanguageIcon />
              </Tooltip>
            </a>
          )}
        </div>
      )
    }

    let settingsContent = (
      <div>
        <DialogContent style={{ display: 'flex' }} classes={{ root: classes.contentRoot }}>
          <Tooltip title={t('common.button.cancel')} placement={'bottom'}>
            <i className="material-icons x-icon" onClick={this.hideManagePeopleDialog}>
              clear
            </i>
          </Tooltip>

          {this.state.ShowEditPicture === false && (
            <div style={styles.imgContainer}>
              {activeOrg && activeOrg.logo ? (
                <img className="avatarPadding" alt="organization logo" src={activeOrg.logo} />
              ) : (
                <div className={classes.settingsLogo}>
                  {activeOrg && activeOrg.name && activeOrg.name.charAt(0)}
                </div>
              )}
              <SecondaryButton onClick={this.handleShowEditPicture}>
                {t('orgSetting.change.photo')}
              </SecondaryButton>
              <p className={classes.logoStyles}>{t('orgSetting.photo.tip')}</p>
            </div>
          )}
          {this.state.ShowEditPicture === true && (
            <div style={styles.imgContainer}>
              <div style={styles.avatarContainer}>
                <AvatarEdit
                  label={t('orgSetting.choose.photo')}
                  width={200}
                  height={200}
                  onCrop={(img) => this.onCropImg(img)}
                  onClose={() => {
                    closePreview()
                  }}
                />
              </div>
              <SecondaryButton onClick={this.handleLogoSave}>
                {t('orgSetting.save.photo')}
              </SecondaryButton>
              <p className={classes.logoStyles}>{t('orgSetting.photo.tip')}</p>
            </div>
          )}

          <div style={styles.textContainer}>
            {/* <DialogActions className={classes.tabStyles}>
              <Tabs
                value={this.state.tabValue}
                onChange={this.handleTabChange}
                classes={{ root: classes.tabColor, indicator: classes.indicator }}
              >
                <Tab label="General" />
                <Tab label="People" />
              </Tabs>
            </DialogActions> */}
            <StyledTextField
              autoFocus={true}
              margin="dense"
              id="newOrgName"
              onChange={this.handleChange('newOrgName')}
              value={this.state.organizationName}
              maxLength={30}
              label={t('orgSetting.name')}
              type="text"
              fullWidth
              // inputProps={{ maxLength: 40 }}
            />
            <StyledTextField
              margin="dense"
              id="whatWeDo"
              onChange={this.handleChange('whatWeDo')}
              value={this.state.whatWeDo}
              maxLength={50}
              label={t('orgSetting.whatWeDo')}
              type="text"
              fullWidth
              // inputProps={{ maxLength: 40 }}
            />
            <StyledTextField
              margin="dense"
              id="linkedIn"
              onChange={this.handleChange('linkedIn')}
              value={this.state.linkedIn}
              // maxLength={50}
              label="LinkedIn"
              type="text"
              fullWidth
              // inputProps={{ maxLength: 40 }}
            />
            <StyledTextField
              margin="dense"
              id="website"
              onChange={this.handleChange('website')}
              value={this.state.website}
              // maxLength={50}
              label="Website"
              type="text"
              fullWidth
              // inputProps={{ maxLength: 40 }}
            />
            <p className="deleteOrganization" onClick={this.openConfirmTransferOwn}>
              {t('orgSetting.transferOwn')}
            </p>
            <p className="deleteOrganization" onClick={this.openConfirmDeleteOrgModal}>
              {t('orgSetting.delete')}
            </p>
          </div>
        </DialogContent>

        <DialogActions className={classes.settingsButtonStyles}>
          <MainButton onClick={this.handleEditOrgSubmit}>{t('common.button.update')}</MainButton>
        </DialogActions>
      </div>
    )

    // if (this.state.tabValue === 1)
    //   settingsContent = (
    //     <div>
    //       <DialogContent style={{ display: 'flex' }} classes={{ root: classes.contentRoot }}>
    //         <Tooltip title={t('common.button.cancel')} placement={'bottom'}>
    //           <i className="material-icons x-icon" onClick={this.hideManagePeopleDialog}>
    //             clear
    //           </i>
    //         </Tooltip>

    //         {this.state.ShowEditPicture === false && (
    //           <div style={styles.imgContainer}>
    //             {activeOrg && activeOrg.logo ? (
    //               <img className="avatarPadding" alt="organization logo" src={activeOrg.logo} />
    //             ) : (
    //               <div className={classes.settingsLogo}>
    //                 {activeOrg && activeOrg.name && activeOrg.name.charAt(0)}
    //               </div>
    //             )}
    //             <SecondaryButton onClick={this.handleShowEditPicture}>{t('orgSetting.change.photo')}</SecondaryButton>
    //             <p className={classes.logoStyles}>{t('orgSetting.photo.tip')}</p>
    //           </div>
    //         )}
    //         {this.state.ShowEditPicture === true && (
    //           <div style={styles.imgContainer}>
    //             <div style={styles.avatarContainer}>
    //               <AvatarEdit
    //                 width={200}
    //                 height={200}
    //                 onCrop={(img) => this.onCropImg(img)}
    //                 onClose={() => {
    //                   closePreview()
    //                 }}
    //               />
    //             </div>
    //             <SecondaryButton onClick={this.handleLogoSave}>{t('orgSetting.save.photo')}</SecondaryButton>
    //             <p className={classes.logoStyles}>{t('orgSetting.photo.tip')}</p>
    //           </div>
    //         )}

    //         <div style={styles.textContainer}>
    //           <DialogActions className={classes.tabStyles}>
    //             <Tabs
    //               value={this.state.tabValue}
    //               onChange={this.handleTabChange}
    //               classes={{ root: classes.tabColor, indicator: classes.indicator }}
    //             >
    //               <Tab label="General" />
    //               <Tab label="People" />
    //             </Tabs>
    //           </DialogActions>

    //           <div className={'scrollBarOver'}>{this.userComponents}</div>
    //         </div>
    //       </DialogContent>

    //       <DialogActions className={classes.settingsButtonStyles}>
    //         <MainButton onClick={this.handleEditOrgSubmit}>Update</MainButton>
    //       </DialogActions>
    //     </div>
    //   )

    return (
      <div className="orgSetting-container">
        {activeOrg && activeOrg.logo ? (
          <div className={classes.companyLogo}>
            <img src={activeOrg.logo} alt="organization log" />
          </div>
        ) : (
          <div className="companyLogo">
            {activeOrg && activeOrg.name && activeOrg.name.charAt(0)}
          </div>
        )}
        <div className={classes.orgInfoContainer}>
          <h2 className={classes.orgNameStyles}> {activeOrg.name} </h2>
          {activeOrg.whatWeDo && <p className={classes.whatWeDoStyles}>{activeOrg.whatWeDo}</p>}

          {optionsContainer}
        </div>

        {/* Settings Dialog */}
        <Dialog
          open={this.state.showManagePeopleDialog}
          aria-labelledby="form-dialog-title"
          fullWidth={false}
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title" classes={{ root: classes.titleRoot }}>
            {t('orgSetting.update')}
          </DialogTitle>

          {settingsContent}
        </Dialog>

        {/* Confirm Org Delete Dialog */}
        <Dialog
          open={this.state.showConfirmDeleteOrgModal}
          onClose={this.handleConfirmDeleteOrgModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={styles.deleteBorder}>
            <DialogTitle id="alert-dialog-title">{t('orgSetting.delete')}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t('orgSetting.delete.description')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={this.handleConfirmDeleteOrgModalClose}>
                {t('common.button.cancel')}
              </SecondaryButton>
              <MainButton
                onClick={() => {
                  this.handleSubmitConfirmDeleteOrgModal()
                  this.hideManagePeopleDialog()
                }}
              >
                {t('common.button.delete')}
              </MainButton>
            </DialogActions>
          </div>
        </Dialog>

        {/* Confirm Transfer Own Dialog */}
        <Dialog
          open={this.state.showConfirmTransferOwnModal}
          onClose={this.handleConfirnTransferOwn}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t('orgSetting.transferOwn')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('orgSetting.transferOwn.description')}
            </DialogContentText>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} style={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-helper-label">
                {t('orgSetting.transferOwn.label')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label={t('orgSetting.transferOwn.label')}
                value={newOwnerId}
                onChange={(event) => {
                  this.setState({ newOwnerId: event.target.value })
                }}
              >
                {Object.values(users)
                  .slice(1)
                  .map((user) => (
                    <MenuItem value={user.id} key={user.id}>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={
                            user && user.avatar
                              ? user.avatar
                              : require('../../assets/images/user.jpg')
                          }
                          alt="user avatar"
                          style={{
                            width: '40px',
                            height: '40px',
                            marginRight: '10px',
                            borderRadius: '50%',
                            border: '1px solid white',
                          }}
                        />
                        <div
                          style={{
                            display: 'block',
                            lineHeight: '12px',
                            minWidth: '180px',
                            marginBottom: '3px',
                          }}
                        >
                          {user.fullName ? user.fullName : user.email}
                        </div>
                      </span>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={this.handleConfirnTransferOwnClose}>
              {t('common.button.cancel')}
            </SecondaryButton>
            <MainButton
              onClick={() => {
                this.handleTransferOwnSubmit()
                this.handleConfirnTransferOwnClose()
              }}
            >
              {t('common.button.transfer')}
            </MainButton>
          </DialogActions>
        </Dialog>

        <Invite
          dialogInviteUser={this.state.dialogInviteUser}
          closeInviteUserDialog={this.closeInviteUserDialog}
        />
        <Snackbar
          open={this.state.error}
          message={this.state.errorMessage}
          autoHideDuration={5000}
          onClose={this.handleClose}
        />
      </div>
    )
  }
}

OrgSettings.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  let orgOwner = null
  if (
    state.instance.org &&
    state.instance.org.owner &&
    state.instance.org.owner[0] &&
    state.database.orgInstanceUsers &&
    state.database.orgInstanceUsers[state.instance.org.owner[0]]
  ) {
    orgOwner = state.database.orgInstanceUsers[state.instance.org.owner[0]]
    orgOwner.id = state.instance.org.owner[0]
  }

  return {
    auth: state.firebase.auth,
    activeOrg: state.instance.org,
    users: state.database.orgInstanceUsers,
    orgOwner,
    previewImage: state.instance.userProfileImagePreview,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideProfileModel: () => dispatch(actions.showProfileModel(false)),
    closePreview: () => dispatch(actions.closePreview()),
    setPreview: (preview) => dispatch(actions.setPreview(preview)),
    setLoadedFile: (loadedFile) => dispatch(actions.setLoadedFile(loadedFile)),
    selectOrg: (orgId) => dispatch(actions.selectOrg(orgId)),

    // hideProfileModel: () => dispatch(showProfileModel(false)),
    // softDeleteOrg: org => dispatch(actions.softDeleteOrg(org)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation('common')(OrgSettings)))
