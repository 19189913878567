
import axios from 'axios';
import { auth } from './firebase-service';

export const createUserAccount = (data) => {
  return axios.post('https://your-api-url/auth/signup', data)
    .then(res => res.data)
}

export function getAccessToken() {
  if(auth().currentUser) return auth().currentUser.xa;
}