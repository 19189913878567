import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const styles = {
  label: {
    justifyContent: 'center',
    // paddingLeft: '10px',
    fontFamily: 'Arial, Helvetica, sans-serif',
    height: '15px',
  },
  activeTab: {
    marginLeft: '6px',
    fontWeight: 'bold',
    backgroundColor: '#3fc4e5',
    borderRadius: '2px',
    textTransform: 'none',
    color: '#ffffff',
    fontFamily: 'Arial, Helvetica, sans-serif',
    '&:hover': {
      backgroundColor: '#3fc4e5',
    },
    fontSize: '10px',
    fontWeight: 'bold',
  },
  inactiveTab: {
    marginLeft: '6px',
    fontWeight: 'normal',
    backgroundColor: '#c7c7c7',
    borderRadius: '2px',
    textTransform: 'none',
    color: 'black',
    fontFamily: 'Arial, Helvetica, sans-serif',
    '&:hover': {
      backgroundColor: '#98dff0',
    },
    fontSize: '10px',
    fontWeight: 'bold',
  },
}

const SidePanelButton = (props) => {
  const { classes } = props

  let button = (
    <Button
      className={classes.inactiveTab}
      classes={{ label: classes.label }}
      // style={{ backgroundColor: '#98dff0' }}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  )

  if (props.isActiveTab) {
    button = (
      <Button
        className={classes.activeTab}
        // style={{ backgroundColor: 'transparent' }}
        classes={{ label: classes.label }}
        onClick={props.onClick}
      >
        {props.children}
      </Button>
    )
  }

  return button
}

SidePanelButton.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SidePanelButton)
