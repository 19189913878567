import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = {
  secondaryButton: {
    border: "black solid 1px",  
    color: "black",
    marginLeft: "4px",
    marginRight: "4px",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow:"none",
      border: "#3EC4E5 solid 1px", 
      color:"#3EC4E5"
    }
  }
};

const SecondaryButton = props => {
  const { classes } = props;

  return (
    <Button 
      className={classes.secondaryButton} 
      variant="outlined"
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

SecondaryButton.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SecondaryButton);