// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';

// Material UI
import Drawer from 'material-ui/Drawer'
import Icon from '@material-ui/core/Icon'

// Local Components
import OptionSubmenu from './OptionSubmenu'
import DuplicateModal from './DuplicateModal'

class SideDrawerRepeat extends Component {
  state = {
    value: 'process',
    filterValue: '',
    dialogDuplicateWinflow: false,
  }

  toggleDuplicateDialog = () => {
    this.setState({
      dialogDuplicateWinflow: !this.state.dialogDuplicateWinflow,
    })
  }

  getDrawerStyles() {
    if (this.props.initialState) {
      return {
        top: '15px',
        width: '250px',
        height: '96%',
        backgroundColor: '#fff',
        borderLeft: '1px solid white',
        borderTop: '1px solid white',
        overflowY: 'scroll',
        // overflowY: 'visible',
        paddingBottom: '20px',
        transform: 'none',
      }
    } else {
      return {
        top: '15px',
        width: '250px',
        height: '96%',
        backgroundColor: '#fff',
        color: '#a1a1a1',
        borderLeft: '1px solid white',
        borderTop: '1px solid white',
        overflowY: 'scroll',
        // overflow: 'visible',
        paddingBottom: '20px',
        transform: 'none',
      }
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Drawer
          open={this.props.showSideDrawerRepeat}
          openSecondary={true}
          className={!this.props.showSideDrawerRepeat ? 'hidden' : null}
          containerStyle={this.getDrawerStyles()}
        >
          <div className="panel_head" onClick={() => this.props.handleSideDrawersToggle('repeat')}>
            <div className="panel_header_text">{t('common.button.autopilot')}</div>
            <div className="panel_buttons panel_button_link">
              <Icon className="material-icons">arrow_right</Icon>
            </div>
          </div>
          <p className="sidedrawer_instructions">
            {t('sideDrawerRepeat.instructions')}
          </p>

          <OptionSubmenu toggleDuplicateDialog={this.toggleDuplicateDialog} />
        </Drawer>

        <DuplicateModal
          dialogDuplicateWinflow={this.state.dialogDuplicateWinflow}
          toggleDuplicateDialog={this.toggleDuplicateDialog}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps, null)(withTranslation('common')(SideDrawerRepeat))
