// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withTranslation } from 'react-i18next';

// Material UI
import Drawer from 'material-ui/Drawer'
import Icon from '@material-ui/core/Icon'

// Local Components
import WorkItemSubmenu from './WorkItemSubmenu'
import MotionSubmenu from './MotionSubmenu'
import PeopleSubmenu from './PeopleSubmenu'
import Invite from '../../containers/Invite'
import { getRolesForUserInCurrentWinflow } from '../../utils/handleRoles'
import DeleteWorkItemDialog from './DeleteWorkItemDialog'
import WorkItemInUseDialog from './WorkItemInUseDialog'

// Utils
import { deleteWorkItem } from '../../utils/graphql-req'

class SideDrawerEdit extends Component {
  state = {
    value: 'process',
    filterValue: '',
    dialogInviteUser: false,
    dialogDuplicateWinflow: false,
    showDeleteWorkItemDialog: false,
    showWorkItemInUseDialog: false,
  }

  openInviteUserDialog = () => {
    this.setState({ dialogInviteUser: true })
  }

  closeInviteUserDialog = () => {
    this.setState({ dialogInviteUser: false })
  }

  toggleDuplicateDialog = () => {
    this.setState({
      dialogDuplicateWinflow: !this.state.dialogDuplicateWinflow,
    })
  }

  getDrawerStyles() {
    if (this.props.initialState) {
      return {
        top: '15px',
        width: '250px',
        height: '96%',
        backgroundColor: '#fff',
        borderLeft: '1px solid white',
        borderTop: '1px solid white',
        overflowY: 'scroll',
        // overflowY: 'visible',
        paddingBottom: '20px',
        transform: 'none',
      }
    } else {
      return {
        top: '15px',
        width: '250px',
        height: '96%',
        backgroundColor: '#fff',
        color: '#a1a1a1',
        borderLeft: '1px solid white',
        borderTop: '1px solid white',
        overflowY: 'scroll',
        // overflow: 'visible',
        paddingBottom: '20px',
        transform: 'none',
      }
    }
  }

  showDeleteWorkItemDialog = (document) => {
    this.setState({ showDeleteWorkItemDialog: true, document: document })
  }

  hideDeleteWorkItemDialog = () => {
    this.setState({ showDeleteWorkItemDialog: false })
  }

  handleDeleteWorkItem = async () => {
    const { document } = this.state

    if (!document) return

    if (_.isEmpty(document.motion)) {
      //okay to delete document
      await deleteWorkItem(document.id, document.mission)
    } else {
      this.setState({ showWorkItemInUseDialog: true })
    }
    this.hideDeleteWorkItemDialog()
  }

  showWorkItemInUseDialog = () => {
    this.setState({ showWorkItemInUseDialog: true })
  }

  hideWorkItemInUseDialog = () => {
    this.setState({ showWorkItemInUseDialog: false })
  }

  render() {
    const { mission, activeMissionMotions, uid, t } = this.props

    // return early if mission and motions haven't loaded
    if (_.isEmpty(activeMissionMotions) || !mission) return null

    const { permissions } = getRolesForUserInCurrentWinflow(uid, activeMissionMotions, mission)
    // const docArr = documents ? Object.keys(documents) : undefined;

    return (
      <div>
        <Icon style={{ fontSize: 14, verticalAlign: -2, marginLeft: 4 }}>help_outline</Icon>
        <Drawer
          open={this.props.showSideDrawerEdit}
          openSecondary={true}
          className={!this.props.showSideDrawerEdit ? 'hidden' : null}
          containerStyle={this.getDrawerStyles()}
        >
          <div className="panel_head" onClick={() => this.props.handleSideDrawersToggle('edit')}>
            <div className="panel_header_text">{t('common.button.flierControl')}</div>
            <div className="panel_buttons panel_button_link">
              <Icon className="material-icons">arrow_right</Icon>
            </div>
          </div>
          <p className="sidedrawer_instructions">
            {t('sideDrawer.instruction')}
          </p>

          {permissions.addWorkItems && (
            <WorkItemSubmenu
              changeDocInProcess={this.props.changeDocInProcess}
              showDeleteWorkItemDialog={this.showDeleteWorkItemDialog}
              showWorkItemInUseDialog={this.showWorkItemInUseDialog}
            />
          )}
          {permissions.addMotionFromSidebar && <MotionSubmenu />}
          {permissions.addNewUserUidInMotion && (
            <PeopleSubmenu openInviteUserDialog={this.openInviteUserDialog} />
          )}

          <DeleteWorkItemDialog
            showDeleteWorkItemDialog={this.state.showDeleteWorkItemDialog}
            hideDeleteWorkItemDialog={this.hideDeleteWorkItemDialog}
            deleteWorkItem={() => this.handleDeleteWorkItem()}
          />

          <WorkItemInUseDialog
            showWorkItemInUseDialog={this.state.showWorkItemInUseDialog}
            hideWorkItemInUseDialog={this.hideWorkItemInUseDialog}
          />
        </Drawer>

        <Invite
          dialogInviteUser={this.state.dialogInviteUser}
          closeInviteUserDialog={this.closeInviteUserDialog}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { workspace } = state.instance

  let activeMissionMotions = []

  if (workspace === 'draft') {
    activeMissionMotions = state.database.orgInstanceDraftMotions[state.instance.mission.id]
  } else if (workspace === 'mission') {
    activeMissionMotions = state.database.orgInstanceActiveMotions[state.instance.mission.id]
  } else if (workspace === 'complete') {
    activeMissionMotions = []
  }

  return {
    uid: state.firebase.auth.uid,
    mission: state.instance.mission,
    motion: state.instance.motion,
    activeMissionMotions,
    // documents: state.redDocuments.documents[missionId]
  }
}

export default connect(mapStateToProps, null)(withTranslation('common')(SideDrawerEdit))
