// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

// Custom Components
import OrgSetting from './OrgSettings'
import Comments from './Comments'
import Invitations from './Invitations'
import OrgMembers from './OrgMembers'
import QuickOrgTemplates from './QuickOrgTemplates'
import OrgSelector from './OrgSelector'

import Alerts from '../../components/Alerts/AlertsNotificationSpaceContainer'

// MATERIAL UI
import Icon from '@material-ui/core/Icon'

import members from '../../assets/images/stats-person.svg'
import statsFlag from '../../assets/images/stats-flag.svg'

// Utils
import { selectWorkspace } from '../../store/actions'
// import { addMission, addMotion } from "../../utils/graphql-req";
// import motionPresets from "../../utils/motionPresets";

// const styles = {
//     button: {
//       color: "white",
//       backgroundColor: "#3EC4E5",
//       marginTop: "10px",
//       boxShadow: "none",
//     },
//   }

class Notifications extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // startDialog: false,
      // showDeleteTemplateDialog: false,
      // showDeleteDialog: false,
      // dialogNewMission: false,
      // targetMission: null,
      // targetTemplate: null,
    }
  }

  // componentDidMount() {

  //   const { activeOrg } = this.props;
  //   if (activeOrg && activeOrg.name)
  //     this.setState({ organizationName: activeOrg.name });
  //   else this.setState({ organizationName: "" });

  //   if (activeOrg && activeOrg.whatWeDo)
  //     this.setState({ whatWeDo: activeOrg.whatWeDo });
  //   else this.setState({ whatWeDo: "" });
  // }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (
  //     this.props.activeOrg &&
  //     nextProps.activeOrg &&
  //     this.props.activeOrg.name !== nextProps.activeOrg.name
  //   ) {
  //     this.setState({ organizationName: nextProps.activeOrg.name });
  //   }

  //   if (
  //     this.props.activeOrg &&
  //     nextProps.activeOrg &&
  //     this.props.activeOrg.whatWeDo !== nextProps.activeOrg.whatWeDo
  //   ) {
  //     this.setState({ whatWeDo: nextProps.activeOrg.whatWeDo });
  //   }

  //   return true;
  // }

  // toggleNewMissionDialog = () => {
  //   this.setState({
  //     dialogNewMission: !this.state.dialogNewMission,
  //     activeStep: 0,
  //   });
  // };

  // create mission form starter template
  // createProcess = async (
  //   processName,
  //   processDescription,
  //   processDate,
  //   fromStart,
  //   blockchain
  // ) => {
  //   const { starterTemplate } = this.state;

  //   //create new Winflow
  //   let newMission = {};
  //   newMission.name = processName || starterTemplate.name;
  //   newMission.description = processDescription || starterTemplate.description;
  //   newMission.blockchain = blockchain;
  //   newMission.creator = this.props.auth.uid;
  //   newMission.status = "draft";
  //   newMission.members = [this.props.auth.uid];
  //   newMission.org = this.props.activeOrg.id;
  //   newMission.invitations = [];

  //   // push to DB
  //   const mission = await addMission(newMission);

  //   const motionTemplates = get(starterTemplate, ["motions"], []);

  //   const newMotions = [];
  //   //Create motions from templete
  //   if (fromStart) {
  //     // Create motions with start date in mind
  //     let date = moment(processDate);
  //     motionTemplates.forEach((item, index) => {
  //       // Create new motion
  //       const newMotion = cloneDeep(motionPresets[item.preset]);
  //       newMotion.org = this.props.activeOrg.id;
  //       newMotion.index = index;
  //       newMotion.mission = mission.id;
  //       newMotion.missionStatus = "draft";
  //       newMotion.motionStatus = "draft";
  //       newMotion.private = false;
  //       newMotion.makeActive = false;
  //       newMotion.description = item.description || "";
  //       newMotion.subheader = item.subheader || "";
  //       newMotion.startDate = date.toDate().toString();
  //       newMotion.endDate = date
  //         .add(item.duration || 1, "days")
  //         .toDate()
  //         .toString();
  //       newMotion.members[0].uid = this.props.auth.uid;
  //       newMotion.comments = [];

  //       newMotions.push(newMotion);
  //     });
  //   } else {
  //     // Create motions with end date in mind
  //     let date = moment(processDate);
  //     let count = 1;
  //     motionTemplates
  //       .slice()
  //       .reverse()
  //       .forEach((item, index) => {
  //         // Create new motion
  //         const newMotion = cloneDeep(motionPresets[item.preset]);
  //         newMotion.org = this.props.activeOrg.id;
  //         newMotion.index = motionTemplates.length - count;
  //         newMotion.mission = mission.id;
  //         newMotion.missionStatus = "draft";
  //         newMotion.motionStatus = "draft";
  //         newMotion.private = false;
  //         newMotion.makeActive = false;
  //         newMotion.description = item.description || "";
  //         newMotion.subheader = item.subheader || "";
  //         newMotion.endDate = date.toDate().toString();
  //         newMotion.startDate = date
  //           .subtract(item.duration, "days")
  //           .toDate()
  //           .toString();
  //         newMotion.members[0].uid = this.props.auth.uid;
  //         count++;

  //         newMotions.push(newMotion);
  //       });
  //   }

  //   // push to database
  //   const motions = [];
  //   newMotions.forEach(async (newMotion) => {
  //     const motion = await addMotion(newMotion);
  //     motions.push(motion);
  //   });

  //   // switch to active mission tab
  //   this.props.selectMission(mission);
  //   this.props.selectMotion(motions.find((motion) => motion.index === 0));
  //   this.props.selectWorkspace("draft");
  //   this.closeStartDialog();
  // };

  // openStartDialog = (template, group) => {
  //   this.setState({
  //     startDialog: true,
  //     starterTemplate: template,
  //     group: group,
  //   });
  // };

  // closeStartDialog = () => {
  //   this.setState({
  //     startDialog: false,
  //     starterTemplate: undefined,
  //     group: undefined,
  //   });
  // };

  render() {
    const { activeOrg, t } = this.props

    let sortedOrgs = this.props.orgs.sort((a, b) => {
      if (b.orgMissions.length === a.orgMissions.length) {
        return b.allActiveMissions.length - a.allActiveMissions.length
      } else {
        return b.orgMissions.length - a.orgMissions.length
      }
    })

    let currentOrg = this.props.orgs.filter((org) => org.id === activeOrg.id)

    let notifSpace = (
      <div className="mission-control">
        <div className={'emptystate'}>
          <div>
            <p> {t('draftSpace.index.emptystate.p1')}</p>
            <p> {t('draftSpace.index.emptystate.p2')}</p>
          </div>
        </div>
      </div>
    )

    if (!_.isEmpty(activeOrg) && currentOrg) {
      notifSpace = (
        <div className="mission-control">
          <div className="org-notifications-members">
            <div className="org-wrapper">
              <OrgSetting />
              <div className="home-org-missions">
                <div className="home-org-members">
                  <img
                    className="stats-flag"
                    src={statsFlag}
                    style={{ marginTop: '6px', marginLeft: '11px', width: '50px' }}
                  />
                  <div style={{ lineHeight: '42px', position: 'absolute' }}>
                    {currentOrg && currentOrg[0].orgMissions.length}
                  </div>
                  <div className="mission-text">{t('home.active.missions')}</div>
                </div>
                <div className="home-org-members">
                  <img className="mission-number" src={members} />
                  <div style={{ lineHeight: '42px', position: 'absolute' }}>
                    {currentOrg && currentOrg[0].members.length}
                  </div>
                  <div className="mission-text">{t('home.missions.members')}</div>
                </div>
                <div className="home-org-members">
                  <img
                    className="stats-flag"
                    src={statsFlag}
                    style={{ marginTop: '6px', marginLeft: '11px', width: '50px' }}
                  />
                  <div style={{ lineHeight: '42px', position: 'absolute' }}>
                    {currentOrg && currentOrg[0].allActiveMissions}
                  </div>
                  <div className="mission-text">{t('home.all.active.missions')}</div>
                </div>
              </div>
            </div>

            <div className="notification-members">
              <div className="notification-wrapper-org">
                <Alerts />
                <Comments />
                <Invitations />
              </div>
              <div className="members-wrapper">
                <OrgMembers />
              </div>
            </div>
          </div>
          <div className="quickstart-container" style={{ overflowY: 'auto' }}>
            <QuickOrgTemplates />
            {/* <div className="quickstart-wrapper">
              <h3>Quick Start Templates</h3>
              <p>Use one of Hi-fliers templates to get started fast.</p>
              <div className="line-div" />
              <ControlledExpansionPanels
                openStartDialog={this.openStartDialog}
              />

              {this.state.startDialog && (
                <StarterModal
                  startDialog={this.state.startDialog}
                  createProcess={this.createProcess}
                  template={this.state.starterTemplate}
                  closeDialog={this.closeStartDialog}
                />
              )}

              <div>
                <h3>Org-wide templates</h3>
                <div className="line-div" />
                <TemplatesMenu
                  openDeleteTemplateDialog={this.openDeleteTemplateDialog}
                />
              </div>

              <div className="create-mission">
                <h3>Create your own</h3>
                <p>Start with a blank canvas and build your process genius.</p>
                <div className="line-div" />
                <MainButton
                  variant="contained"
                  style={styles.button}
                  className="create-button"
                  onClick={() => this.toggleNewMissionDialog()}
                >
                  + Create Mission
                </MainButton>
              </div>
            </div> */}
          </div>
          <Icon
            // value={orgData.org.id}
            onClick={(event) => {
              this.props.selectWorkspace('home')
              // window.location.reload()
            }}
            style={{
              // fontSize: 50,
              color: '#3EC4E5',
              backgroundColor: 'lightgray',
              // borderRadius: "50%",
              width: '26px',
              height: '45px',
              marginTop: '20px',
              paddingTop: '10px',
              // marginLeft: "4px",
              paddingLeft: '4px',
              marginRight: '-26px',
              zIndex: '6',
              boxShadow: '2px 0px 0px 0px rgba(0, 0, 0, 0.1)',
              borderRight: '1px solid darkgrey',
              borderTop: '1px solid darkgrey',
              borderBottom: '1px solid darkgrey',
              borderRadius: '0% 10% 10% 0%',
              cursor: 'pointer',
            }}
          >
            arrow_back_ios
          </Icon>
          <div className="org-selector-container">
            <OrgSelector
              selectorOrgs={sortedOrgs.filter((orgData) => orgData.id != activeOrg.id)}
              addNewOrg={this.props.addNewOrg}
            />
          </div>
          {/* <NewMissionModal
            dialogNewMission={this.state.dialogNewMission}
            toggleNewMissionDialog={this.toggleNewMissionDialog}
          /> */}
        </div>
      )
    }

    return notifSpace
  }
}

const mapStateToProps = (state, ownProps) => {
  // let orgOwner = null;
  //   if (
  //     state.instance.org &&
  //     state.instance.org.owner &&
  //     state.instance.org.owner[0] &&
  //     state.database.orgInstanceUsers &&
  //     state.database.orgInstanceUsers[state.instance.org.owner[0]]){
  //       orgOwner = state.database.orgInstanceUsers[state.instance.org.owner[0]];
  //       orgOwner.id = state.instance.org.owner[0]
  //   }

  return {
    // auth: state.firebase.auth,
    activeOrg: state.instance.org,
    orgs: ownProps.orgs,
    // users: state.database.orgInstanceUsers,
    // orgOwner,
    // previewImage: state.instance.userProfileImagePreview,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // selectMotion: (motion) => dispatch(selectMotion(motion)),
    // selectMission: (mission) => dispatch(selectMission(mission)),
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
    // deleteMission: mission => dispatch(deleteMission(mission)),
    // deleteAllMotions: motionIds => dispatch(deleteAllMotions(motionIds)),
    // deleteTemplate: templateId => dispatch(deleteTemplate(templateId)),
    // deleteTemplateMotions: motions => dispatch(deleteTemplateMotions(motions)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('common')(Notifications))
