import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, cloneDeep } from 'lodash'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

// Material UI
import { withStyles } from '@material-ui/core/styles'

// Custom Components
import ControlledExpansionPanels from '../DraftSpace/ControlledExpansionPanels'
import StarterModal from '../DraftSpace/StarterModal'
import MainButton from '../../components/Buttons/MainButton'
import NewMissionModal from '../DraftSpace/NewMissionModal'
import TemplatesMenu from '../DraftSpace/TemplatesMenu'
import DeleteTemplateDialog from '../DraftSpace/DeleteTemplateDialog'

// Utils
import { selectMotion, selectMission, selectWorkspace } from '../../store/actions'
import { addMission, addMotion, deleteMissionTemplate, addWorkItem } from '../../utils/graphql-req'
// import motionPresets from '../../utils/motionPresets'

const styles = {
  button: {
    color: 'white',
    backgroundColor: '#3EC4E5',
    marginTop: '10px',
    boxShadow: 'none',
  },
  root: {},
}

class QuickOrgTemplates extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDialog: false,
      showDeleteTemplateDialog: false,
      // showDeleteDialog: false,
      dialogNewMission: false,
      // targetMission: null,
      targetTemplate: null,
    }
  }

  toggleNewMissionDialog = () => {
    this.setState({
      dialogNewMission: !this.state.dialogNewMission,
      activeStep: 0,
    })
  }

  openStartDialog = (template, group) => {
    this.setState({
      startDialog: true,
      starterTemplate: template,
      group: group,
    })
  }

  closeStartDialog = () => {
    this.setState({
      startDialog: false,
      starterTemplate: undefined,
      group: undefined,
    })
  }

  openDeleteTemplateDialog = (templateId) => {
    this.setState({
      showDeleteTemplateDialog: true,
      targetTemplate: templateId,
    })
  }

  hideDeleteTemplateDialog = () => {
    this.setState({ showDeleteTemplateDialog: false, targetTemplate: null })
  }

  deleteTemplate = async () => {
    const missionTempleteToDelete = cloneDeep(this.state.targetTemplate)
    delete missionTempleteToDelete['__typename']
    await deleteMissionTemplate(missionTempleteToDelete)
    this.hideDeleteTemplateDialog()
  }

  // create mission form starter template
  createProcess = async (
    processName,
    processDescription,
    processDate,
    fromStart,
    blockchain,
    document
  ) => {
    const { starterTemplate } = this.state
    const { t } = this.props

    //create new Winflow
    let newMission = {}
    newMission.name = processName || starterTemplate.name
    newMission.description = processDescription || starterTemplate.description
    newMission.blockchain = blockchain
    newMission.creator = this.props.auth.uid
    newMission.status = 'draft'
    newMission.members = [this.props.auth.uid]
    newMission.org = this.props.activeOrg.id
    newMission.invitations = []

    // push to DB
    const mission = await addMission(newMission)

    const motionTemplates = get(starterTemplate, ['motions'], [])
    const motionPresets = t('motionPresets:items');
    const newMotions = []
    //Create motions from templete
    if (fromStart) {
      // Create motions with start date in mind
      let date = moment(processDate)
      motionTemplates.forEach((item, index) => {
        // Create new motion
        const newMotion = cloneDeep(motionPresets[item.preset])
        newMotion.org = this.props.activeOrg.id
        newMotion.index = index
        newMotion.mission = mission.id
        newMotion.missionStatus = 'draft'
        newMotion.motionStatus = 'draft'
        newMotion.private = false
        newMotion.makeActive = false
        newMotion.description = item.description || ''
        newMotion.subheader = item.subheader || ''
        newMotion.startDate = date.toDate().toString()
        newMotion.endDate = date
          .add(item.duration || 1, 'days')
          .toDate()
          .toString()
        newMotion.members[0].uid = this.props.auth.uid
        newMotion.comments = []

        newMotions.push(newMotion)
      })
    } else {
      // Create motions with end date in mind
      let date = moment(processDate)
      let count = 1
      motionTemplates
        .slice()
        .reverse()
        .forEach((item, index) => {
          // Create new motion
          const newMotion = cloneDeep(motionPresets[item.preset])
          newMotion.org = this.props.activeOrg.id
          newMotion.index = motionTemplates.length - count
          newMotion.mission = mission.id
          newMotion.missionStatus = 'draft'
          newMotion.motionStatus = 'draft'
          newMotion.private = false
          newMotion.makeActive = false
          newMotion.description = item.description || ''
          newMotion.subheader = item.subheader || ''
          newMotion.endDate = date.toDate().toString()
          newMotion.startDate = date.subtract(item.duration, 'days').toDate().toString()
          newMotion.members[0].uid = this.props.auth.uid
          count++

          newMotions.push(newMotion)
        })
    }

    // push to database
    const motions = []
    newMotions.forEach(async (newMotion) => {
      const motion = await addMotion(newMotion)
      motions.push(motion)
    })

    // create new workItem if one was added
    if (document) {
      const newWorkItem = { ...document }

      delete newWorkItem['isDir']
      delete newWorkItem['linkType']
      delete newWorkItem['thumbnailLink']
      delete newWorkItem['id']
      newWorkItem['mission'] = mission.id
      newWorkItem['motion'] = [motions.id]

      // post to database
      await addWorkItem(newWorkItem)
    }

    // switch to active mission tab
    this.props.selectMission(mission)
    this.props.selectMotion(motions.find((motion) => motion.index === 0))
    this.props.selectWorkspace('draft')
    this.closeStartDialog()
  }

  render() {
    const { activeOrg, t } = this.props
    return (
      <div className="quick-template-create">
        <div className="quickstart-wrapper">
          <h3 style={{ marginTop: '0px' }}>{t('quickOrgTemplates:instantMissions')}</h3>
          <p>{t('quickOrgTemplates:instantMissions.p')}</p>
          {/* <div className="line-div" /> */}
          <ControlledExpansionPanels openStartDialog={this.openStartDialog} />

          {this.state.startDialog && (
            <StarterModal
              startDialog={this.state.startDialog}
              createProcess={this.createProcess}
              template={this.state.starterTemplate}
              closeDialog={this.closeStartDialog}
            />
          )}
        </div>

        <div style={{ height: '21%' }}>
          <h3>{t('quickOrgTemplates:organizationMissions')}</h3>
          {/* <div className="line-div" /> */}
          <TemplatesMenu openDeleteTemplateDialog={this.openDeleteTemplateDialog} />
        </div>

        <div className="create-mission">
          <h3>{t('quickOrgTemplates:customMissions')}</h3>
          <p>{t('quickOrgTemplates:customMissions.p')}</p>
          {/* <div className="line-div" /> */}
          <MainButton
            variant="contained"
            style={styles.button}
            className="create-button"
            onClick={() => this.toggleNewMissionDialog()}
          >
            + {t('quickOrgTemplates:createMission.button')}
          </MainButton>
        </div>
        <NewMissionModal
          dialogNewMission={this.state.dialogNewMission}
          toggleNewMissionDialog={this.toggleNewMissionDialog}
        />
        <DeleteTemplateDialog
          showDeleteTemplateDialog={this.state.showDeleteTemplateDialog}
          hideDeleteTemplateDialog={this.hideDeleteTemplateDialog}
          deleteTemplate={() => this.deleteTemplate()}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    activeOrg: state.instance.org,
    auth: state.firebase.auth,
    orgs: state.database.userOrgs,
    draftMotions: state.database.orgInstanceDraftMotions,
    //   instanceOrgAllActiveMotions,
    //   instanceTemplateMotions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectMotion: (motion) => dispatch(selectMotion(motion)),
    selectMission: (mission) => dispatch(selectMission(mission)),
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
    // deleteMission: mission => dispatch(deleteMission(mission)),
    // deleteAllMotions: motionIds => dispatch(deleteAllMotions(motionIds)),
    // deleteTemplate: templateId => dispatch(deleteTemplate(templateId)),
    // deleteTemplateMotions: motions => dispatch(deleteTemplateMotions(motions)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation(['quickOrgTemplates', 'motionPresets'])(QuickOrgTemplates)))
