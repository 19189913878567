// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEqual, isEmpty, cloneDeep } from 'lodash'
import GooglePicker from 'react-google-picker'
import { withTranslation } from 'react-i18next'

// Material UI
import { withStyles } from '@material-ui/core/styles'
// import TextField from "@material-ui/core/TextField";
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DatePicker from 'material-ui/DatePicker'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
// import Checkbox from "@material-ui/core/Checkbox";
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Tooltip from '@material-ui/core/Tooltip'

//Custom local components
import MainButton from '../../components/Buttons/MainButton'
import SecondaryButton from '../../components/Buttons/SecondaryButton'
import StyledTextField from '../../components/StyledTextField'
import AddItemLink from '../SideDrawerEdit/AddItemLink'

//Helpers
import { selectMotion, selectMission, selectWorkspace } from '../../store/actions'
import {
  addMission,
  addMotion,
  addWorkItem,
  getMotionTemplates,
  getWorkItems,
} from '../../utils/graphql-req'
// import { duration } from "moment";
import { MuiThemeProvider, getMuiTheme } from 'material-ui/styles'
import DropboxPicker from '../../components/DropboxPicker/DropboxPicker'

const mainMuiTheme = getMuiTheme({
  flatButton: {
    // buttonFilterColor: "transparent",
    primaryTextColor: '#333541',
  },
  datePicker: {
    selectColor: '#3EC4E5',
    selectTextColor: '#333541',
    headerColor: '#333541',
  },
})

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 265,
    marginBottom: 15,
  },
  radio: {
    display: 'inline-block',
  },
  button: {
    marginLeft: 10,
    marginRight: 10,
  },
  dialog: {
    width: '75%',
    textAlign: 'center',
    margin: 'auto',
    minHeight: 400,
  },
  checkBox: {
    width: 265,
    marginTop: 15,
  },
  googlelogos: {
    height: '120px',
    width: '120px',
    margin: '10px',
    cursor: 'pointer',
  },
})

class TemplateMissionModal extends Component {
  state = {
    startEndValue: 'start',
    missionName: '',
    missionDescription: '',
    blockchain: false,
    activeStep: 0,
    error: null,
    document: null,
    extensionIcon: 'document',
    pickerApiLoaded: false,
    oauthToken: null,
    clickedMotion: null,
    missionDate: null,
    templateMotions: [],
  }

  async componentDidUpdate(prevProps) {
    const { clickedTemplate } = this.props
    if (!isEqual(prevProps.clickedTemplate, clickedTemplate)) {
      // Get associated Motions
      if (clickedTemplate && clickedTemplate.id) {
        const templateMotions = await getMotionTemplates(clickedTemplate.id)
        this.setState({
          templateMotions,
          missionName: `Copy of ${clickedTemplate.name}`,
          missionDescription: clickedTemplate.description,
        })
      }
    }
  }

  handleChange = (event) => {
    this.setState({ startEndValue: event.target.value })
  }

  handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked })
  }

  handleStepperNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }))
  }

  handleStepperBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }))
  }

  handleSubmitNewLink = (newLink) => {
    this.setState({
      document: newLink,
      error: null,
    })
  }

  extensionIconFinder = (name) => {
    name = name.name ? name.name : name
    if (name.isDir === true) {
      return 'folder'
    } else if (name.split('.').pop() === 'ppt' || name.split('.').pop() === 'pptx') {
      return 'powerpoint'
    } else if (name.split('.').pop() === 'xls' || name.split('.').pop() === 'xlsx') {
      return 'excel'
    } else if (name.split('.').pop() === 'doc' || name.split('.').pop() === 'docx') {
      return 'word'
    } else if (name.split('.').pop() === 'pdf') {
      return 'pdf'
    } else {
      return 'document'
    }
  }

  onSuccessDropboxChooser = (files) => {
    const doc = files[0]
    const extensionIconDropBox = this.extensionIconFinder(doc)

    this.setState({
      document: doc,
      extensionIcon: extensionIconDropBox,
      error: null,
    })
  }

  onSuccessGooglePicker = (files) => {
    const doc = files.docs

    if (doc) {
      const file = doc[0]

      //Choose extenstion
      let extensionIconGoogle = ''
      let folder = 'false'
      if (file.type === 'folder') {
        extensionIconGoogle = 'folder'
        folder = 'true'
      } else if (file.serviceId === 'DoclistBlob') {
        extensionIconGoogle = this.extensionIconFinder(file.name)
      } else if (file.serviceId === 'doc') {
        extensionIconGoogle = 'word'
      } else if (file.serviceId === 'spread') {
        extensionIconGoogle = 'excel'
      } else if (file.serviceId === 'pres') {
        extensionIconGoogle = 'powerpoint'
      } else {
        extensionIconGoogle = 'document'
      }

      const googleDoc = {
        bytes: file.sizeBytes,
        icon: file.iconUrl,
        isDir: folder,
        link: file.url,
        name: file.name,
      }

      this.setState({
        document: googleDoc,
        extensionIcon: extensionIconGoogle,
        error: null,
      })
    }
  }

  //Calculate the difference between dates
  getDateDifference = (date1, date2) => {
    const startDate = new Date(date1)
    const endDate = new Date(date2)
    const msecondsInDays = 1000 * 60 * 60 * 24
    let days = Math.ceil((endDate - startDate) / msecondsInDays)
    return days
  }

  //Get new date for start to end
  getNewDate = (date, numDays) => {
    const origDate = new Date(date)
    const num = numDays
    origDate.setDate(origDate.getDate() + num)
    return origDate
  }

  //duplicates the motions with selected date as start of first motion
  duplicateByStartDate = (newMotions, oldMotions, duplicatedWorkItems, missionId, userId) => {
    let motionDateDiff = 0
    let startDate = this.state.missionDate
    for (let i = 0; i < oldMotions.length; i++) {
      let duplicateMotion = cloneDeep(oldMotions[i])
      let motionDuration = this.getDateDifference(oldMotions[i].startDate, oldMotions[i].endDate)
      if (i > 0) {
        motionDateDiff = this.getDateDifference(
          oldMotions[i - 1].startDate,
          oldMotions[i].startDate
        )
        startDate = newMotions[i - 1].startDate
      }
      for (let j = 0; j < duplicatedWorkItems.length; j++) {
        if (duplicatedWorkItems[j]['id']) delete duplicatedWorkItems[j]['id']
        if (duplicatedWorkItems[j]['__typename']) delete duplicatedWorkItems[j]['__typename']
        const motionIndex = duplicatedWorkItems[j].motion.findIndex(
          (motionId) => motionId === oldMotions[j].id
        )
        if (motionIndex !== -1) duplicatedWorkItems[j].motion[motionIndex] = oldMotions[j].index
      }
      if (duplicateMotion['id']) delete duplicateMotion['id']
      if (duplicateMotion['__typename']) delete duplicateMotion['__typename']
      duplicateMotion.mission = missionId
      duplicateMotion.comments = []
      duplicateMotion.startDate = this.getNewDate(startDate, motionDateDiff)
      duplicateMotion.endDate = this.getNewDate(duplicateMotion.startDate, motionDuration)
      duplicateMotion.members.forEach((member, index) => {
        if (index === 0) member.uid = userId
        else member.uid = null
        member.status = null
        if (member['__typename']) delete member['__typename']
      })
      newMotions.push(duplicateMotion)
    }
  }

  //duplicates the motion with selected date as end of last motion
  duplicateByEndDate = (newMotions, oldMotions, duplicatedWorkItems, missionId, userId) => {
    let motionDateDiff = 0
    let endDate = this.state.missionDate
    for (let i = oldMotions.length - 1; i >= 0; i--) {
      let duplicateMotion = cloneDeep(oldMotions[i])
      let motionDuration = this.getDateDifference(oldMotions[i].startDate, oldMotions[i].endDate)
      if (i < oldMotions.length - 1) {
        motionDateDiff = this.getDateDifference(oldMotions[i + 1].endDate, oldMotions[i].endDate)
        endDate = newMotions[i + 1].endDate
      }
      for (let j = 0; j < duplicatedWorkItems.length; j++) {
        if (duplicatedWorkItems[j]['id']) delete duplicatedWorkItems[j]['id']
        if (duplicatedWorkItems[j]['__typename']) delete duplicatedWorkItems[j]['__typename']
        const motionIndex = duplicatedWorkItems[j].motion.findIndex(
          (motionId) => motionId === oldMotions[j].id
        )
        if (motionIndex !== -1) duplicatedWorkItems[j].motion[motionIndex] = oldMotions[j].index
      }
      if (duplicateMotion['id']) delete duplicateMotion['id']
      if (duplicateMotion['__typename']) delete duplicateMotion['__typename']
      duplicateMotion.mission = missionId
      duplicateMotion.comments = []
      duplicateMotion.endDate = this.getNewDate(endDate, motionDateDiff)
      duplicateMotion.startDate = this.getNewDate(duplicateMotion.endDate, -motionDuration)
      duplicateMotion.members.forEach((member, index) => {
        if (index === 0) member.uid = userId
        else member.uid = null
        member.status = null
        if (member['__typename']) delete member['__typename']
      })
      newMotions[i] = duplicateMotion
    }
  }

  createNewMissionFromTemplate = async () => {
    //create new mission
    let newMission = { ...this.props.clickedTemplate }
    newMission.name = this.state.missionName
    newMission.blockchain = this.state.blockchain
    newMission.creator = this.props.auth.uid
    newMission.status = 'draft'
    newMission.members = [this.props.auth.uid]
    newMission.org = this.props.activeOrg.id
    newMission.invitations = []
    if (newMission['__typename']) delete newMission['__typename']

    // push to DB
    const mission = await addMission(newMission)

    //fetch work items from this template
    const workItems = await getWorkItems(this.props.clickedTemplate.id)
    const duplicateMotions = []
    if (this.state.startEndValue === 'start') {
      this.duplicateByStartDate(
        duplicateMotions,
        this.state.templateMotions,
        workItems,
        mission.id,
        this.props.auth.uid
      )
    } else if (this.state.startEndValue === 'end') {
      this.duplicateByEndDate(
        duplicateMotions,
        this.state.templateMotions,
        workItems,
        mission.id,
        this.props.auth.uid
      )
    }

    const motions = []
    for (let i = 0; i < duplicateMotions.length; i++) {
      const motion = await addMotion(duplicateMotions[i])
      motions.push(motion)
      workItems.forEach((workItem) => {
        const motionIndex = workItem.motion.findIndex((index) => index === motion.index)
        if (motionIndex !== -1) workItem.motion[motionIndex] = motion.id
      })
    }

    for (let i = 0; i < workItems.length; i++) {
      workItems[i].mission = mission.id
      await addWorkItem(workItems[i])
    }

    // create new workItem if one was added
    if (this.state.document) {
      const newWorkItem = { ...this.state.document }

      delete newWorkItem['isDir']
      delete newWorkItem['linkType']
      delete newWorkItem['thumbnailLink']
      delete newWorkItem['id']
      newWorkItem['mission'] = mission.id
      newWorkItem['motion'] = []

      // pust to database
      await addWorkItem(newWorkItem)
    }

    // cleanup
    this.props.selectMission(mission)
    if (!isEmpty(motions)) {
      const firstMotion = motions.find((motion) => motion.index === 0)
      if (firstMotion) this.props.selectMotion(firstMotion)
      else this.props.selectMotion(motions[0])
    }
    this.props.selectWorkspace('draft')
    this.props.toggleTemplateModal()
  }

  render() {
    const { classes, t } = this.props
    const { activeStep } = this.state
    return (
      <Dialog
        modal="true"
        className={classes.dialog}
        open={this.props.showTemplateMissionModal}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle color="primary" id="form-dialog-title">
          {t('newMissionModal.title')}
          <br />
          {activeStep === 0 && (
            <span className="second-line">{t('templateMissionModal.step.0')}</span>
          )}
          {activeStep === 1 && <span className="second-line">{t('newMissionModal.step.2')}</span>}
        </DialogTitle>

        <Stepper activeStep={activeStep} alternativeLabel>
          {t('starterModal.steps').map((label, index) => {
            const props = {}
            const labelProps = {}
            return (
              <Step key={label} {...props}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>

        <DialogContent>
          {activeStep === 0 ? (
            <div className="flex-form">
              <div className="flex50">
                <Tooltip title={t('common.button.cancel')} placement={'bottom'}>
                  <i className="material-icons x-icon" onClick={this.props.toggleTemplateModal}>
                    clear
                  </i>
                </Tooltip>
                <StyledTextField
                  id="standard-name"
                  label={t('common.label.whatMission')}
                  className={classes.textField}
                  value={this.state.missionName}
                  maxLength={40}
                  onChange={(event) => this.setState({ missionName: event.target.value })}
                  margin="normal"
                />
                <StyledTextField
                  id="standard-multiline-flexible"
                  label={t('starterModal.label.describeMission')}
                  multiline
                  rowsMax="4"
                  value={this.state.missionDescription}
                  maxLength={58}
                  onChange={(event) => this.setState({ missionDescription: event.target.value })}
                  className={classes.textField}
                  margin="normal"
                />{' '}
                <br />
              </div>
              <div className="flex50">
                <label className="label">{t('common.label.chooseOne')}:</label>

                <RadioGroup
                  row
                  aria-label="startend"
                  name="startend"
                  className={classes.group}
                  value={this.state.startEndValue}
                  onChange={this.handleChange}
                >
                  <FormControlLabel
                    value="start"
                    control={<Radio color="primary" />}
                    label={t('common.label.startDate')}
                  />
                  <FormControlLabel
                    value="end"
                    control={<Radio color="primary" />}
                    label={t('common.label.completionDate')}
                  />
                </RadioGroup>

                <label className="label">
                  {this.state.startEndValue === 'end'
                    ? t('common.label.shouldDone')
                    : t('common.label.shouldStart')}
                </label>

                <MuiThemeProvider muiTheme={mainMuiTheme}>
                  <DatePicker
                    firstDayOfWeek={0}
                    autoOk={true}
                    hintText={t('common:common.label.date')}
                    style={{ float: 'left' }}
                    locale={'en-US'}
                    onChange={(n, date) => this.setState({ missionDate: new Date(date.getTime()) })}
                    value={this.state.missionDate && new Date(this.state.missionDate)}
                  />
                </MuiThemeProvider>
              </div>
            </div>
          ) : (
            <div>
              <Tooltip title={t('common.button.cancel')} placement={'bottom'}>
                <i className="material-icons x-icon" onClick={this.props.toggleTemplateModal}>
                  clear
                </i>
              </Tooltip>
              <div className="storage-wrapper">
                <DropboxPicker onSuccess={(files) => this.onSuccessDropboxChooser(files)} />

                <AddItemLink onSubmit={this.handleSubmitNewLink} />

                {/* 
                  Google picker hidden until its fixed
                */}

                {/* <GooglePicker
                  clientId={
                    '632460170241-6jq1ihh6tf91r89qh0go8qvcfoc7sr8k.apps.googleusercontent.com'
                  }
                  developerKey={'AIzaSyDqy3cMYPpz5U01WG0WtvJpSs3BFrgDXe8'}
                  scope={['https://www.googleapis.com/auth/drive.readonly']}
                  onAuthenticate={(token) => console.warn('onAuthenticate: ' + token)}
                  onChange={(data) => this.onSuccessGooglePicker(data)}
                  multiselect={false}
                  navHidden={true}
                  authImmediate={false}
                  createPicker={(google, oauthToken) => {
                    const googleViewId = google.picker.ViewId.DOCS

                    const docsView = new google.picker.DocsView(googleViewId)
                      .setIncludeFolders(true)
                      .setSelectFolderEnabled(true)

                    const picker = new window.google.picker.PickerBuilder()
                      .addView(docsView)
                      .setOAuthToken(oauthToken)
                      .setDeveloperKey('AIzaSyDqy3cMYPpz5U01WG0WtvJpSs3BFrgDXe8')
                      .setCallback((data) => {
                        this.onSuccessGooglePicker(data)
                      })

                    picker.build().setVisible(true)
                  }}
                >
                  <div className="dropbox-button">
                    <img
                      className={classes.googlelogos}
                      src={require('../../assets/images/googledrive.png')}
                      alt="require"
                    />
                  </div>
                </GooglePicker> */}
              </div>
              {!!this.state.document ? (
                <img
                  src={require(`../../assets/images/file-${this.state.extensionIcon}.png`)}
                  alt="Document Icon"
                />
              ) : (
                <p></p>
              )}
              {!!this.state.document && (
                <div>
                  {this.state.document ? this.state.document.name : t('common.label.documentName')}
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions className="dialogActions">
          {activeStep === 0 ? (
            <React.Fragment>
              <div>
                <MainButton
                  onClick={this.handleStepperNext}
                  disabled={!this.state.missionName || !this.state.missionDate}
                >
                  {t('common.button.next')}
                </MainButton>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div>
                <SecondaryButton onClick={this.handleStepperBack}>
                  {t('common.button.back')}
                </SecondaryButton>
                <MainButton onClick={this.createNewMissionFromTemplate}>
                  {t('common.button.create')}
                </MainButton>
              </div>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    activeOrg: state.instance.org,
    activeMission: state.instance.mission,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectMotion: (motion) => dispatch(selectMotion(motion)),
    selectMission: (mission) => dispatch(selectMission(mission)),
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation('common')(TemplateMissionModal)))
