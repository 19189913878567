import React from 'react'

import { withTranslation } from 'react-i18next'

function WelcomeScreenContent({ header, imgSrc, alt, subText, t }) {
  return (
    <div>
      <h1>
        <b>{t(header)}</b>
      </h1>

      <img src={imgSrc} alt={alt} />
      <p>
        {subText.map((text) => (
          <>
            {t(text)}
            <br />
          </>
        ))}
      </p>
    </div>
  )
}

export default withTranslation('common')(WelcomeScreenContent)
