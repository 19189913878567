// Libraries
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

//material-ui
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";

// helpers
import templates from '../../utils/templates';

const styles = (theme) => ({
  root: {
    width: "100%",
    // height: '30px',
  },
  expanded: {
    backgroundColor: "#fff",
    padding: "0px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    flexBasis: "33.33%",
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  list: {
    width: "100%",
  },
  icon: {
    color: "#3EC4E5",
  },
  content: {
    height: "10px",
  },
  summary: {
    // height: "4vh",
    minHeight: "4vh",
    // '&$expanded': {
    //   height: "35px",
    //   minHeight: "35px",
    // },
  }
});

class ControlledExpansionPanels extends Component {
  state = {
    expanded: null
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  handleClick = (template, processKey) => {
    this.props.openStartDialog(template.winflows[processKey], template.name);
  }

  render() {
    const { classes, t } = this.props;
    if (!templates) return null;
    const { expanded } = this.state;
    return (
      <div >
        {t('templates').map((template, key) => (
          <ExpansionPanel
            className={classes.root}
            expanded={expanded === `epanel${key}{i}`}
            onChange={this.handleChange(`epanel${key}{i}`)}
            key={`epanel${key}{i}`}>
            <ExpansionPanelSummary
              // className={classes.summary}
              key={`epanel${key}{i}`}
              className="panel-summary"
              expandIcon={
                <Icon className={classes.icon + " material-icons"}>
                  arrow_drop_down
                </Icon>
              }>
              <Typography key={`epanel${key}{i}`} className={classes.heading}>
                {template.name}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              key={`epanel${key}{i}`}
              className={classes.expanded}>
              <List
                key={`epanel${key}{i}`}
                dense
                component="div"
                className={classes.list}>
                {Object.keys(template.winflows).map((processKey, j) => (
                  <Tooltip
                    key={`listitem${key}${processKey}${key}${j}`}
                    title={template.winflows[processKey].description}
                    placement="bottom">
                    <ListItem button key={`listitem${key}${processKey}${key}${j}`}>
                      <ListItemText
                        key={`listitem${key}${processKey}${key}${j}`}
                        primary={template.winflows[processKey].name}
                        onClick={() => this.handleClick(template, processKey)}/>
                    </ListItem>
                  </Tooltip>
                ))}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation('templates')(ControlledExpansionPanels));
