// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RIEInput } from 'riek'
import _ from 'lodash'
import { isString } from 'lodash'
import Flagend from '../../assets/images/Flag-tail.svg'
import { withTranslation } from 'react-i18next'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import grey from '@material-ui/core/colors/grey'
import Tooltip from '@material-ui/core/Tooltip'
import { grey400 } from 'material-ui/styles/colors'
// import Button from '@material-ui/core/Button';

// Custom Components
import MissionDialog from '../MissionDialog'
// import MainButton from "../../components/Buttons/MainButton";

// Helpers
// import { getRolesForUserInCurrentWinflow } from '../../utils/handleRoles';
import { updateMissionTitle, updateMissionDescrip } from '../../utils/graphql-req'

// Constants
// const DRAFT = "Planned";
// const START = "Start";
const ACTIVE = 'active'
const COMPLETE = 'complete'
// const CANCELLED = "cancelled"

const styles = (theme) => ({
  heavyFont: {
    fontWeight: 500,
  },
  icon: {
    color: grey[600],
  },
})

class MissionMeta extends Component {
  state = {
    initiateButtonDisabled: true,
    missionTitle: 'default',
    missionDialog: false,
    missionDescription: '',
    verification: 'Verify',
  }

  changeTitle = (title) => {
    this.title = title.title
  }

  changeDescription = (description) => {
    this.description = description.description
  }

  submitTitle = async () => {
    if (this.props.currentMission.id && this.title)
      await updateMissionTitle(this.props.currentMission.id, this.title)
  }

  submitDescription = async () => {
    if (this.props.currentMission.id && this.description)
      await updateMissionDescrip(this.props.currentMission.id, this.description)
  }

  changeVerification = (text) => {
    this.setState({ verification: text })
  }

  toggleMissionDialog = () => {
    this.setState({ missionDialog: !this.state.missionDialog })
  }

  checkInput = (description) => {
    if (description.match(/^\s+$/) || description === '') {
      return false
    }
    return true
  }

  render() {
    const { classes, currentMission, activeMissionMotions, uid, t } = this.props

    // Return early if current mission data is unavailable
    if (!currentMission) return null

    let nextActionButtonText
    let nextActionStatus
    let missionCompleted = false
    let missionStatus = _.get(currentMission, 'status')
    switch (currentMission.status) {
      case 'active':
        nextActionButtonText = 'Cancel'
        nextActionStatus = 'complete'
        break
      case 'draft':
        nextActionButtonText = 'Start'
        nextActionStatus = 'active'
        break
      case 'complete':
        nextActionButtonText = 'Delete'
        nextActionStatus = 'archived'
        missionCompleted = true
        break
      case 'cancelled':
        nextActionButtonText = 'Delete'
        nextActionStatus = 'archived'
        missionCompleted = true
        break
      case 'template':
        nextActionButtonText = 'Start'
        nextActionStatus = 'duplicate'
        break
      default:
        return null
    }

    // Determine if user can edit title and description
    let editableTitle = false
    let editableDesciption = false
    if (uid && !_.isEmpty(activeMissionMotions) && currentMission) {
      // const { permissions } = getRolesForUserInCurrentWinflow(uid, activeMissionMotions, currentMission);
      editableTitle = currentMission.creator === uid
      editableDesciption = currentMission.creator === uid
    }

    let title = (
      <h2 className="workspace-header">
        <b>{t('missionDialog.title')}:</b> {this.props.currentMission.name}
      </h2>
    )
    if (editableTitle) {
      title = (
        <h2 className="workspace-header">
          <b>{t('missionDialog.title')}:&nbsp;</b>
          <RIEInput
            value={this.props.currentMission.name}
            change={this.changeTitle.bind(this)}
            afterFinish={this.submitTitle.bind(this)}
            propName="title"
            className="workspace-header"
            validate={isString}
            editProps={{ maxLength: '40' }}
          />
          <Tooltip title={t('missionDialog.tooltip')} placement="right">
            <Icon
              className={`material-icons small-space ${classes.icon}`}
              style={{ fontSize: 13, zIndex: 11, color: grey }}
            >
              create
            </Icon>
          </Tooltip>
        </h2>
      )
    }

    let description = null
    if (this.props.currentMission.description) {
      description = (
        <div className="winflow-description-container">
          <h4 className="status-text editable-status">
            <span className={classes.heavyFont}>{t('common:common.description')}:</span>{' '}
            {this.props.currentMission.description}
          </h4>
        </div>
      )
    }
    if (editableDesciption) {
      description = (
        <div className="winflow-description-container">
          <h4 className="status-text description-text">
            <span className={classes.heavyFont}>{t('common:common.description')}:</span>
          </h4>
          <RIEInput
            value={
              this.props.currentMission.description
                ? this.props.currentMission.description
                : t('common:missionMeta.add.description')
            }
            change={this.changeDescription.bind(this)}
            afterFinish={this.submitDescription.bind(this)}
            propName="description"
            className="status-text editable-status"
            validate={this.checkInput.bind(this)}
            editProps={{ maxLength: '58' }}
          />
          <Tooltip title={t('common:missionMeta.click.description')} placement="right">
            <Icon
              className={`material-icons small-space ${classes.icon}`}
              style={{
                fontSize: 13,
                zIndex: 11,
                color: 'grey',
                verticalAlign: -4,
              }}
            >
              create
            </Icon>
          </Tooltip>
        </div>
      )
    }

    // Determine if mission creator exist and accessable
    return (
      <div className="header-container">
        <div className="header-wrapper">
          <div className="flag-wrapper">
            <div className="winflow-title-container">{title}</div>
            <div className="header-status-container">{description}</div>

            <div className="header-status-container">
              {currentMission.creator && (
                <h4 className="status-text">
                  <span className={classes.heavyFont}>{t('missionDialog.owner')}:</span>{' '}
                  {this.props.ownerName}
                  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
                </h4>
              )}

              {!missionCompleted && currentMission.blockchain && (
                <h4 className="status-text">
                  <span className={classes.heavyFont}>
                    {t('common:missionMeta.bblockchain.auditing')}:
                  </span>{' '}
                  {t('common:missionMeta.enabled')}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
                </h4>
              )}

              {missionCompleted && currentMission.blockchain && (
                <h4 className="status-text">
                  <span className={classes.heavyFont}>
                    {t('common:missionMeta.blockchain.recorded')}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
                  </span>{' '}
                </h4>
              )}

              {
                <h4 className="status-text">
                  <span className={classes.heavyFont}>{t('common:common.label.status')}:</span>{' '}
                  {currentMission.status}
                  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
                </h4>
              }

              {!missionCompleted && currentMission.startDateTime && (
                <h4 className="status-text">
                  <span className={classes.heavyFont}>{t('common:common.label.startDate')}:</span>{' '}
                  {currentMission.startDateTime}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
                </h4>
              )}

              {missionCompleted && currentMission.endDateTime && (
                <h4 className="status-text">
                  <span className={classes.heavyFont}>
                    {t('common:common.label.completionDate')}:
                  </span>{' '}
                  {currentMission.endDateTime}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
                </h4>
              )}
              {currentMission.creator === uid && (
                <div>
                  {missionStatus === ACTIVE && missionStatus !== COMPLETE ? (
                    <p className="blue-link" onClick={() => this.setState({ missionDialog: true })}>
                      {t(`special:${nextActionButtonText}`)}
                    </p>
                  ) : null}
                </div>
              )}

              {/* {missionCompleted &&
					currentMission.blockchain && verified == null &&(
						<div>
							<Button
								color="primary"
								className="action-button"
								onClick={() => (verified = verifyBlockchain(this.props.orgId, this.props.missionId), this.changeVerification("VERIFIED"))}>
								{verification}
							</Button>
						</div>
					)} */}
            </div>
            <div className="header-status-container" />
            <MissionDialog
              missionDialog={this.state.missionDialog}
              toggleMissionDialog={this.toggleMissionDialog}
              nextActionButtonText={nextActionButtonText}
              nextActionStatus={nextActionStatus}
            />
          </div>
          <img className="flagend" src={Flagend} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, passed) => {
  const { workspace } = state.instance

  let ownerName = ''
  // let completed = "";
  if (
    state.database.orgInstanceUsers &&
    state.instance.mission &&
    state.database.orgInstanceUsers[state.instance.mission.creator] &&
    state.database.orgInstanceUsers[state.instance.mission.creator].fullName
  ) {
    ownerName = state.database.orgInstanceUsers[state.instance.mission.creator].fullName
  }

  let activeMissionMotions = []
  if (workspace === 'draft') {
    activeMissionMotions = state.database.orgInstanceDraftMotions[state.instance.mission.id]
  } else if (workspace === 'mission') {
    activeMissionMotions = state.database.orgInstanceActiveMotions[state.instance.mission.id]
  } else if (workspace === 'complete') {
    activeMissionMotions = []
  }

  let motionsComplete = true
  if (!_.isEmpty(activeMissionMotions)) {
    Object.values(activeMissionMotions).forEach((motion) => {
      if (motion) {
        if (!motion.startDate || !motion.endDate) {
          motionsComplete = false
          return
        }
        Object.values(motion.members).forEach((member) => {
          if (!member.uid || !member.role) motionsComplete = false
        })
        if (!motionsComplete) return
      }
    })
  } else {
    motionsComplete = false
  }

  return {
    uid: state.firebase.auth.uid,
    currentOrg: state.instance.org,
    currentMission: state.instance.mission,
    draftMotions: state.instance.orgInstanceDraftMotions,
    activeMissionMotions,
    ownerName,
    motionsComplete,
  }
}

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTranslation(['common', 'special'])(MissionMeta)))
