import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = {
  label: {
  	color: "#333"
  }
};

const StyledDialogTitle = props => {
  const { classes } = props;

  return (
    <DialogTitle disableTypography id="invite-dialog">
      <h4 className={classes.label}>{props.children}</h4>
    </DialogTitle>
  );
};

StyledDialogTitle.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(StyledDialogTitle);
