// Libraries
import React, { Component } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

// Files
import statusGray from '../assets/images/start-gray.svg'
import statusCali from '../assets/images/start-cali.svg'
import statusGold from '../assets/images/start-gold.svg'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

// Custom Components
import StatusButton from '../components/Buttons/StatusButton'
import MissionDialog from '../containers/MissionDialog'

// Constants Variables
const DRAFT = 'Planned'
const START = 'Start'

// Constant Display Text
const ACTIVE = 'active'
const COMPLETE = 'complete'
const CANCELLED = 'cancelled'

const styles = {
  MuiTooltip: {
    tooltip: {
      fontSize: '10px',
      color: 'yellow',
      backgroundColor: 'red',
    },
  },
  startButtonContainer: {
    minWidth: '100px',
  },
}

class MissionStart extends Component {
  state = {
    missionDialog: false,
    missionDescription: '',
  }

  toggleMissionDialog = () => {
    this.setState({ missionDialog: !this.state.missionDialog })
  }

  render() {
    const { classes, currentMission, activeMissionMotions, uid, motionsComplete, t } = this.props
    if (!currentMission) return null

    let nextActionButtonText
    let nextActionStatus
    let missionCompleted = false
    let missionStatus = _.get(currentMission, 'status')
    switch (currentMission.status) {
      case 'active':
        nextActionStatus = 'complete'
        break
      case 'draft':
        nextActionButtonText = 'Start'
        nextActionStatus = 'active'
        break
      case 'complete':
        nextActionStatus = 'archived'
        missionCompleted = true
        break
      case 'cancelled':
        nextActionStatus = 'archived'
        missionCompleted = true
        break
      case 'template':
        nextActionButtonText = 'Start'
        nextActionStatus = 'duplicate'
        break
      default:
        return null
    }

    return (
      <div style={{ paddingTop: '310px' }}>
        <div>
          <Tooltip
            title={
              missionStatus !== ACTIVE && missionStatus !== COMPLETE && missionStatus !== CANCELLED
                ? t('missionStart.tooltip.title')
                : ''
            }
            placement="top"
            disabled={true}
          >
            <div className={classes.startButtonContainer}>
              <StatusButton
                onClick={() => this.setState({ missionDialog: true })}
                disabled={!motionsComplete}
                img={
                  missionStatus === ACTIVE || missionStatus === COMPLETE
                    ? statusGold
                    : missionStatus === CANCELLED
                    ? statusGray
                    : currentMission.status === 'draft' && motionsComplete
                    ? statusCali
                    : statusGray
                }
                text={
                  missionStatus === ACTIVE || missionStatus === COMPLETE
                    ? this.props.startDate
                    : missionStatus === CANCELLED
                    ? CANCELLED
                    : missionStatus === 'draft' && motionsComplete
                    ? START
                    : DRAFT
                }
              ></StatusButton>
            </div>
          </Tooltip>
        </div>
        <div className="header-status-container" />
        <MissionDialog
          missionDialog={this.state.missionDialog}
          toggleMissionDialog={this.toggleMissionDialog}
          nextActionButtonText={nextActionButtonText}
          nextActionStatus={nextActionStatus}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let startDate = 'Started'
  const { workspace } = state.instance
  let currentCompletedMotion =
    state.database.orgInstanceActiveMotions[state.instance.mission.id] ||
    state.database.orgInstanceCompleteMotions[state.instance.mission.id]
  let activeMissionMotions = []

  if (workspace === 'draft') {
    activeMissionMotions = state.database.orgInstanceDraftMotions[state.instance.mission.id]
  } else if (workspace === 'mission') {
    activeMissionMotions = state.database.orgInstanceActiveMotions[state.instance.mission.id]
  } else if (workspace === 'complete') {
    activeMissionMotions = []
  }

  if (currentCompletedMotion) {
    let fetchedDate = _.get(state, 'instance.mission.startDate')
    let date = new Date(fetchedDate)

    if (fetchedDate) {
      let formatedDate = `${date.getDate()}/${+date.getMonth() + 1}/${date
        .getFullYear()
        .toString()
        .substr(2)}`
      startDate += '\n' + formatedDate
    }
  }

  let motionsComplete = true
  if (!_.isEmpty(activeMissionMotions)) {
    Object.values(activeMissionMotions).forEach((motion) => {
      if (motion) {
        if (!motion.startDate || !motion.endDate) {
          motionsComplete = false
          return
        }
        Object.values(motion.members).forEach((member) => {
          if (!member.uid || !member.role) motionsComplete = false
        })
        if (!motionsComplete) return
      }
    })
  } else {
    motionsComplete = false
  }
  return {
    currentMission: state.instance.mission,
    currentOrg: state.instance.org,
    motionsComplete,
    startDate,
    uid: state.firebase.auth.uid,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation('common')(MissionStart)))
