// Libraries
import React, { Component } from "react";
import { connect } from 'react-redux';
import _ from "lodash";
import { withTranslation } from 'react-i18next';

// Material UI
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'

// Utils
import { showAddNewOrgDialog, selectWorkspace, selectOrg } from '../../store/actions'
// import { List } from "material-ui";

const styles = {
  select: {
    width: '180px',
    color: 'white',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: '#333541 !important',
  },
  icon: {
    color: 'white',
  },
}

class OrgMenu extends Component {
  handleMenuChange = (value) => {
    if (value === 'add-new') {
      this.props.showAddNewOrgDialog()
    } else {
      const targetOrg = this.props.userOrgs.find((org) => org.id === value)
      // console.log('org in navbar', targetOrg)
      this.props.selectOrg(targetOrg)
      this.props.selectWorkspace('notifications')
    }
  }

  render() {
    const { classes } = this.props;
    const { userOrgs, t } = this.props;

    // Setup menu items
    let menuItems = (
      <MenuItem style={{backgroundColor:'grey'}} value="no" disabled>
        {t('orgMenu.noOrg')}
      </MenuItem>
    )

    if (userOrgs.length !== 0) {
      userOrgs.sort(function (a, b) {
        return a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : b.name.toLowerCase() > a.name.toLowerCase()
          ? -1
          : 0
      })
      menuItems = userOrgs.map((org, index) => {
        return (
          <MenuItem
            key={index}
            style={{
              backgroundColor: '#333541',
              color: 'white',
            }}
            value={org.id}
            label={org.name}
          >
            {org.name}
          </MenuItem>
        )
      })
    }

    // Set Tab Title
    if (this.props.activeOrg && !_.isEmpty(userOrgs)) {
      const orgFound = userOrgs.find((element) => element.id === this.props.activeOrg.id)
      if (orgFound) document.title = 'Hi-Flier | ' + orgFound.name
    } else {
      document.title = 'Hi-Flier'
    }

    return (
      <div>
        <Select
          value={this.props.activeOrg ? this.props.activeOrg.id : ''}
          onChange={(event) => this.handleMenuChange(event.target.value)}
          disableUnderline
          classes={{
            icon: classes.icon,
            select: classes.select
          }}>
          <MenuItem
            value="organizations"
            style={{ fontFamily: "Roboto" }}
            disabled>
            {t('common.organization')}
          </MenuItem>
          {menuItems}
          <Divider />
          <MenuItem value="add-new" style={{ fontFamily: "Roboto" }}>
            + {t('common.addNew')}
          </MenuItem>
        </Select>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userOrgs: state.database.userOrgs,
    activeOrg: state.instance.org,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectOrg: (orgId) => dispatch(selectOrg(orgId)),
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
    showAddNewOrgDialog: () => dispatch(showAddNewOrgDialog()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation('common')(OrgMenu)));
