// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'
import { withTranslation } from 'react-i18next';
// import shortid from 'shortid';

// Material UI
import TextField from '@material-ui/core/TextField'
// import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Snackbar from '@material-ui/core/Snackbar'
import MenuItem from '@material-ui/core/MenuItem'

// Custom Components
import StyledTextField from '../../components/StyledTextField'
import MainButton from '../../components/Buttons/MainButton'
import SecondaryButton from '../../components/Buttons/SecondaryButton'

// Helpers
import { selectMission, selectWorkspace } from '../../store/actions'
import { addMissionTemplate, addMotionTemplate, addWorkItem } from '../../utils/graphql-req'

//Medium Modal
import MediumModal from '../../components/Modals/MediumModal'

class OptionSubmenu extends Component {
  state = {
    organizationalDialogOpen: false,
    templateName: null,
    org: this.props.activeOrg,
    snackBarOpen: false,
  }

  toggleOrganizationalDialog = () => {
    this.setState({
      organizationalDialogOpen: !this.state.organizationalDialogOpen,
    })
  }

  toggleWinflow = (id) => {
    const { selectMission } = this.props
    selectMission(id)
  }

  createOrgTemplate = async (templateName, org) => {
    const { activeMission, activeMissionMotions, workItems } = this.props

    // Save Template Mission
    const missionTemplate = cloneDeep(activeMission)
    if (missionTemplate['id']) delete missionTemplate['id']
    missionTemplate.name = templateName || 'Saved Template'
    missionTemplate.creator = ''
    missionTemplate.status = 'draft'
    missionTemplate.members = []
    missionTemplate.org = org.id
    delete missionTemplate['startDate']
    if (missionTemplate['__typename']) delete missionTemplate['__typename']
    if (missionTemplate['invitations']) delete missionTemplate['invitations']

    // save to db template mission collection
    const template = await addMissionTemplate(missionTemplate)

    //Save Template WorkItems
    const templateWorkItems = cloneDeep(workItems)

    // Save Template Motions
    const templateMotions = []
    activeMissionMotions.forEach((motion) => {
      const templateMotion = cloneDeep(motion)
      for (let i = 0; i < templateWorkItems.length; i++) {
        if (templateWorkItems[i]['id']) delete templateWorkItems[i]['id']
        if (templateWorkItems[i]['__typename']) delete templateWorkItems[i]['__typename']
        // templateWorkItems[i].mission = template.id;
        const motionIndex = templateWorkItems[i].motion.findIndex(
          (motionId) => motionId === templateMotion.id
        )
        if (motionIndex !== -1) templateWorkItems[i].motion[motionIndex] = templateMotion.index
      }
      if (templateMotion['id']) delete templateMotion['id']
      if (templateMotion['__typename']) delete templateMotion['__typename']
      if (templateMotion['private']) delete templateMotion['private']
      templateMotion.members.forEach((member) => {
        member.uid = null
        member.status = null
        if (member['__typename']) delete member['__typename']
      })
      templateMotion.comments = []
      templateMotion.mission = template.id
      templateMotion.missionStatus = 'draft'
      templateMotion.motionStatus = 'draft'
      templateMotion.org = org.id
      // templateMotion.startDate = "";
      // templateMotion.endDate = "";

      templateMotions.push(templateMotion)
    })

    // saveto db template motion collections
    for (let i = 0; i < templateMotions.length; i++) {
      const motionTemplates = await addMotionTemplate(templateMotions[i])
      templateWorkItems.forEach((workItem) => {
        const motionIndex = workItem.motion.findIndex((index) => index === motionTemplates.index)
        if (motionIndex !== -1) workItem.motion[motionIndex] = motionTemplates.id
      })
    }
    //Save template work items to db
    templateWorkItems.forEach(async (templateWorkItem) => {
      templateWorkItem.mission = template.id
      await addWorkItem(templateWorkItem)
    })

    this.setState({ organizationalDialogOpen: false, snackBarOpen: true })
  }

  renderOrgNames = () => {
    return this.props.userOrgs.map((org, i) => {
      return (
        <MenuItem key={`${org}-${i}`} value={org}>
          {org.name}
        </MenuItem>
      )
    })
  }

  render() {
    const { activeMission, t } = this.props;

    // console.log(this.state.org.name)
    return (
      <div className="submenu-container">
        <div className="submenu-body final-submenu">
          <p className="reuse-option" onClick={this.props.toggleDuplicateDialog}>
            {t('optionSubmenu.dublicate')}
          </p>

          {activeMission.status !== 'draft' && (
            <p className="reuse-option" onClick={this.toggleOrganizationalDialog}>
              {t('optionSubmenu.saveAs')}
            </p>
          )}

          <MediumModal
            open={this.state.organizationalDialogOpen}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">{t('optionSubmenu.save')}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('optionSubmenu.description')}
              </DialogContentText>
              <TextField
                margin="dense"
                id="select"
                select
                label={t('common.organization')}
                fullWidth
                value={this.state.org}
                // style={{ margin: '10px 0px 20px' }}
                onChange={(event) => this.setState({ org: event.target.value })}
              >
                {this.renderOrgNames()}
              </TextField>
              <StyledTextField
                autoFocus
                margin="dense"
                id="name"
                label={t('optionSubmenu.template.name')}
                type="text"
                fullWidth
                maxLength={40}
                style={{ margin: '10px 0px 20px' }}
                onChange={(event) => this.setState({ templateName: event.target.value })}
              />
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={this.toggleOrganizationalDialog}>{t('common.button.cancel')}</SecondaryButton>
              <MainButton
                onClick={() => this.createOrgTemplate(this.state.templateName, this.state.org)}
              >
                {t('common.button.save')}
              </MainButton>
            </DialogActions>
          </MediumModal>

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.snackBarOpen}
            onClose={() => {
              this.setState({ snackBarOpen: false })
            }}
            autoHideDuration={3000}
            ContentProps={{ 'aria-describedby': 'message-id' }}
            message={<span id="message-id"> {t('optionSubmenu.success')} </span>}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { workspace } = state.instance

  let activeMissionMotions = []
  if (workspace === 'draft') {
    activeMissionMotions = state.database.orgInstanceDraftMotions[state.instance.mission.id]
  } else if (workspace === 'mission') {
    activeMissionMotions = state.database.orgInstanceActiveMotions[state.instance.mission.id]
  } else if (workspace === 'complete') {
    activeMissionMotions = state.database.orgInstanceCompleteMotions[state.instance.mission.id]
  }

  return {
    auth: state.firebase.auth,
    activeOrg: state.instance.org,
    activeMission: state.instance.mission,
    activeMissionMotions,
    workItems: state.database.workItems,
    userOrgs: state.database.userOrgs,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectMission: (winflowID) => dispatch(selectMission(winflowID)),
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(OptionSubmenu))
