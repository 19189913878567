// Libraries
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { Provider } from 'react-redux'

// Material UI
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

// Components
import App from './App';

// Utils
import client from './utils/provider-setup';
import store from './utils/redux-store';
import * as serviceWorker from './serviceWorker';
import 'bulma/css/bulma.css';
import './styles.css';

// CSS
import "./assets/css/normalize.css";
import "./assets/css/components.css";
import "./assets/css/bipronum.css";
import "./assets/css/App.css";

const muiTheme = getMuiTheme({
    palette: {
      primary1Color: "#074880"
    }
});

const app = (
    <ApolloProvider client={client}>
        <Provider store={store}>
            <MuiThemeProvider muiTheme={muiTheme}>
                <BrowserRouter>
                    <React.Suspense fallback="Loading...">
                        <App />
                    </React.Suspense>
                </BrowserRouter>
            </MuiThemeProvider>
        </Provider>
      </ApolloProvider>
)

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
