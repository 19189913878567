// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { firestoreConnect } from "react-redux-firebase";
// import { compose } from "redux";
import { withTranslation } from 'react-i18next';

// Material UI
import Drawer from 'material-ui/Drawer'
// import FontIcon from "material-ui/FontIcon";
import Icon from '@material-ui/core/Icon'
// import { grey600, grey50 } from "material-ui/styles/colors";

class SideDrawerLog extends Component {
  getLog = () => {
    const { logs, t } = this.props

    if (logs) {
      // sort the logs on the backend in graphQL query****************** missionInstanceLogs query
      // console.log("these are logs", logs)
      let sortedLogs = logs.sort((a, b) => new Date(b.date) - new Date(a.date))
      // console.log("these are sorted logs", logs)

      // console.log(logs.reverse())
      return sortedLogs.map((log) => {
        const date = new Date(Date.parse(log.date))

        const options = {
          hour12: true,
          weekday: 'long',
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          timeZoneName: 'short',
        }

        const textDate = date.toLocaleString('en-US', options)
        const textLog = log.text
        return (
          <li key={log.id}>
            {`${textDate}`}
            <br />
            {`${textLog}`}
          </li>
        )
      })
    } else {
      return <div>{t('sideDrawerLog.getLog')}</div>
    }
  }

  getDrawerStyles() {
    if (this.props.initialState) {
      return {
        top: '15px',
        width: '250px',
        height: '96%',
        backgroundColor: '#fff',
        borderLeft: '1px solid white',
        borderTop: '1px solid white',
        overflowY: 'scroll',
        // overflowY: 'visible',
        paddingBottom: '20px',
        transform: 'none',
      }
    } else {
      return {
        top: '15px',
        width: '250px',
        height: '96%',
        backgroundColor: '#fff',
        color: '#a1a1a1',
        borderLeft: '1px solid white',
        borderTop: '1px solid white',
        overflowY: 'scroll',
        // overflow: 'visible',
        paddingBottom: '20px',
        transform: 'none',
      }
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Drawer
          open={this.props.showSideDrawerLog}
          openSecondary={true}
          className={!this.props.showSideDrawerLog ? 'hidden' : null}
          containerStyle={this.getDrawerStyles()}
        >
          <div className="panel_head" onClick={() => this.props.handleSideDrawersToggle('log')}>
            <div className="panel_header_text">{t('common.button.logbook')}</div>
            <div className="panel_buttons panel_button_link">
              <Icon className="material-icons"> arrow_right </Icon>
            </div>
          </div>
          <p className="sidedrawer_instructions">
            {t('sideDrawerLog.instructions')}
          </p>
          <ul className="info-text">{this.getLog()}</ul>
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    logs: state.database.logs,
  }
}

export default connect(mapStateToProps, null)(withTranslation('common')(SideDrawerLog))
