// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
// import Divider from "@material-ui/core/Divider";

// Helpers
import * as actions from '../../store/actions'
import { getMotionDates } from '../../utils/graphql-req'

const styles = () => ({
  white: {
    backgroundColor: '#fff',
    marginBottom: '10px',
  },

  '@global': {
    '.MuiChip-root': {
      borderRadius: '2px',
      height: '20px',
    },
    '.MuiChip-label': {
      paddingLeft: '5px',
      paddingRight: '5px',
    },
  },
})

class TabMenu extends Component {
  state = {
    chipValue: 'All',
    dueMotions: [],
  }

  async componentDidMount() {
    // console.log(this.props)
    const dueMotions = await getMotionDates(this.props.auth.uid, this.props.org.id, -1, 11)
    this.setState({
      dueMotions: dueMotions,
    })
  }

  handleChipChange = (value) => {
    this.setState({
      chipValue: value,
    })
  }

  getChips = () => {
    const { workspace, t } = this.props

    let chipsLabels = []
    if (workspace === 'complete') {
      chipsLabels = [
        { name: t('common:tabMenu.missionName'), value: 'All' },
        { name: t('common:tabMenu.role'), value: 'Role' },
        { name: t('common:tabMenu.people'), value: 'People' },
        { name: t('common:tabMenu.ownership'), value: 'Ownership' },
      ]
    } else {
      chipsLabels = [
        { name: t('common:tabMenu.missionName'), value: 'All' },
        { name: t('common:tabMenu.role'), value: 'Role' },
        { name: t('common:tabMenu.people'), value: 'People' },
        { name: t('common:tabMenu.due'), value: 'Due' },
        { name: t('common:tabMenu.ownership'), value: 'Ownership' },
      ]
    }

    return chipsLabels.map((item, i) => {
      let styleName = 'chip'
      if (item.value === this.state.chipValue) {
        styleName = 'chipActive'
      }
      return (
        <Chip
          key={item.value + i}
          label={item.name}
          onClick={() => this.handleChipChange(item.value)}
          className={styleName}
        />
      )
    })
  }

  getMissions = () => {
    //return early if orgId doesn't exist or there are no orgs available
    if (!this.props.org || _.isEmpty(this.props.orgs)) return null

    if (_.isEmpty(this.props.activeMissionMotions)) return null
    return this.filter(this.props.activeMissionMotions)
  }

  filter = (motionsToFilter) => {
    const missionsObject = {}
    const missions = {}
    this.props.activeMissions.forEach((mission) => {
      missionsObject[mission.id] = mission.name
      missions[mission.id] = mission
    })
    const { t } = this.props
    switch (this.state.chipValue.toLowerCase()) {
      case 'role': {
        const userRoles = {}

        Object.values(motionsToFilter).forEach((motions) => {
          motions.forEach((motion) => {
            motion.members.forEach((member) => {
              if (member.uid === this.props.auth.uid && member.role) {
                if (
                  userRoles[member.role] &&
                  userRoles[member.role].findIndex((mission) => mission.id === motion.mission) ===
                    -1
                ) {
                  userRoles[member.role].push({
                    id: motion.mission,
                    name: missionsObject[motion.mission],
                  })
                } else {
                  userRoles[member.role] = [
                    { id: motion.mission, name: missionsObject[motion.mission] },
                  ]
                }
              }
            })
          })
        })

        const roleComponents = []
        Object.keys(userRoles)
          .sort()
          .forEach((roleName, index) => {
            roleComponents.push(
              <ul className="drawer-list w-list-unstyled" key={roleName + index}>
                <li className="list-header">{t(`roleNames:${roleName}`)}</li>
                {userRoles[roleName]
                  .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                  .map((mission, index) => {
                    return (
                      <li
                        className={
                          this.props.mission && mission.id === this.props.mission.id
                            ? 'winflow-links-bold'
                            : 'winflow-links'
                        }
                        key={`mission.id${index}`}
                        onClick={() => this.props.selectMission(missions[mission.id])}
                      >
                        {mission.name}
                      </li>
                    )
                  })}
              </ul>
            )
          })

        return roleComponents
      }

      case 'people': {
        const { orgUsers, auth } = this.props
        const users = {}
        Object.values(motionsToFilter).forEach((motions) => {
          motions.forEach((motion) => {
            motion.members.forEach((member) => {
              if (
                member.uid &&
                member.uid !== auth.uid &&
                orgUsers[member.uid] &&
                orgUsers[member.uid].fullName
              ) {
                if (users[orgUsers[member.uid].id]) {
                  let found = false
                  users[orgUsers[member.uid].id].forEach((mission) => {
                    if (mission.id === motion.mission) found = true
                  })
                  if (!found)
                    users[orgUsers[member.uid].id].push({
                      id: motion.mission,
                      name: missionsObject[motion.mission],
                    })
                } else {
                  users[orgUsers[member.uid].id] = [
                    { id: motion.mission, name: missionsObject[motion.mission] },
                  ]
                }
              }
            })
          })
        })

        const userComponents = []
        let searchByPeople = []
        for (let i = 0; i < Object.keys(users).length; i++) {
          //all these sorts need to be taken care of later on the backend
          searchByPeople.push([
            orgUsers[Object.keys(users)[i]].fullName.charAt(0).toUpperCase() +
              orgUsers[Object.keys(users)[i]].fullName.slice(1),
            Object.keys(users)[i],
          ])
        }
        searchByPeople.sort().forEach((user, index) => {
          userComponents.push(
            <ul className="drawer-list w-list-unstyled" key={user[0] + index}>
              <li className="list-header">{user[0]}</li>
              {users[user[1]]
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                .map((mission, index) => {
                  return (
                    <li
                      className={
                        this.props.mission && mission.id === this.props.mission.id
                          ? 'winflow-links-bold'
                          : 'winflow-links'
                      }
                      key={`mission.id${index}`}
                      onClick={() => this.props.selectMission(missions[mission.id])}
                    >
                      {mission.name}
                    </li>
                  )
                })}
            </ul>
          )
        })

        return userComponents
      }

      case 'ownership': {
        const ownerListItems = []
        const participantListItems = []

        // sort the missions
        const sortedMissions = [...this.props.activeMissions]
        sortedMissions.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

        sortedMissions.forEach((mission) => {
          if (mission.creator === this.props.auth.uid) {
            ownerListItems.push(
              <li
                className={
                  this.props.mission && mission.id === this.props.mission.id
                    ? 'winflow-links-bold'
                    : 'winflow-links'
                }
                key={mission.id}
                onClick={() => {
                  this.props.selectMission(mission)
                  if (this.props.workspace === 'mission') this.props.selectWorkspace('mission')
                  if (this.props.workspace === 'complete') this.props.selectWorkspace('complete')
                }}
              >
                {mission.name}
              </li>
            )
          } else {
            participantListItems.push(
              <li
                className={
                  this.props.mission && mission.id === this.props.mission.id
                    ? 'winflow-links-bold'
                    : 'winflow-links'
                }
                key={mission.id}
                onClick={() => {
                  this.props.selectMission(mission)
                  if (this.props.workspace === 'mission') this.props.selectWorkspace('mission')
                  if (this.props.workspace === 'complete') this.props.selectWorkspace('complete')
                }}
              >
                {mission.name}
              </li>
            )
          }
        })

        return (
          <div>
            <ul className="drawer-list w-list-unstyled">
              <li className="list-header">{t('common:tabMenu.owner')}</li>
              {ownerListItems}
            </ul>
            <ul className="drawer-list w-list-unstyled">
              <li className="list-header">{t('common:tabMenu.participant')}</li>
              {participantListItems}
            </ul>
          </div>
        )
      }

      case 'due': {
        const { workspace } = this.props
        if (workspace === 'complete') {
          this.setState({ chipValue: 'All' })
          return
        }

        const sortedDueMotions = [...this.state.dueMotions].sort((a, b) =>
          missionsObject[a.mission]
            .toLowerCase()
            .localeCompare(missionsObject[b.mission].toLowerCase())
        )
        let today = new Date(new Date().setHours(0, 0, 0, 0))
        let fiveDays = new Date(new Date().setHours(0, 0, 0, 0))
        let tenDays = new Date(new Date().setHours(0, 0, 0, 0))
        let dueMotionsCategories = {}
        fiveDays.setDate(fiveDays.getDate() + 5)
        tenDays.setDate(tenDays.getDate() + 10)
        dueMotionsCategories['overdue'] = []
        dueMotionsCategories['today'] = []
        dueMotionsCategories['duein5days'] = []
        dueMotionsCategories['duein10days'] = []

        console.log('dueMotionsCategories', dueMotionsCategories)

        for (let i = 0; i < sortedDueMotions.length; i++) {
          let dateCategory = ''
          if (new Date(sortedDueMotions[i].endDate) < today) {
            dateCategory = 'overdue'
          } else if (new Date(sortedDueMotions[i].endDate).getTime() === today.getTime()) {
            dateCategory = 'today'
          } else if (new Date(sortedDueMotions[i].endDate) <= fiveDays) {
            dateCategory = 'duein5days'
          } else if (new Date(sortedDueMotions[i].endDate) <= tenDays) {
            dateCategory = 'duein10days'
          }
          dueMotionsCategories[dateCategory].push({
            name: missionsObject[sortedDueMotions[i].mission],
            id: sortedDueMotions[i].mission,
          })
        }

        const renderDueMotions = []
        Object.keys(dueMotionsCategories).forEach((categoryName, index) => {
          if (dueMotionsCategories[categoryName].length !== 0) {
            console.log('categoryName', categoryName)
            console.log('what?', categoryName.charAt(0).toUpperCase() + categoryName.slice(1))
            console.log(t(`common:tabMenu.${categoryName}`))
            renderDueMotions.push(
              <ul className="drawer-list w-list-unstyled" key={categoryName + index}>
                <li className="list-header">{t(`common:tabMenu.${categoryName}`)}</li>
                {dueMotionsCategories[categoryName].map((mission, index) => {
                  return (
                    <li
                      className={
                        this.props.mission && mission.id === this.props.mission.id
                          ? 'winflow-links-bold'
                          : 'winflow-links'
                      }
                      key={`mission.id${index}`}
                      onClick={() => this.props.selectMission(missions[mission.id])}
                    >
                      {mission.name}
                    </li>
                  )
                })}
              </ul>
            )
          }
        })

        return renderDueMotions
      }

      default: {
        const listItems = []

        // sort the missions
        const sortedMissions = [...this.props.activeMissions]
        sortedMissions.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

        // create components
        sortedMissions.forEach((mission) => {
          listItems.push(
            <li
              className={
                this.props.mission && mission.id === this.props.mission.id
                  ? 'winflow-links-bold'
                  : 'winflow-links'
              }
              key={mission.id}
              onClick={() => this.props.selectMission(mission)}
            >
              {mission.name}
            </li>
          )
        })

        return <ul className="drawer-list w-list-unstyled">{listItems}</ul>
      }
    }
  }

  render() {
    // const { classes } = this.props;
    return (
      <div>
        <div className="chip-wrapper" style={{ display: 'flex', justifyContent: 'start' }}>
          {this.getChips()}
        </div>

        <div
          className="missionFont"
          style={{ height: 'calc(100vh - 510px)', overflowY: 'auto', overflowX: 'hidden' }}
        >
          {this.getMissions()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { workspace } = state.instance

  let activeMissions = []
  let activeMissionMotions = []
  if (workspace === 'mission' && state.instance.mission) {
    activeMissions = state.database.orgInstanceActiveMissions
    activeMissionMotions = state.database.orgInstanceActiveMotions
  } else if (workspace === 'complete' && state.instance.mission) {
    activeMissions = state.database.orgInstanceCompleteMissions
    activeMissionMotions = state.database.orgInstanceCompleteMotions
  }

  return {
    auth: state.firebase.auth,
    workspace: state.instance.workspace,
    org: state.instance.org,
    mission: state.instance.mission,
    orgs: state.database.userOrgs,
    orgUsers: state.database.orgInstanceUsers,
    activeMissions,
    activeMissionMotions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectMission: (mission) => dispatch(actions.selectMission(mission)),
    selectWorkspace: (workspace) => dispatch(actions.selectWorkspace(workspace)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation(['common', 'roleNames'])(TabMenu)))
