// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withTranslation } from 'react-i18next';

// Material UI
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'

// Custom Components
import AddNewOrgDialog from '../Navbar/AddNewOrgDialog'

// Utils
import { showAddNewOrgDialog, selectWorkspace, selectOrg } from '../../store/actions'

const styles = {
  select: {
    width: '180px',
    color: 'white',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: '#333541 !important',
  },
  icon: {
    color: 'white',
  },
}

class OrgSelector extends Component {
  constructor(props) {
    super(props)
  }

  handleMenuChange = (value) => {
    // if (value === "add-new") {
    //   this.props.showAddNewOrgDialog();
    // } else {
    const targetOrg = this.props.userOrgs.find((org) => org.id === value)
    // console.log('org in navbar', targetOrg)
    this.props.selectOrg(targetOrg)
    this.props.selectWorkspace('notifications')
    // }
  }

  // {userOrg[0].logo ? (
  //   <div className="companyLogo">
  //     <img src={userOrg[0].logo} alt="organization logo" />
  //   </div>
  // ) : (
  //   <div className="companyLogo">
  //     {userOrg[0].name && userOrg[0].name.charAt(0)}
  //   </div>
  // )}

  render() {
    let topMargin
    if (this.props.workspace === 'notifications') {
      topMargin = 25
    } else {
      if (this.props.userOrgs.length === 1) {
        topMargin = 25
      } else if (this.props.userOrgs.length === 2) {
        topMargin = 75
      } else {
        topMargin = 125
      }
    }
    // const { classes } = this.props;
    const { selectorOrgs, userOrgs, workspace, t } = this.props

    // Set Tab Title
    if (this.props.activeOrg && !_.isEmpty(userOrgs) && workspace !== 'home') {
      const orgFound = userOrgs.find((element) => element.id === this.props.activeOrg.id)
      if (orgFound) document.title = 'Hi-Flier | ' + orgFound.name
    } else {
      document.title = 'Hi-Flier | Home'
    }

    // console.log(this.props);
    let orgs
    if (this.props.userOrgs) {
      orgs = selectorOrgs.map((orgData, index) => {
        topMargin += 50
        return (
          <div className="org-selector-page" key={index}>
            <div className="org-logo-tab" style={{ marginTop: topMargin + 'px' }}>
              {orgData.logo ? (
                <Tooltip title={orgData.name} placement="left">
                  <img
                    className="org-image"
                    src={orgData.logo}
                    value={orgData.id}
                    onClick={(event) => this.handleMenuChange(orgData.id)}
                  />
                </Tooltip>
              ) : (
                // <img
                //   className="org-image"
                //   src={org.name.charAt(0)}
                //   value={org.id}
                //   onClick={(event) => this.handleMenuChange(org.id)}
                // />
                <Tooltip title={orgData.name} placement="left">
                  <div className="org-image" onClick={(event) => this.handleMenuChange(orgData.id)}>
                    {orgData.name && orgData.name.charAt(0)}
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        )
      })
    }

    // let homeArrow;
    // if (this.props.workspace === "notifications") {
    //   homeArrow = (
    //     <div className="add-org-test">
    //       <Tooltip title={t('orgSelector.create.title')} placement="bottom">
    //         <Icon
    //           onClick={this.props.showAddNewOrgDialog}
    //           style={{
    //             // fontSize: 50,
    //             color: "white",
    //             backgroundColor: "#3EC4E5",
    //             // borderRadius: "50%",
    //             width: "26px",
    //             height: "45px",
    //             marginTop: topMargin + "px",
    //             paddingTop: "11px",
    //             marginLeft: "4px",
    //             // zIndex: "10"
    //             boxShadow: "2px 0px 0px 0px rgba(0, 0, 0, 0.1)",
    //             borderRight: "1px solid #3EC4E5",
    //             borderRadius: "10%",
    //           }}
    //         >
    //           arrow_backward_ios
    //         </Icon>
    //       </Tooltip>
    //     </div>
    //   );
    //   return homeArrow
    // }

    topMargin += 50

    return (
      <div className="org-selector-wrapper">
        <div className="org-selector">
          {/* {homeArrow} */}
          {orgs}

          <div className="add-org">
            <Tooltip title={t('orgSelector.create.title')} placement="left">
              <Icon
                onClick={this.props.showAddNewOrgDialog}
                style={{
                  // fontSize: 50,
                  color: 'white',
                  backgroundColor: '#3EC4E5',
                  // borderRadius: "50%",
                  width: '26px',
                  height: '45px',
                  marginTop: topMargin + 'px',
                  paddingTop: '11px',
                  marginLeft: '4px',
                  // zIndex: "10"
                  boxShadow: '2px 0px 0px 0px rgba(0, 0, 0, 0.1)',
                  borderRight: '1px solid #3EC4E5',
                  borderRadius: '10%',
                  cursor: 'pointer',
                }}
              >
                add
              </Icon>
            </Tooltip>
          </div>
        </div>
        {/* <img className="org-image-one" src={userOrgs[2].logo}></img> */}
        {/* <div className="add-org-wrapper">
          <Tooltip title={t('orgSelector.create.title')} placement="top">
            <Icon
              onClick={this.props.showAddNewOrgDialog}
              style={{
                fontSize: 50,
                color: "#3EC4E5",
                backgroundColor: "white",
                borderRadius: "50%",
              }}
            >
              add_circle
            </Icon>
          </Tooltip>
        </div> */}
        <AddNewOrgDialog addNewOrg={this.props.addNewOrg} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log(state);
  return {
    userOrgs: state.database.userOrgs,
    selectorOrgs: ownProps.selectorOrgs,
    activeOrg: state.instance.org,
    workspace: state.instance.workspace,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectOrg: (orgId) => dispatch(selectOrg(orgId)),
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
    showAddNewOrgDialog: () => dispatch(showAddNewOrgDialog()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation('common')(OrgSelector)))
