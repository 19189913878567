// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

// Material UI
// import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip'
import Icon from '@material-ui/core/Icon'

// Helpers
import { selectWorkspace, selectMission, selectMotion, selectOrg } from '../../store/actions'

class Comments extends Component {
  handleClick = (status, mission, motion) => {
    const targetOrg = this.props.userOrgs.find((org) => org.id === this.props.orgId)
    this.props.selectOrg(targetOrg)
    this.props.selectMission(mission)
    this.props.selectMotion(motion)
    if (status === 'draft') {
      this.props.selectWorkspace('draft')
    } else if (status === 'active') {
      this.props.selectWorkspace('mission')
    } else {
      this.props.selectWorkspace('complete')
    }
  }

  // renderTooltip = () => {
  //   return(
  //     <Tooltip title="A look at comments you haven't read yet" placement="top">
  //       <Icon style={{ fontSize: 14, color: "grey" }}>info</Icon>
  //     </Tooltip>
  //   );
  // }

  render() {
    const { auth, activeMissions, activeMotions, t } = this.props

    if (_.isEmpty(activeMissions) || _.isEmpty(activeMotions)) {
      return (
        <div className="notification-container">
          <div className="notification-item">
            <h3>{t('common:comments.emptystate.header')}</h3>
            <div className="line-div" />
            <ul className="notification-list-comments">
              <div className="emptystate">
                <li>{t('common:comments.emptystate.li')}</li>
              </div>
            </ul>
          </div>
        </div>
      )
    }

    let commentComponent = []
    // Object.keys(activeMotions).forEach(missionId => {
    activeMotions.forEach((motion) => {
      let motionAdded = false
      const targetMission = activeMissions.find((mission) => mission.id === motion.mission)
      const userInMotion = motion.members.find((member) => member.uid === auth.uid)
      if (!_.isEmpty(motion.comments) && userInMotion) {
        for (const comment in motion.comments) {
          if (motionAdded) break
          if (motion.comments[comment].notification) {
            if (!motion.comments[comment].notification.includes(auth.uid)) {
              motionAdded = true
              commentComponent.push(
                <li key={motion.id}>
                  {t('comments.emprtystate.motionComments', {
                    motion: t(`motionPresets:${motion.name}`),
                  })}{' '}
                  <strong>
                    <a
                      href={null}
                      onClick={() => this.handleClick(targetMission.status, targetMission, motion)}
                    >
                      {targetMission.name}
                    </a>
                  </strong>
                  {/* <Tooltip title="Remove" placement="bottom">
                      <IconButton
                        onClick={null
                          // () => this.props.deleteMotionCommentNofitication(comment, auth.uid)
                        }
                        className="accept-button"
                        style={{ marginLeft: 10 }}>
                        <Icon style={{ fontSize: 12 }}>close</Icon>
                      </IconButton>
                    </Tooltip> */}
                </li>
              )
            }
          }
        }
      }
    })
    // })

    if (_.isEmpty(commentComponent)) {
      return (
        <div className="notification-container">
          <div className="notification-item">
            <h3>{t('common:comments.emptystate.header')}</h3>
            <div className="line-div" />
            <ul className="notification-list-comments">
              <div className="emptystate">
                <li>{t('common:comments.emptystate.li')}</li>
              </div>
            </ul>
          </div>
        </div>
      )
    } else {
      return (
        <div className="notification-container">
          <div className="notification-item">
            <h3>{t('common:comments.emptystate.header')}</h3>
            <div className="line-div" />
            <ul className="notification-list-comments">{commentComponent}</ul>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  //no need to combine all missions and motions, only need active missions and motions
  // combine all missions
  const allMissions = []
  // if(!_.isEmpty(state.database.orgInstanceDraftMissions)) {
  //   state.database.orgInstanceDraftMissions.forEach(mission => {
  //     allMissions.push(mission);
  //   })
  // }
  // if(!_.isEmpty(state.database.orgInstanceCompleteMissions)) {
  //   state.database.orgInstanceCompleteMissions.forEach(mission => {
  //     allMissions.push(mission);
  //   })
  // }
  if (!_.isEmpty(state.database.orgInstanceActiveMissions)) {
    state.database.orgInstanceActiveMissions.forEach((mission) => {
      allMissions.push(mission)
    })
  }

  //combine all motions
  const allMotions = {}
  // if(!_.isEmpty(state.database.orgInstanceDraftMotions)) {
  //   Object.keys(state.database.orgInstanceDraftMotions).forEach(missionId => {
  //     allMotions[missionId] = state.database.orgInstanceDraftMotions[missionId]
  //   })
  // }
  // if(!_.isEmpty(state.database.orgInstanceCompleteMotions)) {
  //   Object.keys(state.database.orgInstanceCompleteMotions).forEach(missionId => {
  //     allMotions[missionId] = state.database.orgInstanceCompleteMotions[missionId]
  //   })
  // }
  if (!_.isEmpty(state.database.orgInstanceActiveMotions)) {
    Object.keys(state.database.orgInstanceActiveMotions).forEach((missionId) => {
      allMotions[missionId] = state.database.orgInstanceActiveMotions[missionId]
    })
  }

  return {
    auth: state.firebase.auth,
    activeOrg: state.instance.org,
    // allMissions,
    // allMotions
    activeMissions: ownProps.orgMissions,
    activeMotions: ownProps.orgMotions,
    orgId: ownProps.orgId,
    userOrgs: state.database.userOrgs,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectOrg: (orgId) => dispatch(selectOrg(orgId)),
    selectMotion: (motionID) => dispatch(selectMotion(motionID)),
    selectMission: (winflowID) => dispatch(selectMission(winflowID)),
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(['common', 'motionPresets'])(Comments))
