// Libraries
import React, { Component } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import Tooltip from '@material-ui/core/Tooltip'
import { withTranslation } from 'react-i18next'

// Helpers
// import motionPresets from '../../utils/motionPresets';

class MotionSubmenu extends Component {
  getNewMicroflowButtons() {
    const { t } = this.props
    return t('motionPresets:items').map((item, i) => {
      return (
        <Draggable draggableId={item.name} key={item.name} index={i}>
          {(provided, snapshot) => (
            <Tooltip
              title={item.subheader ? item.subheader : t('common:newMissionModal.noSubheader')}
            >
              <div className="motionbox-grid33">
                <div
                  className={`edit-microflow ${snapshot.isDragging && 'isDragging'}`}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <div>{t(`motionPresets:${item.name}`)}</div>
                </div>
                {provided.placeholder}
              </div>
            </Tooltip>
          )}
        </Draggable>
      )
    })
  }

  render() {
    const { t } = this.props
    return (
      <div className="submenu-container">
        <div className="submenu-head">{t('common.motions')}</div>
        <div className="submenu-body">
          <Droppable
            droppableId="SIDE_DRAWER"
            direction="horizontal"
            type="MOTION"
            isDropDisabled={false}
          >
            {(provided, snapshot) => (
              <div className="edit-microflow-container" ref={provided.innerRef}>
                {this.getNewMicroflowButtons()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    )
  }
}

export default withTranslation(['common', 'motionPresets'])(MotionSubmenu)
