// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

// Components
import Login from './containers/Login'
import Main from './containers/Main'
import MaintencePage from './containers/MaintencePage';

// Store
import { selectOrg } from './store/actions'
import config from './config';

import './utils/i18n';

class App extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.auth.uid &&
      !this.props.activeOrg &&
      !_.isEmpty(this.props.userOrgs) &&
      this.props.userOrgs.length > prevProps.userOrgs.length
    ) {
      // TODO: organize orgs in alphabetical order
      this.props.selectOrg(this.props.userOrgs[0])
    }
  }

  render() {
    const { auth } = this.props
    let mql = window.matchMedia('(prefers-color-scheme: dark)')
    const favicon = document.getElementById('favicon')
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      favicon.href = require('./assets/images/HI-gold32.png')
    } else if (window.matchMedia('(prefers-color-scheme: light)').matches) {
      favicon.href = require('./assets/images/HI-charcoal32.png')
    }
    if (mql) {
      mql.addListener((e) => {
        if (e.matches) {
          favicon.href = require('./assets/images/HI-gold32.png')
        } else {
          favicon.href = require('./assets/images/HI-charcoal32.png')
        }
      })
    }

    if (config.maintenceMode === "true") {
      return <MaintencePage/>
    }
    if (auth.isLoaded && !auth.uid) {
      return <Login />
    } else if (auth.isLoaded && auth.uid) {
      return <Main />
    }

    return null
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    activeOrg: state.instance.org,
    userOrgs: state.database.userOrgs,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectOrg: (orgId) => dispatch(selectOrg(orgId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
