// Libraries
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Droppable } from "react-beautiful-dnd";
import { withTranslation } from 'react-i18next';

// Custom Components
import PersonInstance from "./PersonInstance";

class PeopleSubmenu extends Component {
  userComponents = [];

  getOrgUser() {
    const { users } = this.props;

    if(users) {
        const sortedUsers = Object.values(users).sort((a, b) => {
            return a.fullName > b.fullName ? 1 : b.fullName > a.fullName ? -1 : 0;
        });

        let sortedUserComponents = [];
        sortedUserComponents = sortedUsers.map((user, i) => {
            if (user) {
              return <PersonInstance user={user} index={i} key={user.id} />;
            } else {
              return null;
            }
        });

        this.userComponents = sortedUserComponents;
    }
  }

  render() {
    this.getOrgUser();
    const { t } = this.props;
    return (
      <div className="submenu-container">
        <div className="submenu-head">
          {t('peopleSubmenu.title')}
          <span className="invite-link" onClick={this.props.openInviteUserDialog}>
            +{t('invite.button')}
          </span>
        </div>
        <div className="submenu-body">
          <Droppable droppableId="NEW_USERS" type="USER" isDropDisabled={false}>
            {(provided, snapshot) => {
              return (
              <div className="edit-people-list" ref={provided.innerRef} {...provided.droppableProps}>
                {this.userComponents}
                {provided.placeholder}
              </div>
            )
          }}
          </Droppable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {  
    return {
        auth: state.firebase.auth,
        activeOrg: state.instance.org,
        users: state.database.orgInstanceUsers
    };
};

export default connect(mapStateToProps, null)(withTranslation('common')(PeopleSubmenu));
