// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

// Material UI
import Snackbar from '@material-ui/core/Snackbar'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'

// Local Components
import ForgotPassword from '../ForgotPassword'
import MainButton from '../../components/Buttons/MainButton'

// Utils
import { signIn, passwordReset, isPasswordReset } from '../../store/actions'
import { envEndpoint } from '../../utils/firebase-service'
import { ThreeSixty } from '@material-ui/icons'

const styles = {
  button: {
    margin: 10,
    color: 'white',
    backgroundColor: '#3EC4E5',
  },
  toolbarlogin: {
    backgroundColor: '#333541',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    alignItems: 'center',
    height: 56,
    minHeight: 'auto',
  },
}

class Login extends Component {
  state = {
    credentials: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    error: false,
    errorMessage: '',
    forgotPasswordVisible: false,
    resetEmailSentVisible: false,
    signupVisible: false,
    showSnackbar: false,
    snackbarMessage: '',
  }

  async componentDidMount() {
    const { t, location } = this.props
    const path = location.pathname
    const value = location.search.split('=')[1]
    if (path === '/signUp') {
      const response = await fetch(`${envEndpoint}/auth/confirmInviteKey`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ key: value }),
      })

      if (response.status !== 200 && response.status !== 201) {
        if (response.status === 404) {
          this.setState({ error: true, errorMessage: 'This invite link is not valid' })
        } else {
          this.setState({ error: true, errorMessage: 'Something Went Wrong!' })
        }
      }
      const { email } = await response.json()
      this.setState({
        credentials: {
          ...this.state.credentials,
          email,
        },
      })
    } else {
      this.setState({
        credentials: {
          ...this.state.credentials,
        },
      })
    }
    if (this.props.passwordResetSnackbar) {
      this.setState({
        showSnackbar: true,
        snackbarMessage: t('common.checkEmail'),
      })
      this.props.isPasswordReset(false)
    }
  }

  toggleForgotPassword = () => {
    this.setState({
      forgotPasswordVisible: !this.state.forgotPasswordVisible,
      resetEmailSent: false,
      signupVisible: false,
    })
  }

  toggleResetEmailSent = () => {
    this.setState({
      resetEmailSentVisible: !this.state.resetEmailSent,
      forgotPasswordVisible: false,
      signupVisible: false,
    })
  }

  toggleSignup = () => {
    this.setState({
      resetEmailSentVisible: false,
      forgotPasswordVisible: false,
      signupVisible: !this.state.signupVisible,
    })
  }

  showLogin = () => {
    this.setState({
      resetEmailSentVisible: false,
      forgotPasswordVisible: false,
      signupVisible: false,
    })
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({
      credentials: {
        ...this.state.credentials,
        [name]: value,
      },
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const { credentials } = this.state
    const result = await this.props.signIn(credentials)
    if (result && result.code !== 'ok') {
      this.setState({ snackbarMessage: result.message, showSnackbar: true })
    }
  }

  handleSignupSubmit = async (event) => {
    event.preventDefault()
    const { t } = this.props
    const { credentials } = this.state
    // console.log('credentials in signup submit', credentials)

    // Make sure both passwords match
    if (credentials.password === credentials.confirmPassword) {
      const response = await fetch(`${envEndpoint}/auth/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: credentials.email,
          password: credentials.password,
        }),
      })

      if (response.ok && response.status === 200) {
        // handle when request completed successfully
        // perform normal signin
        const result = await this.props.signIn(credentials)
        if (result && result.code !== 'ok') {
          this.setState({ snackbarMessage: result.message, showSnackbar: true })
        }
      } else {
        // handle when request not completed successfully
        this.setState({ error: true, errorMessage: t('login.error.signup') })
      }
    } else {
      this.setState({
        snackbarMessage: t('common.verifyPasswords'),
        showSnackbar: true,
      })
    }
  }

  handlePasswordReset = async (event) => {
    event.preventDefault()
    const { t } = this.props
    const { credentials } = this.state
    let params = new URLSearchParams(window.location.search)
    let resetCode = params.get('oobCode')
    // console.log("reset code", resetCode);
    // console.log('credentials in signup submit', credentials)

    // Make sure both passwords match
    if (credentials.password === credentials.confirmPassword) {
      const result = await this.props.passwordReset(resetCode, credentials.password)
      if (result && result.code !== 'ok') {
        this.setState({ snackbarMessage: result.message, showSnackbar: true })
      } else {
        this.setState({ snackbarMessage: result.message, showSnackbar: true })
        setTimeout(function () {
          window.location.pathname = '/login' //will redirect to login
        }, 2000) //will call the function after 2 secs.
      }
    } else {
      this.setState({
        snackbarMessage: t('common.verifyPasswords'),
        showSnackbar: true,
      })
    }
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarMessage: '', showSnackbar: false })
  }

  render() {
    const { t, location } = this.props
    const path = location.pathname

    // default component display
    let body = (
      <div className="workspace flex login-body">
        <div className="login-div">
          <h1 className="login-h1">{t('login.title')}</h1>

          <form onSubmit={this.handleSubmit}>
            <input
              onChange={this.handleChange}
              value={this.state.credentials.email}
              type="email"
              id="email"
              className="login-input w-input"
              name="email"
              data-name="Email"
              placeholder={t('login.email')}
            />

            <input
              onChange={this.handleChange}
              type="password"
              id="password"
              className="login-input w-input"
              name="password"
              data-name="password"
              placeholder={t('login.password')}
            />

            {!this.state.loading ? (
              <Button onClick={this.handleSubmit} type="submit" style={styles.button}>
                {t('login.signin')}
              </Button>
            ) : (
              <Button
                disabled
                disabledBackgroundColor={'#074880'}
                primary={true}
                style={styles.button}
              >
                <img
                  alt="loading gif"
                  style={{ height: 30, marginTop: -2 }}
                  src={require('../../assets/images/Bars.gif')}
                />
              </Button>
            )}

            {/*
            <div className="login-bottom">
              <div onClick={() => this.toggleSignup()} className="forgot-password" >
                Sign-Up
              </div>
            </div>
              */}
            <div onClick={() => this.toggleForgotPassword()} className="forgot-password">
              {t('login.forgotPassword')}
            </div>
          </form>
        </div>
      </div>
    )

    // Toggle the reset email sent component
    if (this.state.resetEmailSentVisible) {
      body = (
        <div className="workspace flex login-body">
          <div className="login-div">
            <div className="forgot-text">{t('login.emailSent')}</div>
            <MainButton onClick={() => this.showLogin()}>{t('login.backToLogin')}</MainButton>
          </div>
        </div>
      )
    }

    if (path === '/signUp') {
      body = !this.state.error ? (
        <div className="workspace flex login-body">
          <div className="login-div">
            <h1 className="login-h1">{t('login.title')}</h1>

            <form onSubmit={this.handleSubmit}>
              <input
                onChange={this.handleChange}
                value={this.state.credentials.email}
                type="email"
                id="email"
                className="login-input w-input login-input"
                name="email"
                data-name="Email"
                placeholder={t('login.email')}
              />

              <p>{t('login.createPassword')}</p>
              <input
                onChange={this.handleChange}
                value={this.state.credentials.password}
                type="password"
                id="password"
                className="login-input w-input login-input"
                name="password"
                data-name="password"
                placeholder={t('login.password')}
              />

              <input
                onChange={this.handleChange}
                value={this.state.credentials.confirmPassword}
                type="password"
                id="password"
                className="login-input w-input login-input"
                name="confirmPassword"
                data-name="confirmPassword"
                placeholder={t('login.confirmPassword')}
              />

              {!this.state.loading ? (
                <Button onClick={this.handleSignupSubmit} type="submit" style={styles.button}>
                  {t('login.signin')}
                </Button>
              ) : (
                <Button
                  disabled
                  disabledBackgroundColor={'#074880'}
                  primary={true}
                  style={styles.button}
                >
                  <img
                    alt="loading gif"
                    style={{ height: 30, marginTop: -2 }}
                    src={require('../../assets/images/Bars.gif')}
                  />
                </Button>
              )}
            </form>
          </div>
        </div>
      ) : (
        <div className="workspace flex login-body">
          <div className="login-div">
            <h1 className="login-h1">
              {this.state.errorMessage || 'This invite link is not valid'}
            </h1>
          </div>
        </div>
      )
    }

    if (path === '/resetPassword') {
      body = (
        <div className="workspace flex login-body">
          <div className="login-div">
            <h2 className="login-h1">{t('login.createNewPassword')}</h2>
            <br />

            <input
              onChange={this.handleChange}
              value={this.state.credentials.password}
              type="password"
              id="password"
              className="login-input w-input login-input"
              name="password"
              data-name="password"
              placeholder={t('login.password')}
            />

            <input
              onChange={this.handleChange}
              value={this.state.credentials.confirmPassword}
              type="password"
              id="password"
              className="login-input w-input login-input"
              name="confirmPassword"
              data-name="confirmPassword"
              placeholder={t('login.confirmPassword')}
            />

            {!this.state.loading ? (
              <Button onClick={this.handlePasswordReset} type="submit" style={styles.button}>
                {t('login.updatePassword')}
              </Button>
            ) : (
              <Button
                disabled
                disabledBackgroundColor={'#074880'}
                primary={true}
                style={styles.button}
              >
                <img
                  alt="loading gif"
                  style={{ height: 30, marginTop: -2 }}
                  src={require('../../assets/images/Bars.gif')}
                />
              </Button>
            )}
          </div>
        </div>
      )
    }

    // Toggle the forgot password component
    if (this.state.forgotPasswordVisible) {
      body = (
        <ForgotPassword
          toggleForgotPassword={this.toggleForgotPassword}
          toggleResetEmailSent={this.toggleResetEmailSent}
        />
      )
    }

    return (
      <div className="body">
        <Toolbar style={styles.toolbarlogin}>
          <img
            alt="logo"
            className="logo logologin"
            src={require('../../assets/images/LOGO-white-gold@600x.png')}
          />
        </Toolbar>

        {body}

        <Snackbar
          open={this.state.showSnackbar}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          TransitionComponent={Fade}
          ContentProps={{ 'aria-describedby': 'message-id' }}
          style={{ color: 'white' }}
          message={<span id="message-id">{this.state.snackbarMessage}</span>}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    passwordResetSnackbar: state.auth.passwordResetSnackbar,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    isPasswordReset: (value) => dispatch(isPasswordReset(value)),
    signIn: (creds) => dispatch(signIn(creds)),
    passwordReset: (code, newPassword) => dispatch(passwordReset(code, newPassword)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Login))
)
