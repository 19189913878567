// import { getCurrentProcessData, getMotionsInCurrentProcess } from './handleData';
// import { getProcessDataById } from "./handleData";
import { clone } from 'lodash'

export const userRoles = {
  // Responsible roles: Author, Reviewer, Approver, Contributor, Receiver, Signer, Counter-Signer, Individual, Proposer, Counter-Proposer
  author: {
    roleId: 'author',
    roleName: 'Author',
    roleGroup: 'responsible',
  },
  reviewer: {
    roleId: 'reviewer',
    roleName: 'Reviewer',
    roleGroup: 'responsible',
  },
  approver: {
    roleId: 'approver',
    roleName: 'Approver',
    roleGroup: 'responsible',
  },
  contributor: {
    roleId: 'contributor',
    roleName: 'Contributor',
    roleGroup: 'responsible',
  },
  receiver: {
    roleId: 'receiver',
    roleName: 'Receiver',
    roleGroup: 'responsible',
  },
  signer: {
    roleId: 'signer',
    roleName: 'Signer',
    roleGroup: 'responsible',
  },
  counterSigner: {
    roleId: 'counterSigner',
    roleName: 'Counter Signer',
    roleGroup: 'responsible',
  },
  individual: {
    roleId: 'individual',
    roleName: 'Individual',
    roleGroup: 'responsible',
  },
  proposer: {
    roleId: 'proposer',
    roleName: 'Proposer',
    roleGroup: 'responsible',
  },
  counterProposer: {
    roleId: 'counterProposer',
    roleName: 'Counter Proposer',
    roleGroup: 'responsible',
  },
  payor: {
    roleId: 'payor',
    roleName: 'Payor',
    roleGroup: 'responsible',
  },
  payee: {
    roleId: 'payee',
    roleName: 'Payee',
    roleGroup: 'responsible',
  },
  decider: {
    roleId: 'decider',
    roleName: 'Decider',
    roleGroup: 'responsible',
  },
  //Accountable roles: Requester, Orchestrator
  requester: {
    roleId: 'requester',
    roleName: 'Requester',
    roleGroup: 'accountable',
  },
  orchestrator: {
    roleId: 'orchestrator',
    roleName: 'Orchestrator',
    roleGroup: 'accountable',
  },
  //Informed roles: Watcher, Subject Expert
  watcher: {
    roleId: 'watcher',
    roleName: 'Watcher',
    roleGroup: 'informed',
  },
  subjectExpert: {
    roleId: 'subjectExpert',
    roleName: 'Subject Expert',
    roleGroup: 'informed',
  },
  //Creators
  creator: {
    roleId: 'creator',
    roleName: 'Creator',
    roleGroup: 'creators',
  },
  creator_responsible: {
    roleId: 'creator_responsible',
    roleName: 'Creator + Responsible',
    roleGroup: 'creators_responsible',
  },
  creator_accountable: {
    roleId: 'creator_accountable',
    roleName: 'Creator + Accountable',
    roleGroup: 'creators_accountable',
  },
  creator_requester: {
    roleId: 'creator_requester',
    roleName: 'Creator + requester',
    roleGroup: 'creators_requester',
  },
}

const groups = {
  creators: {
    deleteMotion: true,
    addMotionFromSidebar: true,
    dragDropMotion: true,
    addNewUserUidInMotion: true,
    changeUserUidInMotion: true,
    changeUserRoleInMotion: true,
    deleteUserUidFromMotion: true,
    changeActionInMotion: false,
    updateTimeline: true,
    addWorkItems: true,
    deleteWorkItems: true,
    addDescription: true,
  },
  accountable: {
    deleteMotion: false,
    addMotionFromSidebar: false,
    dragDropMotion: false,
    addNewUserUidInMotion: true,
    changeUserUidInMotion: true,
    changeUserRoleInMotion: true,
    deleteUserUidFromMotion: true,
    changeActionInMotion: true,
    updateTimeline: false,
    addWorkItems: true,
    deleteWorkItems: true,
    addDescription: false,
    addComment: true,
    cancelWindow: false,
  },
  informed: {
    deleteMotion: false,
    addMotionFromSidebar: false,
    dragDropMotion: false,
    addNewUserUidInMotion: true,
    changeUserUidInMotion: true,
    changeUserRoleInMotion: true,
    deleteUserUidFromMotion: false,
    changeActionInMotion: false,
    updateTimeline: false,
    addWorkItems: true,
    deleteWorkItems: false,
    addDescription: false,
    addComment: true,
    cancelWindow: false,
  },
  responsible: {
    deleteMotion: false,
    addMotionFromSidebar: false,
    dragDropMotion: false,
    addNewUserUidInMotion: true,
    changeUserUidInMotion: true,
    changeUserRoleInMotion: true,
    deleteUserUidFromMotion: false,
    changeActionInMotion: true,
    updateTimeline: false,
    addWorkItems: true,
    deleteWorkItems: false,
    addDescription: true,
    addComment: true,
    cancelWindow: false,
  },
}

const ownerCombinations = {
  creators: {
    deleteMotion: true,
    addMotionFromSidebar: true,
    dragDropMotion: true,
    addNewUserUidInMotion: true,
    changeUserUidInMotion: true,
    changeUserRoleInMotion: true,
    deleteUserUidFromMotion: true,
    changeActionInMotion: true,
    updateTimeline: true,
    addWorkItems: true,
    deleteWorkItems: true,
    addDescription: true,
  },
  accountable: {
    deleteMotion: true,
    addMotionFromSidebar: true,
    dragDropMotion: true,
    addNewUserUidInMotion: true,
    changeUserUidInMotion: true,
    changeUserRoleInMotion: true,
    deleteUserUidFromMotion: true,
    changeActionInMotion: true,
    updateTimeline: true,
    addWorkItems: true,
    deleteWorkItems: true,
    addDescription: true,
    addComment: true,
    cancelWindow: true,
  },
  informed: {
    deleteMotion: true,
    addMotionFromSidebar: true,
    dragDropMotion: true,
    addNewUserUidInMotion: true,
    changeUserUidInMotion: true,
    changeUserRoleInMotion: true,
    deleteUserUidFromMotion: true,
    changeActionInMotion: false,
    updateTimeline: true,
    addWorkItems: true,
    deleteWorkItems: true,
    addDescription: true,
    addComment: true,
    cancelWindow: true,
  },
  responsible: {
    deleteMotion: true,
    addMotionFromSidebar: true,
    dragDropMotion: true,
    addNewUserUidInMotion: true,
    changeUserUidInMotion: true,
    changeUserRoleInMotion: true,
    deleteUserUidFromMotion: true,
    changeActionInMotion: true,
    updateTimeline: true,
    addWorkItems: true,
    deleteWorkItems: true,
    addDescription: true,
    addComment: true,
    cancelWindow: true,
  },
}

//Check action and role, if action allows for role return true
const check = function (action, role, permissionsObj) {
  const userRole = userRoles[role]
  if (userRole) {
    return permissionsObj[userRole.roleGroup][action]
  } else {
    return false
  }
}

//Here we check role for user
// const { creator, roles } = getRolesForUserInCurrentProcess(location, uid, processes);
export const getRolesForUserInCurrentWinflow = (uid, motions, winflow) => {
  //First we check that user is creator
  const isOwner = winflow.creator === uid
  //Then if creator = true, we add him from creator and return the function
  if (isOwner) {
    const permissions = clone(groups.creators)
    let allRoleInProcess = []
    motions.forEach((motion) => {
      if (motion && motion.members) {
        Object.values(motion.members).forEach((user) => {
          if (user.uid === uid && user.role) {
            allRoleInProcess.push(user.role)
          }
        })
      }
    })
    for (let permission in permissions) {
      allRoleInProcess.forEach((role) => {
        if (!check(permission, role, ownerCombinations)) {
          permissions[permission] = false
        } else {
          if (permission === 'changeActionInMotion') {
            permissions[permission] = true
          }
        }
      })
    }
    return { isOwner, permissions }
  } else {
    //if user is not creator, we look for his role and return the permissions for it
    //start value for permissions
    const permissions = {
      deleteMotion: false,
      addMotionFromSidebar: false,
      dragDropMotion: false,
      addNewUserUidInMotion: true,
      changeUserUidInMotion: true,
      changeUserRoleInMotion: true,
      deleteUserUidFromMotion: true,
      changeActionInMotion: false,
      updateTimeline: false,
      addWorkItems: true,
      deleteWorkItems: false,
      addDescription: false,
      addComment: true,
      cancelWindow: false,
    }

    //get current Motions
    //get all roles in the array for authorised user from the current Motion
    const allRoleInProcess = []
    Object.values(motions).forEach((motion) => {
      Object.values(motion.members).forEach((user) => {
        if (user.uid === uid && user.role) {
          allRoleInProcess.push(user.role)
        }
      })
    })
    //check permissions
    for (let permission in permissions) {
      allRoleInProcess.forEach((role) => {
        if (check(permission, role, groups)) {
          permissions[permission] = true
        } else {
          if (permission === 'changeActionInMotion' && permissions[permission] === true) {
            return
          } else {
            permissions[permission] = false
          }
        }
      })
    }

    return { isOwner, permissions }
  }
}

//Here we return role name by role id
// const roleName = getRoleNameByRoleId(id);
export const getRoleNameByRoleId = function (id) {
  if (id) {
    return userRoles[id] ? userRoles[id].roleName : null
  } else {
    return null
  }
}

//Here we are checking that the current user is creator of the process
// const { creator } = getCreatorOfProcess(location, processes);
// export const getCreatorOfProcess = function(
//   location,
//   processes,
//   organizations
// ) {
//   const { currentProcessData } = getProcessDataById(
//     location,
//     processes,
//     organizations
//   );
//   const creatorUid = currentProcessData
//     ? currentProcessData.creator
//       ? currentProcessData.creator
//       : null
//     : null;
//   return { creatorUid };
// };

//returns true if the user role is responsible
export const checkResponsible = function (memberRole) {
  if (!memberRole || (userRoles[memberRole] && userRoles[memberRole].roleGroup === 'responsible'))
    return true
  return false
}

export const checkAccountable = function (memberRole) {
  if (memberRole === null) return false
  if (userRoles[memberRole] && userRoles[memberRole].roleGroup === 'accountable') return true
  return false
}
