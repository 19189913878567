// Libraries
import React, {useState, useRef, useEffect} from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import Picker from 'emoji-picker-react';

//Material UI
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SentimentSatisfiedAlt from '@material-ui/icons/SentimentSatisfiedAlt';
import Popover, { PopoverAnimationVertical } from 'material-ui/Popover';
import InputAdornment from "@material-ui/core/InputAdornment";

const styles = {
	formControl: {
		width: "100%"
	},
  input:{
    backgroundColor:"white",
		border:"1px solid #333541",
		padding: "10px 8px"
  },
  '@global': {
    '.MuiInput-underline:after': {
      borderBottom:"none"
    },
    '.MuiInput-underline:before': {
      transition:"none"
    },
    '.MuiInput-underline:hover': {
      borderBottom:"none"
    },
  }
};

const TextInputEmoji = props => {
  const { classes } = props;

	const [isShowEmoji, setShowEmoji] = useState(false);
	const [anchor, setAnchor] = useState();
	const inputRef = useRef(null);

	const showEmoji = (event) => {
		setShowEmoji(true);
		setAnchor(event.currentTarget);
	}

	const hideEmoji = () => {
		setShowEmoji(false);
		setAnchor(undefined);
		if (inputRef) {
			inputRef.current.focus();
		}
	}

  return (
		<>
			<TextField
				name={props.name}
				id={props.id}
				inputRef={inputRef}
				classes={{root: classes.formControl}}
				InputProps={{
					classes: {root: !props.style && classes.input},
					style: {...props.style ? props.style : {}},
					endAdornment: (
						<InputAdornment>
							<SentimentSatisfiedAlt 
								className="material-icons emoji-icon" 
								onClick={showEmoji}
							/>
						</InputAdornment>
					)
				}}
				value={props.value}
				onChange={props.onChange}
				placeholder={props.placeholder}
				onKeyDown={props.onKeyDown}
				multiline
				rowsMax={props.rowsMax}
				autoFocus={true}
				variant={props.variant}
			/>
			<Popover
				open={isShowEmoji}
				onRequestClose={hideEmoji}
				anchorEl={anchor}
				anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
				animation={PopoverAnimationVertical}
			>
				<Picker onEmojiClick={(event, emojiObject) => props.onEmojiClick(event, emojiObject, inputRef)} />
			</Popover>
		</>
  );
};

TextInputEmoji.propTypes = {
	classes: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
};

export default withStyles(styles)(Radium(TextInputEmoji));