// Libraries
import React, { Component } from "react";
import { withTranslation} from 'react-i18next';

// Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// Custom Components
import MainButton from "../../components/Buttons/MainButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

class DeleteWorkItemDialog extends Component {
  render() {
    const { t } = this.props;
    return (
      <Dialog
        open={this.props.showDeleteWorkItemDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {t('deleteWorkItem.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('deleteWorkItem.description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={this.props.hideDeleteWorkItemDialog}>
            {t('common.button.cancel')}
          </SecondaryButton>
          <MainButton onClick={this.props.deleteWorkItem}>
            {t('common.button.delete')}
          </MainButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation('common')(DeleteWorkItemDialog);