// Libraries
import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";

class PersonInstance extends Component {
  render() {
    const { user, index } = this.props;

    // check if user object is available
    if(!user) return null;

    return (
      <div className="edit-person-container" key={user.id}>
        <Draggable
          draggableId={user.id}
          key={user.id}
          index={index}
          type="USER">
          {(provided, snapshot) => {
            return (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}>
                  <div style={{ display: "flex", flexDirection: "row" }} >
                    <img 
                        src={ user && user.avatar ? user.avatar : require("../../assets/images/user.jpg") }
                        alt="user avatar"
                        style={{ width: "28px", height: "28px", marginRight: "8px", borderRadius: "50%", border: "1px solid white" }}/>
                    <div
                      style={{
                        display: snapshot.isDragging ? "block" : "none",
                        lineHeight: "28px",
                        fontWeight: "300",
                        minWidth: "180px"
                      }}>
                      {user.fullName ? user.fullName : user.email}
                    </div>
                    {provided.placeholder}
                    <div style={{ display: snapshot.isDragging ? "none" : "block", lineHeight: "28px", fontWeight: "300" }} >
                      {user.fullName ? user.fullName : user.email}
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Draggable>
      </div>
    );
  }
}

export default PersonInstance;
