import * as actionTypes from '../actionTypes'
import _ from 'lodash'

const initialState = {
  userOrgs: [],
  userDetails: null,
  orgInstanceUsers: {},
  orgInstanceActiveMissions: [],
  orgInstanceAllActiveMissions: [],
  orgInstanceActiveMotions: {},
  orgInstanceDraftMissions: [],
  orgInstanceDraftMotions: {},
  orgInstanceCompleteMissions: [],
  orgInstanceCompleteMotions: {},
  orgInstanceMissionTemplates: [],
  logs: [],
  workItems: [],
}

const setUserOrgs = (state, action) => {
  return {
    ...state,
    userOrgs: action.payload,
  }
}

const onUserOrgAdded = (state, action) => {
  // update user orgs
  const updatedUserOrgs = _.cloneDeep(state.userOrgs)
  const orgUpdatedIndex = state.userOrgs.findIndex((org) => org.id === action.payload.id)

  if (orgUpdatedIndex !== -1) {
    updatedUserOrgs[orgUpdatedIndex] = action.payload
  } else {
    updatedUserOrgs.push(action.payload)
  }

  return {
    ...state,
    userOrgs: updatedUserOrgs,
  }
}

const deleteUserFromOrgLocal = (state, action) => {
  // for "userOrgs"
  const updatedUserOrgs = [...state.userOrgs]
  const targetOrgIndex = updatedUserOrgs.findIndex((org) => org.id === action.payload.deletedOrg.id)

  if (targetOrgIndex !== -1) {
    const updatedOrg = updatedUserOrgs[targetOrgIndex]
    const targetUserIndex = updatedOrg.members.findIndex(
      (member) => member === action.payload.user.id
    )

    if (targetUserIndex !== -1) {
      updatedOrg.members.splice(targetUserIndex, 1)
    }
    updatedUserOrgs[targetOrgIndex] = updatedOrg
  }

  // for "orgInstanceUsers"
  const updatedInstanceUsers = { ...state.orgInstanceUsers }
  if (updatedInstanceUsers[action.payload.user.id])
    delete updatedInstanceUsers[action.payload.user.id]

  return {
    ...state,
    userOrgs: updatedUserOrgs,
    orgInstanceUsers: updatedInstanceUsers,
  }
}

const setUser = (state, action) => {
  return {
    ...state,
    userDetails: action.payload,
  }
}

const onUserUpdated = (state, action) => {
  return {
    ...state,
    userDetails: action.payload,
  }
}

const updateSingleOrgInstanceUser = (state, action) => {
  return {
    ...state,
    orgInstanceUsers: {
      ...state.orgInstanceUsers,
      [action.payload.id]: action.payload,
    },
  }
}

const setOrgInstanceUsers = (state, action) => {
  return {
    ...state,
    orgInstanceUsers: action.payload,
  }
}

const setOrgInstanceActiveMissions = (state, action) => {
  return {
    ...state,
    orgInstanceActiveMissions: action.payload,
  }
}

const setOrgInstanceAllActiveMissions = (state, action) => {
  return {
    ...state,
    orgInstanceAllActiveMissions: action.payload,
  }
}

const onOrgMemberUpdated = (state, action) => {
  const updatedOrgInstanceUsers = { ...state.orgInstanceUsers }
  if (Object.keys(updatedOrgInstanceUsers).findIndex((item) => item === action.payload.id) !== -1) {
    updatedOrgInstanceUsers[action.payload.id] = action.payload
  }
  return {
    ...state,
    orgInstanceUsers: updatedOrgInstanceUsers,
  }
}

const onOrgInstanceActiveMissionUpdated = (state, action) => {
  // delete in draft missions if it exist there
  const draftMissionDeleteIndex = state.orgInstanceDraftMissions.findIndex(
    (mission) => mission.id === action.payload.id
  )

  let updatedDraftMissions
  if (draftMissionDeleteIndex !== -1) {
    updatedDraftMissions = [...state.orgInstanceDraftMissions]
    updatedDraftMissions.splice(draftMissionDeleteIndex, 1)
  }

  // update in active missions
  const missionUpdatedIndex = state.orgInstanceActiveMissions.findIndex(
    (mission) => mission.id === action.payload.id
  )
  const missionUpdatedIndexFromAll = state.orgInstanceAllActiveMissions.findIndex(
    (mission) => mission.id === action.payload.id
  )
  let updatedActiveMissions
  let updatedAllActiveMissions

  if (missionUpdatedIndex !== -1) {
    if (action.payload.name === 'DELETEME') {
      updatedActiveMissions = [...state.orgInstanceActiveMissions]
      updatedActiveMissions.splice(missionUpdatedIndex, 1)

      updatedAllActiveMissions = [...state.orgInstanceAllActiveMissions]
      updatedAllActiveMissions.splice(missionUpdatedIndexFromAll, 1)
    } else {
      updatedActiveMissions = [...state.orgInstanceActiveMissions]
      updatedActiveMissions[missionUpdatedIndex] = action.payload

      updatedAllActiveMissions = [...state.orgInstanceAllActiveMissions]
      updatedAllActiveMissions[missionUpdatedIndexFromAll] = action.payload
    }
  } else {
    updatedActiveMissions = [...state.orgInstanceActiveMissions]
    updatedActiveMissions.push(action.payload)

    updatedAllActiveMissions = [...state.orgInstanceAllActiveMissions]
    updatedAllActiveMissions.push(action.payload)
  }

  if (draftMissionDeleteIndex !== -1) {
    return {
      ...state,
      orgInstanceDraftMissions: updatedDraftMissions,
      orgInstanceActiveMissions: updatedActiveMissions,
      orgInstanceAllActiveMissions: updatedAllActiveMissions,
    }
  } else {
    return {
      ...state,
      orgInstanceActiveMissions: updatedActiveMissions,
      orgInstanceAllActiveMissions: updatedAllActiveMissions,
    }
  }
}

const setOrgInstanceActiveMotions = (state, action) => {
  const motions = {}
  if (!_.isEmpty(action.payload)) {
    action.payload.forEach((motion) => {
      if (motions[motion.mission]) {
        motions[motion.mission].push(motion)
      } else {
        motions[motion.mission] = [motion]
      }
    })
  }

  return {
    ...state,
    orgInstanceActiveMotions: motions,
  }
}

const onOrgInstanceActiveMotionUpdated = (state, action) => {
  // delete draft motions that have moved over to active motions
  let updatedDraftMotions = { ...state.orgInstanceDraftMotions }
  let existInDraftMotions = updatedDraftMotions[action.payload.mission]
  if (existInDraftMotions) delete updatedDraftMotions[action.payload.mission]

  // update current active motions
  let updatedActiveMotions = { ...state.orgInstanceActiveMotions }
  if (state.orgInstanceActiveMotions[action.payload.mission]) {
    const missionMotions = state.orgInstanceActiveMotions[action.payload.mission]
    const motionLocation = missionMotions.findIndex((motion) => motion.id === action.payload.id)
    if (motionLocation !== -1) {
      if (action.payload.name === 'DELETEME') {
        updatedActiveMotions[action.payload.mission].splice(motionLocation)
      } else {
        updatedActiveMotions[action.payload.mission][motionLocation] = action.payload
      }
    } else {
      if (action.payload.name !== 'DELETEME') {
        updatedActiveMotions[action.payload.mission].push(action.payload)
      }
    }
  } else {
    if (action.payload.name !== 'DELETEME') {
      updatedActiveMotions[action.payload.mission] = [action.payload]
    }
  }

  if (existInDraftMotions) {
    return {
      ...state,
      orgInstanceDraftMotions: updatedDraftMotions,
      orgInstanceActiveMotions: updatedActiveMotions,
    }
  } else {
    return {
      ...state,
      orgInstanceActiveMotions: updatedActiveMotions,
    }
  }
}

const setOrgInstanceDraftMissions = (state, action) => {
  return {
    ...state,
    orgInstanceDraftMissions: action.payload,
  }
}

const onOrgInstanceDraftMissionUpdated = (state, action) => {
  const missionUpdatedIndex = state.orgInstanceDraftMissions.findIndex(
    (mission) => mission.id === action.payload.id
  )
  let updatedDraftMissions

  if (missionUpdatedIndex !== -1) {
    if (action.payload.name === 'DELETEME') {
      updatedDraftMissions = [...state.orgInstanceDraftMissions]
      updatedDraftMissions.splice(missionUpdatedIndex, 1)
    } else {
      updatedDraftMissions = [...state.orgInstanceDraftMissions]
      updatedDraftMissions[missionUpdatedIndex] = action.payload
    }
  } else {
    updatedDraftMissions = [...state.orgInstanceDraftMissions]
    updatedDraftMissions.push(action.payload)
  }

  return {
    ...state,
    orgInstanceDraftMissions: updatedDraftMissions,
  }
}

const setOrgInstanceDraftMotions = (state, action) => {
  const motions = {}
  if (!_.isEmpty(action.payload)) {
    action.payload.forEach((motion) => {
      if (motions[motion.mission]) {
        motions[motion.mission].push(motion)
      } else {
        motions[motion.mission] = [motion]
      }
    })
  }

  return {
    ...state,
    orgInstanceDraftMotions: motions,
  }
}

const onOrgInstanceDraftMotionUpdated = (state, action) => {
  let updatedDraftMotions = { ...state.orgInstanceDraftMotions }
  if (state.orgInstanceDraftMotions[action.payload.mission]) {
    const missionMotions = state.orgInstanceDraftMotions[action.payload.mission]
    const motionLocation = missionMotions.findIndex((motion) => motion.id === action.payload.id)
    if (motionLocation !== -1) {
      if (action.payload.name === 'DELETEME') {
        updatedDraftMotions[action.payload.mission].splice(motionLocation, 1)
      } else {
        updatedDraftMotions[action.payload.mission][motionLocation] = action.payload
      }
    } else {
      if (action.payload.name !== 'DELETEME') {
        updatedDraftMotions[action.payload.mission].push(action.payload)
      }
    }
  } else {
    if (action.payload.name !== 'DELETEME') {
      updatedDraftMotions[action.payload.mission] = [action.payload]
    }
  }

  return {
    ...state,
    orgInstanceDraftMotions: updatedDraftMotions,
  }
}

const setOrgInstanceCompleteMissions = (state, action) => {
  return {
    ...state,
    orgInstanceCompleteMissions: action.payload,
  }
}

const onOrgInstanceCompleteMissionUpdated = (state, action) => {
  // delete in draft missions if it exist there
  const activeMissionDeleteIndex = state.orgInstanceActiveMissions.findIndex(
    (mission) => mission.id === action.payload.id
  )
  const activeMissionDeleteIndexFromAll = state.orgInstanceAllActiveMissions.findIndex(
    (mission) => mission.id === action.payload.id
  )

  let updatedActiveMissions
  if (activeMissionDeleteIndex !== -1) {
    updatedActiveMissions = [...state.orgInstanceActiveMissions]
    updatedActiveMissions.splice(activeMissionDeleteIndex, 1)
  }

  let updatedAllActiveMissions
  if (activeMissionDeleteIndexFromAll !== -1) {
    updatedAllActiveMissions = [...state.orgInstanceAllActiveMissions]
    updatedAllActiveMissions.splice(activeMissionDeleteIndexFromAll, 1)
  }

  // update in active missions
  const missionUpdatedIndex = state.orgInstanceCompleteMissions.findIndex(
    (mission) => mission.id === action.payload.id
  )
  let updatedCompleteMissions

  if (missionUpdatedIndex !== -1) {
    updatedCompleteMissions = [...state.orgInstanceCompleteMissions]
    updatedCompleteMissions[missionUpdatedIndex] = action.payload
  } else {
    updatedCompleteMissions = [...state.orgInstanceCompleteMissions]
    updatedCompleteMissions.push(action.payload)
  }

  if (activeMissionDeleteIndex !== -1) {
    return {
      ...state,
      orgInstanceActiveMissions: updatedActiveMissions,
      orgInstanceAllActiveMissions: updatedAllActiveMissions,
      orgInstanceCompleteMissions: updatedCompleteMissions,
    }
  } else {
    return {
      ...state,
      orgInstanceCompleteMissions: updatedCompleteMissions,
    }
  }
}

const setOrgInstanceCompleteMotions = (state, action) => {
  const motions = {}
  if (!_.isEmpty(action.payload)) {
    action.payload.forEach((motion) => {
      if (motions[motion.mission]) {
        motions[motion.mission].push(motion)
      } else {
        motions[motion.mission] = [motion]
      }
    })
  }

  return {
    ...state,
    orgInstanceCompleteMotions: motions,
  }
}

const onOrgInstanceCompleteMotionUpdated = (state, action) => {
  // delete active motions that have moved over to completed motions
  let updatedActiveMotions = { ...state.orgInstanceActiveMotions }
  let existInActiveMotions = updatedActiveMotions[action.payload.mission]
  if (existInActiveMotions) delete updatedActiveMotions[action.payload.mission]

  // update current active motions
  let updatedCompleteMotions = { ...state.orgInstanceCompleteMotions }
  if (state.orgInstanceCompleteMotions[action.payload.mission]) {
    const missionMotions = state.orgInstanceCompleteMotions[action.payload.mission]
    const motionLocation = missionMotions.findIndex((motion) => motion.id === action.payload.id)
    if (motionLocation !== -1) {
      if (action.payload.name === 'DELETEME') {
        updatedCompleteMotions[action.payload.mission].splice(motionLocation, 1)
      } else {
        updatedCompleteMotions[action.payload.mission][motionLocation] = action.payload
      }
    } else {
      if (action.payload.name !== 'DELETEME') {
        updatedCompleteMotions[action.payload.mission].push(action.payload)
      }
    }
  } else {
    if (action.payload.name !== 'DELETEME') {
      updatedCompleteMotions[action.payload.mission] = [action.payload]
    }
  }

  if (existInActiveMotions) {
    return {
      ...state,
      orgInstanceActiveMotions: updatedActiveMotions,
      orgInstanceCompleteMotions: updatedCompleteMotions,
    }
  } else {
    return {
      ...state,
      orgInstanceCompleteMotions: updatedCompleteMotions,
    }
  }
}

// Templates
const setOrgInstanceMissionTemplates = (state, action) => {
  return {
    ...state,
    orgInstanceMissionTemplates: action.payload,
  }
}

const onOrgInstanceMissionTemplateUpdated = (state, action) => {
  // update store mission templates
  const missionTemplateUpdatedIndex = state.orgInstanceMissionTemplates.findIndex(
    (mission) => mission.id === action.payload.id
  )
  let updatedMissionTemplates

  if (missionTemplateUpdatedIndex !== -1) {
    if (action.payload.name === 'DELETEME') {
      updatedMissionTemplates = [...state.orgInstanceMissionTemplates]
      updatedMissionTemplates.splice(missionTemplateUpdatedIndex, 1)
    } else {
      updatedMissionTemplates = [...state.orgInstanceMissionTemplates]
      updatedMissionTemplates[missionTemplateUpdatedIndex] = action.payload
    }
  } else {
    updatedMissionTemplates = [...state.orgInstanceMissionTemplates]
    updatedMissionTemplates.push(action.payload)
  }

  return {
    ...state,
    orgInstanceMissionTemplates: updatedMissionTemplates,
  }
}

// Logs
const setLogs = (state, action) => {
  return {
    ...state,
    logs: action.payload,
  }
}

const onLogUpdated = (state, action) => {
  const newlogs = [...state.logs]
  newlogs.push(action.payload)

  return {
    ...state,
    logs: newlogs,
  }
}

// Work Items
const setWorkItems = (state, action) => {
  return {
    ...state,
    workItems: action.payload,
  }
}

const onWorkItemUpdated = (state, action) => {
  const newWorkItems = [...state.workItems]

  const targetWorkItemIndex = newWorkItems.findIndex(
    (workItem) => workItem?.id === action.payload.id
  )

  if (action.payload && targetWorkItemIndex !== -1) {
    if (action.payload.name === 'DELETEME') {
      delete newWorkItems[targetWorkItemIndex]
    } else {
      newWorkItems[targetWorkItemIndex] = action.payload
    }
  } else {
    newWorkItems.push(action.payload)
  }

  return {
    ...state,
    workItems: newWorkItems.filter((item) => Boolean(item)),
  }
}

const clearStore = (state, action) => {
  return {
    userOrgs: [],
    userDetails: null,
    orgInstanceUsers: {},
    orgInstanceActiveMissions: [],
    orgInstanceAllActiveMissions: [],
    orgInstanceActiveMotions: {},
    orgInstanceDraftMissions: [],
    orgInstanceDraftMotions: {},
    orgInstanceCompleteMissions: [],
    orgInstanceCompleteMotions: {},
    orgInstanceMissionTemplates: [],
    logs: [],
    workItems: [],
  }
}

const orgInstanceChange = (state, action) => {
  return {
    ...state,
    orgInstanceUsers: {},
    orgInstanceActiveMissions: [],
    orgInstanceAllActiveMissions: [],
    orgInstanceActiveMotions: {},
    orgInstanceDraftMissions: [],
    orgInstanceDraftMotions: {},
    orgInstanceCompleteMissions: [],
    orgInstanceCompleteMotions: {},
    orgInstanceMissionTemplates: [],
    logs: [],
    workItems: [],
  }
}

const database = (state = initialState, action) => {
  switch (action.type) {
    //  User
    case actionTypes.SET_USER:
      return setUser(state, action)
    case actionTypes.ON_USER_UPDATED:
      return onUserUpdated(state, action)
    case actionTypes.SET_ORG_INSTANCE_USERS:
      return setOrgInstanceUsers(state, action)
    case actionTypes.UPDATE_ORG_INSTANCE_USER:
      return updateSingleOrgInstanceUser(state, action)

    // Orgs
    case actionTypes.SET_USER_ORGS:
      return setUserOrgs(state, action)
    case actionTypes.ON_USER_ORG_ADDED:
      return onUserOrgAdded(state, action)
    case actionTypes.DELETE_USER_FROM_ORG_LOCAL:
      return deleteUserFromOrgLocal(state, action)
    case actionTypes.ON_ORG_MEMBER_UPDATED:
      return onOrgMemberUpdated(state, action)

    // Missions and Motions
    case actionTypes.SET_ORG_INSTANCE_ACTIVE_MISSIONS:
      return setOrgInstanceActiveMissions(state, action)
    case actionTypes.SET_ORG_INSTANCE_ALL_ACTIVE_MISSIONS:
      return setOrgInstanceAllActiveMissions(state, action)
    case actionTypes.ON_ORG_INSTANCE_ACTIVE_MISSION_UPDATED:
      return onOrgInstanceActiveMissionUpdated(state, action)
    case actionTypes.SET_ORG_INSTANCE_ACTIVE_MOTIONS:
      return setOrgInstanceActiveMotions(state, action)
    case actionTypes.ON_ORG_INSTANCE_ACTIVE_MOTION_UPDATED:
      return onOrgInstanceActiveMotionUpdated(state, action)

    case actionTypes.SET_ORG_INSTANCE_DRAFT_MISSIONS:
      return setOrgInstanceDraftMissions(state, action)
    case actionTypes.ON_ORG_INSTANCE_DRAFT_MISSION_UPDATED:
      return onOrgInstanceDraftMissionUpdated(state, action)
    case actionTypes.SET_ORG_INSTANCE_DRAFT_MOTIONS:
      return setOrgInstanceDraftMotions(state, action)
    case actionTypes.ON_ORG_INSTANCE_DRAFT_MOTION_UPDATED:
      return onOrgInstanceDraftMotionUpdated(state, action)

    case actionTypes.SET_ORG_INSTANCE_COMPLETE_MISSIONS:
      return setOrgInstanceCompleteMissions(state, action)
    case actionTypes.ON_ORG_INSTANCE_COMPLETE_MISSION_UPDATED:
      return onOrgInstanceCompleteMissionUpdated(state, action)
    case actionTypes.SET_ORG_INSTANCE_COMPLETE_MOTIONS:
      return setOrgInstanceCompleteMotions(state, action)
    case actionTypes.ON_ORG_INSTANCE_COMPLETE_MOTION_UPDATED:
      return onOrgInstanceCompleteMotionUpdated(state, action)

    // Templates
    case actionTypes.SET_ORG_INSTANCE_MISSION_TEMPLATES:
      return setOrgInstanceMissionTemplates(state, action)
    case actionTypes.ON_ORG_INSTANCE_MISSION_TEMPLATES_UPDATED:
      return onOrgInstanceMissionTemplateUpdated(state, action)

    // Logs
    case actionTypes.SET_LOGS:
      return setLogs(state, action)
    case actionTypes.ON_LOGS_UPDATED:
      return onLogUpdated(state, action)

    // Work Items
    case actionTypes.SET_WORK_ITEMS:
      return setWorkItems(state, action)
    case actionTypes.ON_WORK_ITEMS_UPDATED:
      return onWorkItemUpdated(state, action)

    // Misc
    case actionTypes.ORG_INSTANCE_CHANGE:
      return orgInstanceChange(state, action)
    case actionTypes.CLEAR_STORE:
      return clearStore(state, action)
    default:
      return state
  }
}

export default database
