
import * as actionTypes from "../actionTypes";

export function selectWorkspace(workspace, updatingWinflow = false) {
    return {
      type: actionTypes.SELECT_WORKSPACE,
      payload: { workspace, updatingWinflow }
    };
}

export function selectOrg(org) {
  return {
    type: actionTypes.SELECT_ORG,
    payload: org
  };
}

export function selectMission(mission) {
  return {
    type: actionTypes.SELECT_MISSION,
    payload: mission
  };
}

export function selectMotion(motion) {
  return {
    type: actionTypes.SELECT_MOTION,
    payload: motion
  };
}

export function showProfileModel(show) {
  return {
    type: actionTypes.SHOW_PROFILE_MODEL,
    payload: show
  };
}

export function showPasswordResetModal(show) {
  return {
    type: actionTypes.SHOW_PASSWORD_RESET_MODAL,
    payload: show,
  };
}

export function closePreview() {
  return {
    type: actionTypes.CLOSE_PREVIEW
  };
}

export function setPreview(preview) {
  return {
    type: actionTypes.SET_PREVIEW,
    preview: preview
  };
}

export function setLoadedFile(loadedFile) {
  return {
    type: actionTypes.SET_LOADED_FILE,
    loadedFile: loadedFile
  };
}

export const clearInstanceData = () => {
  return {
    type: actionTypes.CLEAR_INSTANCE_DATA
  }
}



