// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Droppable } from 'react-beautiful-dnd'
import _, { get } from 'lodash'
import Lottie from 'react-lottie'
import animationData from '../../assets/images/H-flier-man.json'
import { withTranslation } from 'react-i18next';

// Material UI
import Icon from '@material-ui/core/Icon'

// Custom Components
import MissionMeta from '../MissionMeta'
import Motion from '../Motion'
import SideDrawerEdit from '../SideDrawerEdit'
// import SideDrawerLog from "../SideDrawerLog";
// import SideDrawerRepeat from "../SideDrawerRepeat";
import MissionStart from '../MissionStart'

// helpers
import { selectMission, selectWorkspace, selectMotion } from '../../store/actions'
import { userRoles } from '../../utils/handleRoles'

const isRoleAccountable = (role) => get(userRoles, [role, 'roleGroup'], '') === 'accountable'

class DraftCompletionSpace extends Component {
  state = {
    showSideDrawerEdit: true,
    /*showSideDrawerLog: false,
    showSideDrawerRepeat: false,*/
  }

  motionComponents = []

  componentDidMount() {
    const { missions, activeMission } = this.props

    if (
      (_.isEmpty(activeMission) && missions.length > 0) ||
      missions.findIndex((mission) => mission.id === activeMission.id) === -1
    )
      this.props.selectMission(missions[0])
  }

  handleSideDrawersToggle = (drawerName) => {
    if (drawerName === 'edit') {
      this.setState({
        showSideDrawerEdit: !this.state.showSideDrawerEdit,
      })
    } else if (drawerName === 'log') {
      this.setState({
        showSideDrawerLog: !this.state.showSideDrawerLog,
      })
    } else if (drawerName === 'repeat') {
      this.setState({
        showSideDrawerRepeat: !this.state.showSideDrawerRepeat,
      })
    }
  }

  getMotionComponents = () => {
    const { activeMission, activeMotion, motions, uid, missions } = this.props

    if (activeMission && this.props.motions && this.props.motions[this.props.activeMission.id]) {
      // Setup needed variables
      const motionsForThisMission = motions[activeMission.id]

      if (!_.isEmpty(motionsForThisMission) && activeMission) {
        const isOwner = activeMission.creator === uid
        let activeMotionSet = false
        if (activeMotion && activeMotion.id) {
          motionsForThisMission.forEach((motion) => {
            if (motion.id === activeMotion.id) activeMotionSet = true
          })
        }

        this.motionComponents = []

        // Create a list of motionComponents based on order
        motionsForThisMission
          .sort((a, b) => a.index - b.index)
          .forEach((motion) => {
            if (motion.motionStatus && motion.org && motion.mission) {
              if (!activeMotionSet && motion.motionStatus !== 'complete') {
                const userInMotion =
                  motion.members &&
                  motion.members[uid] &&
                  (isRoleAccountable(motion.members[uid].role) || isOwner)
                // const userInMotion = motion.members && motion.members.find(member => member.uid === uid  && isRoleAccountable(member.role) || isOwner)

                if (!motion.private || userInMotion) {
                  activeMotionSet = true
                  this.props.selectMotion(motion)
                }
              }
              this.motionComponents.push(<Motion passedMotion={motion} key={motion.id} />)
            }
          })

        this.motionComponents.push(
          <div key="star-graphic-2">
            <div className="star-graphic microflowWrapper1">
              <img src={require('../../assets/images/flag-grey.svg')} alt="flag" />
            </div>
          </div>
        )
      }
    }
  }

  render() {
    const { motions, activeMission, uid, missions, t } = this.props

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }

    this.getMotionComponents()

    // if activeMission and motions haven't loaded, return empty early
    if (!activeMission || !motions) {
      return (
        <div className={'emptystate'}>
          <div>
            <p> {t('draftSpace.emptystate.p')} </p>
            <p className={'blue-link'} onClick={() => this.props.selectWorkspace('notifications')}>
              {' '}
              {t('common.space.emptystate.create')}{' '}
            </p>
          </div>
        </div>
      )
    }
    // else if (!activeMission || !motions) {
    //   return (
    //     <div style={{ height: '37vh', width: '17vw', margin: 'auto 50vw' }}>
    //       <Lottie options={defaultOptions} />
    //     </div>
    //   )
    // }

    // if (_.isEmpty(motions)) {
    //   return (
    //     <div className={'emptystate'}>
    //       <div>
    //         <p> You don’t have any planned Missions right now </p>
    //         <p className={'blue-link'} onClick={() => this.props.selectWorkspace('notifications')}>
    //           {' '}
    //           Create one now{' '}
    //         </p>
    //       </div>
    //     </div>
    //   )
    // }

    return (
      <div className="winflow">
        {
          <Droppable
            droppableId={activeMission.id}
            direction="horizontal"
            type="MOTION"
            isDropDisabled={
              activeMission.status === 'complete' ||
              activeMission.status === 'cancelled' ||
              activeMission.creator !== uid
            }
          >
            {(provided) => (
              <div
                className="workspace-canvas"
                style={{
                  paddingRight: this.state.showSideDrawerEdit && '250px',
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <div className="microflowsWrapper">
                  {<MissionStart />}
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    {this.motionComponents}
                  </div>
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        }

        <MissionMeta />

        <SideDrawerEdit
          showSideDrawerEdit={this.state.showSideDrawerEdit}
          handleSideDrawersToggle={this.handleSideDrawersToggle}
        />

        <div className="edit-button-container">
          <div onClick={() => this.handleSideDrawersToggle('edit')} className="edit-button">
            <Icon key={1} className="material-icons small-space">
              arrow_left
            </Icon>
            <div className="edit-button-text">{t('common.button.flierControl')}</div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, passed) => {
  return {
    activeMission: state.instance.mission,
    workspace: state.instance.workspace,
    org: state.instance.org,
    // mission: state.instance.mission,
    activeMotion: state.instance.motion,
    uid: state.firebase.auth.uid,
    updatingWinflow: state.instance.updatingWinflow,
    missions: state.database.orgInstanceDraftMissions,
    motions: state.database.orgInstanceDraftMotions,
  }
}

const mapDispatchToProps = (dispatch) => ({
  selectMotion: (motion) => dispatch(selectMotion(motion)),
  selectMission: (mission) => dispatch(selectMission(mission)),
  selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(DraftCompletionSpace))
