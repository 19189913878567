const templates = [ 
  {
  "name" : "Universal",
  "winflows" : [ {
    "description" : "Use this Mission to see how to make your business fly!",
    "motions" : [ {
      "subheader" : "the Hi-flier FAQ",
      "description" : "Find the Hi-flier FAQs under the question mark icon in the menu bar, read them over and mark yourself as done. Feel free to leave a comment!",
      "duration" : 1,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "by adding a profile picture",
      "description" : "Show yourself to others by adding a picture to your profile, accessed in the top right corner.",
      "duration" : 1,
      "name" : "Action",
      "preset" : 6
    }, {
      "subheader" : "by adding a business item",
      "description" : "In the Flier Controls panel, add a business item from Dropbox or Google Drive, then drag and drop it into this motion. ",
      "duration" : 1,
      "name" : "Action",
      "preset" : 6
    }, {
      "subheader" : "by creating a new Mission",
      "description" : "Click Planned Missions and create a new Mission from scratch or use a Quick Start template. Name your Mission and invite friends or family to join, then add them to motions and give them roles. ",
      "duration" : 2,
      "name" : "Action",
      "preset" : 6
    }, {
      "subheader" : "Start your first Mission!",
      "description" : "Once you've added people and dates, the Start button should be active. Start your new Mission to have invites sent out to people to take action.",
      "duration" : 1,
      "name" : "Action",
      "preset" : 6
    },{
      "subheader" : "your active Missions",
      "description" : "Go back to your Active Missions to see the progress they're making, without you having to nudge them. Mark yourself as done and you've completed this Mission!",
      "duration" : 1,
      "name" : "Review",
      "preset" : 1
    } ],
    "name" : "Get started with Hi-flier"
  }, {
    "description" : "Gather info, create something, and review it. That's it!",
    "motions" : [ {
      "subheader" : "all relevant materials",
      "description" : "Gather the relevant materials and add them as business items in Flier controls. Then drag & drop them into this motion to share.",
      "duration" : 2,
      "name" : "Gather",
      "preset" : 10
    }, {
      "subheader" : "the first draft",
      "description" : "Create the first pass or draft of the new asset based on the collected info and any comments",
      "duration" : 2,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "the asset",
      "description" : "Review the asset and give input and feedback. Add more motions as necessary to achieve your Mission.",
      "duration" : 1,
      "name" : "Review",
      "preset" : 1
    } ],
    "name" : "A basic starter Mission"
  }, {
    "description" : "Create anything as a group! This Mission makes for easy participation.",
    "motions" : [ {
      "subheader" : "a first draft",
      "description" : "Create the first pass or draft of the asset from scratch or based on previous work or a template.",
      "duration" : 5,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "the first draft",
      "description" : "Stakeholders review the first pass and give input and feedback.",
      "duration" : 2,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "the next draft",
      "description" : "Edit and evolve the asset based on stakeholder feedback.",
      "duration" : 3,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "the final asset",
      "description" : "Approve the final asset.",
      "duration" : 1,
      "name" : "Approve",
      "preset" : 2
    }, {
      "subheader" : "receipt of the final asset",
      "description" : "Stakeholders confirm that the final asset has been shared out.",
      "duration" : 1,
      "name" : "Confirm",
      "preset" : 4
    } ],
    "name" : "Create a great (asset)"
  }, {
    "description" : "Impress the client every time with your speedy execution.",
    "motions" : [ {
      "subheader" : "project requirements",
      "description" : "Collect the requirements for a successful project.",
      "duration" : 3,
      "name" : "Gather",
      "preset" : 10
    }, {
      "subheader" : "requirements documentation",
      "description" : "Internally draft the creative brief and technical requirements.",
      "duration" : 2,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "requirements with team",
      "description" : "Bring the internal team together and kickoff the project.",
      "duration" : 1,
      "name" : "Confirm",
      "preset" : 4
    }, {
      "subheader" : "the project",
      "description" : "Incorporate the team's feedback into a project plan.",
      "duration" : 3,
      "name" : "Plan",
      "preset" : 8
    }, {
      "subheader" : "the plan with the client",
      "description" : "Review the project plan with the client.",
      "duration" : 2,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "round 1 of the asset",
      "description" : "Team generates ideas or prototypes of ideas for the asset.",
      "duration" : 10,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "options to show the clientE68	",
      "description" : "Review ideas and decide which to refine and show the client.",
      "duration" : 1,
      "name" : "Decide",
      "preset" : 3
    }, {
      "subheader" : "options with the client",
      "description" : "Review the initial work with the client.",
      "duration" : 2,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "round 2 based on client feedback",
      "description" : "Evolve the asset based on client feedback and direction.",
      "duration" : 2,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "round 2 of the asset",
      "description" : "Internally review the asset, collect feedback, and revise until final.",
      "duration" : 5,
      "name" : "Decide",
      "preset" : 3
    }, {
      "subheader" : "final asset",
      "description" : "Client reviews and approves the final asset.",
      "duration" : 2,
      "name" : "Approve",
      "preset" : 2
    }, {
      "subheader" : "receipt of the final asset",
      "description" : "Put deliverables together and send to client, who confirms receiving.",
      "duration" : 1,
      "name" : "Confirm",
      "preset" : 4
    } ],
    "name" : "Create a great (asset for client)"
  }, {
    "description" : "Big meetings require a lot of coordination. Make it happen one step at a time!",
    "motions" : [ {
      "subheader" : "input on agenda items",
      "description" : "Collect input on what the meeting should include.",
      "duration" : 2,
      "name" : "Gather",
      "preset" : 10
    }, {
      "subheader" : "an agenda",
      "description" : "Create an outline of the meeting agenda.",
      "duration" : 2,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "the agenda",
      "description" : "Stakeholders review the outline and give input.",
      "duration" : 1,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "the final agenda",
      "description" : "Edit the agenda outline based on feedback.",
      "duration" : 1,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "the agenda",
      "description" : "Stakeholders approve the revised agenda.",
      "duration" : 1,
      "name" : "Approve",
      "preset" : 2
    }, {
      "subheader" : "receipt of materials",
      "description" : "Attendees confirm the agenda and provide materials ahead of meeting.",
      "duration" : 1,
      "name" : "Confirm",
      "preset" : 4
    } ],
    "name" : "Prepare an effective meeting"
  }, {
    "description" : "Finalizing the outline is just the first step. Get ready for the big day!",
    "motions" : [ {
      "subheader" : "by preparing meeting presentations",
      "description" : "Contributors prepare presentations or documents for the meeting.",
      "duration" : 5,
      "name" : "Action",
      "preset" : 6
    }, {
      "subheader" : "presentations and feedback",
      "description" : "Review presentations and give feedback.",
      "duration" : 2,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "by editing meeting presentation",
      "description" : "Contributors edit their presentations based on feedback.",
      "duration" : 2,
      "name" : "Action",
      "preset" : 6
    }, {
      "subheader" : "meeting presentation",
      "description" : "Review and approve the final presentations.",
      "duration" : 1,
      "name" : "Approve",
      "preset" : 2
    } ],
    "name" : "Prepare meeting materials"
  } ]
}, {
  "name" : "Legal",
  "winflows" : [ {
    "description" : "Create an agreement and get it approved and signed with this simple Mission.",
    "motions" : [ {
      "subheader" : "terms of the agreement",
      "description" : "Collect input on the terms of the agreement.",
      "duration" : 2,
      "name" : "Gather",
      "preset" : 10
    }, {
      "subheader" : "agreement",
      "description" : "Create an agreement document.",
      "duration" : 5,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "agreement",
      "description" : "Internally review and give feedback on the agreement document.",
      "duration" : 2,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "receipt of agreement",
      "description" : "Client confirms receipt of the agreement document.",
      "duration" : 1,
      "name" : "Confirm",
      "preset" : 4
    }, {
      "subheader" : "agreement with the client",
      "description" : "Client reviews and give feedback on the  agreement document.",
      "duration" : 2,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "terms of the agreement",
      "description" : "Both parties exchange versions until the terms are agreed upon.",
      "duration" : 10,
      "name" : "Negotiate",
      "preset" : 7
    }, {
      "subheader" : "the agreement",
      "description" : "Internally approve the agreement document.",
      "duration" : 2,
      "name" : "Approve",
      "preset" : 2
    }, {
      "subheader" : "the agreement",
      "description" : "All parties sign the final agreement.",
      "duration" : 3,
      "name" : "Sign",
      "preset" : 5
    }, {
      "subheader" : "receipt of the executed agreement",
      "description" : "Stakeholders confirm receiving fully executed agreement.",
      "duration" : 2,
      "name" : "Confirm",
      "preset" : 4
    } ],
    "name" : "Finalize an agreement"
  }, {
    "description" : "Get up and running faster with a signed mutual NDA.",
    "motions" : [ {
      "subheader" : "a draft NDA",
      "description" : "Create an NDA from a template.",
      "duration" : 1,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "terms of the NDA",
      "description" : "Internal stakeholders approve the terms of the NDA.",
      "duration" : 3,
      "name" : "	Approve",
      "preset" : 3
    }, {
      "subheader" : "receipt of suggested NDA",
      "description" : "Send NDA document, confirmed received by other party.",
      "duration" : 2,
      "name" : "Confirm",
      "preset" : 4
    }, {
      "subheader" : "the NDA until done",
      "description" : "Exchange versions until terms are agreed upon.",
      "duration" : 10,
      "name" : "Negotiate",
      "preset" : 7
    }, {
      "subheader" : "the NDA until done",
      "description" : "All parties sign the final NDA",
      "duration" : 5,
      "name" : "Sign",
      "preset" : 5
    }, {
      "subheader" : "receipt of the final NDA",
      "description" : "All parties confirm receipt of fully executed NDA.",
      "duration" : 1,
      "name" : "Confirm",
      "preset" : 4
    } ],
    "name" : "Get mutual NDA signed"
  }, {
    "description" : "Register paperwork with the SEC or other governing body.",
    "motions" : [ {
      "subheader" : "registration documentation",
      "description" : "Create registration documentation based on a template.",
      "duration" : 1,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "registration documentation",
      "description" : "Adapt and review the registration documentation.",
      "duration" : 2,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "registration documentation",
      "description" : "Client approves the registration documentation.",
      "duration" : 2,
      "name" : "Approve",
      "preset" : 2
    }, {
      "subheader" : "by registering",
      "description" : "Register documentation with the government agency.",
      "duration" : 5,
      "name" : "Action",
      "preset" : 6
    }, {
      "subheader" : "registration completion",
      "description" : "Stakeholders confirm receipt of registration confirmation.",
      "duration" : 2,
      "name" : "Confirm",
      "preset" : 4
    } ],
    "name" : "Register with (government entity)"
  }, {
    "description" : "Make running board meetings a snap with this easy-to-follow template.",
    "motions" : [ {
      "subheader" : "meeting agenda",
      "description" : "Create board meeting agenda and proposed resolutions.",
      "duration" : 5,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "meeting materials",
      "description" : "Review the board meeting agenda and proposed resolutions.",
      "duration" : 3,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "meeting materials",
      "description" : "Approve the board meeting agenda and proposed resolutions.",
      "duration" : 2,
      "name" : "Approve",
      "preset" : 2
    }, {
      "subheader" : "receipt of meeting materials",
      "description" : "Board members confirm receipt of the meeting agenda and proposed resolutions.",
      "duration" : 2,
      "name" : "Confirm",
      "preset" : 4
    }, {
      "subheader" : "agenda and resolutions	",
      "description" : "Run board meeting and capture resolutions and annotations.",
      "duration" : 3,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "receipt of final meeting outcomes",
      "description" : "Publish final materials to share with board members and other stakeholders.",
      "duration" : 2,
      "name" : "Confirm",
      "preset" : 4
    } ],
    "name" : "Successful board meeting"
  } ]
}, {
  "name" : "HR",
  "winflows" : [ {
    "description" : "Strengthen your team with this easy job posting Mission.",
    "motions" : [ {
      "subheader" : "job requirements",
      "description" : "Collect potential employee, market, and sourcing information and documents.",
      "duration" : 5,
      "name" : "Gather",
      "preset" : 10
    }, {
      "subheader" : "job description",
      "description" : "Create a job description and other necessary documents for the requisition.",
      "duration" : 2,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "job description",
      "description" : "Submit request to HR and other stakeholders.",
      "duration" : 2,
      "name" : "Approve",
      "preset" : 2
    }, {
      "subheader" : "posting the job description",
      "description" : "Get go-ahead from HR to post the job.",
      "duration" : 1,
      "name" : "Confirm",
      "preset" : 4
    } ],
    "name" : "Create a new position"
  }, {
    "description" : "Close the deal and expedite hiring with this Mission that includes your org and the new hire!",
    "motions" : [ {
      "subheader" : "info on the new hire",
      "description" : "Collect all new hire information and agreed upon terms.",
      "duration" : 3,
      "name" : "Gather",
      "preset" : 10
    }, {
      "subheader" : "new hire documents",
      "description" : "Review all documentation for accuracy.",
      "duration" : 1,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "by conducting a background check",
      "description" : "Conduct a background check.",
      "duration" : 2,
      "name" : "Action",
      "preset" : 6
    }, {
      "subheader" : "new hire",
      "description" : "Reporting manager and hiring manager approve the new hire.",
      "duration" : 2,
      "name" : "Approve",
      "preset" : 2
    }, {
      "subheader" : "receipt of offer",
      "description" : "New hire confirms receipt of the offer package.",
      "duration" : 3,
      "name" : "Confirm",
      "preset" : 4
    }, {
      "subheader" : "new hire agreement",
      "description" : "Company and new hire and company sign the new hire agreement.",
      "duration" : 3,
      "name" : "Sign",
      "preset" : 5
    }, {
      "subheader" : "receipt of onboarding",
      "description" : "New hire and manager receive the onboarding procedure.",
      "duration" : 1,
      "name" : "Confirm",
      "preset" : 4
    } ],
    "name" : "Hire a new employee"
  }, {
    "description" : "Bring new employees on quickly and painlessly so they can start working sooner.",
    "motions" : [ {
      "subheader" : "employee documentation",
      "description" : "Review all employee documentation for accuracy.",
      "duration" : 1,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "employee's start details",
      "description" : "Reporting manager to approve new employee's start date, IT requirements, and location.",
      "duration" : 2,
      "name" : "Approve",
      "preset" : 2
    }, {
      "subheader" : "receipt of new employee request",
      "description" : "IT, operations, and other departments to confirm receipt of new employee information.",
      "duration" : 3,
      "name" : "Confirm",
      "preset" : 4
    }, {
      "subheader" : "by setting up new employee equipment",
      "description" : "It to set up new employee credentials and equipment.",
      "duration" : 2,
      "name" : "Action",
      "preset" : 6
    }, {
      "subheader" : "by setting up new employee payroll",
      "description" : "Finance to set up new employee's payroll.",
      "duration" : 2,
      "name" : "Action",
      "preset" : 6
    }, {
      "subheader" : "employee setup",
      "description" : "Confirm that everything is ready for new employee's start date.",
      "duration" : 1,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "employee onboarding",
      "description" : "Conduct onboarding meeting and presentations.",
      "duration" : 1,
      "name" : "Implement",
      "preset" : 19
    }, {
      "subheader" : "by taking hire to reporting manager",
      "description" : "Hand off new employee to reporting manager.",
      "duration" : 1,
      "name" : "Action",
      "preset" : 6
    } ],
    "name" : "Onboard a new employee"
  } ]
}, {
  "name" : "Marketing",
  "winflows" : [ {
    "description" : "Get content for new marketing campaigns reviewed and approved faster.",
    "motions" : [ {
      "subheader" : "content for marketing campaign",
      "description" : "Create marketing campaign content.",
      "duration" : 10,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "marketing campaign content",
      "description" : "Marketing manager to get approval on marketing campaign.",
      "duration" : 2,
      "name" : "Approve",
      "preset" : 2
    }, {
      "subheader" : "by updating campaign content",
      "description" : "Update marketing campaign content.",
      "duration" : 3,
      "name" : "Action",
      "preset" : 6
    } ],
    "name" : "Create a great marketing campaign"
  }, {
    "description" : "Ramp up the inbound traffic with some help.",
    "motions" : [ {
      "subheader" : "project requirements",
      "description" : "Collect the requirements for project success.",
      "duration" : 3,
      "name" : "Gather",
      "preset" : 10
    }, {
      "subheader" : "outline of the campaign",
      "description" : "Draft outline, goals and budget.",
      "duration" : 2,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "SEO campaign",
      "description" : "Marketing manager to get approval on SEO campaign.",
      "duration" : 2,
      "name" : "Approve",
      "preset" : 2
    }, {
      "subheader" : "the project",
      "description" : "Create a project plan with a consultant's input.",
      "duration" : 3,
      "name" : "Plan",
      "preset" : 8
    }, {
      "subheader" : "running the campaign",
      "description" : "Run the SEO campaign.",
      "duration" : 20,
      "name" : "Implement",
      "preset" : 9
    }, {
      "subheader" : "performance",
      "description" : "Review performance numbers and spend so far.",
      "duration" : 2,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "revision of SEO campaign",
      "description" : "Evolve the plan based on feedback and direction.",
      "duration" : 2,
      "name" : "Plan",
      "preset" : 8
    }, {
      "subheader" : "the revised campaign",
      "description" : "Run the revised SEO campaign",
      "duration" : 20,
      "name" : "Implement",
      "preset" : 9
    }, {
      "subheader" : "performance",
      "description" : "Review performance numbers and spend so far.",
      "duration" : 2,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "receipt of campaign performance",
      "description" : "Inform stakeholders of the campaign results.",
      "duration" : 1,
      "name" : "Confirm",
      "preset" : 4
    } ],
    "name" : "Create a great SEO campaign"
  } ]
}, {
  "name" : "Sales",
  "winflows" : [ {
    "description" : "Expedite the deal with this mission that includes all the stakeholders.",
    "motions" : [ {
      "subheader" : "purchase information",
      "description" : "Collect all necessary information, documents, and figures.",
      "duration" : 3,
      "name" : "Gather",
      "preset" : 10
    }, {
      "subheader" : "a sales agreement",
      "description" : "Create agreement based on template,  SOW or other contract.",
      "duration" : 3,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "terms of the agreement",
      "description" : "Internal stakeholders to approve the terms of the deal.",
      "duration" : 2,
      "name" : "Approve",
      "preset" : 2
    }, {
      "subheader" : "agreement with the client",
      "description" : "Client to review and approve the agreement.",
      "duration" : 5,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "terms of the agreement",
      "description" : "Both parties negotiate the terms of the deal until agreed upon.",
      "duration" : 5,
      "name" : "Negotiate",
      "preset" : 7
    }, {
      "subheader" : "final agreement",
      "description" : "All parties sign final deal agreement.",
      "duration" : 2,
      "name" : "Sign",
      "preset" : 5
    }, {
      "subheader" : "executed agreement",
      "description" : "Client confirms receipt of fully executed agreement to client.",
      "duration" : 1,
      "name" : "Confirm",
      "preset" : 4
    }, {
      "subheader" : "to record sale and receive payment",
      "description" : "Input terms into internal systems and process payment.",
      "duration" : 1,
      "name" : "Action",
      "preset" : 6
    } ],
    "name" : "Complete a sales or purchase order"
  }, {
    "description" : "Turn around a sale faster by updating an existing order and getting it approved.",
    "motions" : [ {
      "subheader" : "existing sales order",
      "description" : "Review existing customer sales order and get feedback from sales and service on status and updates.",
      "duration" : 3,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "updated sales order",
      "description" : "Update the existing sales order as needed.",
      "duration" : 2,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "final sales order",
      "description" : "Get internal approval and feedback on the updated sales order.",
      "duration" : 2,
      "name" : "Approve",
      "preset" : 2
    }, {
      "subheader" : "sales order with customer",
      "description" : "Review the updated sales order with the customer.",
      "duration" : 5,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "sales order",
      "description" : "Customer to sign the updated sales order.",
      "duration" : 2,
      "name" : "Sign",
      "preset" : 5
    }, {
      "subheader" : "receipt of executed sales order",
      "description" : "All parties confirm receipt of the updated sales order.",
      "duration" : 2,
      "name" : "Confirm",
      "preset" : 4
    } ],
    "name" : "Update an existing sales order"
  } ]
}, {
  "name" : "Alliance",
  "winflows" : [ {
    "description" : "Partner up to execute a successful joint marketing event!",
    "motions" : [ {
      "subheader" : "info about the event",
      "description" : "Collect all necessary documents and specifications for the event.",
      "duration" : 5,
      "name" : "Gather",
      "preset" : 10
    }, {
      "subheader" : "the objectives and roles",
      "description" : "Review objectives of the event and negotiate who is doing what.",
      "duration" : 5,
      "name" : "Negotiate",
      "preset" : 7
    }, {
      "subheader" : "budget, time, and resources",
      "description" : "Approve the split of resources, time and financial agreement.",
      "duration" : 3,
      "name" : "Approve",
      "preset" : 2
    }, {
      "subheader" : "necessary agreements",
      "description" : "Sign any agreements necessary and submit paperwork.",
      "duration" : 2,
      "name" : "Sign",
      "preset" : 5
    }, {
      "subheader" : "receipt of event agreement package",
      "description" : "Stakeholders confirm receipt of the event agreement package.",
      "duration" : 2,
      "name" : "Confirm",
      "preset" : 4
    }, {
      "subheader" : "who is doing what",
      "description" : "Outline action plan and responsibilities.",
      "duration" : 3,
      "name" : "Plan",
      "preset" : 8
    }, {
      "subheader" : "plan for the event",
      "description" : "Follow the plan for a great event.",
      "duration" : 20,
      "name" : "Implement",
      "preset" : 9
    } ],
    "name" : "Put on a joint marketing event"
  } ]
}, {
  "name" : "Students",
  "winflows" : [ {
    "description" : "Take the stress out of coordinating a group presentation for a class.",
    "motions" : [ {
      "subheader" : "the assignment",
      "description" : "Meet as a group to review the assignment and divvy up responsibilities.",
      "duration" : 1,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "project information",
      "description" : "Collect research, sources, and supporting works.",
      "duration" : 5,
      "name" : "Gather",
      "preset" : 10
    }, {
      "subheader" : "on material for the presentation",
      "description" : "Get approval and feedback on the individual contributions of the group.",
      "duration" : 10,
      "name" : "Decide",
      "preset" : 3
    }, {
      "subheader" : "presentation",
      "description" : "Work together to create the presentation.",
      "duration" : 5,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "presentation with our mentor",
      "description" : "Review presentation with our mentor and get feedback.",
      "duration" : 5,
      "name" : "Review",
      "preset" : 1
    } ],
    "name" : "Give a great group presentation"
  }, {
    "description" : "Prep for the big exam by studying with a group!",
    "motions" : [ {
      "subheader" : "what we need to learn",
      "description" : "Meet as a group to review the exam and divvy up the material.",
      "duration" : 1,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "study material",
      "description" : "Collect notes, sources, and supporting material.",
      "duration" : 2,
      "name" : "Gather",
      "preset" : 10
    }, {
      "subheader" : "to study ",
      "description" : "Outline a study schedule leading up to the exam.",
      "duration" : 2,
      "name" : "Plan",
      "preset" : 8
    }, {
      "subheader" : "shared learning schedule",
      "description" : "Meet regularly so everyone can teach their material to the group.",
      "duration" : 10,
      "name" : "Implement",
      "preset" : 9
    } ],
    "name" : "Study smarter as a group"
  } ]
}, {
  "name" : "Real Estate",
  "winflows" : [ {
    "description" : "Get everyone to agree and seal the deal every time with this step-by-step action plan.",
    "motions" : [ {
      "subheader" : "property information",
      "description" : "Collect all necessary disclosures, documents, and reports.",
      "duration" : 3,
      "name" : "Gather",
      "preset" : 10
    }, {
      "subheader" : "sales listing with client",
      "description" : "Review the listing with the client and fill in anything missing.",
      "duration" : 2,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "for any outside services",
      "description" : "Bring in any outside services that are required.",
      "duration" : 5,
      "name" : "Negotiate",
      "preset" : 7
    }, {
      "subheader" : "final sales listing",
      "description" : "Create the final sales package.",
      "duration" : 2,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "receipt of sales package",
      "description" : "Relevant internal and external parties confirm receipt of the package.",
      "duration" : 2,
      "name" : "Confirm",
      "preset" : 4
    }, {
      "subheader" : "offers with client",
      "description" : "Meet with client to review offers received.",
      "duration" : 10,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "escrow documents",
      "description" : "Create escrow documents for best offer.",
      "duration" : 3,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "escrow documents",
      "description" : "Client to approve the escrow documents.",
      "duration" : 2,
      "name" : "Approve",
      "preset" : 2
    }, {
      "subheader" : "the terms of the sale",
      "description" : "Send documents to relevant parties and negotiate the terms.",
      "duration" : 5,
      "name" : "Negotiate",
      "preset" : 7
    }, {
      "subheader" : "documents",
      "description" : "All parties approve and sign the documents.",
      "duration" : 5,
      "name" : "Sign",
      "preset" : 5
    }, {
      "subheader" : "receipt of final sale",
      "description" : "All relevant internal and external parties confirm receiving the final sales package.",
      "duration" : 2,
      "name" : "Confirm",
      "preset" : 4
    } ],
    "name" : "Close transaction on (property)"
  } ]
}, {
  "name" : "Mortgages",
  "winflows" : [ {
    "description" : "Get approved loans into the hands of your clients faster and easier with this step-by-step action plan.",
    "motions" : [ {
      "subheader" : "loan application information",
      "description" : "Collect the loan application from the client and all supporting information, documents, and reports.",
      "duration" : 2,
      "name" : "Gather",
      "preset" : 10
    }, {
      "subheader" : "loan application",
      "description" : "Client validates the information and fill in anything missing.",
      "duration" : 2,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "score and price of loan",
      "description" : "Internally score and price the loan.",
      "duration" : 2,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "the best deal ",
      "description" : "Shop the loan to providers to negotiate the best deal",
      "duration" : 5,
      "name" : "Negotiate",
      "preset" : 7
    }, {
      "subheader" : "final package with client",
      "description" : "Review the final package with the client.",
      "duration" : 2,
      "name" : "Review",
      "preset" : 1
    }, {
      "subheader" : "loan package",
      "description" : "Client to approve the loan package.",
      "duration" : 2,
      "name" : "Approve",
      "preset" : 2
    }, {
      "subheader" : "final loan documents",
      "description" : "Compile all necessary items for final loan transaction.",
      "duration" : 3,
      "name" : "Create",
      "preset" : 0
    }, {
      "subheader" : "and close loan package",
      "description" : "Client signs final package to close the loan.",
      "duration" : 5,
      "name" : "Sign",
      "preset" : 5
    }, {
      "subheader" : "receipt of the package",
      "description" : "All relevant internal and external parties confirm receiving package.",
      "duration" : 2,
      "name" : "Confirm",
      "preset" : 4
    } ],
    "name" : "Approved loan for (client)"
  } ]
} 
]

export default templates;