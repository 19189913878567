import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

// MATERIAL UI
import Icon from '@material-ui/core/Icon'

// Custom Components
import OrgSelector from '../NotificationSpace/OrgSelector'
// import Alerts from './Alerts'
import Comments from './Comments'
import Invitations from './Invitations'

import Alerts from '../../components/Alerts/AlertsHomeContainer'

// Utils
import { selectWorkspace, selectOrg } from '../../store/actions'

import members from '../../assets/images/stats-person.svg'
import statsFlag from '../../assets/images/stats-flag.svg'

import {
  getOrgInstanceActiveMissions,
  onOrgInstanceActiveMissionsUpdated,
  getOrgInstanceActiveMotions,
  onOrgInstanceActiveMotionsUpdated,
} from '../../utils/graphql-req'

class Home extends Component {
  constructor(props) {
    super(props)
    // this.state = {
    //   orgs: [],
    // };
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return prevState.orgs != nextProps.orgs ? { orgs: nextProps.orgs } : { orgs: prevState.orgs };
  // }

  handleMenuChange = (value) => {
    // if (value === "add-new") {
    //   this.props.showAddNewOrgDialog();
    // } else {
    const targetOrg = this.props.userOrgs.find((org) => org.id === value)
    // console.log('org in navbar', targetOrg)
    this.props.selectOrg(targetOrg)
    this.props.selectWorkspace('notifications')
    // }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   // console.log("this is next state", nextState);
  //   console.log("this is next props", nextProps.orgs);
  //   // console.log("this is state", this.state);
  //   console.log("this is props", this.props.orgs);
  //   console.log(nextProps.orgs === this.props.orgs);

  //   return !(_.isEqual(nextProps, this.props));
  // }

  // componentDidMount() {
  //   const { auth } = this.props;
  //   // let firstThreeOrgs = this.props.userOrgs.slice(0, 3);
  //   let orgData = [];
  //   this.props.userOrgs.forEach(async(org, index) => {
  //     const orgMissions = await getOrgInstanceActiveMissions(auth.uid, org.id);
  //     const missionIds = orgMissions.map((mission) => mission.id);
  //     const orgMotions = await getOrgInstanceActiveMotions(missionIds, org.id);

  //     let orgObj = {
  //       ...org,
  //       orgMissions: orgMissions,
  //       orgMotions: orgMotions,
  //     };
  //     orgData.push(orgObj)
  //     // console.log("org data after push", orgData)
  //   })
  // this.setState({ orgs: orgData, function () {
  //   console.log("the state in callabck", this.state); }})
  // }

  // componentDidUpdate(prevProps) {
  //     console.log("the previous props", prevProps.orgs);
  //     console.log("the current props", this.props.orgs);
  //     console.log(this.props.orgs === prevProps.orgs);

  //     if (prevProps.orgs !== this.props.orgs) {
  //       this.setState({ orgs: this.props.orgs })
  //     }

  // }

  render() {
    const { t } = this.props
    let orgDetails
    let sortedOrgs = this.props.orgs.sort(
      (a, b) =>
        b.orgMissions.length - a.orgMissions.length || b.allActiveMissions - a.allActiveMissions
    )
    if (this.props.userOrgs.length === 0 && this.props.orgs.length === 0 && !this.props.activeOrg) {
      orgDetails = (
        <div>
          <div className="home-org-wrapper">
            <div className="home-org-details">
              <div className="home-org-detail">
                <div
                  style={{
                    height: '100vh',
                    display: 'flex',
                    width: '25vw',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {t('home.no.org')}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      let firstThreeOrgs = sortedOrgs.slice(0, 3)

      let topMargin = -50

      orgDetails = firstThreeOrgs.map((orgData, index) => {
        topMargin += 50
        return (
          <div className="home-org-wrapper" key={index}>
            <div className="home-org-details">
              <div className="home-org-detail">
                {/* <div className="detail-notification"> */}
                <div className="home-logo-name">
                  <div
                    className="home-org-logo"
                    onClick={(event) => this.handleMenuChange(orgData.id)}
                  >
                    {orgData.logo ? (
                      <div className="companyLogo">
                        <img src={orgData.logo} alt="organization log" />
                      </div>
                    ) : (
                      <div className="companyLogo">{orgData.name && orgData.name.charAt(0)}</div>
                    )}
                  </div>
                  <div className="home-org-name">
                    <h2 style={{ overflow: 'hidden', height: '2.4em' }}>{orgData.name}</h2>
                    <div style={{ textAlign: 'left', lineHeight: '15px' }}>{orgData.whatWeDo}</div>
                  </div>
                </div>
                <div className="home-org-missions">
                  <div className="home-org-members">
                    <img
                      className="stats-flag"
                      src={statsFlag}
                      style={{ marginTop: '6px', marginLeft: '11px', width: '50px' }}
                    />
                    <div style={{ lineHeight: '42px', position: 'absolute' }}>
                      {orgData.orgMissions.length}
                    </div>
                    <div className="mission-text">{t('home.active.missions')}</div>
                  </div>
                  <div className="home-org-members">
                    <img className="mission-number" src={members} />
                    <div style={{ lineHeight: '42px', position: 'absolute' }}>
                      {orgData.members.length}
                    </div>
                    {/* </img> */}
                    <div className="mission-text">{t('home.missions.members')}</div>
                  </div>
                  <div className="home-org-members">
                    <img
                      className="stats-flag"
                      src={statsFlag}
                      style={{ marginTop: '6px', marginLeft: '11px', width: '50px' }}
                    />
                    <div style={{ lineHeight: '42px', position: 'absolute' }}>
                      {orgData.allActiveMissions}
                    </div>
                    <div className="mission-text">{t('home.all.active.missions')}</div>
                  </div>
                </div>
              </div>
              {/* <div className="home-notification-button">
                        <Icon
                          value={orgData.org.id}
                          onClick={(event) => this.handleMenuChange(orgData.org.id)}
                          style={{
                            // fontSize: 50,
                            color: "#3EC4E5",
                            backgroundColor: "white",
                            // borderRadius: "50%",
                            width: "26px",
                            height: "45px",
                            marginTop: topMargin + "px",
                            paddingTop: "11px",
                            marginLeft: "4px",
                            // zIndex: "10"
                            boxShadow: "2px 0px 0px 0px rgba(0, 0, 0, 0.1)",
                            borderRight: "1px solid darkgrey",
                            borderTop: "1px solid darkgrey",
                            borderBottom: "1px solid darkgrey",
                            borderRadius: "0% 10% 10% 0%",
                          }}
                        >
                          arrow_forward_ios
                        </Icon>
                      </div> */}
              {/* </div> */}

              <div style={{ height: '70%' }}>
                <Alerts
                  orgId={orgData.id}
                  orgMissions={orgData.orgMissions}
                  orgMotions={orgData.orgMotions}
                />
                <Comments
                  orgId={orgData.id}
                  orgMissions={orgData.orgMissions}
                  orgMotions={orgData.orgMotions}
                />
                <Invitations
                  orgId={orgData.id}
                  orgMissions={orgData.orgMissions}
                  orgMotions={orgData.orgMotions}
                />
              </div>
            </div>

            <div className="home-notification-button">
              <Icon
                value={orgData.id}
                onClick={(event) => this.handleMenuChange(orgData.id)}
                style={{
                  // fontSize: 50,
                  color: '#3EC4E5',
                  backgroundColor: 'white',
                  // borderRadius: "50%",
                  width: '26px',
                  height: '45px',
                  marginTop: topMargin + 'px',
                  paddingTop: '10px',
                  marginLeft: '4px',
                  // zIndex: "10"
                  boxShadow: '2px 0px 0px 0px rgba(0, 0, 0, 0.1)',
                  borderRight: '1px solid darkgrey',
                  borderTop: '1px solid darkgrey',
                  borderBottom: '1px solid darkgrey',
                  borderRadius: '0% 10% 10% 0%',
                  cursor: 'pointer',
                }}
              >
                arrow_forward_ios
              </Icon>
            </div>
          </div>
        )
      })
    }

    let home = (
      <div className="home-container">
        <div
          className="home-org"
          style={
            this.props.orgs.length > 2
              ? { width: '90%' }
              : this.props.orgs.length === 2
              ? { width: '60%' }
              : { width: '30%' }
          }
        >
          {orgDetails}
        </div>
        <div
          className="org-selector-container"
          style={
            this.props.orgs.length > 2
              ? { width: '10%' }
              : this.props.orgs.length === 2
              ? { width: '40%' }
              : { width: '70%' }
          }
        >
          <OrgSelector selectorOrgs={sortedOrgs.slice(3)} addNewOrg={this.props.addNewOrg} />
        </div>
      </div>
    )

    return home
    // } else {
    //   // console.log("loading");
    //   return <div></div>;
    // }
  }
}

const mapStateToProps = (state, ownProps) => {
  // let orgOwner = null;
  //   if (
  //     state.instance.org &&
  //     state.instance.org.owner &&
  //     state.instance.org.owner[0] &&
  //     state.database.orgInstanceUsers &&
  //     state.database.orgInstanceUsers[state.instance.org.owner[0]]){
  //       orgOwner = state.database.orgInstanceUsers[state.instance.org.owner[0]];
  //       orgOwner.id = state.instance.org.owner[0]
  //   }

  return {
    auth: state.firebase.auth,
    activeOrg: state.instance.org,
    // users: state.database.orgInstanceUsers,
    // orgOwner,
    // previewImage: state.instance.userProfileImagePreview,
    orgs: ownProps.orgs,
    userOrgs: state.database.userOrgs,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // selectMotion: (motion) => dispatch(selectMotion(motion)),
    // selectMission: (mission) => dispatch(selectMission(mission)),
    selectOrg: (orgId) => dispatch(selectOrg(orgId)),
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
    // deleteMission: mission => dispatch(deleteMission(mission)),
    // deleteAllMotions: motionIds => dispatch(deleteAllMotions(motionIds)),
    // deleteTemplate: templateId => dispatch(deleteTemplate(templateId)),
    // deleteTemplateMotions: motions => dispatch(deleteTemplateMotions(motions)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Home))
