// Libraries
import React, { Component, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment/min/moment-with-locales'

import { withTranslation } from 'react-i18next'

// Helpers
import { checkResponsible } from '../../utils/handleRoles'
import { getDays, quicksort } from './utils'

const momentLocales = {
  'en-GB': 'en-gb',
  'de-DE': 'de',
  'uk-UA': 'uk',
}

const formatLocales = {
  'en-GB': 'MMMM D',
  'de-DE': 'D. MMMM ',
  'uk-UA': 'D MMMM',
}

function Alerts({
  auth,
  userOrgs,
  orgId,
  selectOrg,
  activeMissions,
  activeMotions,
  selectMission,
  selectMotion,
  selectWorkspace,
  t,
  i18n,
}) {
  moment.locale(momentLocales[i18n.language])

  const handleClick = (mission, motion) => {
    if (orgId && userOrgs) {
      const targetOrg = userOrgs.find((org) => org.id === orgId)
      selectOrg(targetOrg)
    }
    selectMission(mission)
    selectMotion(motion)
    selectWorkspace('mission')
  }

  const checkAction = (motionMembers) => {
    return motionMembers.some((member) => {
      return member.uid === auth.uid && member.status === null && member.role !== 'requester'
    })
  }

  const sort = (motion, motionDates, sortMotions) => {
    let needAction = false
    motion.members.forEach((member) => {
      if (member.uid === auth.uid && checkResponsible(member.role)) needAction = true
    })
    if (motion.endDate && needAction) {
      const roundedCount = Math.ceil(getDays(motion.endDate))
      motionDates[motion.id] = roundedCount
      sortMotions.push(motion)
    }
  }

  const sortByDate = (activeMotions) => {
    let motionDates = {}
    let sortMotions = []

    if (Array.isArray(activeMotions)) {
      activeMotions.forEach((motion) => {
        sort(motion, motionDates, sortMotions)
      })
    } else {
      Object.values(activeMotions).forEach((mission) => {
        mission.forEach((motion) => {
          sort(motion, motionDates, sortMotions)
        })
      })
    }

    quicksort(sortMotions, 0, sortMotions.length - 1, motionDates)
    return sortMotions
  }

  const renderAlertComponents = () => {
    const alertComponents = []

    if (_.isEmpty(activeMotions) || _.isEmpty(activeMissions)) {
      alertComponents.push(
        <div className="emptystate" key="RandomFillerKey">
          <li>{t('common:alerts.emptystate')}</li>
        </div>
      )
    } else {
      let sortedMotions = sortByDate(activeMotions)

      sortedMotions.forEach((motion) => {
        const targetMission = activeMissions.find((mission) => mission.id === motion.mission)
        const action = checkAction(motion.members)
        const roundedCount = Math.ceil(getDays(motion.endDate))
        if (roundedCount < 11 && action) {
          //Mission Motion Due today
          if (roundedCount === 0) {
            alertComponents.push(
              <div key={motion.id}>
                <li className="alert">
                  {t(`motionPresets:${motion.name}`)} {t('alerts.in')}{' '}
                  <strong>
                    <a href={null} onClick={() => handleClick(targetMission, motion)}>
                      {targetMission.name}
                    </a>
                  </strong>{' '}
                  {t('alerts.dueToday')}{' '}
                  <img src={require('../../assets/images/overdue.svg')} alt="overdue" />
                </li>
              </div>
            )
          }
          //Mission Motion Overdue
          else if (roundedCount < 0) {
            alertComponents.push(
              <div key={motion.id}>
                <li className="alert overdue">
                  {t(`motionPresets:${motion.name}`)} {t('alerts.in')}{' '}
                  <strong>
                    <a href={null} onClick={() => handleClick(targetMission, motion)}>
                      {targetMission.name}
                    </a>
                  </strong>{' '}
                  {t('alerts.overdue')} {Math.abs(roundedCount)}{' '}
                  {Math.abs(roundedCount) === 1 ? t('alerts.dayOverdue') : t('alerts.daysOverdue')}{' '}
                  <img src={require('../../assets/images/overdue.svg')} alt="overdue" />
                </li>
              </div>
            )
          }
          //Mission Motion in the next 5 days
          else if (roundedCount < 6) {
            alertComponents.push(
              <div key={motion.id}>
                <li className="warning">
                  {/* 0 - 7 Days {Motion} in {Mission} by {Friday} day of week */}
                  {t(`motionPresets:${motion.name}`)} {t('alerts.in')}{' '}
                  <strong>
                    <a href={null} onClick={() => handleClick(targetMission, motion)}>
                      {targetMission.name}
                    </a>
                  </strong>{' '}
                  {t('common.label.by')}{' '}
                  {moment(new Date(Date.parse(motion.endDate))).format(
                    formatLocales[i18n.language]
                  )}{' '}
                  <img src={require('../../assets/images/attention.svg')} alt="attention" />
                </li>
              </div>
            )
          }
          //Mission Motion in the next 2 weeks
          else if (roundedCount < 11) {
            alertComponents.push(
              <div key={motion.id}>
                <li className="warning">
                  {/* LESS THAN 14 days {Motion} in {Mission} by {August 13}*/}
                  {t(`motionPresets:${motion.name}`)} {t('alerts.in')}{' '}
                  <strong>
                    {/* eslint-disable-next-line */}
                    <a href={null} onClick={() => handleClick(targetMission, motion)}>
                      {targetMission.name}
                    </a>
                  </strong>{' '}
                  {t('common.label.by')}{' '}
                  {moment(new Date(Date.parse(motion.endDate))).format(
                    formatLocales[i18n.language]
                  )}
                </li>
              </div>
            )
          }
        }
      })

      if (_.isEmpty(alertComponents)) {
        alertComponents.push(
          <div className="emptystate" key="RandomFillerKey2">
            <li>{t('common:alerts.emptystate')}</li>
          </div>
        )
      }
    }

    return alertComponents
  }

  return (
    <div className="notification-container">
      <div className="notification-item">
        <h3 className="action-header">{t('common:alerts.action.header')}</h3>
        <div className="line-div" />
        <ul className="notification-list-actions">{renderAlertComponents()}</ul>
      </div>
    </div>
  )
}

export default withTranslation(['common', 'motionPresets'])(Alerts)
