// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { withTranslation } from 'react-i18next';

// Material UI
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'

// Custom Components
import TemplateMissionModal from './TemplateMissionModel'

// Utils
import { selectMission, selectWorkspace } from '../../store/actions'

class TemplatesMenu extends Component {
  state = {
    showTemplateMissionModal: false,
    clickedTemplate: '',
  }

  toggleTemplateModal = (template) => {
    this.setState({
      clickedTemplate: template,
      showTemplateMissionModal: !this.state.showTemplateMissionModal,
      activeStep: 0,
    })
  }

  shortenedName = (name) => {
    let newName
    if (name.length > 28) {
      newName = name.slice(0, 13) + '...' + name.slice(name.length - 14)
      return newName
    } else {
      return name
    }
  }

  getTemplates() {
    const { templates, t } = this.props
    if (isEmpty(templates)) {
      return (
        <div className="emptystate">
          <li>{t('templatesMenu.emptystate')}</li>
        </div>
      )
    }

    // const templatesArr = templates ? Object.values(templates) : undefined;
    templates.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      else return 1
    })

    return templates.map((item, i) => {
      return (
        <li className="list_item" key={item + i}>
          {/*<span onClick={() => {this.duplicateTemplateWinflow(templatesArr[i])}}> */}
          <span onClick={() => this.toggleTemplateModal(templates[i])}>
            {this.shortenedName(templates[i].name)}{' '}
          </span>
          <div className="delete-draft-template-container">
            <Tooltip title={t('templatesMenu.tooltip.delete')} placement={'bottom'}>
              <Icon
                onClick={() => {
                  this.props.openDeleteTemplateDialog(templates[i])
                }}
                style={{ fontSize: 14, cursor: 'pointer' }}
                className="material-icons"
              >
                delete
              </Icon>
            </Tooltip>
          </div>
        </li>
      )
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="panel_section">
          <ul className="notification-list">{this.getTemplates()}</ul>
        </div>
        <TemplateMissionModal
          showTemplateMissionModal={this.state.showTemplateMissionModal}
          toggleTemplateModal={this.toggleTemplateModal}
          clickedTemplate={this.state.clickedTemplate}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    templates: state.database.orgInstanceMissionTemplates,
    activeOrg: state.instance.org,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectMission: (missionId) => dispatch(selectMission(missionId)),
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(TemplatesMenu))
