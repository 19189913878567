import firebase from "firebase/app";
import "firebase/analytics";
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';

const getEnvEndpoint = () => {
  switch(process.env.REACT_APP_ENV){
    case "development": {
      return "http://localhost:4000";
    }
    case "testing": {
      return "https://backend.dev.hi-flier.com";
    }
    case "non-prod-gcp": {
      return "https://backend.dev.hi-flier.com";
    }
    case "stage": {
      return "https://stage-mcsaleipkq-ew.a.run.app";
    }
    case "production": {
      return "https://backend-qujhf7yzla-ew.a.run.app";
    }
    default: {
      return "http://localhost:4000";
    }
  }
}

const getConfig = () => {
    switch (process.env.REACT_APP_ENV) {
      case "development": {
        return {
            apiKey: "AIzaSyBxgp1glRmtfQ0tcRaEDkTqHB90Pk9mx1w",
            authDomain: "hi-flier-platform-non-prod.firebaseapp.com",
            //databaseURL: "https://advance-medium-170519.firebaseio.com",
            projectId: "hi-flier-platform-non-prod",
            storageBucket: "hi-flier-platform-non-prod.appspot.com",
            messagingSenderId: "666457595312",
            appId: "1:666457595312:web:b42c7bbf418c5b06258a75",
            measurementId: "G-N1W0MCBY90"
        };
      }
      case "production": {
        //   THIS IS CURRENTLY THE SAME AS DEV, NEED TO CHANGE
        return {
            apiKey: "AIzaSyDqy3cMYPpz5U01WG0WtvJpSs3BFrgDXe8",
            authDomain: "hi-flier-platform-prod.firebaseapp.com",
            //databaseURL: "https://advance-medium-170519.firebaseio.com",
            projectId: "hi-flier-platform-prod",
            storageBucket: "hi-flier-platform-prod.appspot.com",
            messagingSenderId: "632460170241",
            appId: "1:632460170241:web:fbe5c4c43c2f046284dc00",
            measurementId: "G-RVG4P8WFZ0"            
        };
      }
      case "non-prod-gcp": {
        //   THIS IS CURRENTLY THE SAME AS DEV, NEED TO CHANGE
        return {
            apiKey: "AIzaSyBxgp1glRmtfQ0tcRaEDkTqHB90Pk9mx1w",
            authDomain: "hi-flier-platform-non-prod.firebaseapp.com",
            //databaseURL: "https://advance-medium-170519.firebaseio.com",
            projectId: "hi-flier-platform-non-prod",
            storageBucket: "hi-flier-platform-non-prod.appspot.com",
            messagingSenderId: "666457595312",
            appId: "1:666457595312:web:b42c7bbf418c5b06258a75",
            measurementId: "G-N1W0MCBY90"            
        };
      }
      default: {
        // Default is the dev environment
        return {
          apiKey: "AIzaSyBxgp1glRmtfQ0tcRaEDkTqHB90Pk9mx1w",
          authDomain: "hi-flier-platform-non-prod.firebaseapp.com",
          //databaseURL: "https://advance-medium-170519.firebaseio.com",
          projectId: "hi-flier-platform-non-prod",
          storageBucket: "hi-flier-platform-non-prod.appspot.com",
          messagingSenderId: "666457595312",
          appId: "1:666457595312:web:b42c7bbf418c5b06258a75",
          measurementId: "G-N1W0MCBY90"
        };
      }
    }
  };

// const config = {
//     apiKey: "AIzaSyCkAFChlb3kMEl0Xtir7nP2e3v5YqjOMEU",
//     authDomain: "advance-medium-170519.firebaseapp.com",
//     //databaseURL: "https://advance-medium-170519.firebaseio.com",
//     projectId: "advance-medium-170519",
//     storageBucket: "advance-medium-170519.appspot.com",
//     messagingSenderId: "1054181492862"
// };
// export const envCode = getEnvCode(environment);

export const envEndpoint = getEnvEndpoint();

const config = getConfig();
firebase.initializeApp(config);
firebase.analytics();
firebase.firestore();

export const auth = firebase.auth;
export const firestore = firebase.firestore;
export const storage = firebase.storage;
export default firebase;