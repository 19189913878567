import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

const styles = {
    dialogPaper: {
        minHeight: '20vh',
        maxHeight: '80vh',
    }
};

const SmallModal = props => {
  const { classes } = props;

  return (
    <Dialog 
    classes={{ paper: classes.dialogPaper }}
      className={classes.dialogPaper} 
      aria-labelledby={props.dialog}
      fullWidth={true} 
      maxWidth="xs"
      open={props.open}
    >
      {props.children}
    </Dialog>
  );
};

SmallModal.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SmallModal);