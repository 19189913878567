import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Droppable } from 'react-beautiful-dnd'
import _ from 'lodash'
import { withTranslation } from 'react-i18next';

// Material UI
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

// Helpers
import { updateWorkItem, addLog } from '../../utils/graphql-req'
// import Motion from ".";

class Document extends Component {
  state = {
    workItem: null,
    showDocMenu: false,
    docMenuState: false,
    dialogDeleteDocument: false,
    anchorEl: null,
  }

  getWorkItemIcon = () => {
    const { passedMotion, active } = this.props
    let icon = null
    let iconSrc = null

    if (passedMotion.status === 'complete') {
      switch (passedMotion.name) {
        case 'Create':
          iconSrc = 'draft.png'
          break
        case 'Review':
          iconSrc = 'reviewed.png'
          break
        case 'Approve':
          iconSrc = 'approved.png'
          break
        case 'Pass Gate':
          iconSrc = 'approved.png'
          break
        case 'Inform':
          iconSrc = 'seen.png'
          break
        case 'Sign':
          iconSrc = 'signed.png'
          break
        case 'Individual Action':
          iconSrc = 'done.png'
          break
        case 'Negotiate':
          iconSrc = 'reviewed.png'
          break
        case 'Form Action Plan':
          iconSrc = 'action.png'
          break
        case 'Implement Action Plan':
          iconSrc = 'inaction.png'
          break
        case 'Gather Facts':
          iconSrc = 'filled.png'
          break
        case 'Pay':
          iconSrc = 'pay.png'
          break
        default:
          iconSrc = 'new.png'
      }
      icon = (
        <img
          className={`document-icon ${active ? '' : 'mini-icon'}`}
          src={require(`../../assets/images/icons/${iconSrc}`)}
          alt="icon"
        />
      )
    }
    /*
    else if (passedMotion.status === 'rejected'){
        iconSrc = 'rejected.png'
        icon = <img
                    className={`document-icon ${active ?'':'mini-icon'}`}
                    src={require(`../../assets/images/icons/${iconSrc}`)}
                    alt='icon'
                />
    }
    */
    return icon
  }

  openWorkItem = (url) => {
    this.setState({ docMenuState: false })

    //************
    // TODO: Update Log
    //************
    // const { orgId, auth, users, passedMotion, missionId } = this.props;
    // const currentUser = users[auth.uid];
    // const displayName = currentUser.fullName
    //   ? currentUser.fullName
    //   : currentUser.email;
    // const logText = `${displayName} opened a business item in the passedMotion: ${
    //   passedMotion.motionName
    // }`;
    // API.updateWinflowLog(orgId, missionId, logText);
    // //open document

    window.open(url, '_blank')
  }

  handleWorkItemClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, docMenuState: true })
  }

  handleWorkItemDelete = async () => {
    const { userDetails, activeMission, activeMotion } = this.props
    const updatedWorkItem = { ...this.state.workItem }
    const targetIndex = updatedWorkItem.motion.findIndex(
      (motion) => motion === this.props.activeMotion.id
    )
    if (targetIndex !== -1) {
      updatedWorkItem.motion.splice(targetIndex, 1)
      if (updatedWorkItem['__typename']) delete updatedWorkItem['__typename']

      console.log(this.props)

      if (activeMission.status === 'active') {
        var actionUser = userDetails.fullName ? userDetails.fullName : userDetails.email
        var logText = `${actionUser} removed ${updatedWorkItem.name} from ${activeMotion.name} ${activeMotion.subheader}`

        const log = {
          mission: activeMission.id,
          date: Date().toString(),
          text: logText,
        }

        await addLog(log)
      }

      // push to db
      await updateWorkItem(updatedWorkItem)
    }

    this.setState({ dialogDeleteDocument: false, workItem: null })
  }

  shortenedDocName = (name) => {
    let newName
    if (name.length > 28) {
      newName = name.slice(0, 13) + '...' + name.slice(name.length - 14)
      return newName
    } else {
      return name
    }
  }

  render() {
    const {
      passedMotion,
      grid,
      active,
      activeMotion,
      activeMission,
      workspace,
      workItems,
      commentCount,
      auth,
      t,
    } = this.props

    workItems.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

    // set business items display amount
    let workItemsArr = null
    if (!workItems) workItemsArr = []
    else workItemsArr = workItems ? Object.keys(workItems) : undefined

    const getMenu = () => {
      // if business items is empty, return early
      if (!workItems) return <MenuItem disabled>{t('document.no.items')}</MenuItem>

      if (
        workspace === 'complete' ||
        passedMotion.status === 'complete' ||
        passedMotion.status === 'cancelled' ||
        activeMission.creator !== auth.uid
      ) {
        return Object.values(workItems)
          .reverse()
          .map((workItem, index) => [
            <MenuItem disabled>{workItem.name}</MenuItem>,
            <Divider />,
            <MenuItem onClick={() => this.openWorkItem(workItem.link)}>{t('common.button.open')}</MenuItem>,
            <MenuItem
              onClick={() => {
                this.props.toggleWorkItemDetails(workItem)
                this.setState({ docMenuState: false })
              }}
            >
              {t('document.see.details')}
            </MenuItem>,
          ])
      } else {
        return Object.values(workItems)
          .reverse()
          .map((workItem, index) => [
            <MenuItem disabled>{this.shortenedDocName(workItem.name)}</MenuItem>,
            <Divider />,
            <MenuItem onClick={() => this.openWorkItem(workItem.link)}>{t('common.button.open')}</MenuItem>,
            <MenuItem
              onClick={() => {
                this.props.toggleWorkItemDetails(workItem)
                this.setState({ docMenuState: false })
              }}
            >
              {t('document.see.details')}
            </MenuItem>,
            <MenuItem
              onClick={() => {
                this.setState({
                  docMenuState: false,
                  dialogDeleteDocument: true,
                  workItem: workItem,
                })
              }}
            >
              {t('common.button.remove')}
            </MenuItem>,
          ])
      }
    }

    return (
      <div className={`grid${grid}`} key={grid}>
        <Dialog
          open={this.state.dialogDeleteDocument}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t('document.remove.item')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('document.dialog.description')}{' '}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ dialogDeleteDocument: false })} color="primary">
              {t('common.button.cancel')}
            </Button>
            <Button onClick={this.handleWorkItemDelete} color="primary" autoFocus>
              {t('common.button.remove')}
            </Button>
          </DialogActions>
        </Dialog>

        {active && (
          <div
            onClick={this.props.toggleMotionDescription}
            className={
              passedMotion.motionStatus === 'complete'
                ? 'instructionsComplete document comments-box'
                : 'document comments-box'
            }
          >
            {t('common.instructions')}
          </div>
        )}

        {!active && (
          <div
            className={`document 
            ${active ? 'active' : 'mini-document'} 
            ${passedMotion.motionStatus === 'rejected' ? 'rejected ' : ''} 
            ${workItemsArr ? '' : 'noCursor '} 
            ${passedMotion.motionStatus === 'complete' ? 'document-completed' : ''}`}
          >
            {this.getWorkItemIcon()}
          </div>
        )}

        {active && (
          <Droppable
            droppableId={`${activeMission.id}/${activeMotion.id}`}
            type="DOC"
            direction="horizontal"
            isDropDisabled={!active}
          >
            {(provided, snapshot) => (
              <div
                className={`document
                ${active ? 'active' : 'mini-document'} 
                ${passedMotion.status === 'rejected' ? 'rejected ' : ''} 
                ${workItemsArr ? '' : 'noCursor '} 
                ${
                  passedMotion.motionStatus === 'complete'
                    ? 'instructionsComplete document-completed '
                    : ''
                } 
                ${snapshot.isDraggingOver && 'isDragging'}`}
                ref={provided.innerRef}
                onClick={this.handleWorkItemClick}
              >
                <div
                  style={
                    passedMotion.motionStatus === 'complete'
                      ? { color: 'black' }
                      : { color: 'white' }
                  }
                  className={`artifact-text numeric-text  ${active ? 'active' : ''}`}
                >
                  {workItemsArr ? workItemsArr.length : 0}
                  <div
                    className="workitem-text "
                    style={
                      passedMotion.motionStatus === 'complete'
                        ? { color: 'black' }
                        : { color: 'white' }
                    }
                  >
                    <br />
                    {t('common.business.item')}
                  </div>
                </div>
                {this.getWorkItemIcon()}
              </div>
            )}
          </Droppable>
        )}

        {active && (
          <div
            onClick={this.props.toggleMotionComments}
            className={
              passedMotion.motionStatus === 'complete'
                ? 'instructionsComplete document comments-box bottom-box'
                : 'document comments-box bottom-box'
            }
          >
            {t('common.comments')}
            {commentCount && <div className="comments-badge">{commentCount}</div>}
          </div>
        )}

        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.docMenuState}
          onClose={() => this.setState({ docMenuState: false })}
          PaperProps={{ style: { maxHeight: 48 * 5 } }}
        >
          {getMenu()}
        </Menu>
      </div>
    )
  }
}

const mapStateToProps = (state, passed) => {
  const { passedMotion } = passed

  let workItems = []
  if (!_.isEmpty(state.database.workItems)) {
    state.database.workItems.forEach((workItem) => {
      if (workItem.motion.findIndex((motion) => motion === passedMotion.id) !== -1)
        workItems.push(workItem)
    })
  }

  let commentCount = null
  if (passedMotion && passedMotion.comments && !_.isEmpty(passedMotion.comments)) {
    Object.values(passedMotion.comments).forEach((comment) => {
      if (comment && !comment.notification.includes(state.firebase.auth.uid)) {
        if (commentCount) commentCount++
        else commentCount = 1
      }
    })
  }

  return {
    auth: state.firebase.auth,
    activeMission: state.instance.mission,
    activeMotion: state.instance.motion,
    userDetails: state.database.userDetails,
    activeOrg: state.instance.org,
    commentCount,
    workItems,
  }
}

export default connect(mapStateToProps, null)(withTranslation('common')(Document))
