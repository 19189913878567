// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Droppable } from 'react-beautiful-dnd'
import Lottie from 'react-lottie'
import animationDataHFman from '../../assets/images/H-flier-man.json'
import _, { get } from 'lodash'
import { withTranslation } from 'react-i18next';

// Material UI
import Icon from '@material-ui/core/Icon'

// Local Components
import MissionMeta from '../MissionMeta'
import Motion from '../Motion'
import SideDrawerLog from '../SideDrawerLog'
import SideDrawerRepeat from '../SideDrawerRepeat'
import MissionStart from '../MissionStart'

// Helpers
import { selectMission, selectMotion } from '../../store/actions'
import { userRoles } from '../../utils/handleRoles'
import animationData from '../../assets/images/3152-star-success.json'

const isRoleAccountable = (role) => get(userRoles, [role, 'roleGroup'], '') === 'accountable'

class CompleteSpace extends Component {
  state = {
    showSideDrawerLog: false,
    showNoCompletedMissions: false,
    foundCompletedMission: false,
  }

  motionComponents = []

  handleSideDrawersToggle = (drawerName) => {
    if (drawerName === 'log') {
      this.setState({ showSideDrawerLog: !this.state.showSideDrawerLog })
    } else if (drawerName === 'repeat') {
      this.setState({ showSideDrawerRepeat: !this.state.showSideDrawerRepeat })
    }
  }

  componentDidMount() {
    const { missions, activeMission } = this.props

    //*********
    // This solves the problem of not having an active mission selected
    //*********
    if (_.isEmpty(activeMission) && missions.length > 0) this.props.selectMission(missions[0])

    //*********
    // This solves the problem of selecting an 'active mission' missionId
    // when the currently missionId is a 'draft mission', for cases when
    // the user is switching from draft mission component to active mission
    // component
    //*********
    if (activeMission && missions.findIndex((mission) => mission.id === activeMission.id) === -1) {
      this.props.selectMission(missions[0])
    }
  }

  componentDidUpdate() {
    const { missions, activeMission } = this.props

    //*********
    // This solves the problem of not having an active mission selected
    //*********
    if (_.isEmpty(activeMission) && missions.length > 0) this.props.selectMission(missions[0])

    //*********
    // This solves the problem of selecting an 'active mission' missionId
    // when the currently missionId is a 'draft mission', for cases when
    // the user is switching from draft mission component to active mission
    // component
    //*********
    if (activeMission && missions.findIndex((mission) => mission.id === activeMission.id) === -1) {
      this.props.selectMission(missions[0])
    }
  }

  getMotionComponents = () => {
    const { activeMission, activeMotion, motions, uid } = this.props
    // console.log("active mission", activeMission.status)
    // console.log(activeMission);

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }
    // console.log("motion props", motions)
    if (this.props.motions && this.props.motions[this.props.activeMission.id]) {
      // Setup needed variables
      const motionsForThisMission = motions[activeMission.id]
      // console.log("motions for this mission", motionsForThisMission)
      if (!_.isEmpty(motionsForThisMission) && activeMission) {
        const isOwner = activeMission.creator === uid
        let activeMotionSet = false
        if (activeMotion && activeMotion.id) {
          motionsForThisMission.forEach((motion) => {
            if (motion.id === activeMotion.id) activeMotionSet = true
          })
        }

        this.motionComponents = []
        // Create a list of motionComponents based on order
        motionsForThisMission
          .sort((a, b) => a.index - b.index)
          .forEach((motion) => {
            if (motion.motionStatus && motion.org && motion.mission) {
              if (!activeMotionSet) {
                const userInMotion =
                  motion.members &&
                  motion.members[uid] &&
                  (isRoleAccountable(motion.members[uid].role) || isOwner)
                // const userInMotion = motion.members && motion.members.find(member => member.uid === uid  && isRoleAccountable(member.role) || isOwner)
                if (!motion.private || userInMotion) {
                  activeMotionSet = true
                  this.props.selectMotion(motion)
                }
              }
              this.motionComponents.push(<Motion passedMotion={motion} key={motion.id} />)
            }
          })
        // console.log(activeMission)
        if (activeMission.status === 'complete') {
          this.motionComponents.push(
            <div key="motion component">
              <div className="star-graphic microflowWrapper1">
                <Lottie options={defaultOptions} />
                <img src={require('../../assets/images/flag-gold.svg')} alt="flag" />
              </div>
            </div>
          )
        } else {
          this.motionComponents.push(
            <div key="motion component">
              <div className="star-graphic microflowWrapper1">
                {/* <Lottie options={defaultOptions} /> */}
                <img src={require('../../assets/images/flag-grey.svg')} alt="flag" />
              </div>
            </div>
          )
        }
      }
    }
  }

  render() {
    const { activeMission, motions, t } = this.props
    const defaultOptionsHFman = {
      loop: true,
      autoplay: true,
      animationData: animationDataHFman,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }

    if (_.isEmpty(activeMission) || _.isEmpty(motions)) {
      return (
        <div className={'emptystate'}>
          <div>
            <p style={{ width: '30vw' }}>
              {t('completeSpace.emptystate.p')}
            </p>
          </div>
        </div>
      )
    }

    this.getMotionComponents()

    return (
      <div className="winflow">
        <Droppable
          droppableId={activeMission.id}
          direction="horizontal"
          type="MOTION"
          isDropDisabled={
            activeMission.status === 'complete' || activeMission.status === 'cancelled'
          }
        >
          {(provided) => (
            <div className="workspace-canvas" ref={provided.innerRef}>
              <div className="microflowsWrapper">
                <MissionStart />
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  {this.motionComponents}
                </div>
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <MissionMeta />

        <SideDrawerLog
          showSideDrawerLog={this.state.showSideDrawerLog}
          handleSideDrawersToggle={this.handleSideDrawersToggle}
        />

        <SideDrawerRepeat
          showSideDrawerRepeat={this.state.showSideDrawerRepeat}
          handleSideDrawersToggle={this.handleSideDrawersToggle}
        />

        <div className="edit-button-container">
          <div onClick={() => this.handleSideDrawersToggle('log')} className="edit-button">
            <Icon key={2} className="material-icons small-space">
              chevron_left
            </Icon>
            <div className="edit-button-text">{t('common.button.logbook')}</div>
          </div>

          <div onClick={() => this.handleSideDrawersToggle('repeat')} className="edit-button">
            <Icon key={2} className="material-icons small-space">
              chevron_left
            </Icon>
            <div className="edit-button-text">{t('common.button.autopilot')}</div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, passed) => {
  return {
    auth: state.firebase.auth,
    activeOrg: state.instance.org,
    activeMission: state.instance.mission,
    activeMotion: state.instance.motion,
    missions: state.database.orgInstanceCompleteMissions,
    motions: state.database.orgInstanceCompleteMotions,
  }
}

const mapDispatchToProps = (dispatch) => ({
  selectMotion: (motionId) => dispatch(selectMotion(motionId)),
  selectMission: (missionId) => dispatch(selectMission(missionId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(CompleteSpace))
