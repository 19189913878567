// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

// Material UI
// import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

// Custom Material UI Components
import StyledDialogTitle from '../../components/StyledDialogTitle'
import StyledDialogContentText from '../../components/StyledDialogContentText'
import StyledTextField from '../../components/StyledTextField'
import MainButton from '../../components/Buttons/MainButton'
import SecondaryButton from '../../components/Buttons/SecondaryButton'

// Utils
import { hideAddNewOrgDialog, selectWorkspace, selectOrg } from '../../store/actions'
import { addOrg } from '../../utils/graphql-req'

//Medium Modal
import MediumModal from '../../components/Modals/MediumModal'

class AddNewOrgDialog extends Component {
  state = {
    newOrgName: '',
    error: undefined,
  }

  handleAddNewOrgNameChange = (event) => {
    this.setState({ newOrgName: event.target.value })
  }

  addOrganization = async () => {
    const { auth, t } = this.props
    const { newOrgName } = this.state

    // if newOrgName is empty, show error and exit early
    if (!newOrgName) {
      this.setState({ error: t('org.dialog.error.name') })
      return false
    }

    if (newOrgName.length > 41) {
      this.setState({
        error: t('addNewOrgDialog.length.error', { length: newOrgName.length }),
      })
      return false
    }

    // create new org
    const newOrg = {
      logo: '',
      creator: auth.uid,
      name: newOrgName,
      status: 'active',
      members: [auth.uid],
      owner: [auth.uid],
      whatWeDo: '',
      linkedIn: '',
      website: '',
    }

    // push to database
    const org = await addOrg(newOrg)

    //cleanup
    this.setState({ error: undefined })
    this.props.addNewOrg(org)
    this.props.selectOrg(org)
    this.props.selectWorkspace('notifications')
    this.props.hideAddNewOrgDialog()
  }

  render() {
    const { error } = this.state
    const { t } = this.props

    return (
      <MediumModal open={this.props.showAddNewOrgDialog} dialog="invite-dialog">
        <StyledDialogTitle>{t('org.dialog.title')}</StyledDialogTitle>

        <DialogContent>
          <StyledTextField
            label={t('org.dialog.label')}
            type="text"
            autoFocus={true}
            maxLength={30}
            onChange={(event) => this.handleAddNewOrgNameChange(event)}
          />
          <StyledDialogContentText>{error}</StyledDialogContentText>
        </DialogContent>

        <DialogActions>
          <SecondaryButton onClick={this.props.hideAddNewOrgDialog}>
            {t('common.button.cancel')}
          </SecondaryButton>
          <MainButton disabled={!this.state.newOrgName} onClick={this.addOrganization}>
            {t('common.button.add')}
          </MainButton>
        </DialogActions>
      </MediumModal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    showAddNewOrgDialog: state.dialogs.showAddNewOrgDialog,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectOrg: (orgId) => dispatch(selectOrg(orgId)),
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
    hideAddNewOrgDialog: () => dispatch(hideAddNewOrgDialog()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('common')(AddNewOrgDialog))
