// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'

//Material UI
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const styles = {
  inputLabel: {
    color: '#777880',
  },
  formLabelRoot: {
    '&:formLabelFocused': { color: 'rgb(0,72,132)' },
  },
  formLabelFocused: {},
  input: {
    '&:after': { borderBottom: '2px solid #333541' },
  },
  '@global': {
    '.MuiFormLabel-root.Mui-focused': {
      color: '#333541',
    },
  },
}

const StyledTextField = (props) => {
  const { classes } = props

  return (
    <TextField
      id="standard-name"
      InputLabelProps={{
        classes: { root: classes.inputLabel },
        // FormLabelClasses: {
        //   root: classes.formLabelRoot,
        //   focused: classes.formLabelFocused
        // }
      }}
      InputProps={{
        classes: { underline: classes.input },
      }}
      inputProps={{ maxLength: props.maxLength }}
      className={props.className}
      autoFocus={props.autoFocus}
      margin="dense"
      label={props.label}
      type={props.type}
      value={props.value}
      defaultValue={props.defaultValue}
      onKeyPress={props.onKeyPress ? props.onKeyPress : null}
      fullWidth
      onChange={props.onChange}
      // autoFocus={props.autoFocus}
    />
  )
}

StyledTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(Radium(StyledTextField))
