import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

const styles = {
  statusButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  text: {
    position: 'absolute',
    right: '28px',
    fontSize: '12px',
    width: '80px',
    overflowWrap: 'normal',
    color: 'black',
    whiteSpace: 'break-spaces',
  },
}

const StatusButton = ({ classes, text, img, onClick, disabled }) => {
  return (
    <IconButton
      className={classes.statusButton}
      onClick={onClick}
      disabled={disabled}
      disableRipple={true}
      disableFocusRipple={true}
      edge={'start'}
      value={'hello'}
    >
      <img src={img} />
      <div className={classes.text}>{text}</div>
    </IconButton>
  )
}

export default withStyles(styles)(StatusButton)
