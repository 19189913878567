
import * as actionTypes from "../actionTypes";
// import _ from 'lodash';

// import { updateOrg } from "../../utils/graphql-req";
// import { envEndpoint } from '../../utils/firebase-service';

// export const signUp = credentials => {
//     return (dispatch, getState, { getFirebase, getFirestore }) => {
//         const firebase = getFirebase();

//         // add user to firebase auth
//         return firebase.auth().createUserWithEmailAndPassword(credentials.email, credentials.password)
//             .then(async result => {
//                 // handle signup in the backend
//                 const response = await fetch(`${envEndpoint}/auth/signup`, {
//                     method: 'POST',
//                     headers: { 'Content-Type': 'application/json' },
//                     body: JSON.stringify({ 
//                         email: result.user.email,
//                         user: result.user,
//                     })
//                 })

//                 const { user } = await response.json();

//                 console.log(user);
//                 if(user) window.location.reload(true);



//                 // // check to see if user has a dummy user profile
//                 // const response = await fetch(`${envEndpoint}/auth/getPlaceholderUsers`, {
//                 //     method: 'POST',
//                 //     headers: { 'Content-Type': 'application/json' },
//                 //     body: JSON.stringify({ email: result.user.email })
//                 // })

//                 // // abstract user data from the response
//                 // const { user } = await response.json();

//                 // // create a user profile that will replace dummy user profile
//                 // let updatedUser = {
//                 //     email: result.user.email,
//                 //     fullName: "",
//                 //     avatar: "",
//                 //     whatIDo: "",
//                 //     phoneNumber: "",
//                 //     invitations: [],
//                 //     userOnboarded: false,
//                 // };

//                 // // if a dummy user exist for that email, extract
//                 // // data from the dummy user and place it into
//                 // // the updated user profile
//                 // if(user){
//                 //     updatedUser = _.cloneDeep(user);
//                 //     updatedUser.email = result.user.email;
//                 //     updatedUser.fullName = "";
//                 //     updatedUser.avatar = "";
//                 //     updatedUser.whatIDo = "";
//                 //     updatedUser.phoneNumber = "";
//                 //     updatedUser.userOnboarded = false;
//                 // }
                              
//                 // update associated orgs, missions, and motions
//                 // TODO: updated mission & motions
//                 // if (updatedUser.invitations) {
//                 //     updatedUser.invitations.forEach(async orgInvitation => {
//                 //         const response = await fetch(`${envEndpoint}/orgs/fetchOrg`, {
//                 //             method: 'POST',
//                 //             headers: { 'Content-Type': 'application/json' },
//                 //             body: JSON.stringify({ orgId: orgInvitation.org.id })
//                 //         })
                        
//                 //         if (response.status !== 200 && response.status !== 201) {
//                 //             console.log('Error!');
//                 //             throw new Error('Something Went Wrong!');
//                 //         }
                        
//                 //         const { org } = await response.json();
//                 //         const updatedOrg = _.cloneDeep(org);
//                 //         updatedOrg.members.push(result.user.uid);
//                 //         delete updatedOrg["__typename"];
//                 //         await updateOrg(updatedOrg);
//                 //     })
//                 // }

//                 // await fetch(`${envEndpoint}/auth/createUser`, {
//                 //     method: 'POST',
//                 //     headers: { 'Content-Type': 'application/json' },
//                 //     body: JSON.stringify({ id: result.user.uid, user: updatedUser })
//                 // })

//                 dispatch(signInSuccess())
//                 return { code: "ok", message: "Successfully Signed In"}
//             })
//             .catch(error => {
//                 dispatch(signInError(error))
//                 return error;
//             })
//     }
// }

export const signIn = (credentials) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        return firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(result => {
            dispatch(signInSuccess())
            return { code: "ok", message: "Successfully Signed In"}
        }).catch(err => {
            dispatch(signInError(err))
            // console.log(err)
            if (err.code === "auth/invalid-email") {
                return { message: "Please enter a valid email address." }
            } else {
                return err;
            }
        })
    }
}

export const signOut = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        await firebase.auth().signOut();
        dispatch(signOutSuccess())
        dispatch(clearStore())
        dispatch(clearInstance())
    }
}

export const passwordReset = (code, newPassword) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        return firebase.auth().confirmPasswordReset(
            code,
            newPassword
        ).then(result => {
            // dispatch(signInSuccess())
            return { code: "ok", message: "Successfully Changed Password! Redirecting to Login."}
        }).catch(err => {
            return err;
        })
    }
}

export const isPasswordReset = (payload) => {
    return {
        type: actionTypes.IS_RESET_PASSWORD,
        payload
    }
}

// Local Functions
const signInSuccess = () => {
    return {
        type: actionTypes.LOGIN_SUCCESS
    }
}

const signInError = (err) => {
    return {
        type: actionTypes.LOGIN_ERROR, 
        payload: err
    }
}

const signOutSuccess = () => {
    return {
        type: actionTypes.LOGOUT_SUCCESS
    }
}

const clearStore = () => {
    return {
        type: actionTypes.CLEAR_STORE
    }
}

const clearInstance = () => {
    return {
        type: actionTypes.CLEAR_INSTANCE_DATA
    }
}