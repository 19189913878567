import React from 'react'
import DropboxChooser from 'react-dropbox-chooser'
import { withTranslation } from 'react-i18next'

import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

import dropboxIcon from '../../assets/images/dropbox.jpg'

const DROPBOX_APP_KEY = 'crmlc09am0wcnv5'

const styles = (theme) => ({
  dropboxlogos: {
    cursor: 'pointer',
  },
  sm: {
    width: '44px',
  },
  md: {
    width: '62px',
  },
})

function DropboxPicker({ size = 'md', classes, onSuccess, t }) {
  return (
    <DropboxChooser
      appKey={DROPBOX_APP_KEY}
      multiselect={false}
      folderselect={true}
      success={onSuccess}
    >
      <Tooltip title={t('workItemSubmenu.dropbox')} placement={'bottom'}>
        <div className={classes.dropboxlogos}>
          <img src={dropboxIcon} alt="require" className={classes[size]} />
        </div>
      </Tooltip>
    </DropboxChooser>
  )
}

export default withStyles(styles)(withTranslation('common')(DropboxPicker))
