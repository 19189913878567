// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { envEndpoint } from '../../utils/firebase-service'
import { withTranslation } from 'react-i18next'

// Material UI
import Snackbar from '@material-ui/core/Snackbar'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

// Custom Components
import MainButton from '../../components/Buttons/MainButton'
import SecondaryButton from '../../components/Buttons/SecondaryButton'

//Small Modal
import SmallModal from '../../components/Modals/SmallModal'

// Helpers
import * as actions from '../../store/actions'

const style = {
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0px 100px 25px 100px',
    minWidth: '200px',
  },
}

class ForgotPasswordProfile extends Component {
  state = {
    errorMessage: '',
    resetSuccess: false,
    error: false,
  }

  sendEmail = async () => {
    const { user, t } = this.props
    if (user.email) {
      try {
        await fetch(`${envEndpoint}/auth/resetPassword`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: user.email,
          }),
        })
        this.props.isPasswordReset(true)
        this.setState({ resetSuccess: true })
        this.props.signOut()
      } catch (err) {
        // This code may need changes in the future with a generic error message to increase security
        switch (err.code) {
          case 'auth/invalid-email':
            this.setState({ errorMessage: t('forgotPassword.invalidEmail'), error: true })
            return
          case 'auth/user-not-found':
            this.setState({ errorMessage: t('forgotPassword.userDontExist'), error: true })
            return
          default:
            this.setState({ errorMessage: t('forgotPassword.Error'), error: true })
        }
      }
    }
  }

  handleClose = () => {
    this.setState({
      resetSuccess: false,
      error: false,
    })
  }

  render() {
    const { t } = this.props

    return (
      <SmallModal
        open={this.props.passwordResetModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('forgotPassword.passwordReset')}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('forgotPassword.reset.description')}
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ display: 'flex' }}>
          <SecondaryButton onClick={() => this.props.hidePasswordResetModal()} width={500}>
            Back
          </SecondaryButton>

          <MainButton
            onClick={() => {
              this.sendEmail()
            }}
            variant="contained"
            style={style.button}
          >
            {t('forgotPassword.reset')}
          </MainButton>
        </DialogActions>

        <Snackbar
          open={this.state.resetSuccess}
          message={t('common.checkEmail')}
          autoHideDuration={8000}
          onClose={this.handleClose}
          style={{ backgroundColor: 'blue', color: 'white' }}
        />
        <Snackbar
          open={this.state.error}
          message={this.state.errorMessage}
          autoHideDuration={5000}
          onClose={this.handleClose}
        />
      </SmallModal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    passwordResetModal: state.instance.showPasswordResetModal,
    user: state.database.userDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(actions.signOut()),
    isPasswordReset: (value) => dispatch(actions.isPasswordReset(value)),
    hidePasswordResetModal: () => dispatch(actions.showPasswordResetModal(false)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('common')(ForgotPasswordProfile))
