import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestore } from '../../utils/firebase-service'
import { Droppable } from 'react-beautiful-dnd'
import moment from 'moment'
import { RIEInput } from 'riek'
import _ from 'lodash'
import { withTranslation } from 'react-i18next';
import Linkify from 'react-linkify';

// Material UI
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import { grey400 } from 'material-ui/styles/colors'

// Custom Components
import Document from './Document'
import UserCircle from './UserCircle'
import AddUserCircle from './AddUserCircle'
import StyledTextInput from '../../components/StyledTextInput'
import TextInputEmoji from '../../components/TextInputEmoji'
// import BlueButton from '../../components/Buttons/BlueButton';
// import MainButton from '../../components/Buttons/MainButton';

import {
  selectMotion,
  //   updateMotionPrivacy,
  //   addMotionLog,
} from '../../store/actions'

import { updateMotionDescription, updateMotion } from '../../utils/graphql-req'

const styles = {
  inputStyles: {
    borderColor: '#BBBBBF',
    marginBottom: '10px',
  },
  centerButtons: {
    textAlign: 'center',
  },
  input: {
    backgroundColor: 'white',
    border: '1px solid #333541',
  },
  '@global': {
    '.MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '.MuiInput-underline:before': {
      transition: 'none',
    },
    '.MuiInput-underline:hover': {
      borderBottom: 'none',
    },
  },
}

class MotionCard extends Component {
  state = {
    input: '',
    editInput: '',
    subheader: '',
    motionDescription: '',
    editComment: null,
    showMotionDescription: false,
    showMotionComments: false,
    showWorkItemDetails: false,
    documentDetails: undefined,
  }

  componentDidMount() {
    //initally update motion description upon component mounting
    const { passedMotion } = this.props
    if (passedMotion && passedMotion.description)
      this.setState({ motionDescription: passedMotion.description })
    if (passedMotion && passedMotion.subheader) this.setState({ subheader: passedMotion.subheader })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps &&
      nextProps.passedMotion &&
      nextProps.passedMotion.subheader !== this.props.passedMotion.subheader
    )
      this.setState({ subheader: nextProps.passedMotion.subheader })

    return true
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeMotion.id !== this.props.activeMotion.id)
      this.setState({
        showMotionComments: false,
        showWorkItemDetails: false,
        showMotionDescription: false,
      })
  }

  onChangeDescription = (name) => async (event) => {
    this.setState({ motionDescription: event.target.value })
    await updateMotionDescription(this.props.passedMotion.id, event.target.value)
  }

  toggleMotionDescription = () => {
    this.setState({ 
      showMotionDescription: !this.state.showMotionDescription,
      motionDescription: this.props.passedMotion.description
    })
  }

  openEditCommentForm = (event, commentId, comment) => {
    event.preventDefault()
    this.setState({ editComment: commentId, editInput: comment.text })
  }

  addComment = async (event) => {
    if (
      event.keyCode === 13 &&
      event.shiftKey === false &&
      this.state.input.trim().match(/^(?!\s*$).+/)
    ) {
      event.preventDefault()

      // process comment
      const newComment = {}
      newComment.commenter = this.props.uid
      newComment.edited = false
      newComment.notification = [this.props.uid]
      newComment.text = this.state.input
      newComment.timestamp = firestore.Timestamp.now().toDate().toString()

      const updatedMotion = _.cloneDeep(this.props.passedMotion)
      if (updatedMotion.comments) {
        updatedMotion.comments.push(newComment)
      } else {
        updatedMotion.comments = [newComment]
      }
      if (updatedMotion['__typename']) delete updatedMotion['__typename']
      if (updatedMotion.members) {
        updatedMotion.members.forEach((member) => {
          if (member['__typename']) delete member['__typename']
        })
      }
      if (updatedMotion.comments) {
        updatedMotion.comments.forEach((comment) => {
          if (comment['__typename']) delete comment['__typename']
        })
      }

      // update motion
      await updateMotion(updatedMotion)

      // cleanup
      this.setState({ input: '' })
    }
  }

  updateComment = async (key) => {
    // update comment
    const updatedComments = _.cloneDeep(this.props.activeCommentsOrdered)
    updatedComments[key].text = this.state.editInput

    // update motion
    const updatedMotion = _.cloneDeep(this.props.passedMotion)
    updatedMotion.comments = updatedComments
    if (updatedMotion['__typename']) delete updatedMotion['__typename']
    if (updatedMotion.members) {
      updatedMotion.members.forEach((member) => {
        if (member['__typename']) delete member['__typename']
      })
    }
    if (updatedMotion.comments) {
      updatedMotion.comments.forEach((comment) => {
        if (comment['__typename']) delete comment['__typename']
      })
    }

    // updated motion in db
    await updateMotion(updatedMotion)

    // cleanup
    this.setState({ editComment: undefined, editInput: '' })
  }

  deleteComment = async (key) => {
    // update comment
    const updatedComments = _.cloneDeep(this.props.activeCommentsOrdered)
    updatedComments.splice(key, 1)

    // update motion
    const updatedMotion = _.cloneDeep(this.props.passedMotion)
    updatedMotion.comments = updatedComments
    if (updatedMotion['__typename']) delete updatedMotion['__typename']
    if (updatedMotion.members) {
      updatedMotion.members.forEach((member) => {
        if (member['__typename']) delete member['__typename']
      })
    }
    if (updatedMotion.comments) {
      updatedMotion.comments.forEach((comment) => {
        if (comment['__typename']) delete comment['__typename']
      })
    }

    // updated motion in db
    await updateMotion(updatedMotion)
  }

  toggleMotionComments = async () => {
    this.setState({ showMotionComments: !this.state.showMotionComments })

    // clear notification if needed
    const { passedMotion, uid } = this.props

    const updatedMotion = _.cloneDeep(passedMotion)
    let requiresUpdate = false
    if (!_.isEmpty(updatedMotion.comments)) {
      updatedMotion.comments.forEach((comment) => {
        if (!comment.notification.includes(uid)) {
          if (!requiresUpdate) requiresUpdate = true
          comment.notification.push(uid)
        }
      })
    }

    if (requiresUpdate) {
      if (updatedMotion['__typename']) delete updatedMotion['__typename']
      if (updatedMotion.members) {
        updatedMotion.members.forEach((member) => {
          if (member['__typename']) delete member['__typename']
        })
      }
      if (updatedMotion.comments) {
        updatedMotion.comments.forEach((comment) => {
          if (comment['__typename']) delete comment['__typename']
        })
      }

      // update motion in db
      await updateMotion(updatedMotion)
    }
  }

  toggleWorkItemDetails = (document) => {
    this.setState({
      showWorkItemDetails: !this.state.showWorkItemDetails,
      documentDetails: document,
    })
  }

  toggleMotionPrivacy = async (newStatus) => {
    // update motion
    const updatedMotion = _.cloneDeep(this.props.passedMotion)
    if (updatedMotion['__typename']) delete updatedMotion['__typename']
    if (updatedMotion.members) {
      updatedMotion.members.forEach((member) => {
        if (member['__typename']) delete member['__typename']
      })
    }
    if (updatedMotion.comments) {
      updatedMotion.comments.forEach((comment) => {
        if (comment['__typename']) delete comment['__typename']
      })
    }
    updatedMotion['private'] = newStatus

    // update motion in db
    await updateMotion(updatedMotion)

    // if(!this.props.motion || !this.props.mission) return;
    // // update the log
    // let actionUser = this.props.auth.displayName ? this.props.auth.displayName : this.props.auth.email;
    // let logText = `${actionUser} changed the privacy of the ${this.props.motion.name} motion`;

    // const log = {
    //   mission: this.props.mission.id,
    //   date: firebase.firestore.Timestamp.now(),
    //   text: logText
    // }

    // this.props.addMotionLog(log);
  }

  formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

  getWorkItemDetails = () => {
    const { documentDetails } = this.state
    const { t } = this.props;
    console.log(documentDetails);
    return Object.keys(documentDetails).map((item, i) => {
      if (documentDetails[item]) {
        if (item === 'icon') {
          return (
            <li key={item + i}>
              <b>Icon: </b>
              <img alt="Item Icon" src={documentDetails[item]} />
            </li>
          )
        } else if (item === 'link') {
          return (
            <li key={item + i}>
              <a style={{color: '#3ec4e5', cursor: 'pointer'}} el="noopener noreferrer" href={documentDetails[item]} target="_blank">
                {t('common:motionCard.business.url')}
              </a>
            </li>
          )
        } else if (item === 'bytes') {
          return (
            <li key={item + i}>
              <b>Size:</b> {this.formatBytes(documentDetails[item])}
            </li>
          )
        } else if (item === 'name') {
          return (
            <li key={item + i}>
                <b>Name:</b> {documentDetails[item]}
            </li>
          )
        } else if (item === 'date') {
          return (
            <li key={item + i}>
                <b>Created:</b> {(new Date(documentDetails[item])).toString()}
            </li>
          )
        }
        // else
        //   return (
        //     <li key={item + i}>
        //       <b>{item}:</b> {documentDetails[item]}
        //     </li>
        //   )
      }
    })
  }

  createPeopleComponents(userCount, active, documents) {
    const { passedMotion, auth, activeMotion } = this.props

    const peopleComponents = []
    let numMembers = 0
    let isUserInMotion = false

    if (passedMotion && passedMotion.members) {
      passedMotion.members.forEach((member) => {
        numMembers++
        peopleComponents[member.index] = (
          <UserCircle
            active={passedMotion.id === activeMotion.id}
            passedMotion={passedMotion}
            passedMember={member}
            key={member.index}
            grid="50"
          />
        )
      })

      //determine if user is in the motion and if so, set isUserInMotion flag
      Object.keys(passedMotion.members).forEach((memberIndex) => {
        if (passedMotion.members[memberIndex].uid === auth.uid) isUserInMotion = true
      })
    }

    //if user is not in the motion and it's set to private:true, they can't view
    if (!isUserInMotion && passedMotion.private === true) {
      return (
        <React.Fragment>
          <Icon className="material-icons" style={{ fontSize: 23 }}>
            visibility_off
          </Icon>

          <Document
            passedMotion={passedMotion}
            active={active}
            toggleMotionDescription={this.toggleMotionDescription}
            toggleMotionComments={this.toggleMotionComments}
            toggleWorkItemDetails={this.toggleWorkItemDetails}
            key={3}
            grid="100"
          />

          <div className="grid100"> </div>
        </React.Fragment>
      )
    } else {
      // There is a better more concise way of doing this, think of like splicing an array and then rendering it half by half
      if (numMembers <= 3) {
        return [
          <div className="grid50" key={1}>
            {peopleComponents[0]}
          </div>,
          <div className="grid50" key={2}>
            {peopleComponents[1]}
          </div>,
          <Document
            passedMotion={passedMotion}
            active={active}
            toggleMotionDescription={this.toggleMotionDescription}
            toggleMotionComments={this.toggleMotionComments}
            toggleWorkItemDetails={this.toggleWorkItemDetails}
            key={3}
            grid="100"
          />,
          <div className="grid50" key={4}>
            {peopleComponents[2]}
          </div>,
          <div className="grid50" key={5}>
            <AddUserCircle
              active={passedMotion.id === activeMotion.id}
              passedMotion={passedMotion}
            />
          </div>,
        ]
      } else if (numMembers === 4 || numMembers === 5) {
        return [
          <div className="grid33" key={1}>
            {peopleComponents[0]}
          </div>,
          <div className="grid33" key={2}>
            {peopleComponents[1]}
          </div>,
          <div className="grid33" key={3}>
            {peopleComponents[2]}
          </div>,
          <Document
            passedMotion={passedMotion}
            active={active}
            toggleMotionDescription={this.toggleMotionDescription}
            toggleMotionComments={this.toggleMotionComments}
            toggleWorkItemDetails={this.toggleWorkItemDetails}
            key={4}
            grid="100"
          />,
          <div className="grid33" key={5}>
            {peopleComponents[3]}
          </div>,
          <div className="grid33" key={6}>
            {peopleComponents[4]}
          </div>,
          <div className="grid33" key={7}>
            <AddUserCircle
              active={passedMotion.id === activeMotion.id}
              passedMotion={passedMotion}
            />
          </div>,
        ]
      } else if (numMembers === 6 || numMembers === 7) {
        return [
          <div className="grid33" key={1}>
            {peopleComponents[0]}
          </div>,
          <div className="grid33" key={2}>
            {peopleComponents[1]}
          </div>,
          <div className="grid33" key={3}>
            {peopleComponents[2]}
          </div>,
          <div className="grid33" key={4}>
            {peopleComponents[3]}
          </div>,
          <Document
            passedMotion={passedMotion}
            active={active}
            toggleMotionDescription={this.toggleMotionDescription}
            toggleMotionComments={this.toggleMotionComments}
            toggleWorkItemDetails={this.toggleWorkItemDetails}
            key={5}
            grid="33"
          />,
          <div className="grid33" key={6}>
            {peopleComponents[4]}
          </div>,
          <div className="grid33" key={7}>
            {peopleComponents[5]}
          </div>,
          <div className="grid33" key={8}>
            {peopleComponents[6]}
          </div>,
          <div className="grid33" key={9}>
            <AddUserCircle
              active={passedMotion.id === activeMotion.id}
              passedMotion={passedMotion}
            />
          </div>,
        ]
      } else if (numMembers >= 8) {
        return [
          <div className="grid33" key={1}>
            {peopleComponents[0]}
          </div>,
          <div className="grid33" key={2}>
            {peopleComponents[1]}
          </div>,
          <div className="grid33" key={3}>
            {peopleComponents[2]}
          </div>,
          <div className="grid33" key={4}>
            {peopleComponents[6]}
          </div>,
          <Document
            passedMotion={passedMotion}
            active={active}
            toggleMotionDescription={this.toggleMotionDescription}
            toggleMotionComments={this.toggleMotionComments}
            toggleWorkItemDetails={this.toggleWorkItemDetails}
            key={5}
            grid="33"
          />,
          <div className="grid33" key={6}>
            {peopleComponents[7]}
          </div>,
          <div className="grid33" key={7}>
            {peopleComponents[3]}
          </div>,
          <div className="grid33" key={8}>
            {peopleComponents[4]}
          </div>,
          <div className="grid33" key={9}>
            {peopleComponents[5]}
          </div>,
        ]
      } else {
        return null
      }
    }
  }

  renderComment = (key, i) => {
    // Checks to make sure comment wasn't recently deleted
    if (!this.props.activeCommentsOrdered[key] || !this.props.activeCommentsOrdered[key].timestamp)
      return

    const comment = this.props.activeCommentsOrdered[key]

    const { t } = this.props;
    const commentTime = moment(new Date(Date.parse(comment.timestamp)))
    const commentDate = commentTime.format('dddd, MMMM Do')
    const user = { ...this.props.users[comment.commenter], id: comment.commenter }
    const previousKey = Object.keys(this.props.activeCommentsOrdered)[i - 1]

    return (
      <div key={key} className="comment-date-collection">
        {(!previousKey ||
          !this.props.activeCommentsOrdered[previousKey].timestamp ||
          moment(
            new Date(Date.parse(this.props.activeCommentsOrdered[previousKey].timestamp))
          ).format('dddd, MMMM Do') !== commentDate) && (
          <div className="comment-date-container">
            <div className="comment-date">{commentDate}</div>
          </div>
        )}

        <div className="comment-item">
          <img
            alt="avatar"
            src={user && user.avatar ? user.avatar : require('../../assets/images/user.jpg')}
            className="comment-icon"
          />
          <div className="comment-top-container">
            <div className="comment-name">{user.fullName}</div>
            <div className="comment-time">{commentTime.format('h:mm a')}</div>
            {comment.edited && <div className="comment-time comment-edited-text">{t('common:motionCard.edited')}</div>}
            {user.id === this.props.auth.uid && (
              <div className="comment-action-container">
                <Icon
                  onClick={(event) => this.openEditCommentForm(event, key, comment)}
                  style={{ fontSize: 14, cursor: 'pointer' }}
                  className="material-icons"
                >
                  edit
                </Icon>
                <Icon
                  onClick={() => this.deleteComment(key)}
                  style={{ fontSize: 14, cursor: 'pointer' }}
                  className="material-icons"
                >
                  delete
                </Icon>
              </div>
            )}
            {this.state.editComment === key ? (
              <div>
                <TextInputEmoji
                  value={this.state.editInput}
                  onChange={(event) => this.setState({ editInput: event.target.value })}
                  name="comment"
                  placeholder={t('common:motionCard.your.mind')}
                  id="comment"
                  style={{ width: "100%"}}
                  multiline
                  rowsMax="2"
                  variant="outlined"
                  onEmojiClick={(event, emojiObject, inputRef) => {
                    let sym = emojiObject.unified.split("-");
                    let codesArray = [];
                    sym.forEach((el) => codesArray.push("0x" + el));
                    let emoji = String.fromCodePoint(...codesArray);
                    this.setState(prevState => {
                      let position = prevState.editInput.length;
                      if (inputRef?.current) {
                        position = inputRef.current.selectionStart;
                      }
                      return {...prevState, editInput: prevState.editInput.slice(0, position) + emoji + prevState.editInput.slice(position)}
                    })
                  }}
                />
                <div>
                  <Button
                    onClick={() => this.setState({ editComment: null, editInput: comment.text })}
                    color="primary"
                  >
                    {t('common:common.button.cancel')}
                  </Button>
                  <Button onClick={() => this.updateComment(key)} color="primary" autoFocus>
                    {t('common:common.button.update')}
                  </Button>
                </div>
              </div>
            ) : (
              <Linkify 
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key} style={{color: '#3ec4e5'}}>
                      {decoratedText}
                  </a>
                )}
              >
                <p className="comment-text">
                  {comment.text}
                </p>
              </Linkify>
            )}
          </div>
        </div>
      </div>
    )
  }

  changeSubtitle = async (values) => {
    // update motion
    const updatedMotion = _.cloneDeep(this.props.passedMotion)
    updatedMotion.subheader = values.subtitle
    if (updatedMotion['__typename']) delete updatedMotion['__typename']
    if (updatedMotion.members) {
      updatedMotion.members.forEach((member) => {
        if (member['__typename']) delete member['__typename']
      })
    }
    if (updatedMotion.comments) {
      updatedMotion.comments.forEach((comment) => {
        if (comment['__typename']) delete comment['__typename']
      })
    }

    // updated motion in db
    await updateMotion(updatedMotion)
  }

  checkInput = (subtitle) => {
    if (subtitle.match(/^\s+$/) || subtitle === '') {
      return false
    }
    return true
  }

  intermediateMotionClick = (passedMotion, canUserView) => {
    let userPartOfMotion = false
    if (passedMotion && passedMotion.members) {
      passedMotion.members.forEach((member) => {
        if (member.uid === this.props.auth.uid) {
          userPartOfMotion = true
          return
        }
      })
    }

    if (userPartOfMotion) this.props.selectMotion(passedMotion)
    else if (!userPartOfMotion && !passedMotion.private) this.props.selectMotion(passedMotion)
    else if (!userPartOfMotion && passedMotion.private && passedMotion.private !== true)
      this.props.selectMotion(passedMotion)
  }

  render() {
    const {
      // users,
      numUsers,
      // org,
      activeMission,
      activeCommentsOrdered,
      // motion,
      passedMotion,
      active,
      auth,
      workspace,
      classes,
      activeMotion,
      showDeleteMotionDialog,
      activeMissionMotions,
      showLastMotionDialog,
      t,
    } = this.props

    if (!activeMission || !passedMotion) return null

    const userCount = passedMotion.members ? passedMotion.members.length : null
    // const userIndex = userCount || 0;

    let card = (
      <div className="microflowCenter__wrapper" style={{ minHeight: active ? '360px' : '115px' }}>
        <div
          className="microflowCenter__people"
          style={{
            borderColor: passedMotion.motionStatus === 'complete' ? '#F7BB3A' : '',
            backgroundColor: passedMotion.motionStatus === 'complete' ? '#fad991' : '',
          }}
          onClick={() => this.intermediateMotionClick(passedMotion, active)}
        >
          {this.createPeopleComponents(userCount, active, passedMotion.documents)}
        </div>

        {active &&
          auth.uid === activeMission.creator &&
          activeMission.status !== 'complete' &&
          activeMission.status !== 'cancelled' && (
            <Tooltip title={t('common:motionCard.delete')} placement={'top'}>
              <Icon
                onClick={() =>
                  activeMissionMotions.length > 1
                    ? showDeleteMotionDialog()
                    : showLastMotionDialog()
                }
                className="material-icons close-container"
                style={{ right: 5, top: 5, fontSize: 18, zIndex: 11 }}
              >
                delete
              </Icon>
            </Tooltip>
          )}

        {active &&
          activeMission.status !== 'complete' &&
          activeMission.status !== 'cancelled' &&
          (auth.uid === activeMission.creator || this.props.canSetPrivateMotion) && (
            <Tooltip
              title={
                passedMotion.private
                  ? t('common:motionCard.toggle.privateOff')
                  : t('common:motionCard.toggle.private')
              }
              placement="top"
            >
              <Icon
                onClick={
                  passedMotion.private
                    ? () => this.toggleMotionPrivacy(false)
                    : () => this.toggleMotionPrivacy(true)
                }
                className="material-icons close-container"
                style={{ right: 25, top: 5, fontSize: 18, zIndex: 11 }}
              >
                {passedMotion.private ? 'visibility' : 'visibility_off'}
              </Icon>
            </Tooltip>
          )}

        {active && (
          <Droppable
            droppableId={`${passedMotion.id}/${numUsers}/${null}/${auth.uid}/${null}`}
            type="USER"
            direction="horizontal"
            isDropDisabled={true}
          >
            {(provided, snapshot) => {
              return (
                <div
                  className={`microflowCenter ${active && 'active'} ${
                    snapshot.isDraggingOver && 'isDragging'
                  }`}
                  ref={provided.innerRef}
                >
                  {provided.placeholder}
                </div>
              )
            }}
          </Droppable>
        )}

        <div
          style={{
            visibility: !active ? 'visible' : 'hidden',
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
          }}
        >
          <div
            className={`microflowCenter 
              ${active && 'active'} 
              ${this.props.snapshot.isDraggingOver && 'isDragging'}`}
            ref={this.props.provided.innerRef}
          />
        </div>
      </div>
    )

    if (this.state.showMotionComments) {
      card = (
        <div className="microflowCenter__wrapper" style={{ minHeight: active ? '360px' : '115px' }}>
          <div className="microflow-message-box">
            <p>{t('common:common.comments')}</p>
            {activeCommentsOrdered &&
              Object.keys(activeCommentsOrdered).map((key, i) => this.renderComment(key, i))}
            <div className="comment-add-container">
              {this.props.activeMission.status === 'active' && (
                <form>
                  <TextInputEmoji
                    value={this.state.input}
                    onChange={(event) => this.setState({ input: event.target.value })}
                    onEmojiClick={(event, emojiObject, inputRef) => {
                      let sym = emojiObject.unified.split("-");
                      let codesArray = [];
                      sym.forEach((el) => codesArray.push("0x" + el));
                      let emoji = String.fromCodePoint(...codesArray);
                      this.setState(prevState => {
                        let position = prevState.input.length;
                        if (inputRef?.current) {
                          position = inputRef.current.selectionStart;
                        }
                        return {...prevState, input: prevState.input.slice(0, position) + emoji + prevState.input.slice(position)}
                      })
                    }}
                    placeholder={t('common:motionCard.your.mind')}
                    onKeyDown={this.addComment}
                    multiline
                    rowsMax="3"
                    variant="standard"
                  />
                </form>
              )}
            </div>
          </div>
          <Icon
            onClick={() => this.setState({ showMotionComments: false })}
            className="material-icons close-container"
            style={{ right: 8, top: 8, fontSize: 24, zIndex: 11 }}
          >
            clear
          </Icon>
        </div>
      )
    }

    let motionDescription = (
      <div className={'description-paragraph'}>
        <h2>{t('common:common.instructions')}</h2>
        {this.state.motionDescription
          ? this.state.motionDescription
          : t('common:motionCard.description')}
      </div>
    )

    if (
      activeMission.creator === auth.uid &&
      this.state.motionDescription &&
      workspace !== 'complete'
    ) {
      motionDescription = (
        <div>
          <div>
            <p>{t('common:common.instructions')}</p>
            <TextField
              id="custom-css-outlined-input"
              className={classes.inputStyles}
              fullWidth
              multiline
              disableUnderline
              InputProps={{
                classes: { root: classes.input },
              }}
              placeholder={t('common:motionCard.description.placcecholder')}
              rows="6"
              value={this.state.motionDescription}
              onChange={this.onChangeDescription()}
              margin="none"
            />
          </div>
          <div className={classes.centerButtons}></div>
        </div>
      )
    } else if (activeMission.creator === auth.uid && workspace !== 'complete') {
      motionDescription = (
        <div>
          <div>
            <p>{t('common:common.instructions')}</p>
            <TextField
              id="custom-css-outlined-input"
              className={classes.inputStyles}
              InputProps={{
                classes: { root: classes.input },
              }}
              fullWidth
              disableUnderline
              multiline
              placeholder={t('common:motionCard.description.placcecholder')}
              rows="6"
              value={this.state.motionDescription}
              onChange={this.onChangeDescription()}
              margin="none"
            />
          </div>
          <div className={classes.centerButtons}></div>
        </div>
      )
    }

    if (this.state.showMotionDescription) {
      card = (
        <div className="microflowCenter__wrapper" style={{ minHeight: active ? '360px' : '115px' }}>
          <div className="microflow-message-box">
            <div className="description-container">
              <Linkify 
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key} style={{color: '#3ec4e5'}}>
                      {decoratedText}
                  </a>
                )}
              >
              {motionDescription}
              </Linkify>
            </div>
          </div>
          <Icon
            onClick={() =>
              this.setState({
                showMotionDescription: false
              })
            }
            className="material-icons close-container"
            style={{ right: 8, top: 8, fontSize: 24, zIndex: 11 }}
          >
            clear
          </Icon>
        </div>
      )
    }

    if (this.state.showWorkItemDetails) {
      card = (
        <div className="microflowCenter__wrapper" style={{ minHeight: active ? '360px' : '115px' }}>
          <div className="microflow-message-box work-item-details">
            <p className="work-item-details-title">{t('common:motionCard.busines.details')}</p>
            <ul>{this.getWorkItemDetails()}</ul>
          </div>
          <Icon
            onClick={() => this.setState({ showWorkItemDetails: !this.state.showWorkItemDetails })}
            className="material-icons close-container"
            style={{ right: 8, top: 8, fontSize: 24, zIndex: 100 }}
          >
            clear
          </Icon>
        </div>
      )
    }

    let editableSubtitle = false
    if (
      passedMotion.id === activeMotion.id &&
      activeMission.creator === this.props.uid &&
      activeMission.status !== 'cancelled'
    ) {
      editableSubtitle = true
    }

    let subtitle = null
    if (this.state.subheader) {
      subtitle = <p className="subheaderText">{this.state.subheader}</p>
    }

    if (
      editableSubtitle &&
      activeMission.status !== 'complete' &&
      passedMotion.motionStatus !== 'complete'
    ) {
      subtitle = (
        <div className={classes.centerButtons}>
          <RIEInput
            value={
              this.state.subheader ? this.state.subheader : t('common:motionCard.short.subhead')
            }
            propName="subtitle"
            change={this.changeSubtitle.bind(this)}
            // afterFinish={this.submitSubtitle.bind(this)}
            validate={this.checkInput.bind(this)}
            className="microflow-subtitle"
            classEditing="RIEInput-editing"
            editProps={{ maxLength: '34' }}
          />
          <Tooltip title={t('common:motionCard.subhead.edit')} placement="right">
            <Icon
              className={`material-icons small-space ${classes.icon}`}
              style={{ fontSize: 13, zIndex: 11, color: 'grey', marginLeft: '2px' }}
            >
              create
            </Icon>
          </Tooltip>
        </div>
      )
    }

    return (
      <div>
        <div className={`top-microflow-container w-clearfix ${this.props.active ? 'active' : ''}`}>
          <h3 className="microflow-h3">{t(`motionPresets:${this.props.passedMotion.name}`)}</h3>
        </div>
        <div className="subheaderText">{subtitle}</div>
        {card}
      </div>
    )
  }
}

const mapStateToProps = (state, passed) => {
  const { workspace } = state.instance
  const { passedMotion } = passed

  let activeMissionMotions = []
  if (workspace === 'draft') {
    activeMissionMotions = state.database.orgInstanceDraftMotions[state.instance.mission.id]
  } else if (workspace === 'mission') {
    activeMissionMotions = state.database.orgInstanceActiveMotions[state.instance.mission.id]
  } else if (workspace === 'complete') {
    activeMissionMotions = []
  }

  let activeCommentsOrdered = []
  if (passedMotion.comments) {
    activeCommentsOrdered = passedMotion.comments.sort((a, b) => {
      return Date.parse(a.timestamp) - Date.parse(b.timestamp)
    })
  }

  return {
    uid: state.firebase.auth.uid,
    workspace: state.instance.workspace,
    auth: state.firebase.auth,
    activeMission: state.instance.mission,
    activeMotion: state.instance.motion,
    users: state.database.orgInstanceUsers,
    activeMissionMotions,
    activeCommentsOrdered,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectMotion: (motion) => dispatch(selectMotion(motion)),
    // updateMotionPrivacy: (motionId, newStatus) => dispatch(updateMotionPrivacy(motionId, newStatus)),
    // addMotionLog: log => dispatch(addMotionLog(log)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation(['common', 'motionPresets'])(MotionCard)))
