import * as actionTypes from "../actionTypes";

const initialState = {
  showAddNewOrgDialog: false
};

const showAddNewOrgDialog = (state, action) => {
    return {
      ...state,
      showAddNewOrgDialog: true
    };
};

const hideAddNewOrgDialog = (state, action) => {
    return {
      ...state,
      showAddNewOrgDialog: false
    };
};

const dialogs = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_ADD_NEW_ORG_DIALOG:
            return showAddNewOrgDialog(state, action);
        case actionTypes.HIDE_ADD_NEW_ORG_DIALOG:
            return hideAddNewOrgDialog(state, action);
        default:
            return state;
    }
  };
  
  export default dialogs;