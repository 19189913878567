// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
// import { cloneDeep } from "lodash";

// Material UI
// import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

// Custom Components
import MainButton from '../../components/Buttons/MainButton'
import SecondaryButton from '../../components/Buttons/SecondaryButton'

// Helpers
import { selectWorkspace, selectMission } from '../../store/actions'
import { updateMission, updateMotion, addLog } from '../../utils/graphql-req'
import { envEndpoint } from '../../utils/firebase-service'

//Small Modal
import SmallModal from '../../components/Modals/SmallModal'

class MissionDialog extends Component {
  // getWinflowEmails = () => {
  //   const { motions, users } = this.props;
  //   let emails = [];
  //   Object.values(motions).forEach(motion => {
  //     emails = emails.concat(
  //       motion.users.map(user => get(users, [user.uid, "email"], undefined))
  //     );
  //   });
  //   return compact(uniq(emails));
  // };

  handleButtonAction = async (activeTab, logText) => {
    const {
      toggleMissionDialog,
      mission,
      activeMissionMotions,
      nextActionButtonText,
      org,
      user,
    } = this.props

    if (nextActionButtonText === 'Start') {
      if (mission.id) {
        // add start log
        // const log = {
        //   mission: mission.id,
        //   date: Date().toString(),
        //   text: logText,
        // };
        // await addLog(log);

        // update mission
        const updatedMission = { ...mission }
        updatedMission.status = 'active'
        updatedMission.startDate = new Date()

        // pull the user emails and send mission start invitations

        if (updatedMission.members) {
          updatedMission.members.forEach(async (userId) => {
            if (userId !== user.id) {
              const response = await fetch(`${envEndpoint}/auth/getUser`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: userId }),
              })

              if (response.status !== 200 && response.status !== 201) {
                console.log('Error!')
                throw new Error('Something Went Wrong!')
              }

              const { reqUser } = await response.json()
              // console.log(missionUser);

              // const { org, user } = this.props;

              let inviteDetails = {
                address: reqUser.email,
                invited: reqUser.fullName,
                inviter: user.fullName,
                mission: mission.name,
                org: org.name,
              }

              // console.log('is user placeholder at Start?', reqUser);

              if (reqUser.placeholder === true) {
                await fetch(`${envEndpoint}/invites/sendStartMissionNewHF`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    inviteDetails: inviteDetails,
                  }),
                })
              } else {
                await fetch(`${envEndpoint}/invites/sendStartMission`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    inviteDetails: inviteDetails,
                  }),
                })
              }
            }
          })
        }

        if (updatedMission.invitations.length !== 0 && updatedMission.invitations[0]['__typename'])
          updatedMission.invitations.map((invite) => delete invite['__typename'])
        if (updatedMission['__typename']) delete updatedMission['__typename']
        await updateMission(updatedMission)

        if (updatedMission.status === 'active') {
          // add start log
          const log = {
            mission: mission.id,
            date: Date().toString(),
            text: logText,
          }
          await addLog(log)
        }

        // update associated motions
        activeMissionMotions.forEach(async (motion) => {
          const updatedMotion = { ...motion }
          updatedMotion.missionStatus = 'active'
          updatedMotion.motionStatus = 'active'
          if (updatedMotion['__typename']) delete updatedMotion['__typename']
          if (updatedMotion.members) {
            updatedMotion.members.forEach((member) => {
              if (member['__typename']) delete member['__typename']
            })
          }
          if (updatedMotion.comments) {
            updatedMotion.comments.forEach((comment) => {
              if (comment['__typename']) delete comment['__typename']
            })
          }
          await updateMotion(updatedMotion)
        })

        this.props.selectWorkspace(activeTab)
        toggleMissionDialog()
      }
    } else if (nextActionButtonText === 'Cancel') {
      // cancel motions
      activeMissionMotions.forEach(async (motion) => {
        const updatedMotion = { ...motion }
        updatedMotion.missionStatus = 'cancelled'
        updatedMotion.motionStatus = 'cancelled'
        if (updatedMotion['__typename']) delete updatedMotion['__typename']
        if (updatedMotion.members) {
          updatedMotion.members.forEach((member) => {
            if (member['__typename']) delete member['__typename']
          })
        }
        if (updatedMotion.comments) {
          updatedMotion.comments.forEach((comment) => {
            if (comment['__typename']) delete comment['__typename']
          })
        }
        await updateMotion(updatedMotion)
      })

      // cancel mission
      const updatedMissionToCancel = { ...mission }
      updatedMissionToCancel.status = 'cancelled'
      delete updatedMissionToCancel['__typename']
      if (updatedMissionToCancel.invitations)
        updatedMissionToCancel.invitations.map((invite) => delete invite['__typename'])
      await updateMission(updatedMissionToCancel)

      // add delete log
      if (updatedMissionToCancel.status === 'cancelled') {
        const log = {
          mission: mission.id,
          date: Date().toString(),
          text: logText,
        }
        await addLog(log)
      }

      // Send email to notify members of mission of cancellation
      if (mission.members) {
        mission.members.forEach(async (userId) => {
          if (userId !== user.id) {
            const response = await fetch(`${envEndpoint}/auth/getUser`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ userId: userId }),
            })

            if (response.status !== 200 && response.status !== 201) {
              console.log('Error!')
              throw new Error('Something Went Wrong!')
            }

            const { reqUser } = await response.json()
            // console.log(missionUser);

            // const { org, user } = this.props;

            let inviteDetails = {
              address: reqUser.email,
              invited: reqUser.fullName,
              inviter: user.fullName,
              mission: mission.name,
              org: org.name,
              language: reqUser.language || 'en-GB',
            }

            // console.log('is user placeholder at Start?', reqUser);

            if (reqUser) {
              await fetch(`${envEndpoint}/invites/sendCanceledMission`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  inviteDetails: inviteDetails,
                }),
              })
            }
          }
        })
      }

      // await deleteMission(updatedMissionToCancel)
      // delete each motion
      // activeMissionMotions.forEach(async motion => {
      //   let motionToDelete = { ...motion };
      //     if(motionToDelete["__typename"]) delete motionToDelete["__typename"];
      //     if(motionToDelete.members) {
      //       motionToDelete.members.forEach(member => {
      //         delete member["__typename"];
      //       })
      //     }
      //     if(motionToDelete.comments) {
      //       motionToDelete.comments.forEach(comment => {
      //         delete comment["__typename"];
      //       })
      //     }
      //     await deleteMotion(motionToDelete)
      // })

      this.props.selectWorkspace(activeTab)
    }

    // const params = {
    //   winflowName,
    //   organizationName: orgName,
    //   env: envCode,
    //   userName: user.fullName,
    //   actionType: nextActionStatus,
    //   address: this.getWinflowEmails()
    // };

    // // API.sendEmail(params);
    // toggleMissionDialog();
    // if (nextActionStatus === "archived") {
    //   selectMission();
    // }
    // //API.updateWinflowLog(orgId, mission.id, logText);
  }

  render() {
    const { nextActionButtonText, toggleMissionDialog, user, t } = this.props
    let dialogContentText
    let activeTab
    let logText

    switch (nextActionButtonText) {
      case 'Cancel':
        dialogContentText = t('common:missionDialog.cancel.content')
        activeTab = 'complete'
        logText = `${user.fullName ? user.fullName : user.email} abandoned the Mission.`
        break
      case 'Start':
        dialogContentText = t('common:missionDialog.start.content')
        activeTab = 'mission'
        logText = `${user.fullName ? user.fullName : user.email} started the Mission.`
        break
      case 'Delete':
        dialogContentText = t('common:missionDialog.delete.content')
        activeTab = 'complete'
        logText = `${user.fullName ? user.fullName : user.email} deleted the Mission.`
        break
      default:
        return null
    }

    return (
      <SmallModal
        open={this.props.missionDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t(`special:${nextActionButtonText}.mission`)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogContentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={toggleMissionDialog}> {t('common:common.button.back')} </SecondaryButton>
          <MainButton onClick={() => this.handleButtonAction(activeTab, logText)}>
            {nextActionButtonText === 'Cancel' ? t('common:common.button.confirm') : t(`special:${nextActionButtonText}`)}
          </MainButton>
        </DialogActions>
      </SmallModal>
    )
  }
}

const mapStateToProps = (state, passed) => {
  const { workspace } = state.instance

  let activeMissionMotions = []
  if (workspace === 'draft') {
    activeMissionMotions = state.database.orgInstanceDraftMotions[state.instance.mission.id]
  } else if (workspace === 'mission') {
    activeMissionMotions = state.database.orgInstanceActiveMotions[state.instance.mission.id]
  } else if (workspace === 'complete') {
    activeMissionMotions = []
  }

  return {
    auth: state.firebase.auth,
    org: state.instance.org,
    mission: state.instance.mission,
    user: state.database.userDetails,
    activeMissionMotions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
    // startMission: missionId => dispatch(startMission(missionId)),
    selectMission: (missionId) => dispatch(selectMission(missionId)),
    // deleteMission: missionId => dispatch(deleteMission(missionId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common', 'special'])(MissionDialog))
