// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, cloneDeep } from 'lodash'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

// Material UI
// import Button from "@material-ui/core/Button";

// Custom Components
import DraftsMenu from './DraftsMenu'
import TemplatesMenu from './TemplatesMenu'
import ControlledExpansionPanels from './ControlledExpansionPanels'
import StarterModal from './StarterModal'
import DeleteTemplateDialog from './DeleteTemplateDialog'
import DeleteDraftDialog from './DeleteDraftDialog'
import NewMissionModal from './NewMissionModal'
import SidePanel from '../SidePanel'

// Utils
import { selectMotion, selectMission, selectWorkspace } from '../../store/actions'
import {
  addMission,
  deleteMission,
  addMotion,
  deleteMotion,
  deleteMissionTemplate,
  addWorkItem,
} from '../../utils/graphql-req'
import motionPresets from '../../utils/motionPresets'

//Custom Components
import MainButton from '../../components/Buttons/MainButton'

const styles = {
  button: {
    color: 'white',
    backgroundColor: '#3EC4E5',
    marginTop: '10px',
    boxShadow: 'none',
  },
  link: {
    textDecoration: 'none',
  },
  centerButtons: {
    textAlign: 'center',
  },
}

class DraftSpace extends Component {
  state = {
    startDialog: false,
    showDeleteTemplateDialog: false,
    showDeleteDialog: false,
    dialogNewMission: false,
    targetMission: null,
    targetTemplate: null,
  }

  toggleNewMissionDialog = () => {
    this.setState({
      dialogNewMission: !this.state.dialogNewMission,
      activeStep: 0,
    })
  }

  openDeleteDialog = (mission) => {
    this.setState({ showDeleteDialog: true, targetMission: mission })
  }

  hideDeleteDialog = () => {
    this.setState({ showDeleteDialog: false, targetMission: null })
  }

  openStartDialog = (template, group) => {
    this.setState({
      startDialog: true,
      starterTemplate: template,
      group: group,
    })
  }

  closeStartDialog = () => {
    this.setState({
      startDialog: false,
      starterTemplate: undefined,
      group: undefined,
    })
  }

  // createProcess = async (
  //   processName,
  //   processDescription,
  //   processDate,
  //   fromStart,
  //   blockchain,
  //   document
  // ) => {
  //   console.log('inside the createProcess')
  //   const { starterTemplate } = this.state

  //   //create new Winflow
  //   let newMission = {}
  //   newMission.name = processName || starterTemplate.name
  //   newMission.description = processDescription || starterTemplate.description
  //   newMission.blockchain = blockchain
  //   newMission.creator = this.props.auth.uid
  //   newMission.status = 'draft'
  //   newMission.members = [this.props.auth.uid]
  //   newMission.org = this.props.activeOrg.id
  //   newMission.invitations = []

  //   // push to DB
  //   const mission = await addMission(newMission)

  //   const motionTemplates = get(starterTemplate, ['motions'], [])

  //   const newMotions = []
  //   //Create motions from templete
  //   if (fromStart) {
  //     // Create motions with start date in mind
  //     let date = moment(processDate)
  //     motionTemplates.forEach((item, index) => {
  //       // Create new motion
  //       const newMotion = cloneDeep(motionPresets[item.preset])
  //       newMotion.org = this.props.activeOrg.id
  //       newMotion.index = index
  //       newMotion.mission = mission.id
  //       newMotion.missionStatus = 'draft'
  //       newMotion.motionStatus = 'draft'
  //       newMotion.private = false
  //       newMotion.makeActive = false
  //       newMotion.description = item.description || ''
  //       newMotion.subheader = item.subheader || ''
  //       newMotion.startDate = date.toDate().toString()
  //       newMotion.endDate = date
  //         .add(item.duration || 1, 'days')
  //         .toDate()
  //         .toString()
  //       newMotion.members[0].uid = this.props.auth.uid
  //       newMotion.comments = []

  //       newMotions.push(newMotion)
  //     })
  //   } else {
  //     // Create motions with end date in mind
  //     let date = moment(processDate)
  //     let count = 1
  //     motionTemplates
  //       .slice()
  //       .reverse()
  //       .forEach((item, index) => {
  //         // Create new motion
  //         const newMotion = cloneDeep(motionPresets[item.preset])
  //         newMotion.org = this.props.activeOrg.id
  //         newMotion.index = motionTemplates.length - count
  //         newMotion.mission = mission.id
  //         newMotion.missionStatus = 'draft'
  //         newMotion.motionStatus = 'draft'
  //         newMotion.private = false
  //         newMotion.makeActive = false
  //         newMotion.description = item.description || ''
  //         newMotion.subheader = item.subheader || ''
  //         newMotion.endDate = date.toDate().toString()
  //         newMotion.startDate = date.subtract(item.duration, 'days').toDate().toString()
  //         newMotion.members[0].uid = this.props.auth.uid
  //         count++

  //         newMotions.push(newMotion)
  //       })
  //   }

  //   // push to database
  //   const motions = []
  //   newMotions.forEach(async (newMotion) => {
  //     const motion = await addMotion(newMotion)
  //     motions.push(motion)
  //   })

  //   console.log('here is the doc', document)

  //   // create new workItem if one was added
  //   if (document) {
  //     const newWorkItem = { ...document }

  //     delete newWorkItem['isDir']
  //     delete newWorkItem['linkType']
  //     delete newWorkItem['thumbnailLink']
  //     delete newWorkItem['id']
  //     newWorkItem['mission'] = mission.id
  //     newWorkItem['motion'] = [motions.id]
  //     console.log(newWorkItem)

  //     // post to database
  //     await addWorkItem(newWorkItem)
  //   }

  //   // switch to active mission tab
  //   this.props.selectMission(mission)
  //   this.props.selectMotion(motions.find((motion) => motion.index === 0))
  //   this.props.selectWorkspace('draft')
  //   this.closeStartDialog()
  // }

  deleteDraft = () => {
    const missionToDelete = this.state.targetMission

    if (!missionToDelete) return null

    // delete mission
    const updatedMissionToDelete = cloneDeep(missionToDelete)
    delete updatedMissionToDelete['__typename']
    if (updatedMissionToDelete.invitations)
      updatedMissionToDelete.invitations.map((invite) => delete invite['__typename'])

    deleteMission(updatedMissionToDelete)

    // delete associated motions
    if (this.props.draftMotions && this.props.draftMotions[updatedMissionToDelete.id]) {
      const motionsToDelete = this.props.draftMotions[updatedMissionToDelete.id]
      motionsToDelete.forEach((motion) => {
        let motionToDelete = { ...motion }
        if (motionToDelete['__typename']) delete motionToDelete['__typename']
        if (motionToDelete.members) {
          motionToDelete.members.forEach((member) => {
            delete member['__typename']
          })
        }
        if (motionToDelete.comments) {
          motionToDelete.comments.forEach((comment) => {
            delete comment['__typename']
          })
        }

        deleteMotion(motionToDelete)
      })
    }

    // cleanup
    this.hideDeleteDialog()
  }

  openDeleteTemplateDialog = (templateId) => {
    this.setState({ showDeleteTemplateDialog: true, targetTemplate: templateId })
  }

  hideDeleteTemplateDialog = () => {
    this.setState({ showDeleteTemplateDialog: false, targetTemplate: null })
  }

  deleteTemplate = async () => {
    const missionTempleteToDelete = cloneDeep(this.state.targetTemplate)
    delete missionTempleteToDelete['__typename']
    await deleteMissionTemplate(missionTempleteToDelete)
    this.hideDeleteTemplateDialog()
  }

  render() {
    const { activeOrg, t } = this.props

    let addSpace = (
      <div className={'emptystate'}>
        <div>
          <p> {t('draftSpace.index.emptystate.p1')}</p>
          <p> {t('draftSpace.index.emptystate.p2')}</p>
        </div>
      </div>
    )

    if (activeOrg) {
      addSpace = (
        <React.Fragment>
          <div className="workspace-canvas notifications-canvas">
            <div className="notification-wrapper">
              <div className="notification-container flex45">
                <div className="new-winflow-button-container">
                  <p> {t('draftSpace.index.activeOrg.p')} </p>
                  <MainButton
                    variant="contained"
                    style={styles.button}
                    className="create-button"
                    onClick={() => this.toggleNewMissionDialog()}
                  >
                    + {t('draftSpace.index.create.mission')}
                  </MainButton>
                </div>

                <div className="notification-item short-container">
                  <h3>{t('draftSpace.index.myMission')}</h3>
                  <div className="line-div" />
                  <SidePanel openDeleteDialog={this.openDeleteDialog} />
                  <DraftsMenu openDeleteDialog={this.openDeleteDialog} />
                </div>

                <div className="notification-item short-container">
                  <h3>{activeOrg ? activeOrg.name : ''} {t('draftSpace.index.templates')}</h3>
                  <div className="line-div" />
                  <TemplatesMenu openDeleteTemplateDialog={this.openDeleteTemplateDialog} />
                </div>

                <DeleteTemplateDialog
                  showDeleteTemplateDialog={this.state.showDeleteTemplateDialog}
                  hideDeleteTemplateDialog={this.hideDeleteTemplateDialog}
                  deleteTemplate={() => this.deleteTemplate()}
                />

                <DeleteDraftDialog
                  showDeleteDialog={this.state.showDeleteDialog}
                  hideDeleteDialog={this.hideDeleteDialog}
                  deleteDraft={this.deleteDraft}
                />
              </div>
              <div className="notification-container flex45 draft-container">
                <div className="notification-item">
                  <h3>{t('draftSpace.index.startTemplates')}</h3>
                  <div className="line-div" />
                  <ControlledExpansionPanels openStartDialog={this.openStartDialog} />

                  {this.state.startDialog && (
                    <StarterModal
                      startDialog={this.state.startDialog}
                      createProcess={this.createProcess()}
                      template={this.state.starterTemplate}
                      closeDialog={this.closeStartDialog}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <NewMissionModal
            dialogNewMission={this.state.dialogNewMission}
            toggleNewMissionDialog={this.toggleNewMissionDialog}
          />
        </React.Fragment>
      )
    }

    return addSpace
  }
}

const mapStateToProps = (state) => {
  return {
    activeOrg: state.instance.org,
    auth: state.firebase.auth,
    orgs: state.database.userOrgs,
    draftMotions: state.database.orgInstanceDraftMotions,
    draftMissions: state.database.orgInstanceDraftMissions,
    activeMission: state.instance.mission,
    //   instanceOrgAllActiveMotions,
    //   instanceTemplateMotions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectMotion: (motion) => dispatch(selectMotion(motion)),
    selectMission: (mission) => dispatch(selectMission(mission)),
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
    // deleteMission: mission => dispatch(deleteMission(mission)),
    // deleteAllMotions: motionIds => dispatch(deleteAllMotions(motionIds)),
    // deleteTemplate: templateId => dispatch(deleteTemplate(templateId)),
    // deleteTemplateMotions: motions => dispatch(deleteTemplateMotions(motions)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(DraftSpace))
