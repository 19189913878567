// Libraries
import React, { Component } from 'react'
import GooglePicker from 'react-google-picker'
import { withTranslation } from 'react-i18next'

// Material UI
import { withStyles } from '@material-ui/core/styles'
// import TextField from "@material-ui/core/TextField";
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DatePicker from 'material-ui/DatePicker'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
// import Checkbox from "@material-ui/core/Checkbox";
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Tooltip from '@material-ui/core/Tooltip'
import { MuiThemeProvider, getMuiTheme } from 'material-ui/styles'

// Custom Components
import MainButton from '../../components/Buttons/MainButton'
import SecondaryButton from '../../components/Buttons/SecondaryButton'
import StyledTextField from '../../components/StyledTextField'
import DropboxPicker from '../../components/DropboxPicker/DropboxPicker'
import AddItemLink from '../SideDrawerEdit/AddItemLink'

const mainMuiTheme = getMuiTheme({
  button: {},
  flatButton: {
    // buttonFilterColor: "transparent",
    primaryTextColor: '#333541',
  },
  datePicker: {
    selectColor: '#3EC4E5',
    selectTextColor: '#333541',
    headerColor: '#333541',
  },
})

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 265,
    marginBottom: 5,
  },
  radio: {
    display: 'inline-block',
  },
  checkBox: {
    width: 265,
    marginTop: 15,
  },
  googlelogos: {
    height: '120px',
    width: '120px',
    margin: '10px',
    cursor: 'pointer',
  },
})

class StarterModal extends Component {
  state = {
    startEndValue: 'start',
    winflowName: '',
    winflowDescription: '',
    winflowDate: null,
    blockchain: false,
    activeStep: 0,
    document: null,
    extensionIcon: 'document',
    error: null,
  }

  componentDidMount() {
    this.setState({
      winflowName: this.props.template.name,
      winflowDescription: this.props.template.description,
    })
  }

  handleChange = (event) => {
    this.setState({ startEndValue: event.target.value })
  }

  handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked })
  }

  createProcess = () => {
    const { winflowName, winflowDescription, winflowDate, startEndValue, document } = this.state
    const fromStart = startEndValue === 'start'
    const blockchain = this.state.blockchain
    console.log('here is the doc', document)
    if (winflowDate) {
      console.log('here is the winflow date', winflowDate)
      console.log('the state', this.state)
      //this.props.createProcess(winflowName, winflowDescription, winflowDate, startEndValue)
      this.props.createProcess(
        winflowName,
        winflowDescription,
        winflowDate,
        fromStart,
        blockchain,
        document
      )
    }
  }

  handleStepperNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }))
  }

  handleStepperBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }))
  }

  handleSubmitNewLink = (newLink) => {
    this.setState({
      document: newLink,
      error: null,
    })
  }

  extensionIconFinder = (name) => {
    name = name.name ? name.name : name
    if (name.isDir === true) {
      return 'folder'
    } else if (name.split('.').pop() === 'ppt' || name.split('.').pop() === 'pptx') {
      return 'powerpoint'
    } else if (name.split('.').pop() === 'xls' || name.split('.').pop() === 'xlsx') {
      return 'excel'
    } else if (name.split('.').pop() === 'doc' || name.split('.').pop() === 'docx') {
      return 'word'
    } else if (name.split('.').pop() === 'pdf') {
      return 'pdf'
    } else {
      return 'document'
    }
  }

  // changeDocInProcess = async (data) => {
  //   const { mission, motion } = this.props

  //   if (!mission || !motion || !data) return null

  //   const newWorkItem = {
  //     ...data,
  //     mission: mission.id,
  //     motion: [],
  //   }

  //   delete newWorkItem['isDir']
  //   delete newWorkItem['linkType']
  //   delete newWorkItem['id']
  //   delete newWorkItem['thumbnailLink']
  //   // console.log(newWorkItem)

  //   // push to DB
  //   await addWorkItem(newWorkItem)
  // }

  onSuccessDropboxChooser = (files) => {
    const doc = files[0]
    //console.log(doc)
    const extensionIconDropBox = this.extensionIconFinder(doc)

    this.setState({
      document: doc,
      extensionIcon: extensionIconDropBox,
      error: null,
    })

    // this.changeDocInProcess(doc)
  }

  onSuccessGooglePicker = (files) => {
    //console.log(files)
    //console.log(files.docs)
    const doc = files.docs

    if (doc) {
      const file = doc[0]

      //Choose extenstion
      let extensionIconGoogle = ''
      let folder = 'false'
      if (file.type === 'folder') {
        extensionIconGoogle = 'folder'
        folder = 'true'
      } else if (file.serviceId === 'DoclistBlob') {
        extensionIconGoogle = this.extensionIconFinder(file.name)
        //console.log(extensionIconGoogle)
      } else if (file.serviceId === 'doc') {
        extensionIconGoogle = 'word'
      } else if (file.serviceId === 'spread') {
        extensionIconGoogle = 'excel'
      } else if (file.serviceId === 'pres') {
        extensionIconGoogle = 'powerpoint'
      } else {
        extensionIconGoogle = 'document'
      }

      const googleDoc = {
        bytes: file.sizeBytes,
        icon: file.iconUrl,
        id: file.id,
        isDir: folder,
        link: file.url,
        name: file.name,
      }

      this.setState({
        document: googleDoc,
        extensionIcon: extensionIconGoogle,
        error: null,
      })

      // this.changeDocInProcess(googleDoc)
    }
  }

  render() {
    const { classes, t } = this.props
    const { activeStep } = this.state

    return (
      <Dialog
        modal="true"
        className="invite-dialog"
        open={this.props.startDialog}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle color="primary" id="form-dialog-title">
          {t('newMissionModal.title')}
          <br />
          {activeStep === 0 && <span className="second-line">{t('newMissionModal.step.0')}</span>}
          {activeStep === 1 && <span className="second-line">{t('newMissionModal.step.2')}</span>}
        </DialogTitle>

        <Stepper activeStep={activeStep} alternativeLabel>
          {t('starterModal.steps').map((label, index) => {
            const props = {}
            const labelProps = {}
            return (
              <Step key={label} {...props}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>

        <DialogContent>
          {activeStep === 0 ? (
            <div className="flex-form">
              <div className="flex50">
                <Tooltip title={t('common.button.cancel')} placement={'bottom'}>
                  <i className="material-icons x-icon" onClick={this.props.closeDialog}>
                    clear
                  </i>
                </Tooltip>

                <StyledTextField
                  id="standard-name"
                  label={t('common.label.whatMission')}
                  className={classes.textField}
                  value={this.state.winflowName}
                  maxLength={40}
                  onChange={(event) => this.setState({ winflowName: event.target.value })}
                  margin="normal"
                />

                <StyledTextField
                  id="standard-multiline-flexible"
                  label={t('starterModal.label.describeMission')}
                  multiline
                  rowsMax="4"
                  value={this.state.winflowDescription}
                  maxLength={58}
                  onChange={(event) => this.setState({ winflowDescription: event.target.value })}
                  className={classes.textField}
                  margin="normal"
                />
              </div>
              <div className="flex50">
                <label className="label">{t('common.label.chooseOne')}:</label>

                <RadioGroup
                  row
                  aria-label="startend"
                  name="startend"
                  className={classes.group}
                  value={this.state.startEndValue}
                  onChange={this.handleChange}
                >
                  <FormControlLabel
                    value="start"
                    control={<Radio color="primary" />}
                    label={t('common.label.startDate')}
                  />
                  <FormControlLabel
                    value="end"
                    control={<Radio color="primary" />}
                    label={t('common.label.completionDate')}
                  />
                </RadioGroup>

                <label className="label">
                  {this.state.startEndValue === 'end'
                    ? t('common.label.shouldDone')
                    : t('common.label.shouldStart')}
                </label>

                <MuiThemeProvider muiTheme={mainMuiTheme}>
                  <DatePicker
                    firstDayOfWeek={0}
                    autoOk={true}
                    hintText={t('common:common.label.date')}
                    style={{ float: 'left' }}
                    locale={'en-US'}
                    onChange={(n, date) => this.setState({ winflowDate: date.getTime() })}
                    value={this.state.winflowDate && new Date(this.state.winflowDate)}
                  />
                </MuiThemeProvider>
              </div>
            </div>
          ) : (
            <div>
              <Tooltip title={t('common.button.cancel')} placement={'bottom'}>
                <i className="material-icons x-icon" onClick={this.props.closeDialog}>
                  clear
                </i>
              </Tooltip>
              <div className="storage-wrapper">
                <DropboxPicker onSuccess={(files) => this.onSuccessDropboxChooser(files)} />

                <AddItemLink onSubmit={this.handleSubmitNewLink} />

                {/* 
                  Google picker hidden until its fixed
                */}

                {/* <GooglePicker
                  clientId={
                    '632460170241-6jq1ihh6tf91r89qh0go8qvcfoc7sr8k.apps.googleusercontent.com'
                  }
                  developerKey={'AIzaSyDqy3cMYPpz5U01WG0WtvJpSs3BFrgDXe8'}
                  scope={['https://www.googleapis.com/auth/drive.readonly']}
                  onAuthenticate={(token) => console.warn('onAuthenticate: ' + token)}
                  onChange={(data) => this.onSuccessGooglePicker(data)}
                  multiselect={false}
                  navHidden={true}
                  authImmediate={false}
                  createPicker={(google, oauthToken) => {
                    const googleViewId = google.picker.ViewId.DOCS

                    const docsView = new google.picker.DocsView(googleViewId)
                      .setIncludeFolders(true)
                      .setSelectFolderEnabled(true)

                    const picker = new window.google.picker.PickerBuilder()
                      .addView(docsView)
                      .setOAuthToken(oauthToken)
                      .setDeveloperKey('AIzaSyDqy3cMYPpz5U01WG0WtvJpSs3BFrgDXe8')
                      .setCallback((data) => {
                        this.onSuccessGooglePicker(data)
                      })

                    picker.build().setVisible(true)
                  }}
                >
                  <div className="dropbox-button">
                    <img
                      src={require('../../assets/images/googledrive.png')}
                      alt="require"
                      className={classes.googlelogos}
                    />
                  </div>
                </GooglePicker> */}
              </div>
              {!!this.state.document ? (
                <img
                  src={require(`../../assets/images/file-${this.state.extensionIcon}.png`)}
                  alt="Document Icon"
                />
              ) : (
                <p></p>
              )}
              {!!this.state.document && (
                <div>
                  {this.state.document ? this.state.document.name : t('common.label.documentName')}
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions className="dialogActions">
          {activeStep === 0 ? (
            <React.Fragment>
              <div>
                <MainButton
                  onClick={this.handleStepperNext}
                  disabled={!this.state.winflowName || !this.state.winflowDate}
                >
                  {t('common.button.next')}
                </MainButton>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div>
                <SecondaryButton onClick={this.handleStepperBack}>
                  {t('common.button.back')}
                </SecondaryButton>
                <MainButton onClick={this.createProcess}>{t('common.button.create')}</MainButton>
              </div>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(withTranslation('common')(StarterModal))
