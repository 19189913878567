// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import GooglePicker from 'react-google-picker'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

import DropboxPicker from '../../components/DropboxPicker/DropboxPicker'
import AddItemLink from './AddItemLink'

// Material Icons
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'

// Helpers
import { addWorkItem } from '../../utils/graphql-req'

class WorkItemSubmenu extends Component {
  state = {
    items: this.props.docs ? Object.keys(this.props.docs) : undefined,
  }

  handleAddItemLink = (newWorkItem) => {
    const { mission, motion } = this.props

    if (!mission || !newWorkItem || !motion) return null

    addWorkItem({ ...newWorkItem, mission: mission.id, motion: [] })
  }

  changeDocInProcess = async (data) => {
    const { mission, motion } = this.props

    if (!mission || !motion || !data) return null

    const newWorkItem = {
      ...data,
      mission: mission.id,
      motion: [],
      date: new Date(),
    }

    delete newWorkItem['isDir']
    delete newWorkItem['linkType']
    delete newWorkItem['id']
    delete newWorkItem['thumbnailLink']

    // push to DB
    await addWorkItem(newWorkItem)
  }

  onSuccessDropboxChooser = (files) => {
    this.changeDocInProcess(files[0])
  }

  onSuccessGooglePicker = (files) => {
    const doc = files.docs

    if (doc) {
      const file = doc[0]
      let folder = 'false'
      if (file.type === 'folder') {
        folder = 'true'
      }

      const googleDoc = {
        bytes: file.sizeBytes,
        icon: file.iconUrl,
        isDir: folder,
        link: file.url,
        name: file.name,
      }

      this.changeDocInProcess(googleDoc)
    }
  }

  shortenedDocName = (name) => {
    let newName
    if (name.length > 28) {
      newName = name.slice(0, 13) + '...' + name.slice(name.length - 14)
      return newName
    } else {
      return name
    }
  }

  render() {
    const { workItems, mission, auth, t } = this.props
    workItems.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

    return (
      <>
        <div className="submenu-container" style={{ zIndex: 900 }}>
          <AddItemLink onSubmit={this.handleAddItemLink} sideBarLayout={true} />
          <div className="submenu-body">
            <div className="workitems-microflow-container">
              <DropboxPicker
                size={'sm'}
                onSuccess={(files) => this.onSuccessDropboxChooser(files)}
              />

              {/* 
                  Google picker hidden until its fixed
                */}

              {/* <GooglePicker
                  clientId={
                    '632460170241-6jq1ihh6tf91r89qh0go8qvcfoc7sr8k.apps.googleusercontent.com'
                  }
                  developerKey={'AIzaSyDqy3cMYPpz5U01WG0WtvJpSs3BFrgDXe8'}
                  scope={['https://www.googleapis.com/auth/drive.readonly']}
                  onAuthenticate={(token) => console.warn('onAuthenticate: ' + token)}
                  onChange={(data) => this.onSuccessGooglePicker(data)}
                  multiselect={false}
                  navHidden={true}
                  authImmediate={false}
                  createPicker={(google, oauthToken) => {
                    const googleViewId = google.picker.ViewId.DOCS

                    const docsView = new google.picker.DocsView(googleViewId)
                      .setIncludeFolders(true)
                      .setSelectFolderEnabled(true)

                    const picker = new window.google.picker.PickerBuilder()
                      .addView(docsView)
                      .setOAuthToken(oauthToken)
                      .setDeveloperKey('AIzaSyDqy3cMYPpz5U01WG0WtvJpSs3BFrgDXe8')
                      .setCallback((data) => {
                        this.onSuccessGooglePicker(data)
                      })

                    picker.build().setVisible(true)
                  }}
                >
                  <div className="dropbox-button">
                    <Tooltip title={t('workItemSubmenu.gdrive')} placement={'bottom'}>
                      <img
                        src={require('../../assets/images/google-drive-512.png')}
                        alt="GoogleDrive"
                        className="google-logo google-logo-side edit-work-items"
                      />
                    </Tooltip>
                  </div>
                </GooglePicker> */}
            </div>

            <Droppable droppableId="ADD_DOC" type="DOC" isDropDisabled={true}>
              {(provided, snapshot) => (
                <div className="edit-documents-list" ref={provided.innerRef}>
                  {!_.isEmpty(workItems) &&
                    workItems.map((doc, index) => {
                      return (
                        <Draggable draggableId={doc.id} key={index} index={index} type="DOCUMENT">
                          {(provided, snapshot) => (
                            <div
                              onClick={null}
                              className="edit-work-items"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <p className="edit-document" style={{ color: 'black' }}>
                                {this.shortenedDocName(doc.name)}
                              </p>
                              <div className="delete-work-item">
                                {mission.creator === auth.uid ? (
                                  <Tooltip title={t('workItemSubmenu.delete')} placement={'bottom'}>
                                    <Icon
                                      onClick={() => this.props.showDeleteWorkItemDialog(doc)}
                                      style={{ cursor: 'pointer', fontSize: 14, color: 'black' }}
                                      className="material-icons"
                                    >
                                      delete
                                    </Icon>
                                  </Tooltip>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    mission: state.instance.mission,
    motion: state.instance.motion,
    workItems: state.database.workItems,
  }
}

export default connect(mapStateToProps, null)(withTranslation('common')(WorkItemSubmenu))
