import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = {
  mainButton: {
    backgroundColor: "#3EC4E5",
    color: "white",
    marginLeft: "4px",
    marginRight: "4px",
    boxShadow:"none",
    "&:hover": {
      backgroundColor: "#93DEF0",
      boxShadow:"none"
    },
    "&:disabled": {
      color: 'white',
    }
  },
};

const MainButton = props => {
  const { classes } = props;

  return (
    <Button 
      className={classes.mainButton} 
      variant="contained"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  );
};

MainButton.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MainButton);