// Libraries
import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

// Material UI
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class WorkItemInUseDialog extends Component {
  render() {
    const { t } = this.props;
    return (
      <Dialog
        open={this.props.showWorkItemInUseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {t('workItemInUse.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('workItemInUse.description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.hideWorkItemInUseDialog} color="primary">
            {t('common.button.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation('common')(WorkItemInUseDialog);