
import * as actionTypes from "../actionTypes";

const initialState = {
  workspace: "home",
  org: null,
  mission: null,
  motion: null,
  showProfileModel: false,
  showPasswordResetModal: false,
  userProfileImagePreview: undefined,
  loadedFile: undefined,
  // updatingMission: false,    
  // user: {}
};

const selectWorkspace = (state, action) => {
  const { workspace, updatingWinflow } = action.payload  

  return {
    ...state,
    workspace,
    updatingWinflow,
  };
};

const selectOrg = (state, action) => {
  return {
    ...state,
    org: action.payload
  };
};

const selectMission = (state, action) => {
  return {
    ...state,
    mission: action.payload,
    updatingMission: false,
  };
};

const selectMotion = (state, action) => {
  return {
    ...state,
    motion: action.payload
  };
};

const showProfileModel = (state, action) => {
  return {
    ...state,
    showProfileModel: action.payload
  };
};

const showPasswordResetModal = (state, action) => {
  return {
    ...state,
    showPasswordResetModal: action.payload,
  };
};

const closePreview = (state, action) => {
  return {
    ...state,
    userProfileImagePreview: null
  };
};

const setPreview = (state, action) => {
  return {
    ...state,
    userProfileImagePreview: action.preview
  };
};

const setLoadedFile = (state, action) => {
  return {
    ...state,
    loadedFile: action.loadedFile
  };
};

const clearInstanceData = (state, action) => {
  return {
    workspace: "home",
    org: null,
    mission: null,
    motion: null,
    showProfileModel: false,
    showPasswordResetModal: false,
    userProfileImagePreview: undefined,
    loadedFile: undefined,
  }
}

const instance = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_WORKSPACE: return selectWorkspace(state, action);
    case actionTypes.SELECT_ORG: return selectOrg(state, action);
    case actionTypes.SELECT_MISSION: return selectMission(state, action);
    case actionTypes.SELECT_MOTION: return selectMotion(state, action);
    case actionTypes.SHOW_PROFILE_MODEL: return showProfileModel(state, action);
    case actionTypes.SHOW_PASSWORD_RESET_MODAL: return showPasswordResetModal(state, action);
    case actionTypes.CLOSE_PREVIEW: return closePreview(state, action);
    case actionTypes.SET_PREVIEW: return setPreview(state, action);
    case actionTypes.SET_LOADED_FILE: return setLoadedFile(state, action);
    case actionTypes.CLEAR_INSTANCE_DATA: return clearInstanceData(state, action);

    // case actionTypes.UNSET_INSTANCE:
    //   return unsetInstance(state, action);
    // case actionTypes.SET_CURRENT_USER:
    //   return setCurrentUser(state, action);


    default: return state;
  }
};

export default instance;