// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

import { withTranslation } from 'react-i18next'

// Helpers
import { selectWorkspace, selectMission, selectMotion, selectOrg } from '../../store/actions'
import Alerts from './Alerts'

class AlertsHomeContainer extends Component {
  render() {
    return <Alerts {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.firebase.auth,
    activeMissions: ownProps.orgMissions,
    activeMotions: ownProps.orgMotions,
    orgId: ownProps.orgId,
    userOrgs: state.database.userOrgs,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectOrg: (orgId) => dispatch(selectOrg(orgId)),
    selectWorkspace: (workspace) => dispatch(selectWorkspace(workspace)),
    selectMission: (mission) => dispatch(selectMission(mission)),
    selectMotion: (motion) => dispatch(selectMotion(motion)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertsHomeContainer)
