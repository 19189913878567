// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import bizniz from 'bizniz'
import { withTranslation } from 'react-i18next'

// Material UI
import DatePicker from 'material-ui/DatePicker'

//Helpers
import { updateMotionStartDate, updateMotionEndDate, addLog } from '../../utils/graphql-req'

import { MuiThemeProvider, getMuiTheme } from 'material-ui/styles'

const mainMuiTheme = getMuiTheme({
  flatButton: {
    primaryTextColor: '#333541',
  },
  datePicker: {
    selectColor: '#3EC4E5',
    selectTextColor: '#333541',
    headerColor: '#333541',
  },
})

class Timeline extends Component {
  getTimeline = (date1, date2) => {
    const { passedMotion } = this.props
    if (passedMotion.motionStatus === 'complete' || passedMotion.motionStatus === 'cancelled') {
      return <div className="completed-motion-timeline" />
    } else {
      var fractionOfTimelineFilled = 0
      //calculates % of timeline to be filled
      if (date1 !== null && date2 !== null) {
        const startDateTimeline = new Date(date1)
        const endDateTimeline = new Date(date2)
        const todayDateTimeline = new Date(Date.now())

        const daysDiffTimeline = Math.ceil(
          bizniz.weekDaysBetween(startDateTimeline, todayDateTimeline)
        )
        const durationTimeline = Math.ceil(
          bizniz.weekDaysBetween(startDateTimeline, endDateTimeline)
        )
        fractionOfTimelineFilled = daysDiffTimeline / durationTimeline
      }

      const result = []
      //32 segments total
      for (var i = 0; i < 33; i++) {
        result.push(
          <div
            key={i}
            className={
              'time-block-segment ' +
              (i === 0 ? 'left-end ' : '') +
              (i === 32 ? 'right-end ' : '') +
              (fractionOfTimelineFilled > i / 32 ? 'filled' : '')
            }
          />
        )
      }
      return result
    }
  }

  getDateDifference = (date1, date2) => {
    const { passedMotion, t } = this.props
    if (passedMotion.motionStatus === 'complete') {
      return t('timeline.done')
    } else if (passedMotion.motionStatus === 'cancelled') {
      return t('timeline.cancelled')
    } else {
      if (date1 === null || date2 === null) {
        return false
      }
      //calculate # of days left
      const startDate = new Date(date1)
      const endDate = new Date(date2)
      const todayDate = new Date(Date.now())
      const msecondsInDays = 1000 * 60 * 60 * 24
      let days = Math.ceil((endDate - todayDate) / msecondsInDays)
      const daysDiff = Math.ceil(bizniz.weekDaysBetween(todayDate, endDate))

      if (daysDiff === 0) return t('timeline.dueToday')
      else if (daysDiff < 0) return t('timeline.overdue')
      else if (todayDate < startDate) return t('timeline.notStarted')
      else if (daysDiff === 1) return t('timeline.dayLeft')
      else if (daysDiff === null) return false
      else return t('timeline.daysLeft', { number: daysDiff })
    }
  }

  getWorkflowMotions = () => {
    return this.props.activeMissionMotions.sort((a, b) => a.index - b.index)
  }

  getPreviousMotion = () => {
    const { passedMotion, activeMissionMotions } = this.props
    const sortedMotions = Object.values(activeMissionMotions).sort((a, b) => a.index - b.index)
    return sortedMotions[passedMotion.index - 1]
  }

  getMinDate = (isStartDate) => {
    const { passedMotion } = this.props
    const previousMotion = this.getPreviousMotion()
    if (!isStartDate && passedMotion.startDate) {
      if (new Date(passedMotion.startDate) > new Date(passedMotion.endDate)) {
        updateMotionEndDate(passedMotion.id, passedMotion.startDate)
      }
      return new Date(passedMotion.startDate)
    }
    if (previousMotion) {
      if (new Date(previousMotion.endDate) > new Date(passedMotion.startDate)) {
        updateMotionStartDate(passedMotion.id, previousMotion.endDate)
      }
      return previousMotion.endDate && new Date(previousMotion.endDate)
    }
  }

  handleMotionStartDateUpdate = async (n, date) => {
    const { activeMission, activeMotion, userDetails } = this.props

    await updateMotionStartDate(this.props.passedMotion.id, date.toString())

    if (activeMission.status === 'active') {
      var actionUser = userDetails.fullName ? userDetails.fullName : userDetails.email
      var logText = `${actionUser} changed the start date on ${activeMotion.name} motion`

      const log = {
        mission: activeMission.id,
        date: Date().toString(),
        text: logText,
      }

      await addLog(log)
    }
  }

  handleMotionEndDateUpdate = async (n, date) => {
    const { activeMission, activeMotion, userDetails } = this.props

    await updateMotionEndDate(this.props.passedMotion.id, date.toString())

    if (activeMission.status === 'active') {
      var actionUser = userDetails.fullName ? userDetails.fullName : userDetails.email
      var logText = `${actionUser} changed the end date on ${activeMotion.name} motion`

      const log = {
        mission: activeMission.id,
        date: Date().toString(),
        text: logText,
      }

      await addLog(log)
    }
  }

  formatDateWithLocalization = (date) => {
    return `${date.getDate()}/${+date.getMonth() + 1}/${date.getFullYear().toString().substr(2)}`
  }

  render() {
    const { passedMotion, activeMotion, activeMission, auth, activeMissionMotions, t } = this.props

    if (!activeMissionMotions) return null

    const targetMotion = activeMissionMotions.find((motion) => motion.id === passedMotion.id)

    let processedStartDate = null
    if (targetMotion && targetMotion.startDate)
      processedStartDate = new Date(Date.parse(targetMotion.startDate))

    let processedEndDate = null
    if (targetMotion && targetMotion.endDate)
      processedEndDate = new Date(Date.parse(targetMotion.endDate))

    if (!targetMotion || !activeMission) return null

    const active = targetMotion.id === activeMotion.id

    return (
      <div
        className={`microflowBottomContainer w-clearfix 
          ${
            active && targetMotion.document && targetMotion.motionStatus !== 'completed'
              ? 'active'
              : ''
          }`}
      >
        <MuiThemeProvider muiTheme={mainMuiTheme}>
          <DatePicker
            formatDate={this.formatDateWithLocalization}
            firstDayOfWeek={0}
            className={`datePicker ${active && 'active'}`}
            textFieldStyle={{ width: 65, fontSize: 11, lineHeight: '27px', height: 'auto' }}
            autoOk={true}
            hintText={t('common:common.label.startDate')}
            value={processedStartDate}
            onChange={this.handleMotionStartDateUpdate}
            minDate={this.getMinDate(true)}
            disabled={
              !(auth.uid === activeMission.creator) ||
              targetMotion.motionStatus === 'complete' ||
              passedMotion.motionStatus === 'cancelled'
            }
            style={{
              marginRight: '0px',
              padding: '0px 7px',
              borderRadius: '8px',
              backgroundColor: processedStartDate ? '#333541' : 'transparent',
              color: '#fff',
            }}
          />
        </MuiThemeProvider>

        <h3 className="days-left">
          {this.getDateDifference(processedStartDate, processedEndDate)}{' '}
        </h3>

        <MuiThemeProvider muiTheme={mainMuiTheme}>
          <DatePicker
            formatDate={this.formatDateWithLocalization}
            firstDayOfWeek={0}
            className={`datePicker ${active && 'active'}`}
            textFieldStyle={{
              width: 65,
              fontSize: 11,
              lineHeight: '27px',
              height: 'auto',
              color: '#fff',
            }}
            autoOk={true}
            hintText={t('common:common.label.startDate')}
            value={processedEndDate}
            onChange={this.handleMotionEndDateUpdate}
            minDate={this.getMinDate(false)}
            disabled={
              !(auth.uid === activeMission.creator) ||
              targetMotion.motionStatus === 'complete' ||
              targetMotion.motionStatus === 'cancelled'
            }
            style={{
              marginRight: '0px',
              padding: '0px 7px',
              borderRadius: '8px',
              backgroundColor: processedEndDate ? '#333541' : 'transparent',
              color: '#fff',
            }}
          />
        </MuiThemeProvider>

        <div className="timeline-container">
          {this.getTimeline(processedStartDate, processedEndDate)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, passed) => {
  const { workspace } = state.instance

  let activeMissionMotions = []

  if (workspace === 'draft') {
    activeMissionMotions = state.database.orgInstanceDraftMotions[state.instance.mission.id]
  } else if (workspace === 'mission') {
    activeMissionMotions = state.database.orgInstanceActiveMotions[state.instance.mission.id]
  } else if (workspace === 'complete') {
    activeMissionMotions = state.database.orgInstanceCompleteMotions[state.instance.mission.id]
  }

  return {
    auth: state.firebase.auth,
    activeMission: state.instance.mission,
    activeMotion: state.instance.motion,
    userDetails: state.database.userDetails,
    activeMissionMotions,
  }
}

export default connect(mapStateToProps, null)(withTranslation('common')(Timeline))
