import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

// import Dialog from "material-ui/Dialog";
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Snackbar from 'material-ui/Snackbar'

//Custom Material UI Components
import StyledDialogTitle from '../../components/StyledDialogTitle'
import StyledDialogContentText from '../../components/StyledDialogContentText'
import StyledTextField from '../../components/StyledTextField'
import MainButton from '../../components/Buttons/MainButton'
import SecondaryButton from '../../components/Buttons/SecondaryButton'

// Helper
import { updateOrg } from '../../utils/graphql-req'
import { envEndpoint } from '../../utils/firebase-service'

//Medium Modal
import MediumModal from '../../components/Modals/MediumModal'

class Invite extends Component {
  state = {
    inviteEmail: '',
    inviteeName: '',
    errorMessage: '',
    error: false,
    inviteSuccess: false,
    userAlreadyExist: false,
  }

  handleRequestClose = () => {
    this.setState({
      error: false,
      errorMessage: '',
      inviteSuccess: false,
      inviteEmail: '',
      inviteeName: '',
    })
  }

  handleUserExistClose = () => {
    this.setState({
      error: false,
      errorMessage: '',
      userAlreadyExist: false,
    })
  }

  handleAddNewEmail = (event) => {
    this.setState({ inviteEmail: event.target.value.trim() })
  }

  handleAddNewFullName = (event) => {
    this.setState({ inviteeName: event.target.value })
  }

  inviteUser = async () => {
    const { activeOrg, activeMission, activeUser, t } = this.props

    const { inviteEmail, inviteeName } = this.state

    const invitation = {
      invitee: activeUser.fullName ? activeUser.fullName : 'Someone',
      org: { name: activeOrg.name, id: activeOrg.id },
      mission: null,
      invitationDate: Date().toString(),
    }

    if (activeMission) {
      invitation.mission = { name: activeMission.name, id: activeMission.id }
    }

    // let enviornment;
    // switch (process.env.REACT_APP_ENV) {
    //   case "development": {
    //     enviornment = "localhost:3000";
    //     break;
    //   }
    //   case "testing": {
    //     enviornment = "dev";
    //     break;
    //   }
    //   case "production": {
    //     enviornment = "success";
    //     break;
    //   }
    //   default: {
    //     enviornment = "success";
    //   }
    // }

    const inviteDetails = {
      address: inviteEmail,
      invited: inviteeName,
      inviter: activeUser.fullName,
      org: activeOrg.name,
      orgId: activeOrg.id,
    }

    try {
      // handle invite request in the backend
      const response = await fetch(`${envEndpoint}/invites/inviteUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: inviteEmail,
          name: inviteeName,
          inviteDetails: inviteDetails,
        }),
      })

      // close invite modal
      this.props.closeInviteUserDialog()

      if (response.ok && response.status === 200) {
        // handle when request completed successfully
        // pull user data
        const { user } = await response.json()

        // console.log('user in invite', user)
        // console.log('active org in invite', activeOrg)

        // check if user is already part of org, if not, add them
        if (activeOrg && activeOrg.members && !activeOrg.members.includes(user.id)) {
          // update org with newly invited user
          const updatedOrg = _.cloneDeep(activeOrg)
          updatedOrg.members.push(user.id)
          // console.log('org with new member', updatedOrg)
          if (updatedOrg['__typename']) delete updatedOrg['__typename']
          await updateOrg(updatedOrg)

          if (user.placeholder === true) {
            await fetch(`${envEndpoint}/invites/sendInviteNewHF`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                inviteDetails: inviteDetails,
              }),
            })
          } else {
            await fetch(`${envEndpoint}/invites/sendInvite`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                inviteDetails: inviteDetails,
              }),
            })
          }

          // show popup stating user has successfully been invited
          this.setState({ inviteSuccess: true })
        } else {
          // show popup stating user already exist
          this.setState({ error: true, errorMessage: t('invite.error.1') })
        }
      } else {
        // handle when request not completed successfully
        this.setState({ error: true, errorMessage: t('invite.error.2') })
      }
    } catch (err) {
      console.log(err)
      this.setState({ error: true, errorMessage: err })
    }

    // try {
    //     const emailExistResponse = await fetch(`${envEndpoint}/invites/doesEmailExist`, {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //           email: inviteEmail,
    //           name: inviteeName,
    //           invitation
    //         })
    //     })

    //     if (emailExistResponse.status === 422) {
    //         throw new Error('Validation failed.');
    //     }

    //     if (emailExistResponse.status !== 200 && emailExistResponse.status !== 201) {
    //         console.log('Error!');
    //         throw new Error('Something Went Wrong!');
    //     }

    //     // close dialog
    //     this.props.closeInviteUserDialog();

    //     const { user } = await emailExistResponse.json();

    //     //if user or placeholder exists and is in the org
    //     if(user && user.id && orgUsers && orgUsers[user.id]){
    //         //user already exist in org, therefore do not send email invitation
    //         //show popup explaining user is already in the organization
    //         this.setState({ userAlreadyExist: true });

    //         if(user.placeholder){
    //             //user is a placeholder user, so let's resend email to invite actual user
    //             await fetch(`${envEndpoint}/invites/sendInviteNewHF`, {
    //               method: 'POST',
    //               headers: {
    //                 'Content-Type': 'application/json'
    //               },
    //               body: JSON.stringify({
    //                 emailMeta
    //               })
    //             });
    //         }
    //     //if user or placeholder exists but is not in org
    //     } else if(user && user.id) {
    //         //if placeholder
    //         if(user.placeholder){
    //             //user is a placeholder user, send email to invite actual user
    //             await fetch(`${envEndpoint}/invites/sendInviteNewHF`, {
    //               method: 'POST',
    //               headers: {
    //                 'Content-Type': 'application/json'
    //               },
    //               body: JSON.stringify({
    //                 emailMeta
    //               })
    //             });

    //             // add placeholder user to organization
    //             const updatedOrg = _.cloneDeep(activeOrg);
    //             updatedOrg.members.push(user.id)
    //             if(updatedOrg["__typename"]) delete updatedOrg["__typename"]
    //             await updateOrg(updatedOrg);

    //             this.setState({ inviteSuccess: true });
    //         //if actual user
    //         } else {

    //             // check to see if user already has an existing invitation to join the org
    //             if(user && user.invitations && user.invitations.findIndex(invitation => invitation.org.id === activeOrg.id && invitation.mission.id === activeMission.id) !== -1){
    //                 //invitation already exist, therefore DO NOT update DB data (do nothing)
    //                 console.log("User already has existing invitation")
    //             } else {
    //               //invitation does not exist, therefore update DB data
    //               //*************
    //               //The code below when we implement invitation that the recieving user will NOT need to accept
    //               //*************
    //               const updatedOrg = _.cloneDeep(activeOrg);
    //               updatedOrg.members.push(user.id);
    //               delete updatedOrg["__typename"];
    //               await updateOrg(updatedOrg);

    //               this.setState({ inviteSuccess: true });

    //               // Also send and email invitation
    //               await fetch(`${envEndpoint}/invites/sendInvite`, {
    //                 method: 'POST',
    //                 headers: {
    //                   'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify({
    //                   emailMeta
    //                 })
    //               });

    //               //*************
    //               //The code below is for when we want to implement invitation that the recieving user will need to accept
    //               //*************
    //               // await fetch(`${envEndpoint}/invites/inviteUser`, {
    //               //     method: 'POST',
    //               //     headers: {
    //               //         'Content-Type': 'application/json'
    //               //     },
    //               //     body: JSON.stringify({
    //               //         docId: user.id,
    //               //         invitation
    //               //     })
    //               // })
    //             }
    //         }
    //     } else {
    //         console.log("User could not be created")
    //         this.setState({ error: true, errorMessage: "User could not be created"})
    //     }
    // } catch(error) {
    //     console.log(error);
    //     this.setState({ error: true, errorMessage: error})
    // }
  }

  render() {
    const { activeOrg, t } = this.props
    const { inviteEmail } = this.state

    return (
      <div>
        <MediumModal open={this.props.dialogInviteUser}>
          <StyledDialogTitle>
            {t('invite.people.to', { name: activeOrg ? activeOrg.name : 'Organization' })}
          </StyledDialogTitle>
          <DialogContent>
            <StyledDialogContentText>{t('invite.dialog.text')}</StyledDialogContentText>
            <br />
            <StyledTextField
              value={inviteEmail}
              autoFocus={true}
              label={t('common.email')}
              type="text"
              onChange={(event) => this.handleAddNewEmail(event)}
            />
            <StyledTextField
              label={t('common.fullName')}
              type="text"
              maxLength={25}
              onChange={(event) => this.handleAddNewFullName(event)}
            />
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={this.props.closeInviteUserDialog}>
              {t('common.button.cancel')}
            </SecondaryButton>
            <MainButton
              disabled={this.state.inviteEmail === '' || this.state.inviteeName === ''}
              onClick={this.inviteUser}
            >
              {t('invite.button')}
            </MainButton>
          </DialogActions>
        </MediumModal>
        <Snackbar
          open={this.state.error}
          message={this.state.errorMessage}
          autoHideDuration={8000}
          onRequestClose={this.props.handleRequestClose}
          style={{ backgroundColor: 'red', color: 'white' }}
        />
        <Snackbar
          open={this.state.inviteSuccess}
          message={t('invite.message.1')}
          autoHideDuration={8000}
          onRequestClose={this.handleRequestClose}
          style={{ backgroundColor: 'blue', color: 'white' }}
        />
        <Snackbar
          open={this.state.userAlreadyExist}
          message={t('invite.message.2')}
          autoHideDuration={8000}
          onRequestClose={this.handleUserExistClose}
          style={{ backgroundColor: 'blue', color: 'white' }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    activeOrg: state.instance.org,
    activeMission: state.instance.mission,
    activeUser: state.database.userDetails,
    orgUsers: state.database.orgInstanceUsers,
  }
}

export default withRouter(connect(mapStateToProps, null)(withTranslation('common')(Invite)))
