export const getDays = (motionEndDate) => {
  const todayDate = new Date(Date.now())
  const diff = new Date(Date.parse(motionEndDate)) - todayDate
  const date = Math.ceil(diff / (1000 * 60 * 60 * 24))
  return date
}

export const partition = (sortMotions, low, high, motionDates) => {
  let pivot = motionDates[sortMotions[Math.floor((high + low) / 2)].id]
  let i = low
  let j = high
  let swap = ''
  while (true) {
    while (motionDates[sortMotions[i].id] < pivot) i++
    while (motionDates[sortMotions[j].id] > pivot) j--
    if (i >= j) return j
    swap = sortMotions[i]
    sortMotions[i] = sortMotions[j]
    sortMotions[j] = swap
    i++
    j--
  }
}

export const quicksort = (sortMotions, low, high, motionDates) => {
  if (low < high) {
    let part = partition(sortMotions, low, high, motionDates)
    quicksort(sortMotions, low, part, motionDates)
    quicksort(sortMotions, part + 1, high, motionDates)
  }
}
