// Libraries
import React, { Component } from "react";
import { Droppable } from "react-beautiful-dnd";
import { withTranslation } from 'react-i18next';

class AddUserCircle extends Component {
    render(){
        const { active, passedMotion, t } = this.props;
        return (
            <div className={`grid50`} style={{ zIndex: 400, marginLeft: '50px', visibility: active ? "visible" : "hidden" }}>
                <Droppable
                    droppableId={`${passedMotion.id}/null/null/null/null/null`}
                    type="USER" direction="horizontal" 
                    isDropDisabled={false}>
                    {(provided, snapshot) => {
                    return (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                <div style={{marginRight: '50px'}} className={ `avatar__wrapper  ${active ? "" : "nonActive"}`} >
                                    <div className={
                                        `avatar__border smallblue
                                        ${active && "smallwhite "} 
                                        ${active ? snapshot.isDraggingOver ? "avatarDraggingOver " : "avatar " : "" }
                                    `}>
                                        <img className="avatar__img" alt="Avatar" src={require("../../assets/images/user.jpg") } />
                                    </div>
                                </div>
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
                {active && (
                    <div className={`user__text ${active && "active"}`} style={{marginRight: '50px'}}>
                        <div className={`name-text  ${ active ? "active-name" : "" }`} style={{ minWidth: '100px'}}>
                            {t('addUserCircle.drag')}
                        </div>
                        <div className={`title-text ${ active ? "active-title" : "" }`}>
                            {t('addUserCircle.toAdd')}
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default withTranslation('common')(AddUserCircle);