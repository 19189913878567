// Libraries
import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar';

const styles = {
    toolbarlogin: {
      backgroundColor: '#333541',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 2,
      alignItems: 'center',
      height: 56,
      minHeight: 'auto',
    },
  }

class MaintencePage extends Component {

    render() {
        return (
            <div className="body">
                <Toolbar style={styles.toolbarlogin}>
                <img
                    alt="logo"
                    className="logo logologin"
                    src={require('../assets/images/LOGO-white-gold@600x.png')}
                />
                </Toolbar>
                <div className="workspace flex login-body">
                    <div className="login-div">
                        <h1 className="login-h1">Temporarily down for maintenance</h1>
                        We are performing scheduled maintenance, we will be back online shortly.
                        Thank you for your patience.
                        <h1 className="login-h1">Hi-flier ist momentan nicht verfügbar</h1>
                        Wir sind gerade dabei planmäßige Wartung durchzuführen.
                        Vielen Dank für deine Geduld.
                        
                    </div>
                </div>
      
            </div>
          )
    }
}

export default MaintencePage;
